import { useUser } from 'context/userContext';

const useCheckUserRole = (allowed?: string[]) => {
  const { user } = useUser();
  const { currentClinicUserRole } = user;

  const isUserNotAllowed =
    currentClinicUserRole &&
    allowed &&
    !allowed.includes(currentClinicUserRole.role);

  return { isUserNotAllowed };
};

export default useCheckUserRole;

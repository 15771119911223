import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { ClinicUserRole, useUser } from 'context/userContext';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isInAdminPage } from 'utils/common';
import { ROLE } from 'utils/constants';
import {
  loadAuthToken,
  loadRole,
  loadSelectedClinicId,
  saveRole,
  saveSelectedClinicId,
} from 'utils/storage';

const useGetUserProfileFirstRender = () => {
  const location = useLocation();

  const { user, dispatch } = useUser();

  const token = loadAuthToken()?.accessToken;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getUserProfile = async () => {
      const urlSerachParams = new URLSearchParams(location.search);

      if (token && !user.email) {
        try {
          const response = await axiosInstance.get('/clinic-user/auth/profile');

          const sortedClinicUserRoles = sortBy(
            response.data.clinicUserRoles,
            (clinicUserRole) => clinicUserRole.clinic.name
          );

          response.data.clinicUserRoles = sortedClinicUserRoles;

          const paramClinicId = urlSerachParams.get('clinicId');

          if (response.data.clinicUserRoles[0].role === ROLE.GLOBAL_ADMIN) {
            if (isInAdminPage(location)) {
              saveRole(ROLE.GLOBAL_ADMIN);
              saveSelectedClinicId('');
              dispatch({
                type: 'getUserProfile',
                payload: response.data,
              });

              return;
            }
          }

          // CHECK PREVIOUS ROLE
          const isPreviousRolePracticeAdmin =
            loadRole() === ROLE.PRACTICE_ADMIN;

          const currentClinicUserRole: ClinicUserRole =
            response.data.clinicUserRoles.find(
              (clinicUserRole: ClinicUserRole) => {
                const clinicId = paramClinicId
                  ? paramClinicId
                  : loadSelectedClinicId();
                return clinicUserRole.clinic.id === clinicId;
              }
            ) ?? response.data.clinicUserRoles[0];

          saveRole(currentClinicUserRole.role);
          saveSelectedClinicId(currentClinicUserRole.clinic.id);
          dispatch({
            type: 'getUserProfile',
            payload: response.data,
          });

          if (
            isPreviousRolePracticeAdmin &&
            currentClinicUserRole.role === ROLE.PRACTICE_USER
          ) {
            renderToast({
              message:
                "Your user's permissions has been updated. Contact Admin for support",
              type: 'error',
            });
          }
        } catch (error) {}
      }
      setIsLoading(false);
    };

    getUserProfile();
  }, [dispatch, location, location.pathname, token, user.email]);

  return { isLoading: !!token && isLoading };
};

export default useGetUserProfileFirstRender;

import { FC, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import Drawer from 'components/Drawer/Drawer';
import UnitDurationSection from './UnitDurationSection/UnitDurationSection';
import CommonButton from 'components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import useClinic from 'hooks/useClinic';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import ConfirmationUnitDurationModal from './UnitDurationSection/ConfirmationUnitDurationModal/ConfirmationUnitDurationModal';
import TimeIntervalSection from './TimeIntervalSection/TimeIntervalSection';
import SlotIntervalSection from './SlotIntervalSection/SlotIntervalSection';

interface AdvancedSettingDrawerProps {
  isShowing: boolean;
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedSettingDrawer: FC<AdvancedSettingDrawerProps> = ({
  isShowing,
  setIsShowing,
}) => {
  const {
    control,
    reset,
    formState: { isSubmitting, isDirty, dirtyFields },
    watch,
    setValue,
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      unitDuration: 10,
      timeInterval: 0,
      slotInterval: 0,
      input: '',
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading, mutate } = useClinic();

  const onSave = async ({
    unitDuration,
    timeInterval,
    slotInterval,
  }: {
    unitDuration: number;
    timeInterval: number;
    slotInterval: number;
  }) => {
    const promises: Promise<AxiosResponse<any, any>>[] = [];

    if (dirtyFields.timeInterval) {
      promises.push(
        axiosInstance.patch('/clinics/time-interval', { timeInterval })
      );
    }

    if (dirtyFields.unitDuration) {
      promises.push(
        axiosInstance.patch('/clinics/unit-duration', { unitDuration })
      );
    }

    if (dirtyFields.slotInterval) {
      promises.push(
        axiosInstance.patch('/clinics/slot-interval', { slotInterval })
      );
    }

    try {
      await Promise.all(promises);
      renderToast({
        type: 'success',
        message: 'Updated settings successfully',
      });
      reset({ unitDuration, timeInterval, slotInterval });
      await mutate();
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  useEffect(() => {
    if (!data || isLoading) return;

    reset({
      unitDuration: data.unitDuration,
      timeInterval: data.timeInterval,
      slotInterval: data.slotInterval,
    });
  }, [data, isLoading, reset]);

  const handleOnSaveClick = async () => {
    const slotInterval = getValues('slotInterval');
    const timeInterval = getValues('timeInterval');
    const unitDuration = getValues('unitDuration');

    const isOnlySlotIntervalDirty =
      dirtyFields.slotInterval && Object.keys(dirtyFields).length === 1;

    if (!isOnlySlotIntervalDirty) {
      return setIsModalOpen(true);
    }

    try {
      await axiosInstance.patch('/clinics/slot-interval', { slotInterval });
      renderToast({
        type: 'success',
        message: 'Updated settings successfully',
      });

      reset({ slotInterval, timeInterval, unitDuration });
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    return;
  };

  return (
    <>
      {isModalOpen && (
        <ConfirmationUnitDurationModal
          control={control}
          inputValue={watch('input')}
          isSubmitting={isSubmitting}
          onClose={() => setIsModalOpen(false)}
          onUpdate={handleSubmit(onSave)}
        />
      )}
      <Drawer
        title={'Advanced Settings'}
        isShowing={isShowing}
        setIsShowing={setIsShowing}
        footer={
          <div className="w-full h-6 flex px-2.4 justify-end items-center shadow-primary absolute bottom-0">
            <CommonButton
              className="!min-w-[12rem]"
              onClick={handleOnSaveClick}
              isLoading={isSubmitting}
              disabled={!isDirty}
            >
              Save
            </CommonButton>
          </div>
        }
      >
        <UnitDurationSection control={control} />
        <TimeIntervalSection
          control={control}
          unitDuration={watch('unitDuration')}
          timeInterval={watch('timeInterval')}
          setValue={setValue}
        />
        <SlotIntervalSection
          control={control}
          unitDuration={watch('unitDuration')}
          slotInterval={watch('slotInterval')}
          setValue={setValue}
        />
      </Drawer>
    </>
  );
};

export default AdvancedSettingDrawer;

import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import MonthHeader from './MonthHeader/MonthHeader';
import MonthInput from './MonthInput/MonthInput';

import './MonthViewNavigation.module.css';

interface MonthViewNavigationProps {
  date: string | null;
  maxDate?: Date;
  setDate: (date: string) => void;
}

const MonthViewNavigation: FC<MonthViewNavigationProps> = ({
  date,
  maxDate,
  setDate,
}) => {
  const onChangeDate = (date: Date) => {
    const dateString = moment(date).format('YYYY-MM-DD');
    const isSameMonthAndYear =
      moment().isSame(moment(dateString, 'YYYY-MM-DD'), 'month') &&
      moment().isSame(moment(dateString, 'YYYY-MM-DD'), 'year');

    if (isSameMonthAndYear) {
      setDate(moment().format('YYYY-MM-DD'));
      return;
    }

    setDate(
      moment(dateString, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD')
    );
  };

  const onClickNext = () => {
    const nextDate = moment(date, 'YYYY-MM-DD')
      .clone()
      .add(1, 'months')
      .toDate();

    onChangeDate(nextDate);
  };

  const onClickPrevious = () => {
    const previousDate = moment(date, 'YYYY-MM-DD')
      .clone()
      .subtract(1, 'months')
      .toDate();

    onChangeDate(previousDate);
  };

  const startedDateOfMonth = moment().startOf('months').toDate();
  const selectedDate = date ? moment(date, 'YYYY-MM-DD').toDate() : null;

  return (
    <div className="calendar-selector-container self-center">
      <DatePicker
        selected={selectedDate}
        minDate={startedDateOfMonth}
        maxDate={maxDate}
        onChange={onChangeDate}
        dateFormat="MMM yyyy"
        showMonthYearPicker
        renderCustomHeader={MonthHeader}
        customInput={
          <MonthInput
            maxDate={maxDate}
            onClickNext={onClickNext}
            onClickPrevious={onClickPrevious}
          />
        }
      />
    </div>
  );
};

export default MonthViewNavigation;

import { FC } from 'react';
import { useForm } from 'react-hook-form';

import CommonButton from 'components/CommonButton/CommonButton';
import PasswordField from 'components/PasswordField/PasswordField';
import CardContainer from 'components/Layout/CardContainer/CardContainer';

import PasswordGuidance, {
  VALIDATIONS,
} from 'components/PasswordGuidance/PasswordGuidance';

interface PasswordFormProps {
  headerComponent: React.ReactChild;
  onSubmit: (data: { password: string; confirmPassword: string }) => void;
}

const PasswordForm: FC<PasswordFormProps> = ({ onSubmit, headerComponent }) => {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    values: { password: '', confirmPassword: '' },
  });

  return (
    <CardContainer headerComponent={headerComponent}>
      <div className="pt-1.6 px-3 pb-2.4">
        <form
          className="flex flex-col gap-y-1.6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <PasswordField
            displayedRequiredIcon
            control={control}
            id="password"
            placeholder="Input your password"
            rules={{
              required: 'This field is required',
              validate: (value: string) => {
                const isPasswordValid = VALIDATIONS.every(({ pattern }) =>
                  pattern.test(value)
                );
                return isPasswordValid;
              },
            }}
          />
          <PasswordGuidance currentPassword={watch('password')} />
          <PasswordField
            displayedRequiredIcon
            control={control}
            label="Confirm password"
            id="confirmPassword"
            placeholder="Re-enter password"
            rules={{
              required: 'This field is required',
              validate: (value: string) => {
                const password = getValues('password');

                if (value !== password) {
                  return 'Passwords do not match';
                }
              },
            }}
          />
          <div className="mx-auto">
            <CommonButton
              disabled={!isValid}
              isLoading={isSubmitting}
              className="mt-0.8"
              type="submit"
            >
              Submit
            </CommonButton>
          </div>
        </form>
      </div>
    </CardContainer>
  );
};

export default PasswordForm;

import { FC } from 'react';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import CommonButton from 'components/CommonButton/CommonButton';

interface ReloadTabProps {
  onRetryClicked: () => void;
}

const ReloadTab: FC<ReloadTabProps> = ({ onRetryClicked }) => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-16 leading-[2.4rem]">
      <EmptyBucket />
      <span className="mt-2.4">Fail to load the page. Please try again.</span>
      <CommonButton className="mt-1.6 w-20 py-1.2" onClick={onRetryClicked}>
        Retry
      </CommonButton>
    </div>
  );
};

export default ReloadTab;

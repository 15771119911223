import { FC, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import {
  UseFormClearErrors,
  UseFormSetValue,
  ValidateResult,
} from 'react-hook-form';

import TextField from 'components/TextField/TextField';
import { ReactComponent as OpenNewViewIcon } from 'assets/icons/open_in_new.svg';
import { PracticeFormState } from 'interfaces/practiceFormState';

interface GooglePlaceFieldProps {
  control: any;
  defaultValue: string;
  setValue: UseFormSetValue<PracticeFormState>;
  clearErrors: UseFormClearErrors<PracticeFormState>;
}

const streetNumberTypes = ['street_number'];
const routeTypes = ['route'];
const placeCityTypes = ['route', 'locality'];
const placeStateTypes = ['administrative_area_level_1'];
const placeCountryTypes = ['country'];
const placePostalCode = ['postal_code'];

const GooglePlaceField: FC<GooglePlaceFieldProps> = ({
  control,
  defaultValue,
  setValue,
  clearErrors,
}) => {
  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService>();

  const [isChecking, setIsChecking] = useState(false);

  useEffect(() => {
    const divElement = document.createElement('div');

    const setUpGoogleService = async () => {
      if (window.google) {
        return setPlacesService(
          new google.maps.places.PlacesService(divElement)
        );
      }

      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
        libraries: ['places'],
      });

      await loader.load();
      setPlacesService(new google.maps.places.PlacesService(divElement));
    };
    setUpGoogleService();

    return () => {
      divElement.remove();
    };
  }, []);

  const checkPlaceIdValid = async (input: string): Promise<ValidateResult> => {
    return new Promise((res) => {
      placesService?.getDetails({ placeId: input }, async (place, status) => {
        let message = '';
        let country = '';
        let state = '';
        let shortState = '';
        let city = '';
        let addressWithoutCityState = '';
        let address = '';
        let postalCode = '';
        if (status !== 'OK') {
          message = 'Invalid Google Places ID';
        }

        const isPlaceValid = place?.opening_hours?.periods;

        if (place && !isPlaceValid) {
          message =
            'Can not get opening hours with a provided Google Places ID';
        }

        if (isPlaceValid && place?.address_components) {
          const addresses = place.address_components;
          addresses.forEach((address) => {
            // COUNTRY VALUE
            if (
              address.types.find((type) => placeCountryTypes.includes(type))
            ) {
              country = address.long_name;
            }
            // STATE VALUE
            if (address.types.find((type) => placeStateTypes.includes(type))) {
              state = address.long_name;
              shortState = address.short_name;
            }
            // CITY VALUE
            if (address.types.find((type) => placeCityTypes.includes(type))) {
              city = address.long_name;
            }
            // POSTAL CODE VALUE
            if (address.types.find((type) => placePostalCode.includes(type))) {
              postalCode = address.long_name;
            }

            // ADDRESS
            if (
              address.types.find((type) => streetNumberTypes.includes(type))
            ) {
              const streetNumber = address.short_name;

              addressWithoutCityState = `${streetNumber}`;
            }

            if (address.types.find((type) => routeTypes.includes(type))) {
              const route = address.short_name;

              addressWithoutCityState += ` ${route}`;
            }
          });

          if (addressWithoutCityState) {
            address = `${addressWithoutCityState}, ${city}, ${shortState}`;
          } else {
            address = place.formatted_address || '';
          }

          place.international_phone_number &&
            setValue('phoneNumber', place.international_phone_number);
          place.website && setValue('website', place.website);
          place.name && setValue('name', place.name);
        }

        setValue('zip', postalCode);
        setValue('address', address);
        setValue('city', city);
        setValue('state', state);
        setValue('country', country);
        // WILL FORCE RE_RENDER
        setValue('googlePlaceResult', place, { shouldValidate: true });

        clearErrors('address');
        clearErrors('city');
        clearErrors('state');
        clearErrors('country');
        clearErrors('name');
        clearErrors('phoneNumber');

        res(message);
      });
    });
  };

  return (
    <TextField
      id={'googlePlaceId'}
      control={control}
      defaultValue={defaultValue}
      disabled={isChecking}
      rules={{
        validate: {
          validate: async (input) => {
            if (!input) {
              setValue('googlePlaceResult', null);
              return 'This field is required';
            }
            if (input === defaultValue) return;

            setIsChecking(true);

            const message = await checkPlaceIdValid(input);

            setIsChecking(false);

            if (message) {
              return message;
            }

            return true;
          },
        },
      }}
      placeholder="Input Google Places ID"
      navigator={
        <button
          className={`absolute flex h-full items-center bottom-0 right-1 group`}
          onClick={() =>
            window.open(
              'https://developers.google.com/maps/documentation/places/web-service/place-id#find-id',
              '_blank'
            )
          }
        >
          <OpenNewViewIcon className="group-hover:all-child:all-child:fill-magenta" />
        </button>
      }
    />
  );
};

export default GooglePlaceField;

import React from 'react';
import { Menu } from '@headlessui/react';

import { highlightText } from 'utils/common';
import { ClinicUserRole } from 'context/userContext';
import { getLocationName } from './utils';
import Option from './Option';

const ClinicOptions = ({
  clinicUserRoles,
  searchingValue,
  onClick,
}: {
  clinicUserRoles: ClinicUserRole[];
  searchingValue: string;
  onClick: (clinicUserRole: ClinicUserRole) => void;
}) => {
  if (clinicUserRoles.length === 0) return null;

  return (
    <div className="border-t-[0.1rem] border-light-grey w-full py-1.6 overflow-hidden">
      <div className="text-[1rem] font-bold text-mid-grey mb-0.8">
        PRACTICES
      </div>

      <div className="overflow-auto overscroll-contain scrollbar h-full">
        {clinicUserRoles.map((clinicUserRole) => {
          if (!clinicUserRole.clinic) {
            return null;
          }

          const clinicName = clinicUserRole.clinic.name || '';

          const parts = highlightText(clinicName, searchingValue);

          return (
            <Menu.Item
              key={clinicUserRole.clinic.id}
              as="button"
              className="w-full"
              onClick={() => onClick(clinicUserRole)}
            >
              <Option
                option={{
                  avatar: clinicUserRole.clinic.avatar,
                  name: clinicUserRole.clinic.name,
                  description: getLocationName(clinicUserRole.clinic.location),
                }}
                highlightedParts={parts}
              />
            </Menu.Item>
          );
        })}
      </div>
    </div>
  );
};

export default ClinicOptions;

import React from 'react';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { TIMEGRID_VIEW } from 'utils/constants';

interface IProps {
  view: string;
  onChangeView: (view: string) => void;
}

export const VIEW_OPTIONS = [
  { label: 'Practitioner View', value: TIMEGRID_VIEW.PRACTITIONER },
  { label: 'Chair View', value: TIMEGRID_VIEW.CHAIR },
];

const ChartViewDropdown = ({ view, onChangeView }: IProps) => {
  const displayLabel = VIEW_OPTIONS.find(
    (appointmentStatus) => appointmentStatus.value === view
  )!.label;

  return (
    <Listbox
      value={view}
      onChange={(value) => {
        onChangeView(value);
      }}
    >
      {({ open }) => {
        return (
          <div className="relative">
            <Listbox.Button
              className={`flex flex-row items-center justify-center relative w-[15rem] h-[2.4rem] cursor-pointer rounded-[1rem] bg-white py-0.3 px-0.8 border-light-grey text-left shadow-primary border border-transparent hover:border-magenta hover:shadow-elevation-10 outline-none ${
                open ? '!border-magenta !shadow-elevation-10' : ''
              }`}
            >
              <div className="block text-darkest-grey text-12 leading-[12px]">
                {displayLabel}
              </div>
              <div className="pointer-events-none flex items-center pl-[1rem]">
                <DropdownIcon className="w-1 h-1" />
              </div>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-[1rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-10">
              {VIEW_OPTIONS.map(({ label, value }) => (
                <Listbox.Option
                  key={label}
                  className="relative cursor-default select-none pl-1.6"
                  value={value}
                >
                  <span
                    className={`block text-12 cursor-pointer hover:text-magenta ${
                      value === view ? 'text-magenta' : ''
                    }`}
                  >
                    {label}
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        );
      }}
    </Listbox>
  );
};

export default ChartViewDropdown;

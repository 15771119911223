import { useMemo } from 'react';
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useClinicService from 'hooks/useClinicService';

import CommonButton from 'components/CommonButton/CommonButton';
import { renderToast } from 'components/Toast/Toast';
import { SERVICE_NAME } from 'utils/constants';
import VisibilityDropdown from 'pages/ServicesPage/Table/VisibilityDropdown/VisibilityDropdown';
import DurationColumn from 'components/TableColumn/DurationColumn/DurationColumn';
import StatusColumn from 'components/TableColumn/StatusColumn/StatusColumn';

const Table = () => {
  const { data: clinicService, isLoading } = useClinicService({
    sort: 'asc',
    sortBy: 'order',
  });

  const handleCopyLink = async (shortLink: string) => {
    const cb = navigator.clipboard;
    await cb.writeText(shortLink);
    renderToast({
      type: 'success',
      message: 'Service link has been copied to clipboard',
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Service',
        accessor: (field: any) => field.name,
        id: 'serviceName',
        width: 250,
      },
      {
        Header: 'Duration',
        accessor: (field: any) => <DurationColumn field={field} />,
        id: 'duration',
      },
      {
        Header: 'Status',
        accessor: (field: any) => <StatusColumn field={field} />,
        id: 'status',
      },
      {
        Header: 'Visibility',
        accessor: (field: any) => <VisibilityDropdown field={field} />,
        id: 'visibility',
      },
      {
        Header: 'Service Link',
        accessor: (field: any) => {
          const isParentExamCleaningService =
            field.name === SERVICE_NAME.EXAM_CLEANING;

          return (
            <CommonButton
              variant="secondary"
              className="!min-h-[3.2rem]"
              onClick={() => handleCopyLink(field.shortLink)}
            >
              {isParentExamCleaningService ? 'Copy Smart Link' : 'Copy Link'}
            </CommonButton>
          );
        },
        id: 'link',
      },
    ],
    []
  );

  const dataTable = useMemo(() => {
    if (isLoading || !clinicService) {
      return { data: [], metadata: null };
    }

    const activeClinicService = clinicService.data.filter(
      (item) => item.isActive
    );

    const updatedDataTableFormat = activeClinicService.map((item) => ({
      ...item,
      ...(item.childServices.length > 0 && {
        subRows: item.childServices.map((service) => ({
          ...service,
          isChildService: true,
        })),
      }),
    }));

    return {
      data: updatedDataTableFormat,
      metadata: clinicService.metadata,
    };
  }, [clinicService, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data ? dataTable.data : [],
        manualPagination: true,
        pageCount: dataTable.metadata ? clinicService?.metadata.total : 0,
      },
      useSortBy,
      useFlexLayout,
      useExpanded,
      usePagination
    );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey pl-5 hover:bg-lightPink items-center group"
            >
              {row.cells.map((cell) => {
                const isColumnExpendable =
                  cell.column.id === 'serviceName' && row.canExpand;
                const isSubRow = row.depth > 0;

                return (
                  <td
                    {...cell.getCellProps()}
                    className={`text-left p-0 py-1.9
                    ${
                      isColumnExpendable
                        ? 'flex items-center cursor-pointer gap-x-1'
                        : ''
                    }
                    ${
                      isSubRow && cell.column.id === 'serviceName'
                        ? 'translate-x-3'
                        : ''
                    } 
                  `}
                    onClick={() => {
                      if (!isColumnExpendable) return;
                      row.toggleRowExpanded();
                    }}
                  >
                    {isColumnExpendable && (
                      <SortArrow
                        className={`${
                          row.isExpanded ? 'rotate-0' : '-rotate-90'
                        } fill-darkest-grey transition-transform`}
                      />
                    )}
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
      <thead className="font-bold">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className="pl-5">
            {headerGroup.headers.map((column) => {
              return (
                <th
                  {...column.getHeaderProps()}
                  className="text-left p-0 py-1.9"
                >
                  <div className="flex items-center gap-x-0.9">
                    <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                      {column.render('Header')}
                    </span>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      {renderTableBody()}
    </table>
  );
};

export default Table;

import moment from 'moment';
import { convertDurationForFE } from 'utils/convertDuration';

const initialFormat = ['HH:mm:ss', 'HH:mm A'];

const getDoctorInfo = (data: any) => {
  const isRootDoctor = !!data.linkedAppointmentId;

  const doctorName = isRootDoctor
    ? data.doctor.name
    : data.linkedAppointment.doctor.name;

  const startTime = isRootDoctor
    ? moment(data.startTime, initialFormat).format('HH:mm')
    : moment(data.linkedAppointment.startTime, initialFormat).format('HH:mm');

  const duration = isRootDoctor
    ? convertDurationForFE(data.duration)
    : convertDurationForFE(data.linkedAppointment.duration);

  const endTime = isRootDoctor
    ? moment(data.startTime, initialFormat).add(duration, 'm').format('HH:mm')
    : moment(data.linkedAppointment.startTime, initialFormat)
        .add(duration, 'm')
        .format('HH:mm');

  return `${doctorName} (${startTime} - ${endTime})`;
};
const getHygienistInfo = (data: any) => {
  const isRootHygienist = !data.linkedAppointmentId;

  const hygienistName = isRootHygienist
    ? data.doctor.name
    : data.linkedAppointment.doctor.name;

  const startTime = isRootHygienist
    ? moment(data.startTime, initialFormat).format('HH:mm')
    : moment(data.linkedAppointment.startTime, initialFormat).format('HH:mm');

  const duration = isRootHygienist
    ? convertDurationForFE(data.duration)
    : convertDurationForFE(data.linkedAppointment.duration);

  const endTime = isRootHygienist
    ? moment(data.startTime, initialFormat).add(duration, 'm').format('HH:mm')
    : moment(data.linkedAppointment.startTime, initialFormat)
        .add(duration, 'm')
        .format('HH:mm');

  return `${hygienistName} (${startTime} - ${endTime})`;
};

const getIsDoctorStartBeforeHyginiest = (data: any) => {
  const isRootDoctor = !!data.linkedAppointmentId;

  return isRootDoctor
    ? moment(data.startTime, initialFormat).isBefore(
        moment(data.linkedAppointment.startTime, initialFormat)
      )
    : moment(data.linkedAppointment.startTime, initialFormat).isBefore(
        moment(data.startTime, initialFormat)
      );
};

export { getDoctorInfo, getHygienistInfo, getIsDoctorStartBeforeHyginiest };

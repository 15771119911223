import { FC } from 'react';
import { Switch } from '@headlessui/react';
import BlockDropdown from './BlockDropdown/BlockDropdown';

interface OptimizedScheduleToggleProps {
  control: any;
  isEnabled: boolean;
  onChange: () => void;
}

const OptimizedScheduleToggle: FC<OptimizedScheduleToggleProps> = ({
  control,

  isEnabled,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-y-2.1">
      <div className="flex gap-x-1.6 items-center">
        <Switch
          checked={isEnabled}
          onChange={onChange}
          className={`${
            isEnabled ? 'bg-magenta' : 'bg-[#78788029]'
          } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
        >
          <span
            className={`${
              isEnabled ? 'translate-x-[2.3rem]' : 'translate-x-[2px]'
            } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
          />
        </Switch>
        <span className="uppercase">{isEnabled ? 'On' : 'Off'}</span>
      </div>
      {isEnabled && <BlockDropdown control={control} />}
    </div>
  );
};

export default OptimizedScheduleToggle;

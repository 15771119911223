import Table from './Table/Table';
import CommonButton from 'components/CommonButton/CommonButton';
import { useState } from 'react';
import AdvancedTrackingDrawer from './AdvancedTrackingDrawer/AdvancedTrackingDrawer';
import WidgetSection from './WidgetSection/WidgetSection';

const OnlineSchedulerPage = () => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <div className="pl-3 pr-4 py-2.4 text-14 leading-[2.1rem]">
      <AdvancedTrackingDrawer
        isShowing={isShowing}
        setIsShowing={setIsShowing}
      />

      <section className="flex justify-between ">
        <h3 className="text-24 leading-[3.6rem] font-bold">Online Scheduler</h3>

        <CommonButton
          variant="secondary"
          className="w-18"
          onClick={() => setIsShowing(true)}
        >
          Advanced
        </CommonButton>
      </section>

      <WidgetSection />

      <section className="py-2.4">
        <p className="font-bold">Service Links</p>
        <div className="bg-white shadow-primary rounded-[1rem] mt-1.6">
          <Table />
        </div>
      </section>
    </div>
  );
};

export default OnlineSchedulerPage;

import React, { Dispatch, useRef, useState } from 'react';

import Item from './Item/Item';

import { IPractitioner } from 'interfaces/practitioners';
import LeavePageModal from 'components/LeavePageModal/LeavePageModal';
import {
  getIsPageDirty,
  loadPractitionerSidebarState,
  savePractitionerSidebarState,
} from 'utils/storage';
import SidePanel from 'components/SidePanel/SidePanel';
import { PRACTITIONER_FILTER_OPTIONS } from 'utils/constants';
import CreatePractitionerModal from '../CreatePractitionerModal/CreatePractitionerModal';

const { ALL, AVAILABLE } = PRACTITIONER_FILTER_OPTIONS;

interface IProps {
  practitioners: IPractitioner[];
  selectedPractitioner: IPractitioner;
  setSelectedPractitioner: Dispatch<React.SetStateAction<IPractitioner | null>>;
  totalPractitionerByStatus: {
    available: number;
    unavailable: number;
    all: number;
  } | null;
}

const PractitionerPanel = ({
  practitioners,
  setSelectedPractitioner,
  selectedPractitioner,
  totalPractitionerByStatus,
}: IProps) => {
  const [selectedFilter, setSelectedFilter] = useState(
    totalPractitionerByStatus?.available === 0 ? ALL : AVAILABLE
  );
  const [isCollapsed, setIsCollapsed] = useState(() =>
    loadPractitionerSidebarState()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isCreatedPractitionerModalOpen, setIsCreatedPractitionerModalOpen] =
    useState(false);

  const cachedData = useRef<{
    selectedFilter: string | null;
    selectedPractitioner: IPractitioner | null;
  }>({
    selectedFilter: null,
    selectedPractitioner: null,
  });

  const getFilterPractitioner = (filterOption: string) => {
    const filterPractitioners =
      filterOption !== ALL
        ? practitioners.filter((practitioner) => {
            if (filterOption === AVAILABLE) {
              return practitioner.isActive;
            }
            return !practitioner.isActive;
          })
        : practitioners;
    return filterPractitioners;
  };

  const filterPractitioners = getFilterPractitioner(selectedFilter);

  const handleSelectFilter = (option: string) => {
    const firstPractitioner = getFilterPractitioner(option)[0];

    const isSamePractitioner = firstPractitioner.id === selectedPractitioner.id;

    if (!isSamePractitioner && getIsPageDirty()) {
      setIsModalOpen(true);
      cachedData.current = {
        selectedPractitioner: firstPractitioner,
        selectedFilter: option,
      };

      return;
    }

    setSelectedFilter(option);
    setSelectedPractitioner(firstPractitioner);
  };

  const isOptionDropdownDisabled = (option: string) => {
    return (
      totalPractitionerByStatus?.[
        option.toLowerCase() as keyof typeof totalPractitionerByStatus
      ] === 0
    );
  };

  const onSelectPractitioner = (item: IPractitioner) => {
    if (getIsPageDirty()) {
      cachedData.current.selectedPractitioner = item;
      return setIsModalOpen(true);
    }

    setSelectedPractitioner(item);
  };

  const handleOnSubmit = () => {
    setSelectedPractitioner(cachedData.current.selectedPractitioner);
    if (cachedData.current.selectedFilter) {
      setSelectedFilter(cachedData.current.selectedFilter);
    }
    cachedData.current = {
      selectedFilter: null,
      selectedPractitioner: null,
    };
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <LeavePageModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleOnSubmit}
        />
      )}
      {isCreatedPractitionerModalOpen && (
        <CreatePractitionerModal
          onClose={() => setIsCreatedPractitionerModalOpen(false)}
          setSelectedPractitioner={setSelectedPractitioner}
        />
      )}
      <SidePanel
        title="Practitioners"
        isCollapsed={isCollapsed}
        onCollapsedIconClicked={() => {
          savePractitionerSidebarState(!isCollapsed);
          setIsCollapsed(!isCollapsed);
        }}
        selectedFilter={selectedFilter}
        onFilterOptionClicked={(option) => handleSelectFilter(option)}
        isOptionDropdownDisabled={isOptionDropdownDisabled}
        numberOfFilters={filterPractitioners.length}
        options={PRACTITIONER_FILTER_OPTIONS}
        onAddClick={() => setIsCreatedPractitionerModalOpen(true)}
      >
        {filterPractitioners.map((item: IPractitioner) => (
          <Item
            key={item.id}
            practitioner={item}
            onClick={() => onSelectPractitioner(item)}
            selectedPractitionerId={selectedPractitioner?.id}
            isCollapsed={isCollapsed}
          />
        ))}
      </SidePanel>
    </>
  );
};

export default PractitionerPanel;

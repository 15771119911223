import React, { FC } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

interface AppointRowProps {
  title: string;
  value?: number | string;
  isBold?: boolean;
  isPaddingIncluded?: boolean;
  isLoading?: boolean;
}

const AppointRow: FC<AppointRowProps> = ({
  title,
  value,
  isBold,
  isPaddingIncluded,
  isLoading,
}) => {
  return (
    <div
      className={`flex justify-between items-center ${
        isPaddingIncluded && 'pl-3'
      }`}
    >
      <span>{title}</span>
      {isLoading ? (
        <LoadingSpinner className="text-magenta" />
      ) : (
        <span className={`text-magenta ${isBold && 'font-bold'}`}>{value}</span>
      )}
    </div>
  );
};

export default AppointRow;

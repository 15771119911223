import { FC } from 'react';
import { convertDurationForFE } from 'utils/convertDuration';

interface DurationColumnProps {
  field: any;
}

const DurationColumn: FC<DurationColumnProps> = ({ field }) => {
  if (field.isSplitScheduling) {
    return (
      <span>
        {convertDurationForFE(field.doctorDuration + field.hygienistDuration)}{' '}
        minutes
      </span>
    );
  }

  return <span>{convertDurationForFE(field.duration)} minutes</span>;
};

export default DurationColumn;

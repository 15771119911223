import { FC } from 'react';

import AvatarPlaceHolder from 'assets/images/no-avatar.svg';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { IMapPractitionerColor } from '../utils';
import getPractitionerName from 'utils/getPractitionerName';

interface PractitionerListProps {
  selectedPractitioner: IMapPractitionerColor | null;
  onChangePractitioner: (
    selectedPractitioner: IMapPractitionerColor | null
  ) => void;
  practitioners?: IMapPractitionerColor[];
  isLoading: boolean;
}

const PractitionerList: FC<PractitionerListProps> = ({
  selectedPractitioner,
  practitioners,
  isLoading,
  onChangePractitioner,
}) => {
  if (isLoading) {
    return (
      <div className="h-full flex justify-center items-center">
        <LoadingSpinner className="all-child:fill-magenta" />
      </div>
    );
  }

  return (
    <div className="flex flex-col p-0.8">
      {practitioners!.map((practitioner, index) => (
        <button
          key={`${practitioner.id}-${index}`}
          className={`flex py-1.3 pl-2.4 gap-x-1.6 items-center rounded-[6px] ${
            selectedPractitioner?.id === practitioner.id
              ? 'bg-magenta-lightest font-bold'
              : ''
          }`}
          onClick={() => {
            if (
              selectedPractitioner === null ||
              selectedPractitioner.id !== practitioner.id
            ) {
              onChangePractitioner(practitioner);
              return;
            }

            if (
              selectedPractitioner &&
              selectedPractitioner.id === practitioner.id
            ) {
              onChangePractitioner(null);
            }
          }}
        >
          <img
            className="rounded-full object-cover w-2.4 h-2.4 aspect-square self-start outline"
            src={practitioner.avatar || AvatarPlaceHolder}
            alt="avatar"
            width={24}
            height={24}
            style={{ outlineColor: practitioner.colorCode }}
          />
          <span className="text-left">{getPractitionerName(practitioner)}</span>
        </button>
      ))}
    </div>
  );
};

export default PractitionerList;

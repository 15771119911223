import React, { FC, useEffect, useState } from 'react';
import PhoneInputCode, { CountryData } from 'react-phone-input-2';
import {
  CountryCode,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { Controller } from 'react-hook-form';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import 'react-phone-input-2/lib/style.css';
import styles from './PhoneField.module.css';
import {
  formatNationalPhoneNumber,
  geCountryByPhoneNumber,
  getAllowedCountry,
} from 'utils/formatPhoneNumber';
import { DEFAULT_COUNTRY_CODE } from 'utils/constants';

interface PhoneFieldProps {
  control: any;
  phoneNumber: string;
}

const PhoneField: FC<PhoneFieldProps> = ({ control, phoneNumber }) => {
  const [tmpPhoneNumber, setTmpPhoneNumber] = useState(phoneNumber);
  const [countryCode, setCountryCode] = useState(
    phoneNumber ? geCountryByPhoneNumber(phoneNumber) : DEFAULT_COUNTRY_CODE
  );

  useEffect(() => {
    if (phoneNumber && countryCode) {
      const formattedValue = formatNationalPhoneNumber(
        phoneNumber,
        countryCode.toUpperCase() as CountryCode
      );
      setTmpPhoneNumber(formattedValue);
    }
  }, [countryCode, phoneNumber]);

  return (
    <Controller
      control={control}
      name="phoneNumber"
      rules={{
        required: 'This field is required',
        validate: (value: string) => {
          const code = countryCode?.toUpperCase() as CountryCode;
          if (!isValidPhoneNumber(value, code)) {
            return 'Invalid phone number';
          }
        },
      }}
      render={({ field, fieldState }) => {
        const { onChange, ...rest } = field;
        return (
          <div className={styles.container}>
            <div className={styles['input-wrapper']}>
              <div className={styles.divider}></div>
              <PhoneInputCode
                onlyCountries={getAllowedCountry()}
                enableSearch
                inputProps={{
                  ...rest,
                  autoComplete: 'tel',
                  value: tmpPhoneNumber,
                  onChange: (event: any) => {
                    let lastLetter =
                      event.target.value[event.target.value.length - 1];

                    if (!/\d/.test(lastLetter)) {
                      const removed = event.target.value.slice(
                        0,
                        event.target.value.length - 1
                      );
                      setTmpPhoneNumber(removed);
                      return;
                    }

                    if (event.target.value[0] === '+') {
                      const rawValue = event.target.value;
                      const phoneNumber = parsePhoneNumber(event.target.value);

                      if (phoneNumber) {
                        const formattedValue = phoneNumber.formatNational();
                        const fullPhoneNumber = rawValue.replace(/\D/g, '');
                        onChange(fullPhoneNumber);
                        setTmpPhoneNumber(formattedValue);
                        setCountryCode(phoneNumber.country!);
                      }
                    } else {
                      const rawValue = event.target.value;

                      if (tmpPhoneNumber.length > event.target.value.length) {
                        // Deleting
                        const currentLastLetter =
                          tmpPhoneNumber[tmpPhoneNumber.length - 1];
                        if (!/\d/.test(currentLastLetter)) {
                          setTmpPhoneNumber(event.target.value);
                          return;
                        }
                      }

                      const formattedValue = formatNationalPhoneNumber(
                        rawValue,
                        countryCode.toUpperCase() as CountryCode
                      );

                      const callingCode = getCountryCallingCode(
                        countryCode.toUpperCase() as CountryCode
                      );

                      const phoneWithoutCountryCode = formattedValue.replace(
                        /\D/g,
                        ''
                      );
                      const fullPhoneNumber = `${callingCode}${phoneWithoutCountryCode}`;

                      onChange(fullPhoneNumber);
                      setTmpPhoneNumber(formattedValue);
                    }
                  },
                }}
                disableCountryGuess
                countryCodeEditable
                disableCountryCode
                placeholder="Input Phone Number"
                country={countryCode}
                preferredCountries={[DEFAULT_COUNTRY_CODE]}
                containerClass={styles.container}
                inputClass={`${styles.input} ${
                  fieldState.error?.message ? styles.error : ''
                }`}
                searchClass={styles.search}
                buttonClass={styles.button}
                onChange={(value, countryData: CountryData) => {
                  if (countryData.countryCode !== countryCode) {
                    setTmpPhoneNumber('');
                    onChange('');
                    setCountryCode(countryData.countryCode);

                    return;
                  }
                }}
              />
            </div>
            {fieldState.error?.message && (
              <div className={`flex items-center gap-x-1 mt-0.8`}>
                <WarningIcon />
                <span className="text-12 text-red-01">
                  {fieldState.error?.message}
                </span>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default PhoneField;

import { FC } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import TextField from 'components/TextField/TextField';
import { useUser } from 'context/userContext';
import useModal from 'hooks/useModal';

interface ConfirmationUnitDurationModalProps {
  control: any;
  inputValue: string;
  isSubmitting: boolean;
  onClose: () => void;
  onUpdate: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
}

const ConfirmationUnitDurationModal: FC<ConfirmationUnitDurationModalProps> = ({
  control,
  inputValue,
  isSubmitting,
  onClose,
  onUpdate,
}) => {
  const {
    user: { globalAdminRole },
  } = useUser();

  const isGlobalAdmin = !!globalAdminRole;
  const { setIsAllowed } = useModal({ onClose });

  const handleOnUpdate = async () => {
    setIsAllowed(false);
    await onUpdate();
    setIsAllowed(false);
  };

  return (
    <DefaultModal>
      <div className="max-w-[48rem] rounded-[1rem] p-2 text-[1.4rem] leading-[2.1rem] text-darkest-grey">
        <h3 className="text-[2rem] leading-[3rem] font-bold">
          Update {isGlobalAdmin ? 'Settings' : 'FirstIn Schedule Time Interval'}
        </h3>
        <p className="my-1.6">
          Are you sure you want to update the{' '}
          {isGlobalAdmin ? 'settings' : 'FirstIn schedule time interval'}? By
          doing so, you may need to update your Office Hours, Practitioner Hours
          and Service Durations.
          <br />
          Please type CONFIRM below to proceed.
        </p>
        <TextField id={'input'} control={control} placeholder="CONFIRM" />

        <div className="flex justify-end gap-x-1.6 mt-2.4">
          <CommonButton
            variant="secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </CommonButton>
          <CommonButton
            disabled={inputValue !== 'CONFIRM'}
            onClick={handleOnUpdate}
            isLoading={isSubmitting}
          >
            Update
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default ConfirmationUnitDurationModal;

import { FC, useMemo, useState } from 'react';
import { capitalize, isEqual } from 'lodash';
import { useForm } from 'react-hook-form';

import ProfileInfo from 'components/ProfileInfo/ProfileInfo';
import useBeforeUnloaded from 'hooks/useBeforeUnloaded';
import axiosInstance from 'apis/axiosInstance';

import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { ReactComponent as DiscardIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EditPencilIcon } from 'assets/icons/edit.svg';
import NoAvatarPractitioner from 'assets/images/no-avatar.svg';
import NavigationBlocker from 'pages/OfficeSchedulePage/NavigationBlocker/NavigationBlocker';
import { IPractitioner } from 'interfaces/practitioners';
import CommonButton from 'components/CommonButton/CommonButton';
import TextField from 'components/TextField/TextField';
import { PMS_TYPE, PRACTITIONER_FILTER_OPTIONS, ROLE } from 'utils/constants';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { loadAuthToken, loadRole, loadSelectedClinicId } from 'utils/storage';
import OperatoryDropdown from './OperatoryDropdown/OperatoryDropdown';
import ServiceDropdown from './ServiceDropdown/ServiceDropdown';
import StudyDropdown from './StudyDropdown/StudyDropdown';
import ProviderIdDropdown from './ProviderIdDropdown/ProviderIdDropdown';
import GenderDropdown from './GenderDropdown/GenderDropdown';
import TitleDropdown from './TitleDropdown/TitleDropdown';
import SpecialtyDropdown from './SpecialtyDropdown/SpecialtyDropdown';
import OptimizedScheduleToggle from './OptimizedScheduleToggle/OptimizedScheduleToggle';
import { BAD_REQUEST } from 'utils/constants/statusCode';

const { AVAILABLE, UNAVAILABLE } = PRACTITIONER_FILTER_OPTIONS;
interface PractitionerProfileProps {
  selectedPractitioner: IPractitioner;
  setSelectedPractitioner: React.Dispatch<
    React.SetStateAction<IPractitioner | null>
  >;
}

const PractitionerProfile: FC<PractitionerProfileProps> = ({
  selectedPractitioner,
  setSelectedPractitioner,
}) => {
  const matchMutate = useMatchMutate();

  const [isEdit, setIsEdit] = useState(false);

  const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [isEnabled, setIsEnabled] = useState(() =>
    Boolean(selectedPractitioner.optimizeSchedule)
  );

  const initialValue = useMemo(() => {
    return {
      isActive: `${selectedPractitioner.isActive}`,
      externalId: selectedPractitioner.externalId,
      firstName: selectedPractitioner.firstName,
      lastName: selectedPractitioner.lastName,
      gender: selectedPractitioner.gender,
      specialties: selectedPractitioner.specialties?.map((item) => {
        return { value: item.id, label: item.name };
      }),
      title: selectedPractitioner.title || '',
      studies: selectedPractitioner.studies.map((item) => {
        return { value: item.id, label: item.name };
      }),
      services: selectedPractitioner.services.map((item) => {
        return { value: item.id, label: item.name };
      }),
      operatories: selectedPractitioner.operatories.map((item) => {
        return { value: item.id, label: item.name };
      }),
      bio: selectedPractitioner.bio,
      avatar: selectedPractitioner.avatar,
      uploadedFile: null,
      optimizeSchedule: selectedPractitioner.optimizeSchedule,
    };
  }, [selectedPractitioner]);

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    getValues,
    formState,
    handleSubmit,
  } = useForm<any>({
    mode: 'all',
    values: initialValue,
  });

  const handleUploadImage = async () => {
    const accessToken = loadAuthToken()?.accessToken;
    const uploadedFile = getValues('uploadedFile');
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);
    const response = await axiosInstance.post(
      `/upload/avatar/practitioners?id=${selectedPractitioner.id}`,
      formData,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          ...(loadRole() === ROLE.PRACTICE_ADMIN
            ? {
                'X-ClinicId': loadSelectedClinicId(),
              }
            : {}),
        },
      }
    );
    return response.data.url;
  };

  const onSubmit = async () => {
    try {
      const clinicId = loadSelectedClinicId();
      const accessToken = loadAuthToken()?.accessToken;

      const url = await handleUploadImage();

      const formData = getValues();

      const imageUrl = url ?? formData.avatar;

      const normalizedPractitionerPayload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        title: formData.title,
        clinic: {
          clinicId,
          externalDoctorId: formData.externalId,
          pmsType: PMS_TYPE.NEX_HEALTH,
        },
        avatar: imageUrl,
        bio: formData.bio,
        isActive: formData.isActive === 'true',
        services: formData.services.map((item: any) => ({
          clinicId,
          serviceId: item.value,
        })),
        studyIds: formData.studies.map((item: any) => item.value),
        operatoryIds: formData.operatories.map((item: any) => item.value),
        specialtyIds: formData.specialties.map((item: any) => item.value),
        optimizeSchedule: formData.optimizeSchedule,
      };

      await axiosInstance.put(
        `/practitioners/${selectedPractitioner.id}`,
        normalizedPractitionerPayload,
        {
          headers: {
            Authorization: `JWT ${accessToken}`,
            'X-ClinicId': clinicId,
          },
        }
      );

      renderToast({
        type: 'success',
        message: `Practitioner's profile has been updated successfully`,
      });

      const pattern = new RegExp(`clinics/${clinicId}/practitioners\\?`);
      const [, mutateResponse] = await matchMutate(pattern);

      const updatedSelectedPractitioner = mutateResponse.data.find(
        (item: IPractitioner) => item.id === selectedPractitioner.id
      );

      setSelectedPractitioner(updatedSelectedPractitioner);
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }
      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  const handleDeletePractitioner = async () => {
    try {
      const clinicId = loadSelectedClinicId();
      const accessToken = loadAuthToken()?.accessToken;

      await axiosInstance.delete(`/practitioners/${selectedPractitioner.id}`, {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'X-ClinicId': clinicId,
        },
      });

      const pattern = new RegExp(`clinics/${clinicId}/practitioners\\?`);
      const mutateResponse = await matchMutate(pattern);

      const updatedPractitioners = mutateResponse[1].data;

      setSelectedPractitioner(
        updatedPractitioners.length > 0 ? updatedPractitioners[0] : null
      );

      renderToast({
        message: 'Successfully delete practitioner',
        type: 'success',
      });
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }
      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  const onChangeOptimizedSchedule = () => {
    const isUpdatedValueEnabled = !isEnabled;
    if (!isUpdatedValueEnabled) {
      setValue('optimizeSchedule', null);
    } else {
      setValue('optimizeSchedule', initialValue.optimizeSchedule);
    }
    setIsEnabled(isUpdatedValueEnabled);
  };

  const isDataChanged = !isEqual(watch(), initialValue);

  useBeforeUnloaded({ when: isDataChanged });

  return (
    <>
      <NavigationBlocker when={isDataChanged} />

      {isOpenDiscardModal && (
        <ConfirmationModal
          title={'Discard changes'}
          description={
            'Are you sure you want to discard all changes? This action cannot be undone.'
          }
          onClose={() => setIsOpenDiscardModal(false)}
          onSubmit={async () => {
            setIsEdit(false);
            reset(initialValue);
            setIsOpenDiscardModal(false);
            setIsEnabled(Boolean(initialValue.optimizeSchedule));
          }}
          submitBtnTitle="Yes, discard"
        />
      )}

      {isOpenDeleteModal && (
        <ConfirmationModal
          title={'Delete this practitioner?'}
          description={
            'Are you sure you want to delete this practitioner? All appointments related to them will be canceled. This action cannot be undone.'
          }
          onClose={() => setIsOpenDeleteModal(false)}
          onSubmit={handleDeletePractitioner}
          submitBtnTitle="Yes, delete"
        />
      )}

      <div className="flex mt-5.7 pb-5 relative mb-6">
        <button
          className={`absolute -top-10 right-0 w-6.4 h-6.4 rounded-full bg-magenta-lightest flex items-center justify-center ${
            isEdit ? 'hidden' : ''
          }`}
          onClick={() => setIsEdit(true)}
        >
          <EditPencilIcon className="all-child:all-child:fill-magenta" />
        </button>

        <div className="flex flex-1 flex-col gap-y-2">
          <ProfileInfo
            title={'Online Scheduler Status'}
            data={selectedPractitioner.isActive ? AVAILABLE : UNAVAILABLE}
            isEdit={isEdit}
          >
            <div className="flex gap-x-8">
              <div className="flex items-center gap-x-0.8">
                <input
                  className="w-2.4 h-2.4"
                  type="radio"
                  id="radioActive"
                  value={'true'}
                  defaultChecked={selectedPractitioner.isActive}
                  {...register('isActive')}
                />
                <label htmlFor="radioActive">Active</label>
              </div>
              <div className="flex items-center gap-x-0.8">
                <input
                  className="w-2.4 h-2.4"
                  type="radio"
                  id="radioInactive"
                  value={'false'}
                  defaultChecked={!selectedPractitioner.isActive}
                  {...register('isActive')}
                />
                <label htmlFor="radioInactive">Inactive</label>
              </div>
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Practitioner ID'}
            data={selectedPractitioner.externalId}
            isEdit={isEdit}
          >
            <div className="min-w-[38rem]">
              {isEdit ? (
                <TextField
                  disabled
                  id="externalId"
                  control={control}
                  defaultValue={getValues('externalId')}
                />
              ) : (
                <ProviderIdDropdown
                  control={control}
                  rules={{
                    required: 'This field is required',
                  }}
                  disabled={!!watch('externalId')}
                />
              )}
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Title'}
            data={getValues('title') || ''}
            isEdit={isEdit}
            isOptional
          >
            <div className="min-w-[38rem]">
              <TitleDropdown control={control} />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'First Name'}
            data={selectedPractitioner.firstName}
            isEdit={isEdit}
          >
            <div className="min-w-[38rem]">
              <TextField
                placeholder="Input First Name"
                id={'firstName'}
                control={control}
                defaultValue={getValues('firstName')}
                rules={{
                  required: 'This field is required',
                }}
              />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Last Name'}
            data={selectedPractitioner.lastName}
            isEdit={isEdit}
          >
            <div className="min-w-[38rem]">
              <TextField
                placeholder="Input Last Name"
                id={'lastName'}
                control={control}
                defaultValue={getValues('lastName')}
                rules={{
                  required: 'This field is required',
                }}
              />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Gender'}
            data={capitalize(selectedPractitioner.gender)}
            isEdit={isEdit}
          >
            <div className="min-w-[38rem]">
              <GenderDropdown
                control={control}
                rules={{
                  required: 'This field is required',
                }}
              />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Suffix'}
            data={(getValues('studies') || [])
              .map((item: any) => item.label)
              .join(', ')}
            isEdit={isEdit}
            isOptional
          >
            <div className="min-w-[38rem]">
              <StudyDropdown control={control} />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Specialty'}
            data={getValues('specialties')
              ?.map((item: any) => item.label)
              .join(', ')}
            isEdit={isEdit}
          >
            <div className="min-w-[38rem]">
              <SpecialtyDropdown
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                specialties={watch('specialties')}
              />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title="Services"
            data={(getValues('services') || [])
              .map((item: any) => item.label)
              .join(', ')}
            isEdit={isEdit}
            isOptional
          >
            <div className="min-w-[38rem]">
              <ServiceDropdown control={control} />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title="Chairs"
            data={(getValues('operatories') || [])
              .map((item: any) => item.label)
              .join(', ')}
            isEdit={isEdit}
            isOptional
          >
            <div className="min-w-[38rem]">
              <OperatoryDropdown control={control} />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title="Optimize Schedule"
            data={
              getValues('optimizeSchedule')
                ? `±${getValues('optimizeSchedule')} Appts available`
                : 'Off'
            }
            isEdit={isEdit}
            isOptional
            isOptimizeScheduleField
            isTooltipDisplayed={isEnabled}
          >
            <div className="min-w-[38rem]">
              <OptimizedScheduleToggle
                control={control}
                onChange={onChangeOptimizedSchedule}
                isEnabled={isEnabled}
              />
            </div>
          </ProfileInfo>

          <ProfileInfo
            title={'Bio'}
            data={getValues('bio')}
            isEdit={isEdit}
            isOptional
          >
            <div className="h-[23.7rem] min-w-[38rem] relative">
              <textarea
                placeholder="Input Bio"
                className="w-full resize-none h-full text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active focus-visible:border-magenta focus-visible:shadow-input-active scrollbar !outline-none !ring-transparent"
                defaultValue={getValues('bio')}
                {...register('bio')}
              />
            </div>
          </ProfileInfo>
        </div>

        <div className="">
          <div className="basis-[20rem]">
            <ImageUpload
              key={`${isEdit}`}
              avatar={watch('avatar')}
              isEdit={isEdit}
              onRemoveAvatar={() => {
                setValue('avatar', '');
                setValue('uploadedFile', null);
              }}
              setValue={setValue}
              avatarPlaceholder={NoAvatarPractitioner}
            />
          </div>
        </div>
      </div>

      {isEdit && (
        <div className="absolute bottom-0 -ml-4 w-full bg-white shadow-elevation-07 h-6 px-4 flex justify-between items-center">
          <button
            className="text-secondary-red flex items-center gap-x-0.8"
            onClick={() => setIsOpenDeleteModal(true)}
          >
            <DiscardIcon className="w-2 h-2 all-child:fill-secondary-red" />
            <span className="font-bold">Delete</span>
          </button>
          <div className="flex gap-x-1">
            <CommonButton
              variant="secondary"
              onClick={() => {
                setIsOpenDiscardModal(true);
              }}
            >
              Discard
            </CommonButton>
            <CommonButton
              isLoading={formState.isSubmitting}
              disabled={!isDataChanged}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </CommonButton>
          </div>
        </div>
      )}
    </>
  );
};

export default PractitionerProfile;

import { FC } from 'react';
import DatePicker, {
  ReactDatePicker,
  ReactDatePickerProps,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import './CustomDatePicker.module.css';

interface CustomDatePickerProps extends ReactDatePickerProps {
  calendarRef?: React.RefObject<ReactDatePicker<never, undefined>>;
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  calendarRef,
  ...props
}) => {
  return (
    <div className="day-selector-container">
      <DatePicker {...props} ref={calendarRef} />
    </div>
  );
};

export default CustomDatePicker;

import { FC, useState } from 'react';
import { Menu } from '@headlessui/react';
import moment from 'moment';
import useClinic from 'hooks/useClinic';
import DefaultModal from 'components/Modals/DefaultModal';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import AvatarPlaceHolder from 'assets/icons/avatar-empty-circle.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import CommonButton from 'components/CommonButton/CommonButton';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
import CountDown from 'components/CountDown/CountDown';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import useModal from 'hooks/useModal';

interface EmailTemplateProps {
  activePatientAmount: number;
  inactivePatientAmount: number;
  lastSyncAt: null | string;
  onClose: () => void;
  onSendMail: (patientStatus: string[]) => Promise<void>;
}

const EmailTemplate: FC<EmailTemplateProps> = ({
  activePatientAmount,
  inactivePatientAmount,
  lastSyncAt,
  onClose,
  onSendMail,
}) => {
  const { data, isLoading } = useClinic();
  const { setIsAllowed } = useModal({ onClose, isLoading });

  const [initialOptions] = useState([
    {
      label: `Active Patients in PMS (${activePatientAmount})`,
      value: 'active',
    },
    {
      label: `Active & Inactive Patients in PMS (${
        activePatientAmount + inactivePatientAmount
      })`,
      value: 'active&inactive',
    },
  ]);

  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>(initialOptions[0]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const onSend = () => {
    setIsAllowed(false);
    if (selectedOption.value === 'active') {
      onSendMail(['active']);
    } else if (selectedOption.value === 'active&inactive') {
      onSendMail(['active', 'inactive']);
    }
    setIsAllowed(true);
  };

  const isLessThan24HourFromLastSync =
    moment().diff(moment(lastSyncAt), 'hours') < 24;

  const disabledSendBtn =
    (selectedOption.value === 'active' && activePatientAmount === 0) ||
    (selectedOption.value === 'active&inactive' &&
      activePatientAmount === 0 &&
      inactivePatientAmount === 0) ||
    isLessThan24HourFromLastSync;

  return (
    <DefaultModal>
      <div className="w-88.2 flex flex-col h-[calc(100vh-10rem)] max-h-90">
        <div className="flex justify-between items-center h-6.7 px-2.4">
          <h3 className="font-bold text-20 leading-[3rem]">
            Email blast review
          </h3>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="flex-1 bg-[#D9D9D9] overflow-auto pb-3">
          <div className="w-65 flex flex-col items-center border-t-[1rem] border-magenta mx-auto mt-1.5 bg-white text-14 leading-[16.1px]">
            <img
              className="w-8 h-8 rounded-full mt-3"
              src={data.avatar || AvatarPlaceHolder}
              alt="Practice Avatar"
            />
            <h3 className="font-hel text-36 leading-[41.4px] text-center max-w-[45.1rem] mt-3">
              You Can Now
              <br />
              Schedule Online!
            </h3>

            <p className="font-hel mt-3">
              Schedule directly online at your convenience—anytime, any place.
            </p>

            <div className="w-36.4 h-22 bg-[#D9D9D9] mt-2.4 bg-onlineIntro bg-center rounded-[10px] bg-cover" />

            <CommonButton className="w-34 mt-3 font-hel">
              Schedule Online Today
            </CommonButton>

            <div className="mt-6 w-full">
              <div className="w-full min-h-[15.5rem] pt-3 pb-2.5 px-4 bg-email-template-gradient text-white flex flex-col justify-center items-center">
                <div className="text-center">
                  <h3 className="font-bold font-hel">{data.name}</h3>
                  <div className="mt-1 leading-[16.8px] text-12">
                    <p className="font-hel">
                      {data.address}, {data.zip}
                    </p>
                    <p className="font-hel">
                      {formatFullPhoneNumber(`+${data.phoneNumber}`)}
                    </p>
                    <p className="font-hel">{data.email}</p>
                  </div>
                </div>
                <div className="mt-6">
                  <span className="font-hel text-12 text-second-lightest-grey">
                    &copy; FirstIn {moment().format('YYYY')} | Unsubscribe
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-[6.6rem] px-2 flex items-center justify-between">
          <Menu as="div">
            <Menu.Button className="font-bold text-14 text-magenta flex items-center relative">
              <div>Send to {selectedOption.label}</div>
              <ArrowDown className="activeIcon ml-0.8" />
            </Menu.Button>

            <Menu.Items className="absolute z-[9999] bg-white -bottom-7 w-30 py-1.6 pl-2 rounded-[10px] shadow-elevation-07 flex flex-col gap-1.6 text-14">
              {initialOptions.map((option) => {
                return (
                  <Menu.Item key={option.value}>
                    {({ active }) => (
                      <div
                        className={`cursor-pointer ${
                          active || selectedOption.value === option.value
                            ? 'text-magenta'
                            : ''
                        }`}
                        onClick={() => setSelectedOption(option)}
                      >
                        {option.label}
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Menu>

          <div className="flex gap-x-1.6">
            <CommonButton variant="secondary" onClick={onClose}>
              Cancel
            </CommonButton>
            <CommonButton onClick={onSend} disabled={disabledSendBtn}>
              <>
                {lastSyncAt && isLessThan24HourFromLastSync ? (
                  <>
                    <span>Send</span>&nbsp;(
                    <CountDown
                      dateTime={moment(lastSyncAt).add(1, 'days').format()}
                    />
                    )
                  </>
                ) : (
                  <span>Send</span>
                )}
              </>
            </CommonButton>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

export default EmailTemplate;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { ClinicSchedule } from 'interfaces/clinicSchedule';

const useClinicSchedule = () => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    ['/clinics-schedule', clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<ClinicSchedule>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicSchedule;

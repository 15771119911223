import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import useAppointmentDetail from 'hooks/useAppointmentDetail';

import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import { ROUTES } from 'utils/constants/routes';
import Toast, { renderToast } from 'components/Toast/Toast';
import toast from 'react-hot-toast';
import { clearHistoryLocation } from 'utils/storage';
import AppointmentDetailModal from 'components/AppointmentDetailModal/AppointmentDetailModal';
import { getErrorReason } from 'utils/common';

interface ErrorNavigationProps {
  appointmentId: string;
}

const ErrorNavigation: FC<ErrorNavigationProps> = ({ appointmentId }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading } = useAppointmentDetail(appointmentId);

  const isClickOnSumbit = useRef(false);

  const errorReason = data && getErrorReason(data);

  const handleCloseModal = () => {
    clearHistoryLocation();
    if (!isClickOnSumbit.current) {
      return navigate(ROUTES.APPOINTMENT, { replace: true });
    }
    setIsModalOpen(false);
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{data?.patient.name}</strong> has been resolved.
        Please accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  useEffect(() => {
    if (!data) return;

    const isAppointmentNewStatus = data.status === APPOINTMENT_STATUS.NEW.KEY;

    if (!errorReason || !isAppointmentNewStatus) {
      renderToast({
        message:
          'The appointment error was either already resolved or does not exist',
        type: 'error',
      });
      navigate(ROUTES.APPOINTMENT, { replace: true });
      return;
    }

    setIsModalOpen(true);
  }, [data, errorReason, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      {isModalOpen && errorReason === ERROR_REASON.WRITE_FAIL && (
        <AppointmentDetailModal
          appointmentId={appointmentId!}
          onClose={handleCloseModal}
        />
      )}

      {isModalOpen && errorReason === ERROR_REASON.DUPLICATE_BOOKING && (
        <DuplicateAppointment
          id={appointmentId!}
          mode={ERROR_REASON.DUPLICATE_BOOKING}
          onClose={handleCloseModal}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}

      {isModalOpen && errorReason === ERROR_REASON.PARTIAL_PATIENT_MATCH && (
        <PartialPatient
          id={appointmentId!}
          onClose={handleCloseModal}
          onErrorPersist={(message) => {
            toast((t) => {
              t.duration = Infinity;
              return (
                <Toast
                  id={t.id}
                  message={
                    <>
                      This partial patient match error for{' '}
                      <strong>${data?.patient?.name}</strong> has been resolved.{' '}
                      {message}.
                    </>
                  }
                  type={'warning'}
                  isButtonIncluded
                  buttonTitle="Resolve Now"
                  onButtonClicked={() => {
                    setIsModalOpen(true);
                  }}
                />
              );
            });
          }}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
        />
      )}
    </>
  );
};

export default ErrorNavigation;

import { FC, useMemo, Fragment, useState } from 'react';
import moment from 'moment';

import { ReactComponent as AddIcon } from 'assets/icons/add_circle_outline.svg';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useClinicCustomDay from 'hooks/useClinicCustomDay';
import { ExceptionDate } from 'interfaces/exceptionDate';
import CustomDayItem from './CustomDayItem/CustomDayItem';
import EditScheduleModal from '../EditScheduleModal/EditScheduleModal';
import { DEFAULT_WORKING_HOUR, NEXT_23_MONTH } from 'utils/constants';
import normalizeCustomDay from 'utils/normalizeCustomDay';
import useScrollToMonthCalendar from 'hooks/useScrollToMonthCalendar';

interface CustomDaysListProps {
  monthValue: string;
  setDate: (date: string) => void;
  isNavigatedByCalendar: boolean;
  source: string;
  version: number;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
}

const CustomDaysList: FC<CustomDaysListProps> = ({
  monthValue,
  setDate,
  isNavigatedByCalendar,
  source,
  onCheckOutsideOfficeHourAppts,
  version,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading } = useClinicCustomDay({
    shouldRun: true,
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(NEXT_23_MONTH, 'months').format('YYYY-MM-DD'),
    source,
  });

  const normalizeData = useMemo(() => normalizeCustomDay(data), [data]);

  const { register } = useScrollToMonthCalendar({
    isNavigatedByCalendar,
    monthValue,
    data,
  });

  const handleOnRowClick = (exceptionDate: ExceptionDate) => {
    setDate(exceptionDate.date);
  };

  return (
    <>
      {isModalOpen && (
        <EditScheduleModal
          source={source}
          selectedDate={{
            date: moment().format('YYYY-MM-DD'),
            day: moment().format('ddd'),
            isException: false,
            workingHour: [
              {
                start: DEFAULT_WORKING_HOUR.START,
                end: DEFAULT_WORKING_HOUR.END,
              },
            ],
          }}
          onClose={() => setIsModalOpen(false)}
          onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
          isCreatedMode
          version={version}
        />
      )}
      <div className="w-full border border-t-0 border-light-grey">
        {isLoading ? (
          <div className="flex w-full h-[43.4rem] justify-center items-center">
            <LoadingSpinner className="all-child:fill-magenta" />
          </div>
        ) : (
          <div className="relative">
            <div className="h-[39.4rem] overflow-y-auto scrollbar pb-1.6">
              {normalizeData.map((data) => (
                <Fragment key={data.monthlyTitle}>
                  <div
                    {...register(data.monthlyTitle)}
                    className="h-4.6 flex items-center px-2 bg-lightest-grey font-bold"
                  >
                    {data.monthlyTitle}
                  </div>
                  {data.days.map((day) => (
                    <CustomDayItem
                      key={day.id}
                      day={day}
                      onClick={handleOnRowClick}
                      source={source}
                      onCheckOutsideOfficeHourAppts={
                        onCheckOutsideOfficeHourAppts
                      }
                      version={version}
                    />
                  ))}
                </Fragment>
              ))}
            </div>
            <button
              className="w-full h-4 flex bg-white justify-center border-t border-light-grey items-center text-magenta gap-x-1 z-0"
              onClick={() => setIsModalOpen(true)}
            >
              <AddIcon className="w-2 h-2 all-child:all-child:fill-magenta" />
              <span className="font-bold">Add custom day</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDaysList;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { IAppointmentDetail } from 'interfaces/appointments';
import { loadSelectedClinicId } from 'utils/storage';

const useOutsidePractitionerHour = (practitionerId: string) => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR<IAppointmentDetail[]>(
    [
      `/appointments/out-practitioner-hours?doctorId=${practitionerId}`,
      clinicId,
    ],
    async (url) => {
      const response = await axiosInstance(url);

      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useOutsidePractitionerHour;

import React, { FC, useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ERROR_REASON, STATUS } from 'utils/constants';
import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { toast } from 'react-hot-toast';
import Toast, { renderToast } from 'components/Toast/Toast';
import moment from 'moment';
import { getErrorReason } from 'utils/common';

interface ThreeDotsOptionProps {
  id: string;
  options: string[];
  errorReason: string;
  patientName: string;
  onClickAccept: () => void;
  onClickReject: () => void;
  updateDashboardUI: () => void;
}

const {
  DUPLICATE_BOOKING,
  OUT_OF_OFFICE_HOURS,
  OUT_OF_PRACTITIONER_HOURS,
  PARTIAL_PATIENT_MATCH,
  GENERAL_ERROR,
  PATIENT_NEED_ADD_TO_FAMILY,
  WRITE_FAIL,
  NON_SPLIT_TO_SPLIT_SCHEDULING,
} = ERROR_REASON;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  id,
  options,
  errorReason,
  patientName,
  onClickAccept,
  onClickReject,
  updateDashboardUI,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isDuplicatedApptModalOpen, setIsDuplicatedApptModalOpen] =
    useState(false);

  // Service is updated after resolving partial patient match
  const [updatedBookingInfo, setUpdatedBookingInfo] = useState<{
    service: {
      id: string;
      name: string;
      duration: number;
    };
    patientId: string;
  } | null>(null);

  const [isChecking, setIsChecking] = useState(false);

  const { data, isLoading, mutate } = useAppointmentDetail(id, isChecking);

  useEffect(() => {
    if (isLoading || !isChecking) return;

    const isErrorReasonExisted = data ? !!getErrorReason(data) : false;

    setIsChecking(false);

    if (isErrorReasonExisted) return setIsModalOpen(true);

    updateDashboardUI();
  }, [isLoading, isChecking, updateDashboardUI, data]);

  const handleClickOpen = async () => {
    // FORCE HOOK TO RUN BEFORE MODAL OPEN
    await mutate(null, { revalidate: true });
    setIsChecking(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (isDuplicatedApptModalOpen) {
      setIsDuplicatedApptModalOpen(false);
    }
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{patientName}</strong> has been resolved. Please
        accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  const getErrorDropdownOption = () => {
    if (
      errorReason === DUPLICATE_BOOKING ||
      errorReason === OUT_OF_OFFICE_HOURS ||
      errorReason === OUT_OF_PRACTITIONER_HOURS
    ) {
      return 'Modify Appointment';
    }
    if (errorReason === PARTIAL_PATIENT_MATCH) {
      return 'Select matching patient';
    }
  };

  const handleOptionClicked = async ({
    option,
    isFirstOption,
  }: {
    option: string;
    isFirstOption: boolean;
  }) => {
    if (isFirstOption && errorReason) {
      return await handleClickOpen();
    }
    const isAccepted = option === STATUS.ACCEPT;
    isAccepted ? onClickAccept() : onClickReject();
  };

  const handleNonSplitToSplitScheduling = (
    bookingInfo: {
      service: { name: string; id: string; duration: number };
      patientId: string;
    } | null
  ) => {
    setIsDuplicatedApptModalOpen(true);
    setUpdatedBookingInfo(bookingInfo);
  };

  const isErrorSelectAnotherTimeSlot = [
    DUPLICATE_BOOKING,
    OUT_OF_OFFICE_HOURS,
    OUT_OF_PRACTITIONER_HOURS,
  ].includes(errorReason);

  const isInTodayTab = options.length === 1;

  const isDropdownDisabled = isInTodayTab && !errorReason;

  const isAppointmentInThePast = moment(
    data?.appointmentDate,
    'YYYY-MM-DD'
  ).isBefore(moment());

  const renderActionOptions = () => {
    if (errorReason === GENERAL_ERROR || errorReason === WRITE_FAIL) {
      return (
        <>
          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickAccept}
          >
            Accept
          </Popover.Button>

          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickReject}
          >
            Reject
          </Popover.Button>
        </>
      );
    }

    if (errorReason === PATIENT_NEED_ADD_TO_FAMILY) {
      return (
        <Popover.Button
          className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
          onClick={onClickReject}
        >
          Reject
        </Popover.Button>
      );
    }

    return options.map((option, index) => (
      <Popover.Button
        key={option}
        className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
        onClick={() =>
          handleOptionClicked({ option, isFirstOption: index === 0 })
        }
      >
        {index === 0 && errorReason ? getErrorDropdownOption() : option}
      </Popover.Button>
    ));
  };

  return (
    <>
      {isChecking && <LoadingScreen />}
      {((isModalOpen && isErrorSelectAnotherTimeSlot) ||
        isDuplicatedApptModalOpen) && (
        <DuplicateAppointment
          id={id}
          mode={errorReason}
          onClose={handleCloseModal}
          updatedBookingInfo={updatedBookingInfo}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}
      {isModalOpen &&
        [PARTIAL_PATIENT_MATCH, NON_SPLIT_TO_SPLIT_SCHEDULING].includes(
          errorReason
        ) && (
          <PartialPatient
            id={id}
            onClose={handleCloseModal}
            onHandleNonSplitToSplitScheduling={handleNonSplitToSplitScheduling}
            onErrorPersist={(message: string) => {
              toast((t) => {
                t.duration = Infinity;
                return (
                  <Toast
                    id={t.id}
                    message={
                      <>
                        This partial patient match error for{' '}
                        <strong>{patientName}</strong> has been resolved.{' '}
                        {message}
                      </>
                    }
                    type={'warning'}
                    isButtonIncluded
                    buttonTitle="Resolve Now"
                    onButtonClicked={() => {
                      setIsModalOpen(true);
                    }}
                  />
                );
              });
            }}
            onAppointmentAlreadyResolved={() =>
              handleAppointmentAlreadyResolved(false)
            }
            onClickAccept={() => {
              onClickAccept();
              handleCloseModal();
            }}
          />
        )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button
          className="flex items-center disabled:cursor-not-allowed disabled:opacity-30"
          disabled={isDropdownDisabled}
        >
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel
          className={`flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 ${
            errorReason &&
            ![GENERAL_ERROR, PATIENT_NEED_ADD_TO_FAMILY, WRITE_FAIL].includes(
              errorReason
            ) &&
            !isAppointmentInThePast
              ? 'w-21'
              : 'w-16.5'
          }`}
        >
          {renderActionOptions()}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;

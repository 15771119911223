import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as PaginateArrow } from 'assets/icons/paginate_arrow.svg';
import { ReactComponent as DropDownArrow } from 'assets/icons/dropdown_arrow.svg';

export interface ITablePaginate {
  perPage: number;
  currPage: number;
  total: number;
}

interface IPaginate {
  handlePaginating?: (paginate: ITablePaginate) => void;
  data?: ITablePaginate;
  isBorderNotIncluded?: boolean;
}

interface IPaginateData {
  start: number;
  end: number;
  totalPage: number;
  hasNext: boolean;
  hasPrev: boolean;
}

interface IState {
  prevPage: number | null;
  activePage: number;
  limit: number;
  isSelectingLimit: boolean;
  paginateX: string | null;
  paginateY: string | null;
}

const Pagination = (props: IPaginate) => {
  const { handlePaginating, isBorderNotIncluded = true } = props;
  const { currPage, total, perPage } = props.data!;
  const wrapperRef = useRef<any>();

  const [state, setState] = useState<IState>({
    prevPage: null,
    activePage: currPage,
    limit: perPage,
    isSelectingLimit: false,
    paginateX: null,
    paginateY: null,
  });
  const {
    prevPage,
    activePage,
    limit,
    isSelectingLimit,
    paginateX,
    paginateY,
  } = state;

  const onPaginationClick = (page: number) => {
    setState((prevState: IState) => ({
      ...prevState,
      prevPage: prevState.activePage,
      activePage: page,
    }));
  };

  const onNextPageClick = () => {
    setState((prevState: IState) => ({
      ...prevState,
      prevPage: prevState.activePage,
      activePage: prevState.activePage + 1,
    }));
  };

  const onPrevPageClick = () => {
    setState((prevState: IState) => ({
      ...prevState,
      prevPage: prevState.activePage,
      activePage: prevState.activePage - 1,
    }));
  };

  const toggleSelectingLimit = (event: any) => {
    const isAbleRender =
      document.body.clientHeight - event.target.getBoundingClientRect().y > 150;

    setState((prevState: IState) => ({
      ...prevState,
      isSelectingLimit: !prevState.isSelectingLimit,
      paginateX: isAbleRender ? 'top-3' : '-top-[100px]',
      paginateY: isAbleRender ? 'left-0' : 'left-0',
    }));
  };

  const onSelectedValue = (value: number) => {
    setState((prevState: IState) => ({
      ...prevState,
      isSelectingLimit: false,
      limit: value,
    }));
  };

  useEffect(() => {
    if (prevPage || limit !== perPage) {
      handlePaginating!({
        currPage: limit !== perPage ? 1 : activePage,
        perPage: limit,
        total,
      });
    }
  }, [prevPage, activePage, handlePaginating, currPage, total, perPage, limit]);

  useEffect(() => {
    const handleOutsieClick = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setState((prevState: IState) => ({
          ...prevState,
          isSelectingLimit: false,
        }));
      }
    };

    window.addEventListener('mousedown', handleOutsieClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsieClick);
    };
  }, [wrapperRef]);

  const calculatePagination = (params: ITablePaginate): IPaginateData => {
    const { total, perPage, currPage } = params;
    const totalPage =
      total % perPage === 0
        ? total / perPage
        : parseInt(`${total / perPage}`, 10) + 1;

    const data = {
      totalPage,
      start: (currPage - 1) * perPage + 1,
      end: currPage !== totalPage ? currPage * perPage : total,
      hasNext: currPage !== totalPage,
      hasPrev: currPage !== 1 || activePage !== 1,
    };

    return data;
  };

  const paginate = calculatePagination({
    currPage: activePage,
    total,
    perPage,
  });

  return (
    <div
      className={`flex items-center justify-end text-15 pr-2.4 ${
        !isBorderNotIncluded && 'border-b border-solid border-[#E8E8E8]'
      } text-[#373737] h-full `}
    >
      <div
        ref={wrapperRef}
        className="flex items-center justify-center mt-0.3 min-w-[165px]"
      >
        <div>Row per page:</div>
        <div className="flex items-center relative cursor-pointer">
          <div className="ml-1 pr-1 w-2.5" onClick={toggleSelectingLimit}>
            {limit}
          </div>
          <DropDownArrow
            className={`cursor-pointer h-0.8 ${
              isSelectingLimit && 'rotate-180'
            }`}
            onClick={toggleSelectingLimit}
          />
          {isSelectingLimit && (
            <div
              className={`absolute w-[165px] text-right shadow-2xl rounded-lg bg-white text-14 ${paginateX} ${paginateY}`}
            >
              <div
                className="flex items-center justify-start hover:bg-[#B01BAE] hover:bg-opacity-10 hover:text-magenta w-full h-3 px-2"
                onClick={() => onSelectedValue(5)}
              >
                5 rows
              </div>
              <div
                className="flex items-center justify-start hover:bg-[#B01BAE] hover:bg-opacity-10 hover:text-magenta w-full h-3 px-2"
                onClick={() => onSelectedValue(10)}
              >
                10 rows
              </div>
              <div
                className="flex items-center justify-start hover:bg-[#B01BAE] hover:bg-opacity-10 hover:text-magenta w-full h-3 px-2"
                onClick={() => onSelectedValue(25)}
              >
                25 rows
              </div>
              <div
                className="flex items-center justify-start hover:bg-[#B01BAE] hover:bg-opacity-10 hover:text-magenta w-full h-3 px-2"
                onClick={() => onSelectedValue(50)}
              >
                50 rows
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center ml-3 mr-7.5 mt-0.3 w-[120px]">
        <div>{paginate.start}</div>
        <div className="mx-[5px]">-</div>
        <div>{paginate.end}</div>
        <div className="mx-[5px]">of</div>
        <div>{total}</div>
      </div>

      <div className="flex items-center">
        <div
          className={`flex items-center mr-3 ${
            paginate.hasPrev
              ? 'cursor-pointer text-magenta'
              : 'cursor-not-allowed pointer-events-none'
          }`}
          onClick={onPrevPageClick}
        >
          <PaginateArrow
            className="rotate-180"
            style={{ fill: paginate.hasPrev ? '#b01bae' : '#373737' }}
          />
          <span className="mt-0.3 pl-2">PREVIOUS</span>
        </div>

        {paginate.totalPage > 5 && (
          <div className="flex items-center mr-[5px]">
            <div
              className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 cursor-pointer ${
                activePage === 1 && 'text-magenta pointer-events-none'
              }`}
              onClick={() => onPaginationClick(1)}
            >
              1
            </div>

            <div
              className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 cursor-pointer ${
                activePage === 2 && 'text-magenta pointer-events-none'
              }`}
              onClick={() => onPaginationClick(2)}
            >
              2
            </div>

            <div
              className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 pointer-events-none ${
                activePage > 2 &&
                activePage < paginate.totalPage - 1 &&
                'text-magenta'
              }`}
              onClick={() => onPaginationClick(activePage + 1)}
            >
              {activePage >= 3 && activePage < paginate.totalPage - 1
                ? activePage
                : '...'}
            </div>

            <div
              className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 pointer-events-none ${
                activePage === paginate.totalPage - 1 && 'text-magenta'
              }`}
              onClick={() => onPaginationClick(activePage + 1)}
            >
              {activePage === paginate.totalPage - 1 ? activePage : '...'}
            </div>

            <div
              className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 cursor-pointer ${
                activePage === paginate.totalPage &&
                'text-magenta pointer-events-none'
              }`}
              onClick={() => onPaginationClick(paginate.totalPage)}
            >
              {paginate.totalPage}
            </div>
          </div>
        )}

        {paginate.totalPage <= 5 &&
          Array.from(Array(paginate.totalPage).keys()).map((page) => {
            return (
              <div
                key={page}
                className={`flex items-center w-[20px] p-0.5 mr-3 mt-0.3 cursor-pointer ${
                  activePage === page + 1 && 'text-magenta pointer-events-none'
                }`}
                onClick={() => onPaginationClick(page + 1)}
              >
                {page + 1}
              </div>
            );
          })}

        <div
          className={`flex items-center ${
            paginate.hasNext
              ? 'cursor-pointer text-magenta'
              : 'cursor-not-allowed pointer-events-none'
          }`}
          onClick={onNextPageClick}
        >
          <span className="mt-0.3 pr-2">NEXT</span>
          <PaginateArrow
            style={{ fill: paginate.hasNext ? '#b01bae' : '#373737' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;

import { WorkingHour } from 'interfaces/clinicSchedule';
import { DEFAULT_WORKING_HOUR } from './constants';
import { Option } from 'components/SelectType/SelectType';

const getInitialType = (
  workingHour: string | WorkingHour[],
  options: Option[]
) => {
  const [workingDayOption, workingOffOption] = options;
  if (typeof workingHour === 'string') {
    return workingOffOption;
  }
  const { start, end } = workingHour[0];
  const isWorkingDay = start && end;

  return isWorkingDay ? workingDayOption : workingOffOption;
};

const getInitialWorkingHours = (workingHour: string | WorkingHour[]) => {
  const defaultWorkingHour = {
    start: DEFAULT_WORKING_HOUR.START,
    end: DEFAULT_WORKING_HOUR.END,
  };

  if (typeof workingHour === 'string') {
    return defaultWorkingHour;
  }
  const { start, end } = workingHour[0];
  const isWorkingDay = start && end;

  return isWorkingDay ? { start, end } : defaultWorkingHour;
};

export { getInitialType, getInitialWorkingHours };

import React, { FC } from 'react';

import { renderToast } from 'components/Toast/Toast';
import CommonButton from 'components/CommonButton/CommonButton';

interface ScriptSnippetProps {
  isLoading: boolean;
  url: string;
  dataApp: string;
}

const ScriptSnippet: FC<ScriptSnippetProps> = ({ isLoading, url, dataApp }) => {
  const onHandleCopy = async () => {
    const cb = navigator.clipboard;
    const content = `<script src="${url}" data-app="${dataApp}" defer></script>`;

    await cb.writeText(content);
    renderToast({ message: 'Copied successfully', type: 'success' });
  };

  return (
    <CommonButton
      variant="secondary"
      className="!min-h-[3.2rem]"
      isLoading={isLoading}
      onClick={onHandleCopy}
    >
      Copy Script
    </CommonButton>
  );
};

export default ScriptSnippet;

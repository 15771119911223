import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

interface IUseClinicDayOffParams {
  startDate: string;
  endDate: string;
  isClosed: boolean;
}

const useClinicDateOff = ({
  startDate,
  endDate,
  isClosed,
}: IUseClinicDayOffParams) => {
  const query = new URLSearchParams({
    startDate,
    endDate,
    isClosed: `${isClosed}`,
  }).toString();

  const clinicId = loadSelectedClinicId();
  const url = `/clinics-schedule/working-hours?${query}`;

  const { data, error, mutate } = useSWR<{ date: string }[]>(
    [url, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicDateOff;

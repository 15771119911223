import { useState } from 'react';
import ClinicFilter from './ClinicFilter/ClinicFilter';
import Table from './Table/Table';
import AppointmentStatus from 'components/AppontmentStatus/AppointmentStatus';
import { IAppointmentStatus } from 'hooks/useAppointment';
import {
  CALENDAR_TYPE,
  getDateRangeByCalendarType,
} from 'utils/getDateRangeByCalendarType';
import AppointmentDateRange from 'components/AppointmentDateRange/AppointmentDateRange';
import CSVExport from './CSVExport/CSVExport';
import CSVScheduleInsights from './CSVScheduleInsights/CSVScheduleInsights';

const defaultTableSortState = {
  sortBy: 'appointmentDate',
  sort: 'desc' as const,
};

const AdminAppointmentPage = () => {
  const [selectedClinicId, setSelectedClinicId] = useState<string | null>(null);

  const [selectedStatus, setSelectedStatus] =
    useState<IAppointmentStatus>('new');

  const [selectedDateRange, setSelectedDateRange] = useState<{
    fromDate: string;
    toDate: string;
  }>(
    () =>
      getDateRangeByCalendarType(CALENDAR_TYPE.THIS_MONTH) as {
        fromDate: string;
        toDate: string;
      }
  );

  const [sort, setSort] = useState<'asc' | 'desc'>(defaultTableSortState.sort);

  const [sortBy, setSortBy] = useState(defaultTableSortState.sortBy);

  const onChangeDateRange = (dateRange: {
    fromDate: string;
    toDate: string;
  }) => {
    setSelectedDateRange(dateRange);
  };

  return (
    <div className="pt-2.4 pl-1.8 pr-4 text-14 leading-[2.1rem] text-darkest-grey pb-12">
      <div className="flex flex-row justify-between">
        <h3 className="text-24 font-bold leading-[3.6rem]">Appointments</h3>
        <div className="flex flex-row gap-x-[1.6rem]">
          <CSVScheduleInsights
            appointmentStatus={selectedStatus}
            clinicId={selectedClinicId}
            dateRange={selectedDateRange}
            sort={sort}
            sortBy={sortBy}
          />
          <CSVExport
            appointmentStatus={selectedStatus}
            clinicId={selectedClinicId}
            dateRange={selectedDateRange}
            sort={sort}
            sortBy={sortBy}
          />
        </div>
      </div>

      <div className="my-2.4 flex gap-x-1.6 items-center">
        <AppointmentStatus
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
        <ClinicFilter
          clinicId={selectedClinicId}
          onSelect={(clinicId: string | null) => setSelectedClinicId(clinicId)}
        />

        <div className="flex-1 text-right">
          <AppointmentDateRange
            clinicId={selectedClinicId}
            dateRange={selectedDateRange}
            onChangeDateRange={onChangeDateRange}
          />
        </div>
      </div>

      <div className="bg-white shadow-primary rounded-[1rem]">
        <Table
          key={`${selectedStatus}${selectedDateRange.fromDate}${selectedDateRange.toDate}${selectedClinicId}`}
          clinicId={selectedClinicId}
          status={selectedStatus}
          dateRange={selectedDateRange}
          sort={sort}
          setSort={setSort}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </div>
    </div>
  );
};

export default AdminAppointmentPage;

import { useUser } from 'context/userContext';
import { ROLE } from 'utils/constants';
import GlobalAdminSync from './GlobalAdminSync/GlobalAdminSync';
import PracticeAdminSync from './PracticeAdminSync/PracticeAdminSync';

const SyncPage = () => {
  const {
    user: { currentClinicUserRole },
  } = useUser();

  return (
    <div className="py-2.4 pl-3 pr-4 text-14 leading-[2.1rem]">
      <h3 className="text-24 font-bold leading-[3.6rem]">Sync</h3>
      {currentClinicUserRole?.role === ROLE.GLOBAL_ADMIN && <GlobalAdminSync />}
      {currentClinicUserRole?.role === ROLE.PRACTICE_ADMIN && (
        <PracticeAdminSync />
      )}
    </div>
  );
};

export default SyncPage;

import { FC } from 'react';
import { getDentistWorkingHour } from 'utils/getDentistWorkingHour';
import getPractitionerName from 'utils/getPractitionerName';

interface PractitionerNameColumnProps {
  field: any;
}

const PractitionerNameColumn: FC<PractitionerNameColumnProps> = ({ field }) => {
  if (field.linkedAppointment) {
    return (
      <span>
        {getPractitionerName(field.doctor)}
        <br />
        {getPractitionerName(field.linkedAppointment.doctor)}
      </span>
    );
  }

  if (field.isSplitScheduling) {
    return (
      <span>
        {getPractitionerName(field.doctor)}
        <br />
        {getDentistWorkingHour(field)}
      </span>
    );
  }

  return <span>{getPractitionerName(field.doctor)}</span>;
};

export default PractitionerNameColumn;

import { MutatorOptions, useSWRConfig } from 'swr';

const useMatchMutate = () => {
  const { cache, mutate } = useSWRConfig();
  return (matcher: RegExp, options?: boolean | MutatorOptions<any>) => {
    if (!(cache instanceof Map)) {
      throw new Error(
        'matchMutate requires the cache provider to be a Map instance'
      );
    }

    const keys = [];

    for (const key of cache.keys() as any) {
      if (matcher.test(key)) {
        keys.push(key);
      }
    }

    const mutations = keys.map((key) =>
      options ? mutate(key, undefined, options) : mutate(key)
    );
    return Promise.all(mutations);
  };
};

export default useMatchMutate;

const TOKEN_KEY = 'token';
const SELECTED_CLINIC_ID_KEY = 'selectedClinicId';
const ROLE_KEY = 'role';
const AUTH_ERROR = 'authError';
const IS_PAGE_DIRTY = 'isPageDirty';
const PRACTITIONER_SIDEBAR_COLLAPSED_STATE =
  'practitionerSidebarCollapsedState';
const HISTORY_LOCATION = 'historyLocation';

interface IToken {
  accessToken: string;
  refreshToken: string;
}

export const saveAuthToken = (token: IToken) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const loadAuthToken = () => {
  try {
    const token = localStorage.getItem(TOKEN_KEY);
    return token ? (JSON.parse(token) as IToken) : null;
  } catch (error) {
    // Clear all
    localStorage.clear();
    return null;
  }
};

export const saveSelectedClinicId = (selectedClinicId: string) => {
  sessionStorage.setItem(SELECTED_CLINIC_ID_KEY, selectedClinicId);
};

export const loadSelectedClinicId = () => {
  return sessionStorage.getItem(SELECTED_CLINIC_ID_KEY) ?? '';
};

export const saveRole = (role: string) => {
  sessionStorage.setItem(ROLE_KEY, role);
};

export const loadRole = () => {
  return sessionStorage.getItem(ROLE_KEY) ?? '';
};

export const savePractitionerSidebarState = (isCollapsed: boolean) => {
  localStorage.setItem(PRACTITIONER_SIDEBAR_COLLAPSED_STATE, `${isCollapsed}`);
};

export const loadPractitionerSidebarState = () => {
  const state = localStorage.getItem(PRACTITIONER_SIDEBAR_COLLAPSED_STATE);
  return state ? Boolean(JSON.parse(state)) : false;
};

export const clearStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(PRACTITIONER_SIDEBAR_COLLAPSED_STATE);

  sessionStorage.removeItem(ROLE_KEY);
  sessionStorage.removeItem(SELECTED_CLINIC_ID_KEY);
};

export const saveAuthError = () => {
  sessionStorage.setItem(AUTH_ERROR, JSON.stringify(true));
};

export const loadAuthError = () => {
  const authError = sessionStorage.getItem(AUTH_ERROR);
  return authError ? JSON.parse(authError) : '';
};

export const clearSessionStorage = () => {
  sessionStorage.removeItem(AUTH_ERROR);
};

export const setPageDirty = () => {
  return sessionStorage.setItem(IS_PAGE_DIRTY, JSON.stringify(true));
};

export const clearIsPageDirty = () => {
  sessionStorage.removeItem(IS_PAGE_DIRTY);
};

export const getIsPageDirty = () => {
  const isPageDirty = sessionStorage.getItem(IS_PAGE_DIRTY);
  return isPageDirty ? JSON.parse(isPageDirty) : '';
};

export const saveHistoryLocation = (location: string) => {
  localStorage.setItem(HISTORY_LOCATION, location);
};

export const getHistoryLocation = () => {
  return localStorage.getItem(HISTORY_LOCATION);
};

export const clearHistoryLocation = () => {
  return localStorage.removeItem(HISTORY_LOCATION);
};

import React, { useState } from 'react';

import CommonButton from 'components/CommonButton/CommonButton';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useAppointmentDateRange from 'hooks/useAppointmentDateRange';

import CalendarPicker from './CalendarPicker';
import {
  CALENDAR_TYPE,
  getDateRangeByCalendarType,
} from 'utils/getDateRangeByCalendarType';

interface IProps {
  clinicId: string | null;
  dateRange: {
    fromDate: string;
    toDate: string;
  };
  calendarType: string;
  onClose: () => void;
  onClickApply: (params: {
    calendarType: string;
    fromDate: string;
    toDate: string;
  }) => void;
}

const AppointmentDateRangePanel = ({
  clinicId,
  dateRange,
  calendarType,
  onClose,
  onClickApply,
}: IProps) => {
  const { data: allTimeAppointmentDateRange, isLoading } =
    useAppointmentDateRange({
      clinicId,
    });

  const [selectedCalendarType, setSelectedCalendarType] =
    useState(calendarType);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    fromDate: string;
    toDate: string | null;
  } | null>(() => {
    // Keep last date range when choosing custom date range after close and open panel
    if (
      ![CALENDAR_TYPE.ALL_TIME, CALENDAR_TYPE.CUSTOM_DATE_RANGE].includes(
        calendarType
      )
    ) {
      return getDateRangeByCalendarType(calendarType);
    } else {
      return dateRange;
    }
  });

  const onClickCalendarType = (type: string) => {
    setSelectedCalendarType(type);

    if (
      ![CALENDAR_TYPE.ALL_TIME, CALENDAR_TYPE.CUSTOM_DATE_RANGE].includes(type)
    ) {
      const dateRangeByType = getDateRangeByCalendarType(type);
      setSelectedDateRange(dateRangeByType);
      return;
    }

    if (type === CALENDAR_TYPE.ALL_TIME) {
      setSelectedDateRange(
        allTimeAppointmentDateRange
          ? {
              fromDate: allTimeAppointmentDateRange.earliestDateTime,
              toDate: allTimeAppointmentDateRange.latestDateTime,
            }
          : null
      );

      return;
    }

    if (type === CALENDAR_TYPE.CUSTOM_DATE_RANGE) {
      if (calendarType === type) {
        setSelectedDateRange(dateRange);
      } else {
        setSelectedDateRange(null); // set it is null to reset date range on calendar
      }

      return;
    }
  };

  const onApply = () => {
    if (
      selectedDateRange &&
      selectedDateRange.fromDate &&
      selectedDateRange.toDate
    ) {
      onClickApply({
        calendarType: selectedCalendarType,
        fromDate: selectedDateRange.fromDate,
        toDate: selectedDateRange.toDate,
      });

      onClose();
    }
  };

  const onChangeDate = ({
    fromDate,
    toDate,
  }: {
    fromDate: string | null;
    toDate: string | null;
  }) => {
    if (fromDate === null && toDate === null) {
      setSelectedDateRange(null);
    } else {
      setSelectedDateRange({
        fromDate: fromDate!,
        toDate,
      });
    }
  };

  if (isLoading) {
    return (
      <div className="w-32 h-10 flex flex-row justify-center items-center">
        <LoadingSpinner className="all-child:fill-magenta w-3 h-3 " />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {Object.values(CALENDAR_TYPE).map((type: string) => {
          return (
            <button
              key={type}
              className={`w-[15rem] h-[3rem] rounded-[1rem] shadow-elevation-02-light mb-0.8 text-12 hover:border hover:border-magenta focus:bg-magenta focus:text-white ${
                selectedCalendarType === type ? 'bg-magenta text-white' : ''
              }`}
              onClick={() => onClickCalendarType(type)}
            >
              {type}
            </button>
          );
        })}
      </div>

      <div className="min-w-[48rem] flex flex-col justify-between">
        <div className="mx-3.2">
          <CalendarPicker
            dateRange={selectedDateRange}
            calendarType={selectedCalendarType}
            onChange={onChangeDate}
          />
        </div>
        <div className="self-end pb-0.8">
          <CommonButton
            onClick={onApply}
            className="!min-h-[3.2rem] !min-w-[10rem] w-[10rem] h-[3.2rem]"
            disabled={
              selectedCalendarType === CALENDAR_TYPE.CUSTOM_DATE_RANGE &&
              (selectedDateRange === null || !selectedDateRange.toDate)
            }
          >
            Apply
          </CommonButton>
        </div>
      </div>
    </>
  );
};

export default AppointmentDateRangePanel;

import React from 'react';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

const GenderDropdown = (props: IProps) => {
  return (
    <Controller
      {...props}
      name="gender"
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        const option = OPTIONS.find((item) => item.value === value);

        return (
          <>
            <MultipleSelect
              isMulti={false}
              isError={!!error?.message}
              placeholder={'Select Gender'}
              options={OPTIONS}
              value={option}
              onChange={(newValue: any) => {
                onChange(newValue.value);
              }}
            />
            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-1`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default GenderDropdown;

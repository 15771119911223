import React from 'react';
import defaultAvatar from 'assets/icons/avatar-empty-circle.svg';

const Option = ({
  option,
  isActive = false,
  isGlobalAdmin = false,
  highlightedParts,
}: {
  option: {
    avatar: string;
    name: string;
    description?: string;
  };
  isActive?: boolean;
  isGlobalAdmin?: boolean;
  highlightedParts: { text: string; highlight: boolean }[];
}) => {
  return (
    <div
      className={`flex flex-row items-center w-full p-0.8 rounded-[1rem] ${
        !isActive && 'hover:bg-magenta-lightest'
      }`}
    >
      <div
        className={`w-3 h-3 rounded-full mr-0.8 ${
          isGlobalAdmin && 'bg-white border border-light-grey p-0.5'
        }`}
      >
        <img
          src={option.avatar || ''}
          alt={option.name}
          className={!isGlobalAdmin ? 'rounded-full h-full w-full' : ''}
          onError={(event) => {
            event.currentTarget.src = defaultAvatar;
          }}
        />
      </div>

      <div>
        <div className="flex flex-col items-start">
          <div
            className={`truncate max-w-[20rem] ${isActive ? 'font-bold' : ''}`}
          >
            {highlightedParts.map((part, index) => (
              <span
                key={index}
                className={part.highlight ? 'text-magenta' : ''}
              >
                {part.text}
              </span>
            ))}
          </div>
          {option.description && (
            <div className="text-[1.1rem] text-lightBlack text-left">
              {option.description}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Option;

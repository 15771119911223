import { FC } from 'react';
import Select, { Props } from 'react-select';
import ValueContainer from './ValueContainer/ValueContainer';
import CustomOption from './CusomOption/CustomOption';
import SingleOption from './SingleOption/SingleOption';

interface MultipleSelectProps extends Props {
  MenuList?: any;
  isError?: boolean;
}

export interface Option {
  label: string;
  value: string;
}

const MultipleSelect: FC<MultipleSelectProps> = ({ isError, ...props }) => {
  return (
    <Select
      isMulti
      isSearchable={false}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      closeMenuOnScroll={false}
      components={{
        IndicatorSeparator: () => null,
        MultiValueRemove: () => null,
        ...(props.MenuList
          ? {
              MenuList: props.MenuList,
            }
          : {
              Option: props.isMulti === false ? SingleOption : CustomOption,
            }),
        ValueContainer: ValueContainer,
      }}
      styles={{
        menu: (styles, state) => {
          return {
            ...styles,
            ...(state.options.length !== 0 && {
              paddingLeft: '20px',
              paddingTop: '20px',
            }),
            borderRadius: '1rem',
            backgroundColor: 'var(--white)',
            boxShadow: 'var(--shadow-primary)',
            margin: '0px',
            marginTop: '2px',
            padding: '1.6rem 1.2rem',
          };
        },
        indicatorsContainer: (styles) => ({ ...styles, marginRight: '5px' }),
        control: (styles, state) => {
          return {
            ...styles,
            borderRadius: '1rem',
            height: '4.8rem',
            border: isError
              ? '0.5px solid var(--secondary-red)'
              : state.menuIsOpen
              ? '0.5px solid var(--magenta)'
              : '0.5px solid var(--lighest-grey)',
            boxShadow: isError
              ? 'var(----shadow-elevation-07)'
              : state.menuIsOpen
              ? 'var(--shadow-input-active)'
              : 'var(--shadow-primary)',
            ':hover': {
              border: isError
                ? '0.5px solid var(--secondary-red)'
                : '0.5px solid var(--magenta)',
              boxShadow: isError
                ? 'var(----shadow-elevation-07)'
                : 'var(--shadow-input-active)',
            },
          };
        },
        menuList: (styles) => ({
          ...styles,
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.6rem',
          padding: '0px',
          '&::-webkit-scrollbar': {
            width: '6px',
          },

          '&::-webkit-scrollbar-button': {
            height: '5px',
          },

          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },

          '&::-webkit-scrollbar-thumb': {
            background: 'var(--light-mid-grey)',
            borderRadius: '6px',
          },
        }),
        placeholder: (styles) => ({ ...styles, color: 'var(--grey-01)' }),
      }}
      {...props}
    />
  );
};

export default MultipleSelect;

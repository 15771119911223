import { Dispatch, FC } from 'react';
import { Listbox } from '@headlessui/react';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
export interface Option {
  label: string;
  isWorkingDay: boolean;
}

interface SelectTypeProps {
  selectedType: Option;
  setSelectedType: Dispatch<React.SetStateAction<Option>>;
  options: Option[];
}

const SelectType: FC<SelectTypeProps> = ({
  selectedType,
  setSelectedType,
  options,
}) => {
  return (
    <Listbox value={selectedType} onChange={setSelectedType}>
      {({ open }) => (
        <div className="relative basis-[27.7rem] text-13 leading-[2rem] ml-2.4">
          <Listbox.Button
            className={`relative w-full h-3 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
              open ? 'border-magenta shadow-input-active' : ''
            }`}
          >
            <span className="pl-1.2">{selectedType.label}</span>
            <ArrowDownIcon className="w-1.2 h-1.2 absolute right-[0.9rem] top-[0.9rem]" />
          </Listbox.Button>
          <Listbox.Options className="absolute mt-0.2 w-full bg-white py-1.6 text-base flex flex-col gap-y-1.8 rounded-[1rem] shadow-primary z-10">
            {options.map((option) => (
              <Listbox.Option
                key={option.label}
                value={option}
                className={({ active, selected }) =>
                  `cursor-pointer pl-2 ${
                    active || selected ? 'text-magenta' : ''
                  }`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  );
};

export default SelectType;

import SelectTime from 'pages/OfficeSchedulePage/DayTimeSlot/SelectTime/SelectTime';
import { FC, useState } from 'react';

interface SelectHoursRangeProps {
  day: string;
  defaultValue: {
    start: string;
    end: string;
  };
  start?: string;
  end?: string;
  onChange: (day: string, value: string, type: string) => void;
}

const SelectHoursRange: FC<SelectHoursRangeProps> = ({
  day,
  defaultValue,
  start,
  end,
  onChange,
}) => {
  const [defaultStartValue, setDefaultStartValue] = useState<string>(
    defaultValue.start!
  );

  const [defaultEndValue, setDefaultEndValue] = useState<string>(
    defaultValue.end!
  );

  return (
    <div className="flex flex-1 ml-1.8 justify-evenly items-center">
      <SelectTime
        type="start"
        day={day}
        value={defaultStartValue}
        end={end}
        onChange={(day, value, type) => {
          setDefaultStartValue(value);
          onChange(day, value, type);
        }}
        isInEditModal
      />
      <div className="h-full mx-1">-</div>
      <SelectTime
        type="end"
        day={day}
        value={defaultEndValue}
        start={start}
        onChange={(day, value, type) => {
          setDefaultEndValue(value);
          onChange(day, value, type);
        }}
        isInEditModal
      />
    </div>
  );
};

export default SelectHoursRange;

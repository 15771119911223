import React, { useEffect, useState } from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import MainSection from './MainSection/MainSection';
import PractitionerPanel from './PractitionerPanel/PractitionerPanel';

import { IPractitioner } from 'interfaces/practitioners';
import useClinicPractitioner from 'hooks/useClinicPractitioner';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import CreatePractitionerModal from './CreatePractitionerModal/CreatePractitionerModal';

const PractitionerPage = () => {
  const { data: practitioners, isLoading } = useClinicPractitioner({
    shouldRun: true,
    sortBy: 'isActive,firstName',
    sort: 'desc,asc',
  });

  const [selectedPractitioner, setSelectedPractitioner] =
    useState<IPractitioner | null>(null);

  const [isCreatedPractitionerModalOpen, setIsCreatedPractitionerModalOpen] =
    useState(false);

  const getTotalPractitionerByStatus = () => {
    if (!practitioners) return null;
    const totalAvailableUser = practitioners.data.filter(
      (practitioner) => practitioner.isActive
    ).length;

    const totalUnavailableUser = practitioners.data.filter(
      (practitioner) => practitioner.isActive === false
    ).length;

    return {
      available: totalAvailableUser,
      unavailable: totalUnavailableUser,
      all: practitioners.metadata.total,
    };
  };

  useEffect(() => {
    if (!practitioners || practitioners.metadata.total === 0) return;
    if (selectedPractitioner) return;

    setSelectedPractitioner(practitioners.data[0]);
  }, [practitioners, selectedPractitioner]);

  if (isLoading) {
    return (
      <LoadingSpinner className="mx-auto mt-4.8 w-4.8 h-4.8 text-magenta" />
    );
  }

  if (isCreatedPractitionerModalOpen) {
    return (
      <CreatePractitionerModal
        onClose={() => setIsCreatedPractitionerModalOpen(false)}
        setSelectedPractitioner={setSelectedPractitioner}
      />
    );
  }

  if (practitioners?.metadata.total === 0) {
    return (
      <EmptyContent
        labelFor="practitioner"
        onClickAdd={() => setIsCreatedPractitionerModalOpen(true)}
      />
    );
  }

  return selectedPractitioner ? (
    <div className="flex">
      <PractitionerPanel
        practitioners={practitioners!.data}
        setSelectedPractitioner={setSelectedPractitioner}
        selectedPractitioner={selectedPractitioner}
        totalPractitionerByStatus={getTotalPractitionerByStatus()}
      />
      <MainSection
        selectedPractitioner={selectedPractitioner}
        setSelectedPractitioner={setSelectedPractitioner}
      />
    </div>
  ) : null;
};

export default PractitionerPage;

import { FC } from 'react';
import { Row, TableBodyProps } from 'react-table';

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import Column from './Column/Column';

interface DraggableServiceTableBodyProps {
  onDragEnd: (result: DropResult) => void;
  getTableBodyProps: () => TableBodyProps;
  page: Row<any>[];
  prepareRow: (row: Row<any>) => void;
  onRowMouseEnter: (rowId: string) => void;
  enabled: boolean;
}

const DraggableServiceTableBody: FC<DraggableServiceTableBodyProps> = ({
  onDragEnd,
  getTableBodyProps,
  page,
  prepareRow,
  onRowMouseEnter,
  enabled,
}) => {
  const firstInactiveItem = page.find((item) => !item.original.isActive);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppableProvided, droppableStateSnapshot) => (
          <tbody
            {...getTableBodyProps()}
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            className="text-darkest-grey"
          >
            {page.map((row) => {
              const isDraggableService =
                enabled &&
                row.index > 0 &&
                row.depth === 0 &&
                row.original.isActive;

              prepareRow(row);
              if (!isDraggableService) {
                return (
                  <tr
                    {...row.getRowProps()}
                    onMouseEnter={() => onRowMouseEnter(row.id)}
                    className={`bg-white border-b border-light-grey pl-5 
                    ${
                      droppableStateSnapshot.isDraggingOver
                        ? ''
                        : 'group hover:bg-lightPink'
                    } ${
                      enabled &&
                      firstInactiveItem &&
                      firstInactiveItem.index === row.index
                        ? 'border-t-2 border-t-grey'
                        : ''
                    }`}
                  >
                    {row.cells.map((cell) => (
                      <Column key={cell.column.id} cell={cell} row={row} />
                    ))}
                  </tr>
                );
              }

              return (
                <Draggable key={row.id} draggableId={row.id} index={row.index}>
                  {(provided, snapshot) => (
                    <>
                      <tr
                        {...row.getRowProps()}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        // NEED TO MANUALLY MERGE 2 STYLES TO PREVENT CONFLICT
                        style={{
                          ...row.getRowProps().style,
                          ...provided.draggableProps.style,
                        }}
                        onMouseDown={(e) => {
                          e.currentTarget.style.cursor = 'grabbing';
                        }}
                        onMouseUp={(e) => {
                          e.currentTarget.style.cursor = 'grab';
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.cursor = 'grab';
                        }}
                        onMouseEnter={() => onRowMouseEnter(row.id)}
                        className={`bg-white border-b border-light-grey pl-5 group hover:bg-lightPink ${
                          snapshot.isDragging ? 'shadow-elevation-08' : ''
                        }`}
                      >
                        {row.cells.map((cell) => (
                          <Column
                            key={cell.column.id}
                            isSortingEnabled={row.original.isActive}
                            cell={cell}
                            row={row}
                          />
                        ))}
                      </tr>
                      {firstInactiveItem &&
                        row.index + 1 === firstInactiveItem.index &&
                        droppableProvided.placeholder}
                    </>
                  )}
                </Draggable>
              );
            })}
            {!firstInactiveItem && droppableProvided.placeholder}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableServiceTableBody;

import React from 'react';

interface IProps {
  title: string;
  value: number;
}

const StatisticRow = ({ title, value }: IProps) => {
  return (
    <div>
      <div className="text-darkest-grey text-[1.6rem]">{title}</div>
      <h2 className="font-bold text-magenta text-[3rem]">{value}</h2>
    </div>
  );
};

export default StatisticRow;

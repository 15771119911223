import React from 'react';
import { DoctorSchedule } from 'interfaces/clinicSchedule';
import { IScheduleLayer } from 'pages/SchedulePage';
import { DAY_OFF, OFFICE_CLOSED, PMS_EVENT_TYPE } from 'utils/constants';
import { formatWorkingHour } from 'utils/datetime';

interface IProps {
  clinicWorkingHour: {
    startTime: string;
    endTime: string;
  };
  eventWorkingHour: {
    startTime: string;
    endTime: string;
  };
  type: string;
  isHoliday: boolean;
  isClosed: boolean;
  workingHourPractitioners: (DoctorSchedule & { name: string })[];
  scheduleLayer: IScheduleLayer;
}

const TimeGridTooltip = ({
  clinicWorkingHour,
  eventWorkingHour,
  type,
  isHoliday,
  isClosed,
  workingHourPractitioners,
  scheduleLayer,
}: IProps) => {
  const renderOfficeHour = () => {
    if (
      !scheduleLayer.isOfficeHour ||
      (isHoliday && scheduleLayer.isOfficeHoliday)
    )
      return null;

    let workingHourLabel = '';

    if (isClosed) {
      workingHourLabel = OFFICE_CLOSED;
    } else {
      workingHourLabel = formatWorkingHour(
        {
          fromTime: clinicWorkingHour.startTime,
          toTime: clinicWorkingHour.endTime,
        },
        'hh:mm A'
      );
    }

    return (
      <div>
        <div>Office Hours</div>
        <div className="font-bold">{workingHourLabel}</div>
      </div>
    );
  };

  const renderOfficeHoliday = () => {
    if (!isHoliday || !scheduleLayer.isOfficeHoliday) return null;

    return (
      <div>
        <div>Office Holidays</div>
        <div className="font-bold">{OFFICE_CLOSED}</div>
      </div>
    );
  };

  const renderEvent = () => {
    if (!scheduleLayer.isEvent || type !== PMS_EVENT_TYPE.EVENT) return null;

    const workingHourLabel = formatWorkingHour(
      {
        fromTime: eventWorkingHour.startTime,
        toTime: eventWorkingHour.endTime,
      },
      'hh:mm A'
    );

    return (
      <div>
        <div>Event</div>
        <div className="font-bold">{workingHourLabel}</div>
      </div>
    );
  };

  const renderPractitionerHour = () => {
    if (scheduleLayer.practitionerIds.length === 0) return null;

    return scheduleLayer.practitionerIds.map((practitionerId) => {
      const foundPractitionerWorkingHour = workingHourPractitioners.find(
        (item) => item.id === practitionerId
      );

      const practitionerName = foundPractitionerWorkingHour?.name || '';

      let practitionerWorkingHourLabel = '';

      if (foundPractitionerWorkingHour?.workingHour) {
        practitionerWorkingHourLabel =
          foundPractitionerWorkingHour?.workingHour.start !== null &&
          foundPractitionerWorkingHour?.workingHour.end !== null
            ? formatWorkingHour(
                {
                  fromTime: foundPractitionerWorkingHour?.workingHour.start,
                  toTime: foundPractitionerWorkingHour?.workingHour.end,
                },
                'hh:mm A'
              )
            : DAY_OFF;
      }

      return (
        <div key={`practitioner-hour-${practitionerId}`}>
          <div>{practitionerName} Hours</div>
          <div className="font-bold">
            {(isHoliday && scheduleLayer.isOfficeHoliday) || isClosed
              ? DAY_OFF
              : practitionerWorkingHourLabel}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="text-[12px] text-darkest-grey py-1.9 pl-2.3 flex flex-col gap-[16px]">
      {renderOfficeHour()}
      {renderOfficeHoliday()}
      {renderEvent()}
      {renderPractitionerHour()}
    </div>
  );
};

export default TimeGridTooltip;

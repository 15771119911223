const SingleOption = (props: any) => {
  const { innerRef, innerProps } = props;
  return (
    <div
      className="flex gap-x-1.6 items-center w-full"
      ref={innerRef}
      {...innerProps}
    >
      <div
        key={props.data.value}
        className={`${
          props.isSelected ? 'text-magenta cursor-pointer' : 'cursor-pointer'
        }`}
      >
        {props.data.label}
      </div>
    </div>
  );
};

export default SingleOption;

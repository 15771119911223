import { FC } from 'react';
import { capitalize } from 'lodash';
import { CLINIC_USER_STATUS } from 'utils/constants';

interface StatusProps {
  status: string;
}

const Status: FC<StatusProps> = ({ status }) => {
  const getClassNameBasedOnStatus = () => {
    if (status === CLINIC_USER_STATUS.ACTIVE) {
      return 'text-magenta bg-[#FFF7FF]';
    }
    if (status === CLINIC_USER_STATUS.PENDING) {
      return 'text-[#4D97E1] bg-[#F4FAFF]';
    }
    if (status === CLINIC_USER_STATUS.INACTIVE) {
      return 'text-mid-grey bg-lightest-grey';
    }
  };

  return (
    <div
      className={`w-max h-2.2 flex justify-center items-center px-1 py-0.2 text-12 rounded-full border-[0.3px] ${getClassNameBasedOnStatus()}`}
    >
      {capitalize(status)}
    </div>
  );
};

export default Status;

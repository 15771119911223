import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import useClinicPractitioner from 'hooks/useClinicPractitioner';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';

import usePMSAppointment, { IPMSAppointment } from 'hooks/usePMSAppointment';
import { IPractitioner } from 'interfaces/practitioners';
import {
  getMapPractitionerColor,
  getMapPractitionerAppointment,
  DEFAULT_COLOR_CODE,
  DEFAULT_BACKGROUND_COLOR_CODE,
  IMapPractitionerColor,
} from './utils';
import { PMS_TYPE, TIMEGRID_VIEW } from 'utils/constants';

import PractitionerList from './PractitionerList/PractitionerList';
import TimeGrid from './TimeGrid';
import DebugToggleHeader from './DebugToggleHeader';
import AppointmentList from './AppointmentList/AppointmentList';
import ScheduleLayer from './ScheduleLayer/ScheduleLayer';

export interface IScheduleLayer {
  isOfficeHour: boolean;
  isOfficeHoliday: boolean;
  isEvent: boolean;
  practitionerIds: string[];
}

export interface INormalizeData extends IPractitioner {
  colorCode: string;
  appointments: IPMSAppointment[];
}

const SchedulePage = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [isPractitionerTab, setIsPractitionerTab] = useState(true);
  const [selectedView, setSelectedView] = useState(TIMEGRID_VIEW.CHAIR);
  const [selectedPractitioner, setSelectedPractitioner] =
    useState<IMapPractitionerColor | null>(null);

  const [isDebugMode, setIsDebugMode] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [scheduleLayer, setScheduleLayer] = useState<IScheduleLayer>({
    isOfficeHour: true,
    isOfficeHoliday: true,
    isEvent: true,
    practitionerIds: [],
  });

  const { data: practitioners, isLoading: isClinicPractitionerLoading } =
    useClinicPractitioner({
      shouldRun: true,
      isIgnoredPractitionerTemporary: true,
      sortBy: 'isActive,firstName',
      sort: 'desc,asc',
    });

  const { data: appointments = [], isLoading: isPMSAppointmentLoading } =
    usePMSAppointment({
      date,
      selectedView,
      shouldRun: !isClinicPractitionerLoading,
    });

  useEffect(() => {
    if (isDebugMode) {
      setSelectedView(TIMEGRID_VIEW.CHAIR);
    }
  }, [isDebugMode]);

  // practitioners is not deleted
  const existingPractitioners =
    practitioners?.data.filter(
      (practitioner) => !(practitioner as any).inactive
    ) || [];

  // practitioners can be deleted and they have appts
  const practitionersByAppts = appointments.reduce(
    (accum: any, currentAppt) => {
      if (currentAppt.doctor) {
        const isAlreadyInsertion = accum.some(
          (doctor: any) => doctor.externalId === currentAppt.doctor!.externalId
        );

        const isFoundInExistingPractitioners = existingPractitioners?.some(
          (doctor: any) => doctor.externalId === currentAppt.doctor!.externalId
        );

        if (!isAlreadyInsertion && !isFoundInExistingPractitioners) {
          accum.push(currentAppt.doctor);
        }
      }

      return accum;
    },
    []
  ) as IPractitioner[];

  const activePractitioners =
    existingPractitioners.concat(practitionersByAppts);

  const availableAppointments = appointments.filter(
    (appointment) =>
      !appointment.unavailable &&
      (appointment.deletedAt === null ||
        (appointment.deletedAt !== null &&
          appointment.pmsType === PMS_TYPE.NEX_HEALTH))
  );

  const unavailableAppointments = appointments.filter(
    (appointment) => appointment.unavailable && appointment.deletedAt === null
  );

  const practitionersWithColor = getMapPractitionerColor(activePractitioners);

  const getNormalizeData = () => {
    if (!appointments || !practitioners) return [];

    const normalizeData = getMapPractitionerAppointment(
      practitionersWithColor!,
      availableAppointments!
    );

    return normalizeData;
  };

  const normalizeData = getNormalizeData();

  const timeGridData = useMemo(() => {
    if (!isDebugMode && selectedPractitioner) {
      return normalizeData.filter(
        (item) => item.id === selectedPractitioner.id
      );
    }

    return normalizeData;
  }, [normalizeData, selectedPractitioner, isDebugMode]);

  const getApptsWithColorCode = () => {
    return availableAppointments?.map((appointment) => {
      const practitionerColor = normalizeData.find(
        (practitioner) => practitioner.id === appointment.doctor?.id
      );

      return {
        ...appointment,
        colorCode: practitionerColor?.colorCode ?? DEFAULT_COLOR_CODE,
        backgroundColorCode:
          practitionerColor?.backgroundColorCode ??
          DEFAULT_BACKGROUND_COLOR_CODE,
      };
    });
  };

  if (activePractitioners?.length === 0 && !isClinicPractitionerLoading) {
    return (
      <div className="flex flex-col justify-center items-center mt-14">
        <EmptyBucket />
        <span className="mt-2.4 text-16 leading-[2.4rem]">
          No practitioners at the moment
        </span>
      </div>
    );
  }

  const onChangeDebugMode = () => {
    if (!activePractitioners) return;

    setIsDebugMode((isDebugMode) => !isDebugMode);
    setScheduleLayer((scheduleLayer) => ({
      ...scheduleLayer,
      practitionerIds: activePractitioners.map(
        (practitioner) => practitioner.id
      ),
    }));
  };

  return (
    <div className="px-2.4 py-2 relative">
      <DebugToggleHeader
        isDebugMode={isDebugMode}
        onChangeDebugMode={onChangeDebugMode}
      />

      <div className="flex text-14 leading-[2.1rem] border-light-grey border-solid rounded-b-[10px] border-[0.5px] h-[calc(100vh-4rem-6rem-4rem)]">
        <div
          className={`flex flex-col transition-all duration-300 ${
            isCollapsed
              ? 'w-0 all-child:invisible'
              : 'basis-[32rem] all-child:visible'
          }`}
        >
          <div>
            <CustomDatePicker
              inline
              calendarClassName="!shadow-none"
              selected={moment(date, 'YYYY-MM-DD').toDate()}
              onChange={(date) => {
                if (!date) return;
                setDate(moment(date).format('YYYY-MM-DD'));
              }}
            />
          </div>
          {isDebugMode ? (
            <ScheduleLayer
              practitioners={practitionersWithColor}
              scheduleLayer={scheduleLayer}
              onChangeScheduleLayers={(data) => {
                setScheduleLayer((state) => {
                  return {
                    ...state,
                    ...data,
                  };
                });
              }}
            />
          ) : (
            <>
              <div className="w-full">
                <div className="h-4.8 flex justify-evenly items-center border-y border-light-grey text-mid-grey">
                  <button
                    className={`flex-1 h-full ${
                      isPractitionerTab
                        ? 'text-darkest-grey font-bold border-b-4 border-magenta'
                        : ''
                    }`}
                    onClick={() => setIsPractitionerTab(true)}
                  >
                    Practitioners
                  </button>

                  <button
                    className={`flex-1 h-full ${
                      !isPractitionerTab
                        ? 'text-darkest-grey font-bold border-b-4 border-magenta'
                        : ''
                    }`}
                    onClick={() => setIsPractitionerTab(false)}
                  >
                    Appointments
                  </button>
                </div>
              </div>

              <div className="flex-1 overflow-auto scrollbar border border-t-0 border-light-grey">
                {isPractitionerTab ? (
                  <PractitionerList
                    selectedPractitioner={selectedPractitioner}
                    onChangePractitioner={(item) =>
                      setSelectedPractitioner(item)
                    }
                    practitioners={practitionersWithColor}
                    isLoading={isClinicPractitionerLoading}
                  />
                ) : (
                  <AppointmentList
                    appointments={getApptsWithColorCode()}
                    isLoading={isPMSAppointmentLoading}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <TimeGrid
          practitioners={practitionersWithColor}
          data={timeGridData}
          unavailableAppointments={unavailableAppointments || []}
          scheduleLayer={scheduleLayer}
          isCollapsed={isCollapsed}
          date={date}
          onChangeDate={(value: string) => {
            setDate(value); // YYYY-MM-DD
          }}
          onToggleCollapse={() => setIsCollapsed((state) => !state)}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          isDebugMode={isDebugMode}
          isLoading={isPMSAppointmentLoading}
          selectedPractitioner={selectedPractitioner}
        />
      </div>
    </div>
  );
};

export default SchedulePage;

import { FC, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import AvatarPlaceHolder from 'assets/icons/avatar-empty-circle.svg';
import { ReactComponent as UploadImageIcon } from 'assets/icons/camera.svg';
import { renderToast } from 'components/Toast/Toast';

interface ImageUploadProps {
  avatar: string | null;
  isEdit: boolean;
  onRemoveAvatar: () => void;
  setValue: UseFormSetValue<any>;
  avatarPlaceholder?: any;
}

const ImageUpload: FC<ImageUploadProps> = ({
  avatar,
  isEdit,
  onRemoveAvatar,
  setValue,
  avatarPlaceholder = AvatarPlaceHolder,
}) => {
  const [imgSrc, setImgSrc] = useState('');

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isFileExisted = e.target.files && e.target.files.length > 0;
    if (!isFileExisted) return;

    const bytesOf2MB = 2097152;
    const file = e.target.files![0];

    if (file.size > bytesOf2MB) {
      renderToast({ type: 'error', message: 'Image can not exceed 2MB' });
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImgSrc(reader.result?.toString() || '');
      setValue('uploadedFile', file);
    });
    reader.readAsDataURL(file);
  };

  const isRemoveButtonDisplayed = isEdit && (avatar || imgSrc);

  const handleRemoveAvatar = () => {
    setImgSrc('');
    onRemoveAvatar();
  };

  return (
    <>
      <div className="flex justify-between">
        <h3 className="font-bold">Avatar</h3>
        {isRemoveButtonDisplayed && (
          <button className="text-magenta" onClick={handleRemoveAvatar}>
            Remove
          </button>
        )}
      </div>
      <div className="relative">
        {imgSrc ? (
          <img
            src={imgSrc}
            alt="avatar"
            className={`rounded-full object-cover w-20 h-20 aspect-square shrink-0 mt-1 brightness-50`}
            width={200}
            height={200}
          />
        ) : (
          <img
            src={avatar || avatarPlaceholder}
            alt="avatar"
            className={`rounded-full object-cover w-20 h-20 aspect-square shrink-0 mt-1 ${
              isEdit ? 'brightness-50' : ''
            }`}
            width={200}
            height={200}
            onError={(event) => {
              event.currentTarget.src = avatarPlaceholder;
            }}
          />
        )}
        {isEdit && (
          <>
            <div className="w-full h-full absolute top-0 flex flex-col items-center justify-center rounded-full text-white cursor-pointer">
              <UploadImageIcon className="all-child:fill-white" />
              <span className="font-bold">Upload photo</span>
              <span className="text-12 leading-[1.8rem]">File size: 2 MB</span>
            </div>
            <input
              key={imgSrc}
              className="w-full h-full absolute top-0 rounded-full opacity-0 cursor-pointer"
              type="file"
              accept=".png,.jpeg,.jpg,.webp,.avif"
              onInput={onSelectFile}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ImageUpload;

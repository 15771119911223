import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as WarningErrorIcon } from 'assets/icons/warning.svg';

interface OutsideApptIndicatorProps {
  onClick: () => void;
  numberOfAppts: number;
  size?: 'sm' | 'lg';
}

const OutsideApptIndicator: FC<OutsideApptIndicatorProps> = ({
  onClick,
  numberOfAppts,
  children,
  size = 'sm',
}) => {
  return (
    <>
      <button
        className="flex items-center rounded-[100px] py-[6px] px-[10px] shadow-primary cursor-pointer absolute right-0"
        onClick={onClick}
        data-for="outside-hour-tooltip"
        data-tip=""
      >
        <WarningErrorIcon />
        <div className="text-11 text-secondary-red ml-[6px]">
          {numberOfAppts}
        </div>
      </button>
      <ReactTooltip
        id="outside-hour-tooltip"
        place="bottom"
        effect="solid"
        className={`!bg-white !border-light-grey !rounded-[0.8rem] !px-[0.8rem] !py-[0.4rem] !opacity-100 z-[9999] flex justify-center items-center ${
          size === 'sm' ? '!w-[128px]' : '!w-[164px]'
        } !h-[44px] shadow-primary before:hidden after:hidden`}
        offset={{ left: size === 'sm' ? 20 : 40 }}
      >
        <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal">
          {children}
        </span>
      </ReactTooltip>
    </>
  );
};

export default OutsideApptIndicator;

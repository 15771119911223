import React from 'react';
import useClinic from 'hooks/useClinic';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ViewOrEditPractice from 'pages/AdminPracticePage/ViewOrEditPractice/ViewOrEditPractice';

const Home: React.FC = () => {
  const { data, isLoading } = useClinic();

  if (isLoading) {
    return (
      <LoadingSpinner className="mx-auto mt-4.8 w-4.8 h-4.8 text-magenta" />
    );
  }

  return (
    <ViewOrEditPractice
      key={data.updatedAt}
      selectedPractice={data}
      isPracticeView
    />
  );
};

export default Home;

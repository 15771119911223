export const VIEW_ACTION: {
  IS_SCROLLING: 'isScrolling';
  SCROLL_TO_TOP: 'scrollToTop';
  SCROLL_TO_BOTTOM: 'scrollToBottom';
  RESET_WITH_ENOUGH_BLOCKS: 'resetWithEnoughBlocks';
  RESET_NOT_ENOUGH_BLOCKS: 'resetNotEnoughBlocks';
} = {
  IS_SCROLLING: 'isScrolling',
  SCROLL_TO_TOP: 'scrollToTop',
  SCROLL_TO_BOTTOM: 'scrollToBottom',
  RESET_WITH_ENOUGH_BLOCKS: 'resetWithEnoughBlocks',
  RESET_NOT_ENOUGH_BLOCKS: 'resetNotEnoughBlocks',
};

interface IAction {
  type: typeof VIEW_ACTION[keyof typeof VIEW_ACTION];
}
interface IState {
  isScrolling: boolean;
  displayBlurBottom: boolean;
  displayRemoveAnimate: boolean;
  displayScrollbar: boolean;
}

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case VIEW_ACTION.SCROLL_TO_TOP:
      return {
        ...state,
        isScrolling: false,
        displayRemoveAnimate: false,
        displayBlurBottom: true,
      };
    case VIEW_ACTION.SCROLL_TO_BOTTOM:
      return { ...state, displayBlurBottom: false };
    case VIEW_ACTION.IS_SCROLLING:
      return {
        ...state,
        isScrolling: true,
        displayRemoveAnimate: true,
        displayBlurBottom: true,
      };
    case VIEW_ACTION.RESET_WITH_ENOUGH_BLOCKS:
      return {
        ...state,
        displayRemoveAnimate: true,
        displayBlurBottom: false,
        displayScrollbar: false,
        isScrolling: false,
      };
    case VIEW_ACTION.RESET_NOT_ENOUGH_BLOCKS:
      return {
        ...state,
        displayRemoveAnimate: false,
        displayBlurBottom: true,
        displayScrollbar: true,
        isScrolling: false,
      };
    default:
      throw Error('Unhandled action type');
  }
};

const initialState = {
  isScrolling: false,
  displayBlurBottom: true,
  displayRemoveAnimate: false,
  displayScrollbar: false,
};

export default reducer;
export { initialState };

import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CardContainer from 'components/Layout/CardContainer/CardContainer';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ROUTES } from 'utils/constants/routes';

interface ResultPageProps {
  isSuccess: boolean;
}

const ResultPage: FC<ResultPageProps> = ({ isSuccess }) => {
  const [countDown, setCountDown] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuccess) return;
    const id = setInterval(() => {
      setCountDown((countDown) => countDown - 1);
    }, 1000);
    return () => clearInterval(id);
  }, [isSuccess]);

  useEffect(() => {
    if (countDown !== 0) return;
    navigate(ROUTES.LOGIN, { replace: true });
  }, [countDown, navigate]);

  return (
    <CardContainer>
      <div
        className={`flex flex-col justify-center items-center gap-y-2.4 
      ${isSuccess ? 'py-4.8' : 'py-4'}`}
      >
        {isSuccess ? <ConfirmIcon /> : <CrossIcon />}
        <span className="text-16 font-bold leading-[2.4rem]">
          {isSuccess
            ? 'Account successfully set up!'
            : 'Your verification email link has expired'}
        </span>
        <Link
          to={ROUTES.LOGIN}
          className={`px-3 py-1.2 bg-magenta text-white font-bold rounded-full hover:bg-magenta-lighter 
          ${isSuccess ? '-mt-0.4' : ''}`}
        >
          Go to Practice App
        </Link>
        {isSuccess && (
          <span className="-mt-1.4 justify-center">
            Redirecting in {countDown}s
          </span>
        )}
      </div>
    </CardContainer>
  );
};

export default ResultPage;

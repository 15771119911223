import React, { FC } from 'react';
import { DayCellContentArg } from '@fullcalendar/react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { datetimeFormat, isNextMonth, isPastMonth } from 'utils/datetime';
import { WorkingHour } from 'interfaces/clinicSchedule';
import { DAY_OFF, OFFICE_CLOSED } from 'utils/constants';

export interface DayCellData {
  date: string;
  isPractitionerClosed: boolean;
  isClinicClosed: boolean;
  isPractitionerException: boolean;
  clinicWorkingHour: WorkingHour[] | string;
  practitionerWorkingHour: WorkingHour[] | string;
  holidayName: string | undefined | null;
}

interface DayCellProps extends DayCellContentArg {
  dayInfo?: DayCellData;
  onCellClicked: (dayCellData: DayCellData) => void;
  selectedDate: string;
  highlightedDate: string;
  resetHighlightedDate: () => void;
}

const DayCell: FC<DayCellProps> = ({
  date,
  selectedDate,
  dayInfo,
  dayNumberText,
  isToday,
  isPast,
  highlightedDate,
  resetHighlightedDate,
  onCellClicked,
}) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');

  const isRegularDayOff = (workingHour: string | WorkingHour[] | undefined) => {
    if (Array.isArray(workingHour)) {
      const data = workingHour[0];

      if (!data?.start && !data?.end) {
        return true;
      }
    }
  };

  const isPractitionerDayOff = () => {
    if (dayInfo?.isPractitionerClosed) return true;

    if (isRegularDayOff(dayInfo?.practitionerWorkingHour)) return true;

    return false;
  };

  const isClinicOff = () => {
    // holiday return isClosed: null
    if (dayInfo?.isClinicClosed || dayInfo?.holidayName) return true;

    if (isRegularDayOff(dayInfo?.clinicWorkingHour)) return true;

    return false;
  };

  const isPractitionerHourOutsideClinic = () => {
    if (
      dayInfo?.clinicWorkingHour &&
      dayInfo?.practitionerWorkingHour &&
      !isPractitionerDayOff() &&
      !isClinicOff() &&
      isFutureInThisMonth
    ) {
      const clinicWorkingHour = dayInfo.clinicWorkingHour[0] as WorkingHour;
      const practitionerWorkingHour = dayInfo
        .practitionerWorkingHour[0] as WorkingHour;

      const isPractitionerStartBeforeClinicHour = moment(
        practitionerWorkingHour.start,
        'HH:mm:ss'
      ).isBefore(moment(clinicWorkingHour.start, 'HH:mm:ss'));

      const isPractitionerEndAfterClinicHour = moment(
        practitionerWorkingHour.end,
        'HH:mm:ss'
      ).isAfter(moment(clinicWorkingHour.end, 'HH:mm:ss'));

      return (
        isPractitionerStartBeforeClinicHour || isPractitionerEndAfterClinicHour
      );
    }
  };

  const renderWorkingHour = () => {
    if (isNextMonth(formattedDate, selectedDate)) {
      return null;
    }

    if (dayInfo?.holidayName) {
      return dayInfo.holidayName;
    }

    if (isClinicOff()) {
      return OFFICE_CLOSED;
    }

    if (Array.isArray(dayInfo?.practitionerWorkingHour)) {
      const workingHour = dayInfo?.practitionerWorkingHour[0];

      if (workingHour?.start && workingHour?.end) {
        const startTime = moment(workingHour.start, 'HH:mm:ss').format(
          'hh:mmA'
        );
        const endTime = moment(workingHour.end, 'HH:mm:ss').format('hh:mmA');

        return `${startTime} - ${endTime}`;
      }
    }

    if (isPractitionerDayOff()) {
      return DAY_OFF;
    }
  };

  const renderDayNumber = () => {
    const firstDayOfNextMonth = moment()
      .clone()
      .add(1, 'months')
      .startOf('months');

    if (firstDayOfNextMonth.isSame(moment(date), 'days')) {
      return `${dayNumberText} ${firstDayOfNextMonth.format('MMM')}`;
    }

    return dayNumberText;
  };

  const isInThisMonth =
    !isPastMonth(formattedDate, selectedDate) &&
    !isNextMonth(formattedDate, selectedDate);

  const isFutureInThisMonth = !isPast && isInThisMonth;

  const isPractitionerExcpetionDisplayed = () =>
    dayInfo?.isPractitionerException &&
    !isPractitionerDayOff() &&
    isFutureInThisMonth;

  const handleOnCellClicked = () => {
    if (!isFutureInThisMonth || isClinicOff()) return;

    onCellClicked(dayInfo!);
  };

  const getCellClassName = () => {
    const clinicOffClassName = 'bg-lightPink text-grey cursor-not-allowed';
    const practitionerOffClassName = 'bg-[#F4FAFF] text-current';

    if (isClinicOff()) {
      return clinicOffClassName;
    }

    if (isPractitionerDayOff()) {
      return practitionerOffClassName;
    }
  };

  return (
    <div
      className={`relative h-full flex flex-col justify-between py-[0.5rem] px-[0.7rem] cursor-pointer border 
      ${
        highlightedDate === formattedDate
          ? 'border-magenta'
          : 'border-transparent'
      } 
      ${getCellClassName()} 
      ${
        isFutureInThisMonth
          ? 'hover:border-magenta'
          : 'cursor-not-allowed !bg-white'
      }
      ${dayInfo ? '' : 'cursor-not-allowed'}
      `}
      onClick={handleOnCellClicked}
      onMouseEnter={() => {
        if (highlightedDate && isFutureInThisMonth) {
          resetHighlightedDate();
        }
      }}
      onMouseLeave={() => {
        if (highlightedDate === formattedDate) {
          resetHighlightedDate();
        }
      }}
    >
      <div
        className={`font-bold text-right ${
          isFutureInThisMonth ? 'text-darkest-grey' : 'text-grey'
        } ${
          isToday
            ? 'bg-magenta w-[24px] h-[24px] leading-[24px] border-magenta rounded-full !text-center !text-white self-end'
            : ''
        }`}
      >
        <div className="absolute left-[10px] flex items-center gap-x-0.4">
          {isPractitionerExcpetionDisplayed() && (
            <div className="bg-lightBlue w-[6px] h-[6px] rounded-full" />
          )}
          {isPractitionerHourOutsideClinic() && (
            <>
              <button data-for={formattedDate} data-tip="">
                <InfoIcon className="w-1.4 h-1.4" />
              </button>
              <ReactTooltip
                id={formattedDate}
                place="bottom"
                effect="solid"
                className="!bg-white !border-light-grey !rounded-[0.8rem] !w-auto !h-auto !px-[1rem] !py-[0.4rem] !opacity-100 z-[9999] flex justify-center items-center shadow-primary  before:hidden after:hidden !min-w-[11.8rem] min-h-[4.4rem]"
              >
                <div className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal">
                  <div className="flex flex-col">
                    <span className="self-start">Practice Hour</span>
                    <span>
                      {datetimeFormat({
                        dateString: (
                          dayInfo?.clinicWorkingHour[0] as WorkingHour
                        ).start!,
                        format: 'HH:mm:ss',
                        pattern: 'hh:mm A',
                      })}{' '}
                      -{' '}
                      {datetimeFormat({
                        dateString: (
                          dayInfo?.clinicWorkingHour[0] as WorkingHour
                        ).end!,
                        format: 'HH:mm:ss',
                        pattern: 'hh:mm A',
                      })}
                    </span>
                  </div>
                </div>
              </ReactTooltip>
            </>
          )}
        </div>
        <div className={`${isClinicOff() ? 'text-grey' : ''}`}>
          {renderDayNumber()}
        </div>
      </div>
      {isFutureInThisMonth && (
        <div className="text-center font-normal">{renderWorkingHour()}</div>
      )}
    </div>
  );
};

export default DayCell;

import React from 'react';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';

const EmptyPartialPatient = () => {
  return (
    <div className="w-full h-[24rem] flex flex-col gap-y-1.6 justify-center items-center">
      <EmptyBucket />
      <span>No partial patient match was found</span>
    </div>
  );
};

export default EmptyPartialPatient;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadAuthToken } from 'utils/storage';

interface IUserAllAdminParams {
  limit: number;
  page: number;
  sort: 'asc' | 'desc';
  sortBy: string;
}

const useAllAdmin = ({ limit, page, sort, sortBy }: IUserAllAdminParams) => {
  const params = {
    limit: limit.toString(),
    page: page.toString(),
    sort,
    sortBy,
  };

  const query = new URLSearchParams(params).toString();
  const url = `/clinic-users/global-admin?${query}`;

  let { data, error } = useSWR(url, async (url) => {
    const response = await axiosInstance(url, {
      headers: {
        Authorization: `JWT ${loadAuthToken()!.accessToken}`,
      },
    });
    return response.data;
  });

  return { data, error, isLoading: !data && !error };
};

export default useAllAdmin;

import React from 'react';
import { ReactComponent as AppLogo } from 'assets/images/app-logo.svg';
import { ReactComponent as ConstructionIcon } from 'assets/icons/construction.svg';

const Chart = () => {
  return (
    <div className="bg-white rounded-[1rem] shadow-primary flex flex-col justify-center items-center h-full">
      <AppLogo className="opacity-[30%] w-[26.9rem] h-[7.5rem] mb-2.4" />
      <div className="flex flex-row items-center">
        <ConstructionIcon />
        <span className="text-darkest-grey text-16 ml-0.5">Coming soon!</span>
      </div>
    </div>
  );
};

export default Chart;

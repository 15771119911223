import { FC } from 'react';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import MultipleSelect from '../../MultipleSelect/MultipleSelect';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

interface BlockDropdownProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const OPTIONS = [
  { value: '1', label: '±1 Appts available' },
  { value: '2', label: '±2 Appts available' },
  { value: '3', label: '±3 Appts available' },
];

const BlockDropdown: FC<BlockDropdownProps> = (props) => {
  return (
    <Controller
      {...props}
      name="optimizeSchedule"
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        const option = OPTIONS.find((item) => item.value === value);

        return (
          <>
            <MultipleSelect
              isMulti={false}
              isError={!!error?.message}
              placeholder={'Select Block'}
              options={OPTIONS}
              value={option}
              onChange={(newValue: any) => {
                onChange(newValue.value);
              }}
            />
            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-1`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default BlockDropdown;

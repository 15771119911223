import { useEffect } from 'react';
import { saveHistoryLocation } from 'utils/storage';
import useAppointmentSearchParam from './useAppointmentSearchParam';

const useSaveHistoryLocation = () => {
  const {
    isErrorParamCombinationValid,
    isActionParamCombinationValid,
    search,
  } = useAppointmentSearchParam();

  useEffect(() => {
    const isNavigatedFromEmail =
      isErrorParamCombinationValid || isActionParamCombinationValid;

    if (!isNavigatedFromEmail) return;

    saveHistoryLocation(search);
  }, [isActionParamCombinationValid, isErrorParamCombinationValid, search]);
};

export default useSaveHistoryLocation;

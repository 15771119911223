import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

const FullScreenLoading = () => {
  return (
    <div className="w-screen h-screen flex flex-col justify-center">
      <LoadingSpinner className="text-magenta w-4.8 h-4.8 mx-auto my-auto" />
    </div>
  );
};

export default FullScreenLoading;

import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';

const getQueryTimeSlotPerDay = (changedTimeSlots: WeeklyTimeslot[]) => {
  const queryTimeSlotPerDay: { [key: string]: string } = {};

  changedTimeSlots.forEach(({ day, start, end }) => {
    queryTimeSlotPerDay[`${day}StartTime`] = `${start}`;
    queryTimeSlotPerDay[`${day}EndTime`] = `${end}`;
  });
  return queryTimeSlotPerDay;
};

export default getQueryTimeSlotPerDay;

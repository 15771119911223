import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { Study } from 'interfaces/study';

const useStudy = () => {
  const { data, error, mutate } = useSWR(['/studies'], async (...resource) => {
    const [url] = resource;
    const response = await axiosInstance.get<Study[]>(url);
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useStudy;

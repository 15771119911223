import OperatorySection from '../GlobalAdminSync/OperatorySection/OperatorySection';
import PractitionerSection from '../GlobalAdminSync/PractitionerSection/PractitionerSection';
import InstallationSection from './InstallationSection/InstallationSection';

const PracticeAdminSync = () => {
  return (
    <div className="mt-4 w-[71rem]">
      <div className="text-14 leading-[2.1rem] text-darkest-grey flex flex-col gap-y-2">
        <InstallationSection />
        <OperatorySection />
        <PractitionerSection />
      </div>
    </div>
  );
};

export default PracticeAdminSync;

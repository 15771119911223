import React, { useEffect } from 'react';
import { sortBy } from 'lodash';
import { ReactComponent as Logo } from 'assets/images/app-logo.svg';
import HeroImg from 'assets/images/hero.webp';

import { useForm } from 'react-hook-form';
import TextField from 'components/TextField/TextField';
import PasswordField from 'components/PasswordField/PasswordField';
import { isValidEmail } from 'utils/validations';
import CommonButton from 'components/CommonButton/CommonButton';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Toast from 'components/Toast/Toast';
import axiosInstance from 'apis/axiosInstance';
import {
  clearSessionStorage,
  getHistoryLocation,
  loadAuthError,
  loadAuthToken,
  saveAuthToken,
  saveRole,
  saveSelectedClinicId,
} from 'utils/storage';
import { ClinicUserRole, useUser } from 'context/userContext';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';
import { FORBIDDEN, UNAUTHORIZED } from 'utils/constants/statusCode';
import { ROLE } from 'utils/constants';

// DUE TO WIDTH OF IMAGE AND INSET FROM LIBRARY
const centerToastInPageSection = {
  marginLeft: 'calc(466.22px + 16px)',
};

const LoginPage = () => {
  const {
    control,
    formState: { isValid, isSubmitting },
    handleSubmit,
  } = useForm({
    mode: 'all',
    values: {
      email: '',
      password: '',
    },
  });

  const { dispatch } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    const isAuthError = loadAuthError();
    if (!isAuthError) return;
    toast((t) => {
      t.style = centerToastInPageSection;
      return (
        <Toast
          id={t.id}
          message={
            'Your account has been deactivated and/or deleted. Please contact your admin to support.'
          }
          type="error"
        />
      );
    });
    clearSessionStorage();
  }, []);

  useEffect(() => {
    const token = loadAuthToken()?.accessToken;
    if (!token) return;

    navigate(ROUTES.DASHBOARD, { replace: true });
  }, [navigate]);

  const navigateUserToAppointmentPage = (
    data: any,
    historyLocation: string
  ) => {
    const urlSearchParams = new URLSearchParams(historyLocation);

    const clinicId = urlSearchParams.get('clinicId');

    const isUserAllowed = data.profile.clinicUserRoles.find(
      (clinicUserRole: ClinicUserRole) => clinicUserRole.clinic.id === clinicId
    );

    if (!isUserAllowed) return;
    navigate(`${ROUTES.APPOINTMENT}${historyLocation}`);
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    const historyLocation = getHistoryLocation();
    try {
      const response = await axiosInstance.post(
        '/clinic-user/auth/login',
        data
      );

      const sortedClinicUserRoles = sortBy(
        response.data.profile.clinicUserRoles,
        (clinicUserRole) => clinicUserRole.clinic?.name
      );

      response.data.profile.clinicUserRoles = sortedClinicUserRoles;
      const firstClinicUserRole = response.data.profile.clinicUserRoles[0];
      const role = firstClinicUserRole.role;

      saveRole(role);
      saveAuthToken({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });

      if (historyLocation) {
        const urlSearchParams = new URLSearchParams(historyLocation);
        saveSelectedClinicId(urlSearchParams.get('clinicId')!);
      }

      dispatch({ type: 'login', payload: response.data.profile });

      if (historyLocation) {
        navigateUserToAppointmentPage(response.data, historyLocation);
      } else if (role === ROLE.GLOBAL_ADMIN) {
        navigate(ADMIN_ROUTES.DASHBOARD);
      } else {
        saveSelectedClinicId(firstClinicUserRole.clinic.id);
        navigate(ROUTES.DASHBOARD);
      }
    } catch (error: any) {
      const statusCode = error.response?.data?.statusCode;

      let message = 'An error has occurred. Please try again.';
      if (statusCode === UNAUTHORIZED) {
        message = 'Incorrect email or password';
      }
      if (statusCode === FORBIDDEN) {
        message =
          'Your account has been deactivated and/or deleted. Please contact your admin to support.';
      }
      toast((t) => {
        t.style = centerToastInPageSection;
        return <Toast id={t.id} message={message} type="error" />;
      });
    }
  };

  return (
    <div className="flex h-screen">
      <img src={HeroImg} alt="hero" className="flex-1 object-cover" />
      <div className="text-16 flex flex-col items-center justify-center flex-[3] min-w-[500px]">
        <div className="mx-auto w-1/2">
          <div className="flex flex-col gap-y-2 items-center">
            <Logo />
            <span>Sign in to FirstIn Practice App</span>
          </div>
          <form
            className="flex flex-col justify-center gap-y-1.6 mt-7.2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              id="email"
              control={control}
              label="Email Address"
              placeholder="Input your email address"
              rules={{
                required: 'This field is required',
                validate: (value) => {
                  if (!isValidEmail(value)) return 'Invalid email address';
                },
              }}
            />
            <PasswordField
              id="password"
              placeholder="Input your password"
              control={control}
              rules={{ required: 'This field is required' }}
            />
            <div className="flex flex-col items-center gap-y-2.4 mt-0.8">
              <CommonButton
                disabled={!isValid}
                isLoading={isSubmitting}
                type="submit"
              >
                Sign in
              </CommonButton>
              <Link
                className="underline text-magenta font-bold text-14 leading-[2.1rem]"
                to={ROUTES.FORGOT_PASSWORD}
              >
                Forgot password
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import { FC, useMemo, useState } from 'react';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import Pagination, { ITablePaginate } from 'components/Tables/Pagination';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

import useAllAdmin from 'hooks/useAllAdmin';
import Status from 'pages/UserPage/TableSection/Status/Status';
import { datetimeFormat } from 'utils/datetime';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';
import { useUser } from 'context/userContext';

const defaultTablePaginationState = {
  currentPage: 1,
  pageSize: 10,
  sort: 'desc' as const,
  sortBy: 'firstName',
};

interface TableProps {}

const Table: FC<TableProps> = () => {
  const [pageSize, setPageSize] = useState(
    defaultTablePaginationState.pageSize
  );
  const [currentPage, setCurrentPage] = useState(
    defaultTablePaginationState.currentPage
  );
  const [sort, setSort] = useState<'asc' | 'desc'>(
    defaultTablePaginationState.sort
  );
  const [sortBy, setSortBy] = useState(defaultTablePaginationState.sortBy);
  const [highlightedRowId, setHighlighedRowId] = useState('');

  const { data, isLoading } = useAllAdmin({
    limit: pageSize,
    page: currentPage,
    sort,
    sortBy,
  });

  const isActionColumn = (columnId: string) => columnId !== 'action_btn';

  const { user } = useUser();

  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
        id: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        id: 'lastName',
      },
      {
        Header: 'Email',
        accessor: (field: any) => {
          const isLoggedInAdmin = user.id === field.clinicUser.id;
          return (
            <div>
              {field.clinicUser.email}{' '}
              {isLoggedInAdmin ? (
                <span className="text-magenta">(You)</span>
              ) : (
                ''
              )}
            </div>
          );
        },
        id: 'email',
      },
      {
        Header: 'Status',
        accessor: (field: any) => <Status status={field.status} />,
        id: 'status',
      },
      {
        Header: 'Created Date',
        accessor: (field: any) =>
          datetimeFormat({
            dateString: field.createdAt,
            pattern: 'MMM DD, YYYY',
          }),
        id: 'createdAt',
      },
      {
        id: 'action_btn',
        width: 25,
        Cell: ({ row }: any) => {
          const isLoggedInAdmin = user.id === row.original.clinicUser.id;
          return (
            <ThreeDotsOption
              key={highlightedRowId}
              isLoggedInAdmin={isLoggedInAdmin}
              data={row.original}
            />
          );
        },
      },
    ],
    [highlightedRowId, user.id]
  );

  const dataTable = useMemo(() => {
    if (isLoading) {
      return { data: [], metadata: null };
    }
    return { data: data.data, metadata: data.metadata };
  }, [data?.data, data?.metadata, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data ? dataTable.data : [],
        manualPagination: true,
        pageCount: dataTable.metadata ? data.metadata.total : 0,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      useFlexLayout
    );

  const handleSortColumn = (column: HeaderGroup<object>) => {
    setCurrentPage(1);
    setSortBy(column.id);
    if (column.id === sortBy) {
      return setSort((sort) => (sort === 'asc' ? 'desc' : 'asc'));
    }
    setSort('asc');
  };

  const handlePaginating = (data: ITablePaginate) => {
    setCurrentPage(data.perPage === pageSize ? data.currPage : 1);
    setPageSize(data.perPage);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              onMouseEnter={() => setHighlighedRowId(row.id)}
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey px-2 group hover:bg-lightPink"
            >
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className={`text-left p-0 py-1.9`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="px-2">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div
                      className="flex items-center gap-x-0.9 cursor-pointer"
                      onClick={() => handleSortColumn(column)}
                    >
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>

                      <span>
                        {isActionColumn(column.id) && (
                          <SortArrow
                            className={`${
                              column.id === sortBy && 'fill-magenta'
                            } ${
                              column.id === sortBy &&
                              sort === 'asc' &&
                              'rotate-180'
                            } transition-all`}
                          />
                        )}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {renderTableBody()}
      </table>
      {dataTable.metadata && dataTable.metadata.total > 0 && (
        <div className="py-1.6">
          <Pagination
            isBorderNotIncluded
            handlePaginating={handlePaginating}
            data={{
              currPage: dataTable.metadata.page,
              perPage: dataTable.metadata.limit,
              total: dataTable.metadata.total,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Table;

export const ROUTES = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  APPOINTMENT: '/appointments',
  SCHEDULE: '/schedule',
  OFFICE_SCHEDULE: '/office-schedule',
  PRACTICE_PROFILE: '/practice',
  PRACTITIONER: '/practitioners',
  SERVICE: '/services',
  ONLINE_SCHEDULER: '/online-scheduler',
  USER: '/users',
  SYNC: '/sync',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SETUP_ACCOUNT: '/setup-account',
};

export const ADMIN_ROUTES = {
  DASHBOARD: '/admin/dashboard',
  APPOINTMENT: 'admin/appointments',
  PRACTICE: '/admin/practices',
  USER: '/admin/users',
  SERVICE: '/admin/services',
};

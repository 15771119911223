import useSWR from 'swr';

import axiosInstance from '../apis/axiosInstance';

export interface SearchingService {
  serviceId: string;
  duration: number;
  isSplitScheduling: boolean;
  hygienistDuration: number;
  doctorDuration: number;
}

export interface AvailableBlockDentistForSplitScheduling {
  dentistId: string;
  headAvailableBlockNumbers: number[];
  tailAvailableBlockNumbers: number[];
}

export interface AvailableBlockOperatory {
  operatoryId: string;
  blocks: number;
}

export interface IAvailableBlocks {
  date: string;
  availableBlockOperatories: AvailableBlockOperatory[];
  blocks: number[];
  dentists?: AvailableBlockDentistForSplitScheduling[];
  searchingService: SearchingService;
}

interface IUsePractitionerWeeklyTimeSlotParams {
  practitionerId: string;
  serviceId: string;
  dates: [string, string];
  currentDate: string;
  timeBlocks: number[];
  timezone: string;
}

const usePractitionerWeeklyTimeSlot = ({
  practitionerId,
  dates,
  timeBlocks,
  currentDate,
  ...rest
}: IUsePractitionerWeeklyTimeSlotParams) => {
  const query = new URLSearchParams(rest as any);

  const convertTimeBlocksToCorrectFormat = timeBlocks.reduce(
    (prev, curr) => prev + `&timeBlocks[]=${curr}`,
    ''
  );

  const url = `/practitioners/${practitionerId}/appointments/weekly-timeslots?${query}&dates[]=${dates[0]}&dates[]=${dates[1]}${convertTimeBlocksToCorrectFormat}`;

  const { data, error, mutate } = useSWR(url, async (url: string) => {
    const response = await axiosInstance.get(url, {
      params: {
        // currentDate is not stable
        currentDate,
      },
    });
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePractitionerWeeklyTimeSlot;

import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

const CountDown = ({ dateTime }: { dateTime: string }) => {
  const [time, setTime] = useState(() => {
    return Math.abs(moment(dateTime).diff(moment(), 'seconds'));
  });

  const countDown = useCallback(() => {
    setTime(time - 1);
  }, [time]);

  useEffect(() => {
    const timeout = setInterval(countDown, 1000);

    return () => {
      clearInterval(timeout);
    };
  }, [countDown]);

  const hour = moment.duration(time, 'seconds').hours();
  const minute = moment.duration(time, 'seconds').minutes();
  const second = moment.duration(time, 'seconds').seconds();

  return <div>{`${moment({ hour, minute, second }).format('HH:mm:ss')}`}</div>;
};

export default CountDown;

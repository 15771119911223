import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import DefaultLayout from 'components/Layout/DefaultLayout';
import ReloadTab from 'components/ReloadTab/ReloadTab';
import useMatchMutate from 'hooks/useMatchMutate';

const LayoutRoute = ({ isGlobalAdminPage }: { isGlobalAdminPage: boolean }) => {
  const location = useLocation();
  const [retryTime, setRetryTime] = useState(0);
  const [isOffline, setIsOffline] = useState(false);
  const matchMutate = useMatchMutate();

  const handleRetryClicked = async () => {
    setRetryTime((retryTime) => retryTime + 1);
    await matchMutate(/./, { revalidate: false });
  };

  useEffect(() => {
    if (!navigator.onLine) {
      setIsOffline(true);
    } else {
      setIsOffline(false);
    }
  }, [retryTime]);

  useEffect(() => {
    const checkOnline = () => {
      setIsOffline(false);
    };

    const checkOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', checkOnline);

    window.addEventListener('offline', checkOffline);

    return () => {
      window.removeEventListener('online', checkOnline);
      window.removeEventListener('offline', checkOffline);
    };
  }, []);

  if (isOffline) {
    return (
      <DefaultLayout isGlobalAdminPage={isGlobalAdminPage}>
        <ReloadTab onRetryClicked={handleRetryClicked} />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout isGlobalAdminPage={isGlobalAdminPage}>
      <ErrorBoundary
        key={location.pathname + retryTime}
        fallback={<ReloadTab onRetryClicked={handleRetryClicked} />}
      >
        <Outlet />
      </ErrorBoundary>
    </DefaultLayout>
  );
};

export default LayoutRoute;

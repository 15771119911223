import { FC, useState } from 'react';
import { PractitionerException } from 'interfaces/practitionerException';
import CustomDayRow from 'components/CustomDayRow/CustomDayRow';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';
import { CUSTOM_HOURS, DAY_OFF } from 'utils/constants';
import { IPractitioner } from 'interfaces/practitioners';

interface CustomDayItemProps {
  day: PractitionerException;
  onClick: (exceptionDate: PractitionerException) => void;
  version: number;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
  selectedPractitioner: IPractitioner;
}

const CustomDayItem: FC<CustomDayItemProps> = ({
  day,
  onClick,
  version,
  onCheckOutsidePractitionerHourAppts,
  selectedPractitioner,
}) => {
  const [isShown, setIsShown] = useState(false);

  const displayDate = (date: PractitionerException) => {
    if (typeof date.workingHour !== 'string') {
      return CUSTOM_HOURS;
    }

    return DAY_OFF;
  };

  return (
    <CustomDayRow
      setIsShown={setIsShown}
      onClick={onClick}
      getDisplayDate={displayDate}
      day={day}
    >
      <ThreeDotsOption
        day={day}
        isShown={isShown}
        version={version}
        onCheckOutsidePractitionerHourAppts={
          onCheckOutsidePractitionerHourAppts
        }
        selectedPractitioner={selectedPractitioner}
      />
    </CustomDayRow>
  );
};

export default CustomDayItem;

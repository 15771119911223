import { Dispatch, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info_hover.svg';
import moment from 'moment';
import { WorkingHour } from 'interfaces/clinicSchedule';

interface IExceptionDate {
  id?: string;
  workingHour: WorkingHour[] | string;
  date: string;
}

const CustomDayRow = <T extends IExceptionDate>({
  day,
  getDisplayDate,
  onClick,
  setIsShown,
  children,
}: {
  setIsShown: Dispatch<React.SetStateAction<boolean>>;
  onClick: (day: T) => void;
  getDisplayDate: (date: T) => string;
  day: T;
  children: ReactNode;
}) => {
  return (
    <div
      key={day.id}
      className="h-4.6 flex items-center px-2 bg-white hover:bg-[#F5F0F5] cursor-pointer group"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      onClick={() => onClick(day)}
    >
      <div className="flex-1 flex items-center">
        <span>{getDisplayDate(day)}</span>
        {Array.isArray(day.workingHour) && (
          <>
            <InfoIcon
              className="w-1.6 h-1.6 ml-0.6 all-child:fill-mid-grey outline-none"
              data-for={`${day.id}-info`}
              data-tip=""
            />
            <ReactTooltip
              id={`${day.id}-info`}
              place="bottom"
              effect="solid"
              className={`!bg-white !rounded-[0.8rem] !w-[12rem] !h-[3.2rem] !p-0 !opacity-100 z-[9999] flex justify-center items-center shadow-primary`}
            >
              <span className="text-11 text-darkest-grey flex h-full justify-center items-center normal-case min-w-[12rem]">
                {`${moment(day.workingHour[0].start, 'HH:mm:ss').format(
                  'hh:mmA'
                )} - ${moment(day.workingHour[0].end, 'HH:mm:ss').format(
                  'hh:mmA'
                )}`}
              </span>
            </ReactTooltip>
          </>
        )}
      </div>
      <span className="flex-1">
        {moment(day.date, 'YYYY-MM-DD').format('ddd, MMM DD')}
      </span>
      {children}
    </div>
  );
};

export default CustomDayRow;

import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import useAppointmentStatistic from 'hooks/useAppointmentStatistic';

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';
import AppointRow from '../AppointRow/AppointRow';
import { UpcomingAppointmentStatistic } from 'interfaces/appointmentStatistic';
import styles from './UpcomingAppointSection.module.css';

const UPCOMING_TITLE = [
  {
    title: 'Today',
    property: 'totalInToday' as keyof UpcomingAppointmentStatistic,
  },
  {
    title: 'This week',
    property: 'totalInThisWeek' as keyof UpcomingAppointmentStatistic,
  },
  {
    title: 'Next week',
    property: 'totalInNextWeek' as keyof UpcomingAppointmentStatistic,
  },
  {
    title: 'Next 30 days',
    property: 'totalIn30days' as keyof UpcomingAppointmentStatistic,
  },
];

const UpcomingAppointSection = () => {
  const { data, isLoading } = useAppointmentStatistic();

  const getUpcomingData = (property: keyof UpcomingAppointmentStatistic) => {
    if (!data) return;
    if (property === 'totalInToday') {
      return data.upcoming[property];
    }
    return data.upcoming[property] === 0 ? '-' : data.upcoming[property];
  };

  return (
    <div className="bg-white shadow-primary px-3 py-2 flex flex-col">
      <h4 className="text-16 font-bold leading-[2.4rem]">
        Upcoming online appointments
      </h4>
      <main className="text-14 leading-[2.1rem] text-darkest-grey font-normal flex flex-col gap-y-1.6 mt-1.6">
        {UPCOMING_TITLE.map(({ title, property }) => (
          <AppointRow
            key={title}
            title={title}
            value={getUpcomingData(property)}
            isLoading={isLoading}
            isBold={title === 'Today'}
          />
        ))}
      </main>
      <Link
        to={`${ROUTES.APPOINTMENT}?tab=accepted`}
        className="flex gap-x-0.8 items-center text-12 font-normal mt-auto self-end"
      >
        <span>View more</span>
        <ArrowRightIcon className={`w-1.2 h-1.2 ${styles.icon}`} />
      </Link>
    </div>
  );
};

export default UpcomingAppointSection;

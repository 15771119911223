import { FC } from 'react';
import { getErrorReason } from 'utils/common';
import { APPOINTMENT_STATUS } from 'utils/constants';

interface AppointmentDetailStatusProps {
  appointment: any;
}

const {
  NEW: { KEY: newStatus },
  ACCEPTED: { KEY: acceptedStatus },
  CANCELLED: { KEY: cancelledStatus },
  COMPLETED: { KEY: completedStatus },
  MISSED: { KEY: missedStatus },
} = APPOINTMENT_STATUS;

const getLabel = (appointment: any) => {
  if (getErrorReason(appointment)) {
    return 'Error';
  } else if (appointment.status === newStatus) {
    return 'New Appointment Request';
  } else if (appointment.status === acceptedStatus) {
    return 'Accepted';
  } else if (appointment.status === completedStatus) {
    return 'Completed';
  } else if (appointment.status === missedStatus) {
    return 'Missed';
  } else if (appointment.status === cancelledStatus) {
    return 'Cancelled';
  }
};

const getClassName = (appointment: any) => {
  let className =
    'flex justify-center items-center px-1 py-0.2 border-0.3 rounded-full text-12 leading-[1.8rem] ';

  if (getErrorReason(appointment)) {
    className += 'text-[#D31759] bg-[#FFF2F3]';
  } else if (appointment.status === newStatus) {
    className += 'text-magenta bg-[#FFF6FF]';
  } else if (appointment.status === acceptedStatus) {
    className += 'text-[#47B2AD] bg-[#E1F7F6]';
  } else if (appointment.status === completedStatus) {
    className += 'text-[#4D97E1] bg-[#F4FAFF]';
  } else if (appointment.status === missedStatus) {
    className += 'text-[#FFC656] bg-[#FFF9EE]';
  } else if (appointment.status === cancelledStatus) {
    className += 'text-[#51169D] bg-[#F8F8FF]';
  }
  return className;
};

const AppointmentDetailStatus: FC<AppointmentDetailStatusProps> = ({
  appointment,
}) => {
  return (
    <div className={getClassName(appointment)}>{getLabel(appointment)}</div>
  );
};

export default AppointmentDetailStatus;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { ExceptionDate } from 'interfaces/exceptionDate';

interface IUseClinicCustomDayParams {
  shouldRun?: boolean;
  source: string;
  startDate: string;
  endDate: string;
}

const useClinicCustomDay = ({
  shouldRun,
  source,
  startDate,
  endDate,
}: IUseClinicCustomDayParams) => {
  const query = new URLSearchParams({
    startDate,
    endDate,
    source,
  }).toString();

  const clinicId = loadSelectedClinicId();
  const url = `/clinics-exception/custom-days?${query}`;

  const { data, error, mutate } = useSWR<ExceptionDate[]>(
    shouldRun ? [url, clinicId] : null,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicCustomDay;

import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import useCheckUserRole from 'hooks/useCheckUserRole';
import styles from './SidebarLink.module.css';

interface SidebarLinkProps {
  to: string;
  icon: React.ElementType;
  title: string;
  isCollapsed: boolean;
  allowed?: string[];
}

const SidebarLink: FC<SidebarLinkProps> = ({
  to,
  icon: Icon,
  title,
  isCollapsed,
  allowed,
}) => {
  const { isUserNotAllowed } = useCheckUserRole(allowed);

  if (isUserNotAllowed) return null;

  return (
    <NavLink to={to} className="block">
      {({ isActive }) => (
        <div
          className={`flex items-center gap-x-1 overflow-hidden py-1.2 h-4 relative ${
            isActive && 'bg-[#F7EDF7]'
          }`}
        >
          {isActive && (
            <span className="w-0.3 bg-magenta absolute h-[calc(100%-16px)]"></span>
          )}
          <div data-for={title} data-tip="" className="flex shrink-0 ml-1.2">
            <Icon className={`${isActive && styles.active}`} />
          </div>
          {isCollapsed ? (
            <ReactTooltip
              id={title}
              place="right"
              effect="solid"
              className={`${styles.tooltip} shadow-tooltip`}
            >
              <span className="text-11 text-darkest-grey flex h-full items-center">
                {title}
              </span>
            </ReactTooltip>
          ) : (
            <span
              className={`flex ${isActive && 'text-magenta'} ${
                isCollapsed && 'opacity-0'
              }`}
            >
              {title}
            </span>
          )}
        </div>
      )}
    </NavLink>
  );
};

export default SidebarLink;

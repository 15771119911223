import { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';
import { useUser } from 'context/userContext';
import { Controller } from 'react-hook-form';
import TimeDropdown from 'components/TimeDropdown/TimeDropdown';
import { TIME_DURATION_OPTIONS } from 'utils/constants';

interface UnitDurationSectionProps {
  control: any;
}

const UnitDurationSection: FC<UnitDurationSectionProps> = ({ control }) => {
  const { user } = useUser();

  const isGlobalAdmin = !!user.globalAdminRole;

  return (
    <>
      <div className="flex justify-between items-center text-14 leading-[2.1rem]">
        <div className="flex items-center gap-x-0.8">
          <h3 className="font-bold">PMS Unit Duration</h3>
          <button data-for="unitDurationInfo" data-tip="">
            <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
          </button>
          <ReactTooltip
            id="unitDurationInfo"
            effect="solid"
            place="bottom"
            className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
          >
            <span className="text-11 text-darkest-grey flex items-center font-normal">
              {isGlobalAdmin
                ? 'Set your pms unit duration to structure the Schedule page and optimize scheduling. Choose from: 5, 10, 15, 20, or 30 minutes.'
                : 'Please contact FirstIn Support if you wish to update the unit duration.'}
            </span>
          </ReactTooltip>
        </div>
      </div>
      <Controller
        name={'unitDuration'}
        control={control}
        render={({ field }) => {
          return (
            <TimeDropdown
              options={TIME_DURATION_OPTIONS}
              value={field.value}
              listBoxProps={{ ...field, disabled: !isGlobalAdmin }}
              listBoxButtonClassName={`${
                !isGlobalAdmin ? 'pointer-events-none opacity-40' : ''
              }`}
            />
          );
        }}
      />
    </>
  );
};

export default UnitDurationSection;

import React from 'react';
import moment from 'moment';
import useAppointmentStatistic from 'hooks/useAppointmentStatistic';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import AppointmentSection from './AppointmentSection/AppointmentSection';

import { UpToDateAppointmentStatistic } from 'interfaces/appointmentStatistic';

const TOTAL_DATA = [
  {
    title: 'Total online appointments',
    property: 'totalAppointments' as keyof UpToDateAppointmentStatistic,
  },
  {
    title: 'Total online patients',
    property: 'totalPatients' as keyof UpToDateAppointmentStatistic,
  },
];

const TotalAppointSection = () => {
  const { data, isLoading } = useAppointmentStatistic();
  const getTotalData = (property: keyof UpToDateAppointmentStatistic) => {
    if (!data) return;
    return data.upToDate[property] === 0 ? '-' : data.upToDate[property];
  };

  return (
    <div className="bg-white shadow-primary px-3 py-2 flex flex-col">
      {TOTAL_DATA.map(({ title, property }, index) => (
        <AppointmentSection
          title={title}
          isLoading={isLoading}
          key={title}
          value={getTotalData(property)}
          isMarginTopIncluded={index === 1}
        />
      ))}
      {isLoading ? (
        <LoadingSpinner className="text-12 text-darkest-grey font-normal mt-auto self-end all-child:fill-magenta" />
      ) : (
        <span className="text-12 text-darkest-grey font-normal mt-auto self-end">
          Since {moment(data!.clinic.createdAt).format('MMM YYYY')}
        </span>
      )}
    </div>
  );
};

export default TotalAppointSection;

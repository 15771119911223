import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

import styles from './SidebarLink.module.css';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';

interface BackToMenuProps {
  isCollapsed: boolean;
  isGlobalAdminPage: boolean;
}

const BackToMenu: FC<BackToMenuProps> = ({
  isCollapsed,
  isGlobalAdminPage,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className="flex gap-x-1 items-center overflow-hidden pl-1.8 h-3 mt-2.6"
        onClick={() => {
          navigate(
            isGlobalAdminPage ? ADMIN_ROUTES.DASHBOARD : ROUTES.DASHBOARD
          );
        }}
      >
        <div data-for={'backToMenu'} data-tip="" className="flex flex-shrink-0">
          <ArrowRightIcon className="rotate-180" />
        </div>
        {isCollapsed ? (
          <ReactTooltip
            id={'backToMenu'}
            place="right"
            effect="solid"
            className={`${styles.tooltip} shadow-tooltip`}
          >
            <span className="text-11 text-darkest-grey flex h-full items-center">
              Back to menu
            </span>
          </ReactTooltip>
        ) : (
          <span className={`${isCollapsed && 'opacity-0'} truncate`}>
            Back to menu
          </span>
        )}
      </button>
      <span className="text-12 leading-[1.8rem] font-bold uppercase px-2.1 mt-3.8">
        {isCollapsed ? 'S' : 'Settings'}
      </span>
    </>
  );
};

export default BackToMenu;

import { FC, useRef, useState } from 'react';
import { IPractitioner } from 'interfaces/practitioners';
import TabButton from './TabButton/TabButton';
import PractitionerProfile from './PractitionerProfile/PractitionerProfile';
import PractitionerHours from './PractitionerHours/PractitionerHours';
import LeavePageModal from 'components/LeavePageModal/LeavePageModal';
import { getIsPageDirty } from 'utils/storage';
import getPractitionerName from 'utils/getPractitionerName';

interface MainSectionProps {
  selectedPractitioner: IPractitioner;
  setSelectedPractitioner: React.Dispatch<
    React.SetStateAction<IPractitioner | null>
  >;
}

const MainSection: FC<MainSectionProps> = ({
  selectedPractitioner,
  setSelectedPractitioner,
}) => {
  const [isProfileTab, setIsProfileTab] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const cachedProfileTab = useRef<{ isProfileTab: boolean | null }>({
    isProfileTab: null,
  });

  const handleChangeTab = (isProfileTab: boolean) => {
    if (getIsPageDirty()) {
      cachedProfileTab.current.isProfileTab = isProfileTab;
      return setIsModalOpen(true);
    }

    setIsProfileTab(isProfileTab);
  };

  const handleOnSubmit = () => {
    setIsModalOpen(false);

    setIsProfileTab(cachedProfileTab.current.isProfileTab!);
    cachedProfileTab.current.isProfileTab = null;
  };

  return (
    <>
      {isModalOpen && (
        <LeavePageModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleOnSubmit}
        />
      )}
      <div
        className="relative flex-1 px-4 pt-2.4 text-14 leading-[2.1rem]"
        key={`${selectedPractitioner.id}${selectedPractitioner.updatedAt}`}
      >
        <h3 className="text-24 leading-[3.6rem] font-bold">
          {getPractitionerName(selectedPractitioner)}
        </h3>
        <div className="mt-2 flex gap-x-2.4">
          <TabButton
            isActive={!isProfileTab}
            onClick={() => handleChangeTab(false)}
          >
            Hours
          </TabButton>
          <TabButton
            isActive={isProfileTab}
            onClick={() => handleChangeTab(true)}
          >
            Profile
          </TabButton>
        </div>
        {isProfileTab ? (
          <PractitionerProfile
            selectedPractitioner={selectedPractitioner}
            setSelectedPractitioner={setSelectedPractitioner}
          />
        ) : (
          <PractitionerHours selectedPractitioner={selectedPractitioner} />
        )}
      </div>
    </>
  );
};

export default MainSection;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';

const useAppointmentDetail = (appointmentId: string, shouldRun = true) => {
  const { data, error, mutate } = useSWR(
    shouldRun ? `/appointments/${appointmentId}` : null,
    async (url) => {
      const response = await axiosInstance.get(url);

      return response.data;
    }
  );
  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAppointmentDetail;

import { FC, Fragment, useMemo, useState } from 'react';
import moment from 'moment';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as AddIcon } from 'assets/icons/add_circle_outline.svg';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';

import usePractitionerCustomDay from 'hooks/usePractitionerCustomDay';
import { IPractitioner } from 'interfaces/practitioners';
import { DEFAULT_WORKING_HOUR, NEXT_23_MONTH } from 'utils/constants';
import normalizeCustomDay from 'utils/normalizeCustomDay';
import CustomDayItem from './CustomDayItem/CustomDayItem';
import { PractitionerException } from 'interfaces/practitionerException';
import useScrollToMonthCalendar from 'hooks/useScrollToMonthCalendar';
import EditScheduleModal from '../EditScheduleModal/EditScheduleModal';

interface CustomDaysListProps {
  selectedPractitioner: IPractitioner;
  setDate: (date: string) => void;
  isNavigatedByCalendar: boolean;
  monthValue: string;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
  version: number;
}

const CustomDaysList: FC<CustomDaysListProps> = ({
  selectedPractitioner,
  setDate,
  isNavigatedByCalendar,
  monthValue,
  onCheckOutsidePractitionerHourAppts,
  version,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading } = usePractitionerCustomDay({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(NEXT_23_MONTH, 'months').format('YYYY-MM-DD'),
    practitionerId: selectedPractitioner.id,
  });

  const normalizeData = useMemo(() => normalizeCustomDay(data), [data]);

  const { register } = useScrollToMonthCalendar({
    isNavigatedByCalendar,
    monthValue,
    data,
  });

  const handleOnRowClick = (exceptionDate: PractitionerException) => {
    setDate(exceptionDate.date);
  };

  const isPractitionerHaveCustomDays = data && normalizeData.length > 0;

  return (
    <>
      {isModalOpen && (
        <EditScheduleModal
          isCreatedMode
          selectedDate={{
            date: moment().format('YYYY-MM-DD'),
            workingHour: [
              {
                start: DEFAULT_WORKING_HOUR.START,
                end: DEFAULT_WORKING_HOUR.END,
              },
            ],
          }}
          onClose={() => setIsModalOpen(false)}
          selectedPractitioner={selectedPractitioner}
          onCheckOutsidePractitionerHourAppts={
            onCheckOutsidePractitionerHourAppts
          }
          version={version}
        />
      )}
      <div className="w-full border border-t-0 border-light-grey">
        {isLoading ? (
          <div className="flex w-full h-[43.4rem] justify-center items-center">
            <LoadingSpinner className="all-child:fill-magenta" />
          </div>
        ) : (
          <div className="relative">
            <div className="h-[39.4rem] overflow-y-auto scrollbar pb-1.6">
              {!isPractitionerHaveCustomDays ? (
                <div className="flex flex-col justify-center items-center h-full gap-y-2">
                  <EmptyBucket />
                  <p>No custom days to display</p>
                </div>
              ) : (
                normalizeData.map((data) => (
                  <Fragment key={data.monthlyTitle}>
                    <div
                      key={data.monthlyTitle}
                      className="h-4.6 flex items-center px-2 bg-lightest-grey font-bold"
                      {...register(data.monthlyTitle)}
                    >
                      {data.monthlyTitle}
                    </div>
                    {data.days.map((day) => (
                      <CustomDayItem
                        key={day.id}
                        day={day}
                        onClick={handleOnRowClick}
                        version={version}
                        onCheckOutsidePractitionerHourAppts={
                          onCheckOutsidePractitionerHourAppts
                        }
                        selectedPractitioner={selectedPractitioner}
                      />
                    ))}
                  </Fragment>
                ))
              )}
            </div>
            <button
              className="w-full h-4 flex bg-white justify-center border-t border-light-grey items-center text-magenta gap-x-1 z-0"
              onClick={() => setIsModalOpen(true)}
            >
              <AddIcon className="w-2 h-2 all-child:all-child:fill-magenta" />
              <span className="font-bold">Add custom day</span>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDaysList;

import React, { forwardRef, LegacyRef, useEffect, useState } from 'react';
import moment from 'moment';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

import styles from './MonthInput.module.css';
import { NEXT_23_MONTH } from 'utils/constants';

interface IProps {
  value?: string;
  maxDate?: Date;
  onClick?: () => void;
  onClickPrevious: () => void;
  onClickNext: () => void;
}

const MonthInput = (props: IProps, ref: LegacyRef<HTMLDivElement>) => {
  const [isDisabledRight, setIsDisabledRight] = useState(false);

  const isCurrentMonth = moment(props.value, 'MMMM YYYY')
    .clone()
    .isSame(moment(), 'months');

  useEffect(() => {
    if (props.maxDate) {
      const next11Month = moment()
        .add(NEXT_23_MONTH, 'months')
        .format('YYYY-MM-DD');
      const date = moment(props.value, 'MMMM YYYY')
        .clone()
        .add(1, 'months')
        .format('YYYY-MM-DD');

      if (
        moment(date, 'YYYY-MM-DD').isAfter(moment(next11Month, 'YYYY-MM-DD'))
      ) {
        setIsDisabledRight(true);
      } else {
        setIsDisabledRight(false);
      }
    }
  }, [props.value, props.maxDate]);

  return (
    <div className={styles.inputWrapper} ref={ref}>
      <button
        disabled={isCurrentMonth}
        className={styles.leftArrowBtn}
        onClick={props.onClickPrevious}
      >
        <LeftArrow className="w-1.6 h-1.6" />
      </button>
      <div className={styles.label} onClick={props.onClick}>
        {props.value}
      </div>
      <button
        className={styles.rightArrowBtn}
        onClick={props.onClickNext}
        disabled={isDisabledRight}
      >
        <RightArrow className="w-1.6 h-1.6" />
      </button>
    </div>
  );
};

export default forwardRef(MonthInput);

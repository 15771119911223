import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { IChairHeaderCellProps } from '../HeaderCell';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import AvatarPlaceHolder from 'assets/images/no-avatar.svg';
import getPractitionerWorkingHour from '../utils/getPractitionerWorkingHour';
import getPractitionerName from 'utils/getPractitionerName';
import { DoctorSchedule } from 'interfaces/clinicSchedule';

interface ChairHeaderCellProps {
  headerCellItem: IChairHeaderCellProps;
  practitioners?: IMapPractitionerColor[];
  date: string;
  workingHourPractitioners: (DoctorSchedule & { name: string })[];
}

const ChairHeaderCell: FC<ChairHeaderCellProps> = ({
  headerCellItem,
  practitioners,
  date,
  workingHourPractitioners,
}) => {
  const parentHeaderCell = document.querySelector('div[data-header-cell=true]');

  const getIsPractitionerAssignedChairFromFirstIn = (
    practitioner: IMapPractitionerColor
  ) => {
    if (!practitioner.isActive) return;
    return practitioner.operatories?.find(
      (operatory) => operatory.id === headerCellItem.id
    );
  };

  const practitionersAssignedChair =
    practitioners?.filter((practitioner) =>
      getIsPractitionerAssignedChairFromFirstIn(practitioner)
    ) ?? [];

  const getPractitionersAssignedChairCell = () => {
    if (!parentHeaderCell) return practitionersAssignedChair;

    const paddingY = 20;
    const parentWidth = parentHeaderCell.clientWidth - paddingY;

    const numberOfItem = practitionersAssignedChair.length;

    const imgWidth = 16;

    const spaceNeeded = numberOfItem * imgWidth;

    // ALWAYS EXLUDE TWO LAST ITEMS IF POSSIBLE
    const indexOfEnd = Math.floor(parentWidth / imgWidth) - 1;

    return parentWidth >= spaceNeeded
      ? practitionersAssignedChair
      : [
          ...practitionersAssignedChair.slice(0, indexOfEnd),
          {
            id: 'none',
            avatar: 'none',
            name: `+${numberOfItem - indexOfEnd}`,
            colorCode: 'none',
          },
        ];
  };

  return (
    <div className="flex flex-col justify-center items-center h-full leading-[18px] px-0.8">
      <div className="text-center w-full font-bold text-[12px]">
        {headerCellItem.name}
      </div>
      {practitionersAssignedChair.length > 0 && (
        <>
          <div
            className="flex cursor-pointer"
            data-for={`practitioners-assigned-chair-${headerCellItem.id}`}
            data-tip=""
          >
            {getPractitionersAssignedChairCell().map((headerCellItem) =>
              headerCellItem.id === 'none' ? (
                <div
                  key={headerCellItem.id}
                  className="flex items-center justify-center w-1.8 h-1.8 text-[9px] leading-[1.8rem] bg-light-grey rounded-full shrink-0"
                >
                  {headerCellItem.name}
                </div>
              ) : (
                <img
                  key={headerCellItem.id}
                  src={headerCellItem.avatar || ''}
                  alt={headerCellItem.name}
                  className="rounded-full object-cover w-1.6 h-1.6 aspect-square self-start outline"
                  onError={(event) => {
                    event.currentTarget.src = AvatarPlaceHolder;
                  }}
                  style={{ outlineColor: headerCellItem.colorCode }}
                />
              )
            )}
          </div>
          <ReactTooltip
            id={`practitioners-assigned-chair-${headerCellItem.id}`}
            effect="solid"
            place="bottom"
            className="!bg-white !rounded-[0.8rem] !w-auto !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden !px-1"
          >
            <div className="flex flex-col gap-y-0.6">
              {practitionersAssignedChair.map((practitioner) => (
                <div
                  className="flex items-center gap-x-0.6"
                  key={practitioner.id}
                >
                  <img
                    src={practitioner.avatar || ''}
                    alt={practitioner.name}
                    className="rounded-full object-cover w-1.6 h-1.6 aspect-square self-start outline"
                    onError={(event) => {
                      event.currentTarget.src = AvatarPlaceHolder;
                    }}
                    style={{ outlineColor: practitioner.colorCode }}
                  />
                  <p className="flex items-center gap-x-0.6 text-darkest-grey text-11">
                    <span className="">
                      {getPractitionerName(practitioner)}
                    </span>
                    <span>
                      {getPractitionerWorkingHour(
                        practitioner.id,
                        workingHourPractitioners
                      )}
                    </span>
                  </p>
                </div>
              ))}
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

export default ChairHeaderCell;

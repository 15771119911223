import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import EditOrCreateUser, {
  IEditOrCreateUserForm,
} from 'pages/UserPage/EditOrCreateUser/EditOrCreateUser';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import { renderToast } from 'components/Toast/Toast';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { CLINIC_USER_STATUS, ROLE } from 'utils/constants';
import { ClinicUser } from 'interfaces/clinicUser';

interface ThreeDotsOptionProps {
  isLoggedInAdmin: boolean;
  data: ClinicUser;
}

const OPTIONS = {
  EDIT: { label: 'Edit', value: 'edit' },
  ACTIVATE: { label: 'Activate', value: 'activate' },
  DEACTIVATE: { label: 'Deactivate', value: 'deactivate' },
  RESEND: { label: 'Resend invitation', value: 'resend' },
  DELETE: { label: 'Delete', value: 'delete' },
};

const { EDIT, ACTIVATE, DEACTIVATE, RESEND, DELETE } = OPTIONS;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  isLoggedInAdmin,
  data,
}) => {
  const [modalMode, setModalMode] = useState<typeof EDIT | null>(null);
  const matchMutate = useMatchMutate();

  const url = '/clinic-user/auth/update-account';

  const defaultValues = {
    email: data.clinicUser.email,
    firstName: data.firstName,
    lastName: data.lastName,
    role: data.role,
  };

  const getOptions = () => {
    const status = data.status;

    const options = [EDIT];

    if (isLoggedInAdmin) {
      return options;
    }

    if (status === 'active') {
      options.push(DEACTIVATE);
    }

    if (status === 'inactive') {
      options.push(ACTIVATE);
    }

    if (status === 'pending') {
      options.push(RESEND);
    }

    options.push(DELETE);

    return options;
  };

  const onError = (error: any) => {
    let message = 'Something went wrong. Please try again later';
    if (error.response?.status === BAD_REQUEST) {
      message = error.response?.data.message;
    }

    renderToast({
      type: 'error',
      message,
    });
  };

  const handleOptionClicked = (option: typeof EDIT) => {
    setModalMode(option);
  };

  const onEditUser = async (formData: IEditOrCreateUserForm) => {
    try {
      await axiosInstance.patch(url, {
        clinicUserId: data.clinicUserId,
        clinics: [
          {
            id: null,
            role: formData.role,
            firstName: formData.firstName,
            lastName: formData.lastName,
          },
        ],
      });

      await matchMutate(/\/clinic-users\/global-admin\?[\s\S]+/);
      renderToast({
        message: 'User’s information has updated successfully',
        type: 'success',
      });
      onClose();
    } catch (error: any) {
      onError(error);
    }
  };

  const onDeleteUser = async () => {
    try {
      await axiosInstance.delete(
        `/clinic-user/auth/${data.clinicUserId}/clinic-user-roles/${data.id}`
      );
      renderToast({
        message: `${data.clinicUser.email} has been deleted successfully`,
        type: 'success',
      });

      await matchMutate(/\/clinic-users\/global-admin\?[\s\S]+/);
      onClose();
    } catch (error: any) {
      onError(error);
    }
  };

  const onResend = async () => {
    try {
      await axiosInstance.post('/clinic-user/auth/setup-account-link', {
        email: data.clinicUser.email,
        clinicId: null,
      });
      renderToast({
        message: 'Invitation email has been sent successfully',
        type: 'success',
      });
      onClose();
    } catch (error) {
      onError(error);
    }
  };

  const onUpdateStatus = async () => {
    const isActivateModal = modalMode === ACTIVATE;
    try {
      await axiosInstance.patch('/clinic-user/auth/update-account', {
        clinicUserId: data.clinicUserId,
        clinics: [
          {
            id: null,
            status: isActivateModal
              ? CLINIC_USER_STATUS.ACTIVE
              : CLINIC_USER_STATUS.INACTIVE,
            role: ROLE.GLOBAL_ADMIN,
            firstName: data.firstName,
            lastName: data.lastName,
          },
        ],
      });
      renderToast({
        message: `${data.clinicUser.email} has been ${
          isActivateModal ? 'activated' : 'deactivated'
        }  successfully`,
        type: 'success',
      });
      await matchMutate(/\/clinic-users\/global-admin\?[\s\S]+/);
      onClose();
    } catch (error) {
      onError(error);
    }
  };

  const onClose = () => setModalMode(null);

  return (
    <>
      {modalMode === EDIT && (
        <EditOrCreateUser
          isGlobalAdminMode
          defaultValues={defaultValues}
          onClose={onClose}
          onSubmit={onEditUser}
        />
      )}
      {modalMode === RESEND && (
        <ConfirmationModal
          title="Resend invitation?"
          description={`Are you sure you want to resend the invitation to ${data.clinicUser.email}?`}
          onClose={onClose}
          onSubmit={onResend}
          submitBtnTitle="Yes, resend"
        />
      )}
      {modalMode === DELETE && (
        <ConfirmationModal
          title="Delete user?"
          description={`Are you sure you want to delete ${data.clinicUser.email}? This action cannot be undone.`}
          onClose={onClose}
          onSubmit={onDeleteUser}
          submitBtnTitle="Yes, delete"
        />
      )}
      {(modalMode === ACTIVATE || modalMode === DEACTIVATE) && (
        <ConfirmationModal
          title={`${modalMode.label} user?`}
          description={`Are you sure you want to ${modalMode.value} ${data.clinicUser.email}?`}
          onClose={onClose}
          onSubmit={onUpdateStatus}
          submitBtnTitle={`Yes, ${modalMode.value}`}
        />
      )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button className="flex items-center disabled:cursor-not-allowed disabled:opacity-30">
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 w-16.5 bg-white shadow-primary rounded-[2rem] mt-0.6">
          {getOptions().map((option) => (
            <Popover.Button
              key={option.value}
              className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left"
              onClick={() => handleOptionClicked(option)}
            >
              {option.label}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;

import React from 'react';
import useOperatory from 'hooks/useOperatory';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { sortBy } from 'lodash';

import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const OperatoryDropdown = (props: IProps) => {
  const { data: operatories, isLoading } = useOperatory();
  const operatoryOptions = operatories?.map((operatory) => ({
    value: operatory.id,
    label: operatory.name,
    isDisabled: !operatory.status,
  }));

  return (
    <Controller
      {...props}
      name="operatories"
      render={({ field, fieldState }) => {
        const { onChange, value } = field;
        return (
          <MultipleSelect
            isError={!!fieldState.error}
            key={`${isLoading}`}
            isLoading={isLoading}
            placeholder={'Select Chairs'}
            options={sortBy(operatoryOptions, 'label')}
            value={isLoading ? [] : value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
          />
        );
      }}
    />
  );
};

export default OperatoryDropdown;

import { useState } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import EmailTemplate from './EmailTemplate/EmailTemplate';
import useSync from 'hooks/useSync';
import { SYNC_STATUS } from 'utils/constants';
import SyncStatus from 'pages/SyncPage/SyncStatus/SynStatus';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import LatestSyncMessage from '../LatestSyncMessage/LatestSyncMessage';

const EmailBlastSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading, mutate } = useSync();

  const onSendMail = async (patientStatus: string[]) => {
    try {
      await axiosInstance.post('/clinics/online-intro', {
        patientStatus,
      });

      setIsModalOpen(false);

      renderToast({
        message: 'Intro email has been sent successfully',
        type: 'success',
      });
    } catch (error: any) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });

      setIsModalOpen(false);
    }

    mutate();
  };

  const patient = data?.onlineIntro?.patient || null;

  const activePatients =
    patient && patient.totalActivePatientWithOnlineIntro !== 0
      ? `${patient.totalActivePatientWithOnlineIntro} Active`
      : '';

  const inactivePatients =
    patient && patient.totalInactivePatientWithOnlineIntro !== 0
      ? `and ${patient.totalInactivePatientWithOnlineIntro} Inactive`
      : '';

  return (
    <>
      {isModalOpen && patient && (
        <EmailTemplate
          activePatientAmount={patient.totalActivePmsPatient}
          inactivePatientAmount={patient.totalInactivePmsPatient}
          onClose={() => setIsModalOpen(false)}
          onSendMail={onSendMail}
          lastSyncAt={data?.onlineIntro?.lastSyncAt || null}
        />
      )}
      <div className="py-2 border-b border-light-grey">
        <div className="flex justify-between">
          <div className="basis-[45rem]">
            <div className="flex gap-x-0.9">
              <h3 className="font-bold">Online Intro. Email Blast</h3>
            </div>
            <p className="mt-2">
              Click the Preview Email Template button to send the intro email to
              patients in your Practice Software
            </p>
          </div>
          <div className="flex flex-col items-end">
            <CommonButton
              variant="secondary"
              className="!min-h-[3.2rem] px-4 py-0.5"
              onClick={() => setIsModalOpen(true)}
              isLoading={patient && isLoading}
              disabled={
                data?.onlineIntro.status === SYNC_STATUS.IN_PROGRESS ||
                isLoading
              }
            >
              Preview Email Template
            </CommonButton>
          </div>
        </div>
        <div className="flex flex-col gap-y-0.8 mt-2">
          <SyncStatus
            isLoading={isLoading}
            data={{
              ...data?.onlineIntro,
              status:
                patient && isLoading
                  ? SYNC_STATUS.IN_PROGRESS
                  : data?.onlineIntro?.status,
            }}
            successfulMessage={
              patient
                ? `Sent to ${activePatients} ${inactivePatients} patients in PMS successfully`
                : ''
            }
          />
          <LatestSyncMessage
            isLoading={isLoading}
            lastSyncAt={data?.onlineIntro?.lastSyncAt}
            lastSuccessSyncAt={data?.onlineIntro?.lastSuccessSyncAt}
            successfulMessage="Last successful sent"
          />
        </div>
      </div>
    </>
  );
};

export default EmailBlastSection;

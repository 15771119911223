import { range } from 'lodash';
import {
  AvailableBlockDentistForSplitScheduling,
  AvailableBlockOperatory,
  IAvailableBlocks,
  SearchingService,
} from 'hooks/usePractitionerWeeklyTimeSlot';
import { EXAM_PLACEMENT } from 'utils/constants';

export const BOOKING_SPILT_SCHEDULING_TYPE = {
  HEAD: 'head' as const, // Book dentist first, then hygienist
  TAIL: 'tail' as const, // Book hygienist first, then dentist
};

export const getOperatoryId = ({
  selectedTimeSlot,
  availableBlocks,
}: {
  selectedTimeSlot: {
    date: string;
    value: number;
  };
  availableBlocks: IAvailableBlocks[];
}) => {
  const operatoryId =
    availableBlocks
      .find((item: any) => item.date === selectedTimeSlot!.date)
      ?.availableBlockOperatories.find((item: any) =>
        item.blocks.includes(selectedTimeSlot!.value)
      )?.operatoryId || null;

  return operatoryId;
};

const isFirstContainedBySecondBlockNumbers = (
  firstBlockNumbers: number[],
  secondBlockNumbers: number[]
) => {
  return firstBlockNumbers.every((item) => secondBlockNumbers.includes(item));
};

export const getDentistIdForSplitScheduling = ({
  date,
  timeSlot,
  availableBlocks,
}: {
  date: string;
  timeSlot: number;
  availableBlocks: {
    date: string;
    availableBlockOperatories?: AvailableBlockOperatory[];
    blocks: number[];
    searchingService: SearchingService;
    dentists?: AvailableBlockDentistForSplitScheduling[];
  }[];
}) => {
  const availableBlock = availableBlocks.find((item) => item.date === date);

  const searchingService = availableBlock?.searchingService;

  const doctorDuration = searchingService?.doctorDuration || 0;
  const hygienistDuration = searchingService?.hygienistDuration || 0;

  const headRange = range(timeSlot, timeSlot + doctorDuration);
  const tailRange = range(
    timeSlot + hygienistDuration,
    timeSlot + hygienistDuration + doctorDuration
  );

  const dentists = availableBlock?.dentists || [];

  const isExistingHead = dentists.some((item) =>
    isFirstContainedBySecondBlockNumbers(
      headRange,
      item.headAvailableBlockNumbers
    )
  );

  const isExistingTail = dentists.some((item) =>
    isFirstContainedBySecondBlockNumbers(
      tailRange,
      item.tailAvailableBlockNumbers
    )
  );

  if (isExistingHead && isExistingTail) {
    return {
      id: null,
      timeSlot,
      timeSlotType: null,
    };
  }

  if (isExistingHead && !isExistingTail) {
    return {
      id: null,
      timeSlot,
      timeSlotType: EXAM_PLACEMENT.HEAD,
    };
  }

  if (isExistingTail && !isExistingHead) {
    return {
      id: null,
      timeSlot,
      timeSlotType: EXAM_PLACEMENT.TAIL,
    };
  }

  return {
    id: null,
    timeSlot,
    timeSlotType: null,
  };
};

import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import { DatePickerInput } from '@mantine/dates';
import moment from 'moment';
import './DateRange.module.css';

import { NEXT_23_MONTH } from 'utils/constants';

export interface IDateRange {
  dateFrom: Date | null;
  dateTo: Date | null;
}

interface DateRangeProps {
  dateRange: IDateRange;
  setDateRange: Dispatch<SetStateAction<IDateRange>>;
  dateRangePickerRef: MutableRefObject<HTMLButtonElement | null>;
  excludeDates?: Date[];
}

const DateRange: FC<DateRangeProps> = ({
  dateRange,
  setDateRange,
  dateRangePickerRef,
  excludeDates,
}) => {
  const { dateFrom, dateTo } = dateRange;

  return (
    <>
      <div id="date-range-picker" className="relative">
        <DatePickerInput
          minDate={moment().toDate()}
          maxDate={moment().add(NEXT_23_MONTH, 'months').toDate()}
          type="range"
          numberOfColumns={2}
          size="xl"
          withCellSpacing={false}
          firstDayOfWeek={0}
          ref={(instance) => (dateRangePickerRef.current = instance)}
          value={[dateFrom, dateTo]}
          onChange={(value) => {
            const isUserClickOutside = !value[0] && !value[1];
            if (isUserClickOutside) return;

            setDateRange({ dateFrom: value[0]!, dateTo: value[1] });
          }}
          popoverProps={{ classNames: { dropdown: '!top-4 !-left-8' } }}
          excludeDate={(date) => {
            if (excludeDates) {
              return !!excludeDates.find((excludeDate) =>
                moment(date).isSame(excludeDate)
              );
            }
            return false;
          }}
        />
      </div>
      <div className="flex items-center">
        <span className="text-13 leading-[2rem]">From</span>
        <button
          className="w-12.4 h-3 rounded-[1rem] shadow-primary flex justify-center items-center ml-2.3 bg-white border-lightest-grey "
          onClick={() => {
            dateRangePickerRef.current?.click();
          }}
        >
          {dateFrom ? moment(dateFrom).format('MMM DD YYYY') : ''}
        </button>
      </div>
      <div className="flex items-center">
        <span className="mx-0.9">to</span>
        <button
          className="w-12.4 h-3 rounded-[1rem] shadow-primary flex justify-center items-center bg-white border-lightest-grey"
          onClick={() => {
            dateRangePickerRef.current?.click();
          }}
        >
          {dateTo ? moment(dateTo).format('MMM DD YYYY') : ''}
        </button>
      </div>
    </>
  );
};

export default DateRange;

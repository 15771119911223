import React, { FC, useState } from 'react';
import moment from 'moment';
import axios from 'axios';

import { ExceptionDate } from 'interfaces/exceptionDate';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import EditScheduleModal from 'pages/OfficeSchedulePage/EditScheduleModal/EditScheduleModal';
import { ClinicException } from 'interfaces/clinicException';
import { useVersion } from 'pages/OfficeSchedulePage/context/versionContext';
import CustomDayPopover from 'components/CustomDayPopover/CustomDayPopover';

interface ThreeDotsOptionProps {
  day: ExceptionDate;
  source: string;
  isShown: boolean;
  version: number;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
}

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  day,
  source,
  isShown,
  version,
  onCheckOutsideOfficeHourAppts,
}) => {
  const matchMutate = useMatchMutate();
  const { setIsOldVersion } = useVersion();
  const [modal, setModal] = useState<'edit' | 'delete' | null>(null);

  const handleDeleteCustomDay = async () => {
    try {
      await axiosInstance.delete('/clinics-exception', {
        params: {
          source,
        },
        headers: {
          version,
        },
        data: [{ id: day.id }],
      });
      renderToast({
        type: 'success',
        message: 'Deleted a custom day successfully',
      });

      await matchMutate(/\/clinics-exception[\s\S]+/);

      setModal(null);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again. ',
      });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {modal === 'delete' && (
        <ConfirmationModal
          title={'Delete this custom day?'}
          description={`Are you sure you want to delete this custom day on ${moment(
            day.date,
            'YYYY-MM-DD'
          ).format(
            'MMMM DD, YYYY'
          )}? This will revert this day to regular hours.`}
          onClose={() => setModal(null)}
          onSubmit={handleDeleteCustomDay}
          submitBtnTitle="Yes, delete"
        />
      )}
      {modal === 'edit' && (
        <EditScheduleModal
          source={source}
          selectedDate={
            {
              ...day,
              isException: true,
              day: moment(day.date, 'YYYY-MM-DD').format('ddd'),
            } as ClinicException
          }
          onClose={() => setModal(null)}
          onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
          version={version}
        />
      )}
      <CustomDayPopover isShown={isShown} setModal={setModal} />
    </div>
  );
};

export default ThreeDotsOption;

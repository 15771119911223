import moment from 'moment';
import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { IAdminStatisticResponse } from 'interfaces/adminStatistic';

const useAdminStatistic = () => {
  const { data, error, mutate } = useSWR(
    ['/statistics/admin'],
    async (...resource) => {
      const [url] = resource;
      const currentDate = moment().format('YYYY-MM-DD');

      const response = await axiosInstance.get<IAdminStatisticResponse>(url, {
        params: {
          appointmentDates: [
            moment
              .parseZone(currentDate, 'YYYY-MM-DD')
              .startOf('year')
              .format(),
            moment.parseZone(currentDate, 'YYYY-MM-DD').endOf('year').format(),
          ],
        },
      });

      return response.data;
    }
  );
  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAdminStatistic;

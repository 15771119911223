import { Dispatch, FC } from 'react';
import { Popover } from '@headlessui/react';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';

interface CustomDayPopoverProps {
  isShown: boolean;
  setModal: Dispatch<React.SetStateAction<'edit' | 'delete' | null>>;
}

const CustomDayPopover: FC<CustomDayPopoverProps> = ({ isShown, setModal }) => {
  return (
    <Popover
      className={`relative invisible group-hover:visible`}
      key={`${isShown}`}
    >
      <Popover.Button className={`flex items-center`}>
        <MoreIcon className="w-1.6 h-1.6" />
      </Popover.Button>
      <Popover.Panel
        className={`w-15 absolute right-0 z-50 bg-white shadow-primary mt-0.6 flex flex-col gap-y-1.5 py-1.5 translate-x-1`}
      >
        <Popover.Button
          className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
          onClick={() => setModal('edit')}
        >
          Edit
        </Popover.Button>
        <Popover.Button
          className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
          onClick={() => {
            setModal('delete');
          }}
        >
          Delete
        </Popover.Button>
      </Popover.Panel>
    </Popover>
  );
};

export default CustomDayPopover;

import React from 'react';
import { components } from 'react-select';

const MenuList = (props: any) => {
  const { pmsProviders, isSynced, ...rest } = props;

  return (
    <components.MenuList {...rest}>
      {rest.options.map((item: any) => {
        const practitionerName = pmsProviders.find(
          (pms: any) => pms.externalId === item.value
        )?.name;

        const isSelected = rest.focusedOption?.value === item.value;

        return (
          <div
            key={item.value}
            className={`hover:bg-magenta-lightest cursor-pointer py-0.8 px-1.2 rounded-[6px] ${
              isSelected ? 'bg-magenta-lightest' : ''
            }`}
            onClick={() => rest.selectOption(item)}
          >
            <div className="font-bold mb-0.2">{practitionerName}</div>
            <div>{item.value}</div>
          </div>
        );
      })}
    </components.MenuList>
  );
};

export default MenuList;

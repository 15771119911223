import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import CardContainer from 'components/Layout/CardContainer/CardContainer';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirm.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ROUTES } from 'utils/constants/routes';

interface ResultPageProps {
  isSuccess: boolean;
}

const ResultPage: FC<ResultPageProps> = ({ isSuccess }) => {
  return (
    <CardContainer>
      <div className="py-4 flex flex-col justify-center items-center gap-y-2.4">
        {isSuccess ? <ConfirmIcon /> : <CrossIcon />}
        <span className="text-16 font-bold leading-[2.4rem]">
          {isSuccess
            ? 'Your password has been successfully reset'
            : 'Your verification email link has expired'}
        </span>
        <Link
          to={ROUTES.LOGIN}
          className="px-3.9 py-1 bg-magenta text-white font-bold rounded-full hover:bg-magenta-lighter"
        >
          Go to Practice App
        </Link>
      </div>
    </CardContainer>
  );
};

export default ResultPage;

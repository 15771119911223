import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { IUseClinicServiceResponse } from './useClinicService';

interface IUseAdminServiceParams {
  sort?: string;
  sortBy?: string;
}

const useAdminService = (params?: IUseAdminServiceParams) => {
  const query = new URLSearchParams(params as any).toString();

  const url = `/services/global-admin?${query}`;

  const { data, error, mutate } = useSWR(url, async (url) => {
    const response = await axiosInstance.get<IUseClinicServiceResponse>(url);
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAdminService;

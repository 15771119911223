import useSWR from 'swr';
import { sortBy } from 'lodash';

import axiosInstance from 'apis/axiosInstance';
import { IPatient, IPmsPatient } from 'interfaces/patients';

interface PartialPatientResponse extends IPatient {
  pmsPatient: IPmsPatient;
}

const usePartialPatient = (patientId?: string) => {
  const { data, error, mutate } = useSWR(
    patientId ? `/patients/${patientId}/partial-patients` : null,
    async (url) => {
      const response = await axiosInstance.get<PartialPatientResponse[]>(url);

      return sortBy(response.data, ['name']);
    }
  );
  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePartialPatient;

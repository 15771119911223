import { FC, useState } from 'react';
import { capitalize } from 'lodash';
import moment from 'moment';
import axios from 'axios';

import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import { ExceptionDate } from 'interfaces/exceptionDate';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';

import axiosInstance from 'apis/axiosInstance';
import { datetimeFormat } from 'utils/datetime';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { useVersion } from 'pages/OfficeSchedulePage/context/versionContext';
import useOverrideModal from 'hooks/useOverrideModal';
import OverrideModalBody, {
  OptionClinicException,
  OptionPractitionerException,
} from 'components/OverrideModalBody/OverrideModalBody';
import useModal from 'hooks/useModal';

interface OverrideModalProps {
  exceptionsDates: ExceptionDate[];
  changedTimeSlots: WeeklyTimeslot[];
  onClose: () => void;
  onBtnClose?: () => void;
  onUpdateSchedule: () => Promise<void>;
  source: string;
  version: number;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
}

const OverrideModal: FC<OverrideModalProps> = ({
  exceptionsDates,
  changedTimeSlots,
  onClose,
  onUpdateSchedule,
  onBtnClose,
  source,
  version,
  onCheckOutsideOfficeHourAppts,
}) => {
  const { setIsOldVersion } = useVersion();
  const matchMutate = useMatchMutate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setIsAllowed } = useModal({
    onClose: onBtnClose ? onBtnClose : onClose,
  });

  const {
    options,
    selectAllRef,
    handleChangeCheckbox,
    handleChangeSelectAll,
    totalSelectOptions,
  } = useOverrideModal(exceptionsDates);

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    setIsAllowed(false);

    const filterCheckedOptions = options.filter((option) => option.checked);

    const body = filterCheckedOptions.map((option) => {
      const day = changedTimeSlots.find(
        (timeSlot) =>
          moment(option.date, 'YYYY-MM-DD').day() ===
          moment(capitalize(timeSlot.day), 'ddd').day()
      )!;

      return {
        id: option.id,
        date: option.date,
        isClosed: !day.start && !day.end,
        isHoliday: false,
        shifts: [
          {
            start: day.start,
            end: day.end,
          },
        ],
      };
    });

    try {
      await onUpdateSchedule();

      if (body.length > 0) {
        await axiosInstance.patch('/clinics-exception', body, {
          params: {
            source,
          },
          headers: {
            version,
          },
        });
      }

      await Promise.all([
        matchMutate(/\/clinics-schedule[\s\S]+/),
        matchMutate(/\/clinics-exception[\s\S]+/),
      ]);

      await onCheckOutsideOfficeHourAppts();
      renderToast({
        type: 'success',
        message: 'Regular hours has been updated successfully',
      });

      setIsAllowed(true);
      onClose();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }

      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
      setIsSubmitting(false);
    }
  };

  const displayHour = (
    option: OptionClinicException | OptionPractitionerException
  ) => {
    if (typeof option.workingHour !== 'string') {
      const startDate = datetimeFormat({
        dateString: option.workingHour[0].start!,
        format: 'HH:mm:ss',
        pattern: 'hh:mmA',
      });
      const endDate = datetimeFormat({
        dateString: option.workingHour[0].end!,
        format: 'HH:mm:ss',
        pattern: 'hh:mmA',
      });

      return `${startDate} - ${endDate}`;
    }

    if ('holidayName' in option && option.holidayName) {
      // REMOVE ALL CONTENT INSIDE ()
      return (option.holidayName as string).split('(')[0];
    }

    return 'Closed';
  };

  return (
    <DefaultModal>
      <div className="w-48 max-h-[50.2rem] pt-2 pb-2.9 px-2 text-darkest-grey text-14 leading-[2.1rem]">
        <h3 className="font-bold text-20 leading-[3rem]">
          Override these custom days?
        </h3>
        <span className="mt-0.8">
          Unselect the days you would like to keep as custom
        </span>
        <OverrideModalBody
          options={options}
          selectAllRef={selectAllRef}
          handleChangeCheckbox={handleChangeCheckbox}
          handleChangeSelectAll={handleChangeSelectAll}
          totalSelectOptions={totalSelectOptions}
          displayHour={displayHour}
        />

        <div className="flex justify-end gap-x-1.6 mt-2.5">
          <CommonButton
            variant="secondary"
            onClick={onBtnClose ? onBtnClose : onClose}
            disabled={isSubmitting}
          >
            Cancel
          </CommonButton>
          <CommonButton isLoading={isSubmitting} onClick={handleOnSubmit}>
            Confirm
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default OverrideModal;

import moment from 'moment-timezone';

export const DATE_TIME_FORMAT = {
  DOB: 'MMM DD, YYYY',
};

export const datetimeFormat = (params: {
  dateString: string;
  format?: string | string[];
  pattern: string;
  timezone?: string;
}) => {
  if (!params.dateString) {
    return '';
  }

  const { dateString, pattern, format, timezone } = params;
  const dateFormat = timezone
    ? moment(dateString, format || '')
        .tz(timezone)
        .format(pattern)
    : moment(dateString, format || '').format(pattern);

  return dateFormat;
};

export const hour12Format = (params: { value: string; format: string }) => {
  const { value, format } = params;

  const hourFormat = moment(
    `01-01-1991,  ${value}`,
    `DD-MM-YYYY, ${format}`
  ).format('LT');
  return hourFormat;
};

export const parseDateStringWithoutAffectTz = (
  dateString: string,
  format: string,
  timezone: string
) => moment.tz(dateString, format, timezone);

export const formatCurrentDateByTz = (timezone: string) => {
  return moment().tz(timezone).format('YYYY-MM-DD');
};

export const getBufferdTimeString = (time: string, minutes: number) => {
  const buffered = moment(time, 'HH:mm:ss')
    .add(minutes, 'minutes')
    .format('HH:mm:ss');
  return buffered;
};

export const getCurrentTimeString = (timezone: string) => {
  return moment().tz(timezone).format('HH:mm:ss');
};

export const convertMinutesIntoHoursOfDay = (params: {
  minutes: number;
  format: string;
}) => {
  const { minutes, format } = params;
  return moment().startOf('day').add(minutes, 'minutes').format(format);
};

export const isNextMonth = (date: string, currentDate: string) => {
  return moment(date, 'YYYY-MM-DD').isAfter(
    moment(currentDate, 'YYYY-MM-DD'),
    'months'
  );
};

export const isPastMonth = (date: string, currentDate: string) => {
  return moment(date, 'YYYY-MM-DD').isBefore(
    moment(currentDate, 'YYYY-MM-DD'),
    'months'
  );
};

export const formatWorkingHour = (
  { fromTime, toTime }: { fromTime: string; toTime: string },
  newFormat: string
) => {
  const fromTimeFormatted = moment(fromTime, 'HH:mm:ss').format(newFormat);
  const toTimeFormatted = moment(toTime, 'HH:mm:ss').format(newFormat);

  return `${fromTimeFormatted} - ${toTimeFormatted}`;
};

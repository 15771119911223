import { sortBy } from 'lodash';
import moment from 'moment';

const normalizeCustomDay = <T extends { date: string }>(data?: T[]) => {
  if (!data) return [];

  const normalizeData: {
    monthlyTitle: string;
    days: T[];
    sortValue: number;
  }[] = [];

  data.forEach((data) => {
    const month = moment(data.date).format('MMMM YYYY');

    const monthlyData = normalizeData.find((d) => d.monthlyTitle === month);
    if (monthlyData) {
      return monthlyData.days.push(data);
    }

    normalizeData.push({
      monthlyTitle: month,
      days: [data],
      sortValue: moment(data.date).valueOf(),
    });
  });

  const sortedNormalizeData = normalizeData.map((data) => ({
    ...data,
    days: sortBy(data.days, ['date']),
  }));

  return sortBy(sortedNormalizeData, ['sortValue']);
};

export default normalizeCustomDay;

import { FC } from 'react';

interface LegendProps {
  icon: 'circle' | 'square';
  className?: string;
}

const Legend: FC<LegendProps> = ({ children, icon, className }) => {
  return (
    <div
      className={`text-11 flex flex-row items-center before:mr-[1rem] ${
        className ? className : ''
      }
      ${
        icon === 'circle'
          ? 'before:w-[6px] before:h-[6px] before:rounded-full before:bg-lightBlue'
          : 'before:w-1.4 before:h-1.4  before:mb-[1px] before:bg-lightPink'
      }`}
    >
      {children}
    </div>
  );
};

export default Legend;

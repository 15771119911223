import { range } from 'lodash';
import momentTz from 'moment-timezone';

export const MINUTE_OF_A_BLOCK = 5;
export const MAX_BLOCKS_IN_A_DAY = 288;

export const calculateBlocks = (
  time: string, // HH:mm:ss,
  minuteOfBlock?: number
) => {
  const minute = minuteOfBlock ? minuteOfBlock : MINUTE_OF_A_BLOCK;
  const timeInMinutes = momentTz.duration(time).asMinutes();
  return Math.floor(timeInMinutes / minute);
};

export const calculateRangeBlock = (
  startTime: string, // HH:mm:ss
  endTime: string // HH:mm:ss
) => {
  const startOrdinalBlock = calculateBlocks(startTime);
  const endOrdinalBlock = calculateBlocks(endTime);

  if (startOrdinalBlock > endOrdinalBlock) {
    return range(
      startOrdinalBlock,
      endOrdinalBlock === 0
        ? MAX_BLOCKS_IN_A_DAY
        : startOrdinalBlock + endOrdinalBlock
    ).map((block) => {
      if (block >= MAX_BLOCKS_IN_A_DAY) {
        return block - MAX_BLOCKS_IN_A_DAY;
      }

      return block;
    });
  }

  if (startOrdinalBlock === 0 && endOrdinalBlock === 0) {
    return range(0, MAX_BLOCKS_IN_A_DAY);
  }

  return range(startOrdinalBlock, endOrdinalBlock);
};

export const convertBlockNumberToTime = (blockNumber: number) => {
  const minutes = blockNumber * MINUTE_OF_A_BLOCK;

  return momentTz().startOf('day').add(minutes, 'minutes').format('HH:mm:ss');
};

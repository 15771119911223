import React, { FC, useEffect, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import useClinicPractitioner from 'hooks/useClinicPractitioner';
import { IPractitioner } from 'interfaces/practitioners';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { convertMinutesIntoHoursOfDay, datetimeFormat } from 'utils/datetime';
import getPractitionerName from 'utils/getPractitionerName';
import BookNowPanel from 'pages/DashboardPage/Table/BookNowPanel/BookNowPanel';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import axios from 'axios';
import {
  ALREADY_RESOLVED_MESSAGE,
  ErrorAppointmentStatusType,
  ERROR_APPOINTMENT_STATUS,
  ERROR_REASON,
  TIMESLOT_NOT_AVAILABLE_MESSAGE,
} from 'utils/constants';
import { renderToast } from 'components/Toast/Toast';
import { getAppointmentStartTime } from 'components/TableColumn/utils/getAppointmentTime';
import { IAvailableBlocks } from 'hooks/usePractitionerWeeklyTimeSlot';
import { getDentistIdForSplitScheduling, getOperatoryId } from './utils';
import { convertDurationForFE } from 'utils/convertDuration';
import { MINUTE_OF_A_BLOCK } from 'utils/getTimeBlockFromTImeString';
import useModal from 'hooks/useModal';

const { DUPLICATE_BOOKING, OUT_OF_OFFICE_HOURS, OUT_OF_PRACTITIONER_HOURS } =
  ERROR_REASON;

interface DuplicateAppointmentProps {
  id: string;
  onClose: () => void;
  onAppointmentAlreadyResolved: () => void;
  mode: string;
  updatedBookingInfo?: {
    service: { id: string; name: string; duration: number };
    patientId: string;
  } | null;
}
interface ISelectedTimeSlot {
  date: string;
  value: number;
}

export type SelectedTimeSlot = ISelectedTimeSlot | null;

const DuplicateAppointment: FC<DuplicateAppointmentProps> = ({
  id,
  onClose,
  onAppointmentAlreadyResolved,
  mode,
  updatedBookingInfo = null,
}) => {
  const { data: appointmentDetail, isLoading: isAppointmentDetailLoading } =
    useAppointmentDetail(id);

  const { data: practitionerList, isLoading: isPractitionerListLoading } =
    useClinicPractitioner({
      shouldRun: appointmentDetail,
      serviceId: appointmentDetail?.service.id,
    });

  const [selectedPractitioner, setSelectedPractitioner] =
    useState<IPractitioner | null>(null);

  const [selectedTimeSlot, setSelectedTimeSlot] =
    useState<SelectedTimeSlot>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatedAvailableBlocksRef = useRef<IAvailableBlocks[]>([]);

  const matchMutate = useMatchMutate();

  const isDataLoading =
    isAppointmentDetailLoading ||
    isPractitionerListLoading ||
    !selectedPractitioner;

  const { setIsAllowed } = useModal({ onClose, isLoading: isDataLoading });

  useEffect(() => {
    if (appointmentDetail) {
      setSelectedPractitioner(appointmentDetail.doctor);
    }
  }, [appointmentDetail]);

  const practitioners = practitionerList?.data.filter((item) => item.isActive);

  const getConflictedPatientsName = () => {
    if (!appointmentDetail) return;
    return appointmentDetail.conflictedPatients.length > 0
      ? appointmentDetail.conflictedPatients[0].name
      : 'Event';
  };

  const getHeaderContent = () => {
    if (mode === DUPLICATE_BOOKING) {
      return 'Duplicate Appointment';
    }
    if (mode === OUT_OF_OFFICE_HOURS) {
      return 'Appointment scheduled outside office hours';
    }
    if (mode === OUT_OF_PRACTITIONER_HOURS) {
      return 'Appointment scheduled outside practitioner’s hours ';
    }
  };

  const getBodyContent = () => {
    if (mode === DUPLICATE_BOOKING) {
      return (
        <>
          Oops… It looks like an appointment already exists on{' '}
          <strong>{renderAppointmentTime()}</strong> for{' '}
          <strong>{getConflictedPatientsName()}</strong>. Please select another
          time slot for <strong>{appointmentDetail.patient.name}</strong> or
          reschedule {getConflictedPatientsName()} in your practice software.
        </>
      );
    }
    if (mode === OUT_OF_OFFICE_HOURS) {
      return (
        <>
          This future appointment is scheduled for{' '}
          <strong>{renderAppointmentTime()}</strong>, which is outside of your
          new office hours. Please schedule another appointment time for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }
    if (mode === OUT_OF_PRACTITIONER_HOURS) {
      const { firstName, lastName, title } = appointmentDetail.doctor;
      const fullName = `${firstName} ${lastName}`;

      return (
        <>
          This future appointment is scheduled for {renderAppointmentTime()},
          which is outside of {title} {fullName}’s new hours. Please schedule
          another appointment time for{' '}
          <strong>{appointmentDetail.patient.name}</strong>.
        </>
      );
    }
  };

  const handleOnSubmit = async () => {
    const { SUCCESS, ERROR, ALREADY_RESOLVED, TIMESLOT_NOT_AVAILABLE } =
      ERROR_APPOINTMENT_STATUS;
    let status: ErrorAppointmentStatusType = SUCCESS;
    try {
      setIsAllowed(false);
      setIsSubmitting(true);

      if (!selectedTimeSlot) {
        return null;
      }

      const operatoryId = getOperatoryId({
        selectedTimeSlot,
        availableBlocks: updatedAvailableBlocksRef.current,
      });

      // Check updated booking info for non-split to split service
      const dentist =
        appointmentDetail.isSplitScheduling || updatedBookingInfo
          ? getDentistIdForSplitScheduling({
              date: selectedTimeSlot!.date,
              timeSlot: selectedTimeSlot!.value,
              availableBlocks: updatedAvailableBlocksRef.current,
            })
          : undefined;

      const updatedErrorApptPayload = {
        appointmentId: appointmentDetail.id,
        patientId: appointmentDetail.patient.id,
        doctorId: selectedPractitioner!.id,
        appointmentDate: selectedTimeSlot!.date,
        startTime: convertMinutesIntoHoursOfDay({
          minutes: selectedTimeSlot!.value * MINUTE_OF_A_BLOCK,
          format: 'HH:mm:ss',
        }),
        operatoryId,
        dentist,
      };

      await axiosInstance.patch('/bookings', {
        ...updatedErrorApptPayload,
        ...(updatedBookingInfo
          ? {
              patientId: updatedBookingInfo.patientId,
              serviceId: updatedBookingInfo.service.id,
            }
          : {}),
      });

      renderToast({
        message: 'Appointment updated successfully',
        type: 'success',
      });
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.message === ALREADY_RESOLVED_MESSAGE
      ) {
        status = ALREADY_RESOLVED;
      } else if (
        axios.isAxiosError(error) &&
        error.response?.data?.message === TIMESLOT_NOT_AVAILABLE_MESSAGE
      ) {
        status = TIMESLOT_NOT_AVAILABLE;
        renderToast({
          message:
            'Selected time slot is not available. Please select another time',
          type: 'info',
        });
      } else {
        status = ERROR;
        renderToast({
          message: 'An error has occurred. Please try again.',
          type: 'error',
        });
      }
    }
    setIsAllowed(true);
    setIsSubmitting(false);
    if (status === ERROR) return;
    if (status === ALREADY_RESOLVED) {
      onAppointmentAlreadyResolved();
    }
    if (status === TIMESLOT_NOT_AVAILABLE) {
      return await matchMutate(/\/weekly-timeslots\?[\s\S]+/, {
        revalidate: true,
      });
    }
    onClose();
    await matchMutate(/\/appointments\?[\s\S]+/);
  };

  const handleOnClose = () => {
    if (isSubmitting) return;
    onClose();
  };

  const renderAppointmentTime = () => {
    return (
      <strong>
        {datetimeFormat({
          dateString: appointmentDetail.appointmentDate,
          format: 'YYYY-MM-DD',
          pattern: 'MMM DD, YYYY',
        })}{' '}
        at {getAppointmentStartTime(appointmentDetail)}
      </strong>
    );
  };

  const renderServiceInfo = () => {
    if (updatedBookingInfo?.service) {
      const service = updatedBookingInfo.service;
      return (
        <p>
          {service.name} ({convertDurationForFE(service.duration)} min)
        </p>
      );
    }

    return (
      <p>
        {appointmentDetail.service.name} (
        {convertDurationForFE(appointmentDetail.duration)} min)
      </p>
    );
  };

  return (
    <DefaultModal>
      <div className="w-[70.2rem] h-[74.5rem] p-3 text-darkest-grey text-14 normal-case">
        <h3 className="font-bold text-20 leading-[3rem]">
          {getHeaderContent()}
        </h3>
        {isDataLoading ? (
          <div className="w-full h-full flex justify-center mt-10">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <p className="leading-[2.1rem] mt-1.6">{getBodyContent()}</p>
            <div className="h-[47.4rem] shadow-primary rounded-2xl border border-lightest-grey mt-2.2">
              <div className="px-3 pt-2.5 grid grid-rows-2 gap-y-0.9">
                <p className="font-bold text-16 leading-[2.4rem] basis-[26rem]">
                  Select another schedule for {appointmentDetail.patient.name}
                </p>
                <div className="flex justify-between">
                  {renderServiceInfo()}

                  <div className="relative">
                    <Listbox
                      value={selectedPractitioner}
                      onChange={setSelectedPractitioner}
                    >
                      <Listbox.Button className="flex items-center gap-x-0.8 relative">
                        <span className="font-bold w-[15rem] text-right truncate">
                          {selectedPractitioner &&
                            getPractitionerName(selectedPractitioner)}
                        </span>
                        <ArrowDownIcon />
                      </Listbox.Button>
                      <Listbox.Options className="absolute mt-0.5 w-[20rem] overflow-auto rounded-[2rem] bg-white py-1 text-base p-2.4 flex flex-col gap-y-1.6 rouded-[2rem] border border-[#E8E8E8] z-10 max-h-15 scrollbar">
                        {practitioners?.map((practitioner: IPractitioner) => (
                          <Listbox.Option
                            key={practitioner.id}
                            value={practitioner}
                            className={({ active, selected }) =>
                              `cursor-pointer ${
                                (active ||
                                  selected ||
                                  selectedPractitioner.id ===
                                    practitioner.id) &&
                                'text-magenta'
                              }`
                            }
                          >
                            {getPractitionerName(practitioner)}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Listbox>
                  </div>
                </div>
                <BookNowPanel
                  practitionerId={selectedPractitioner.id}
                  appointmentId={id}
                  selectedTimeSlot={selectedTimeSlot}
                  updateAvailableBlocksRef={(data: IAvailableBlocks[]) => {
                    updatedAvailableBlocksRef.current = data;
                  }}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  isOutsideHourMode={mode !== DUPLICATE_BOOKING}
                  updatedBookingInfo={updatedBookingInfo}
                />
              </div>
            </div>
            <div className="flex justify-end gap-x-1.6 mt-4.1">
              <CommonButton variant="secondary" onClick={handleOnClose}>
                Cancel
              </CommonButton>
              <CommonButton
                className="w-[18rem]"
                onClick={handleOnSubmit}
                isLoading={isSubmitting}
                disabled={!selectedTimeSlot?.value}
              >
                Update Appointment
              </CommonButton>
            </div>
          </>
        )}
      </div>
    </DefaultModal>
  );
};

export default DuplicateAppointment;

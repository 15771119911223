import { FC, useState } from 'react';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { IPMSAppointment } from 'hooks/usePMSAppointment';
import AppointmentItem from './AppointmentItem/AppointmentItem';

export interface AppointmentWithColorCode extends IPMSAppointment {
  colorCode: string;
  backgroundColorCode: string;
}

interface AppointmentListProps {
  appointments?: AppointmentWithColorCode[];
  isLoading: boolean;
}

const AppointmentList: FC<AppointmentListProps> = ({
  appointments,
  isLoading,
}) => {
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="all-child:fill-magenta" />
      </div>
    );
  }

  if (appointments?.length === 0) {
    return (
      <div className="w-full h-[20rem] flex flex-col gap-y-1.6 justify-center items-center mt-1">
        <EmptyBucket />
        <span>No appointment available on this list</span>
      </div>
    );
  }

  return (
    <div className="all-child:border-b-[0.5px] all-child:border-light-grey">
      {appointments!.map((appointment, index) => (
        <AppointmentItem
          index={index}
          key={appointment.id}
          appointment={appointment}
          selectedAppointmentId={selectedAppointmentId}
          setSelectedAppointmentId={setSelectedAppointmentId}
        />
      ))}
    </div>
  );
};

export default AppointmentList;

import axios, { AxiosError } from 'axios';

import { FORBIDDEN, UNAUTHORIZED } from 'utils/constants/statusCode';
import {
  clearStorage,
  loadAuthToken,
  loadSelectedClinicId,
  saveAuthError,
  saveAuthToken,
} from 'utils/storage';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
});

const excludedUrl = [
  `/clinic-user/auth/login`,
  `/clinic-user/auth/reset-password-link`,
  `/clinic-user/auth/reset-password/verify`,
  `/clinic-user/auth/setup-account/verify`,
  `/clinic-user/auth/setup-account`,
];

const endpointWithoutXClinicId = [
  `/clinic-user/auth/profile`,
  `/clinic-user/auth/create-account`,
  `/clinic-user/auth/update-account`,
  '/services',
  /^\/clinic-user\/auth\/[^/]+\/clinic-user-roles\/[^/]+$/,
  '/statistics/admin',
  '/clinics',
  /^\/services\/global-admin\?[^/]+$/,
  '/services/order',
];

let parallelRun = 0;

const resetPage = () => {
  clearStorage();
  saveAuthError();
  window.location.reload();
};

axiosInstance.interceptors.request.use((config) => {
  const token = loadAuthToken();
  const clinicId = loadSelectedClinicId();
  const isAuthorizationHeaderSet = config.headers?.Authorization;

  const isEndpointWithoutXClinicId = endpointWithoutXClinicId.find((value) =>
    typeof value === 'string' ? value === config.url! : value.test(config.url!)
  );

  if (token && !isAuthorizationHeaderSet && isEndpointWithoutXClinicId) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${token.accessToken}`,
    };
  } else if (token && clinicId && !isAuthorizationHeaderSet) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${token.accessToken}`,
      'X-ClinicId': clinicId,
    };
  } else if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${token.accessToken}`,
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (successResponse) => successResponse,
  async (errorResponse: any) => {
    const error = errorResponse as AxiosError;
    const originalRequest = error.config;

    const isExcludedUrl = excludedUrl.includes(error.config.url!);

    const isErrorForbidden =
      error.request?.status === FORBIDDEN && !isExcludedUrl;

    if (
      isErrorForbidden &&
      error.response?.data.message === 'The account is deactivated'
    ) {
      resetPage();
    }

    if (
      isErrorForbidden &&
      error.response?.data.message === 'Role is restricted'
    ) {
      window.location.reload();
    }

    if (error.request?.status === UNAUTHORIZED && !isExcludedUrl) {
      parallelRun += 1;
      const refreshToken = loadAuthToken()?.refreshToken;

      if (!refreshToken) {
        return Promise.reject('Unauthorized');
      }

      // Prevent multiple refresh token requests
      if (parallelRun > 1) {
        while (parallelRun > 1) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        const newUpdatedAccessToken = loadAuthToken()?.accessToken;

        try {
          const response = await axios.request({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `JWT ${newUpdatedAccessToken}`,
            },
          });
          return response;
        } catch (error) {
          window.location.reload();
          return;
        }
      }

      try {
        const {
          data: { accessToken, refreshToken: newRefreshToken },
        } = await axios.post<{ accessToken: string; refreshToken: string }>(
          'clinic-user/auth/refresh-token',
          null,
          {
            headers: {
              Authorization: `JWT ${refreshToken}`,
            },
            baseURL: process.env.REACT_APP_BACK_END_URL,
          }
        );
        saveAuthToken({ accessToken, refreshToken: newRefreshToken });

        const response = await axios.request({
          ...originalRequest,
          headers: {
            ...originalRequest.headers,
            Authorization: `JWT ${accessToken}`,
          },
        });

        parallelRun = 0;
        return response;
      } catch (error: any) {
        if (error.request?.status === UNAUTHORIZED) {
          window.location.reload();
        }
      }
    }

    throw error;
  }
);

export default axiosInstance;

import moment from 'moment';
import useSWR from 'swr';

import { loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';

const useClinic = () => {
  const clinicId = loadSelectedClinicId();

  const { data, error, mutate } = useSWR(
    `/clinics/${clinicId}`,
    async (url) => {
      const response = await axiosInstance.get(url, {
        params: {
          date: moment().format('YYYY-MM-DD'),
        },
      });
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinic;

import { FC, useEffect } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';
import { Controller, UseFormSetValue } from 'react-hook-form';
import TimeDropdown from 'components/TimeDropdown/TimeDropdown';
import { INTERVAL_OPTIONS_PER_UNIT_DURATION } from 'utils/constants';

interface SlotIntervallProps {
  control: any;
  unitDuration: number;
  slotInterval: number;
  setValue: UseFormSetValue<{
    unitDuration: number;
    timeInterval: number;
    slotInterval: number;
    input: string;
  }>;
}

const SlotIntervalSection: FC<SlotIntervallProps> = ({
  control,
  unitDuration,
  setValue,
  slotInterval,
}) => {
  const options =
    INTERVAL_OPTIONS_PER_UNIT_DURATION[
      unitDuration as keyof typeof INTERVAL_OPTIONS_PER_UNIT_DURATION
    ];

  useEffect(() => {
    if (options.includes(slotInterval)) {
      return;
    }
    setValue('slotInterval', options[0], {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  }, [options, setValue, slotInterval]);

  return (
    <>
      <div className="flex justify-between items-center text-14 leading-[2.1rem] mt-2.4">
        <div className="flex items-center gap-x-0.8">
          <h3 className="font-bold">Online Scheduling Time Interval</h3>
          <button data-for="slotIntervalInfo" data-tip="">
            <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
          </button>
          <ReactTooltip
            id="slotIntervalInfo"
            effect="solid"
            place="bottom"
            className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
          >
            <span className="text-11 text-darkest-grey flex items-center font-normal">
              Set the specific time interval for your Online Scheduler
            </span>
          </ReactTooltip>
        </div>
      </div>
      <Controller
        name={'slotInterval'}
        control={control}
        render={({ field }) => {
          return (
            <TimeDropdown
              options={options}
              value={options.includes(field.value) ? field.value : options[0]}
              listBoxProps={field}
            />
          );
        }}
      />
    </>
  );
};

export default SlotIntervalSection;

const CustomOption = (props: any) => {
  const { innerRef, innerProps } = props;
  return (
    <div
      className="flex gap-x-1.6 items-center w-full"
      ref={innerRef}
      {...innerProps}
    >
      <input
        id={props.data.id}
        key={props.isSelected}
        type="checkbox"
        defaultChecked={props.isSelected}
        className="w-1.8 h-1.8 shrink-0"
        disabled={props.isDisabled}
      />
      <label
        htmlFor={props.data.id}
        className="text-darkest-grey text-14 leading-[2.1rem]"
      >
        {props.data.label}
      </label>
    </div>
  );
};

export default CustomOption;

import React, { FC, useEffect, useState } from 'react';
import DayViewNavigation from './DayViewNavigation/DayViewNavigation';

import useAppointmentDetail from 'hooks/useAppointmentDetail';
import usePractitionerWeeklyTimeSlot, {
  IAvailableBlocks,
} from 'hooks/usePractitionerWeeklyTimeSlot';

import moment from 'moment';
import { calculateBlocks } from 'utils/getTimeBlockFromTImeString';
import { datetimeFormat } from 'utils/datetime';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import DayView from '../DayView/DayView';
import MonthView from '../MonthView/MonthView';
import { SelectedTimeSlot } from 'components/DuplicateAppointment/DupplicateAppointment';
import { sortBy } from 'lodash';
import getTimeSlotPer30Minutes from 'utils/getTimeSlotsPer30Minutes';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';

interface BookNowPanelProps {
  appointmentId: string;
  practitionerId: string;
  selectedTimeSlot: SelectedTimeSlot;
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<SelectedTimeSlot>>;
  updateAvailableBlocksRef: (data: IAvailableBlocks[]) => void;
  isOutsideHourMode: boolean;
  updatedBookingInfo: {
    service: {
      id: string;
      name: string;
      duration: number;
    };
    patientId: string;
  } | null;
}

const BookNowPanel: FC<BookNowPanelProps> = ({
  practitionerId,
  appointmentId,
  selectedTimeSlot,
  setSelectedTimeSlot,
  updateAvailableBlocksRef,
  isOutsideHourMode,
  updatedBookingInfo,
}) => {
  const [isDayView, setIsDayView] = useState(true);
  const { data: appointmentDetail } = useAppointmentDetail(appointmentId);

  const duplicateAppointmentDate = appointmentDetail.appointmentDate;

  const [date, setDate] = useState(duplicateAppointmentDate);

  const currentDate = moment().format();

  const endDate = moment(date).add('4', 'day').format('YYYY-MM-DD');

  const { data, isLoading } = usePractitionerWeeklyTimeSlot({
    practitionerId,
    serviceId: updatedBookingInfo?.service?.id || appointmentDetail.service.id,
    currentDate,
    dates: [date, endDate],
    timeBlocks: [0],
    timezone: appointmentDetail.timezone,
  });

  const convertTo24Format = datetimeFormat({
    dateString: appointmentDetail.startTime,
    format: 'hh:mm A',
    pattern: 'HH:mm',
  });

  const currentDuplicateTimeBlock = calculateBlocks(convertTo24Format);

  const isSamePractitioner = appointmentDetail.doctor.id === practitionerId;

  const availableBlocks = getTimeSlotPer30Minutes(
    data?.[0]?.clinic?.availableBlocks
  );

  const updatedAvailableBlocks = availableBlocks.map((block: any) => {
    if (block.date === duplicateAppointmentDate) {
      let timeBlocks = [...block.blocks];

      if (isSamePractitioner && !isOutsideHourMode) {
        timeBlocks.push(currentDuplicateTimeBlock);
        timeBlocks = sortBy(timeBlocks);
      }

      return {
        ...block,
        ...(isSamePractitioner && { disabledValue: currentDuplicateTimeBlock }),
        blocks: timeBlocks,
      };
    }

    return block;
  });

  useEffect(() => {
    updateAvailableBlocksRef(updatedAvailableBlocks);
  }, [updateAvailableBlocksRef, updatedAvailableBlocks]);

  useEffect(() => {
    setSelectedTimeSlot(null);
  }, [practitionerId, setSelectedTimeSlot]);

  useEffect(() => {
    if (selectedTimeSlot) return;
    updatedAvailableBlocks.forEach((block: any) => {
      const blocks = block.blocks;
      const duplicatedSlotIndex = blocks.findIndex(
        (value: number) => value === currentDuplicateTimeBlock
      );
      if (block.date === duplicateAppointmentDate) {
        if (!isSamePractitioner) {
          return setSelectedTimeSlot({
            date: duplicateAppointmentDate,
            value:
              duplicatedSlotIndex !== -1
                ? currentDuplicateTimeBlock
                : blocks[0],
          });
        }
        setSelectedTimeSlot({
          date: duplicateAppointmentDate,
          value: blocks[duplicatedSlotIndex + 1],
        });
      }
    });
  }, [
    currentDuplicateTimeBlock,
    duplicateAppointmentDate,
    isSamePractitioner,
    selectedTimeSlot,
    setSelectedTimeSlot,
    updatedAvailableBlocks,
  ]);

  useEffect(() => {
    if (isLoading) return;
    const isNavigatedFromNextAvail =
      selectedTimeSlot?.date && selectedTimeSlot?.value === 0;
    if (isNavigatedFromNextAvail) {
      return setSelectedTimeSlot({
        ...selectedTimeSlot,
        value: updatedAvailableBlocks[0].blocks[0],
      });
    }
  }, [
    isLoading,
    selectedTimeSlot,
    setSelectedTimeSlot,
    updatedAvailableBlocks,
  ]);

  const handleChangeDate = (newDate: string) => {
    setDate(newDate);
    setSelectedTimeSlot(null);
  };

  const handleNavigateDayView = (newDate: string) => {
    setDate(newDate);
    setIsDayView(true);
    setSelectedTimeSlot(null);
  };

  return (
    <>
      <div className="flex mt-1.5 justify-between">
        {isDayView ? (
          <DayViewNavigation date={date} onDateChange={handleChangeDate} />
        ) : (
          <MonthViewNavigation date={date} setDate={handleChangeDate} />
        )}
        <div className="flex gap-x-1">
          <button
            className={`text-14 flex justify-center items-center leading-[2.1rem] border-0 rounded-[0.8rem] font-bold py-[0.45rem] px-[1.8rem] w-[12rem] ${
              !isDayView ? 'shadow-none font-normal' : 'shadow-tooltip'
            }`}
            onClick={() => setIsDayView(true)}
          >
            Day View
          </button>
          <button
            className={`text-14 flex justify-center items-center leading-[2.1rem] border-0 rounded-[0.8rem] font-bold py-[0.45rem] px-[1.8rem] w-[12rem] ${
              isDayView ? 'shadow-none font-normal' : 'shadow-tooltip'
            }`}
            onClick={() => setIsDayView(false)}
          >
            Month View
          </button>
        </div>
      </div>
      <div className="border w-full border-[#CBCBCB] mt-0.2" />
      {isDayView ? (
        isLoading && !data ? (
          <div className="w-full flex justify-center mt-2">
            <LoadingSpinner className="all-child:fill-magenta" />
          </div>
        ) : (
          <DayView
            startDateBlock={date}
            setDate={setDate}
            practitionerId={practitionerId}
            serviceId={appointmentDetail.service.id}
            availableBlocks={updatedAvailableBlocks}
            selectedTimeSlot={selectedTimeSlot}
            setSelectedTimeSlot={setSelectedTimeSlot}
          />
        )
      ) : (
        <MonthView
          serviceId={appointmentDetail.service.id}
          practitionerId={practitionerId}
          date={date}
          selectedDate={currentDate}
          onNavigateToDayView={handleNavigateDayView}
          timeBlocks={[0]}
          currentDate={currentDate}
          timezone={appointmentDetail.timezone}
        />
      )}
    </>
  );
};

export default BookNowPanel;

import { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';
import { Controller } from 'react-hook-form';
import TimeDropdown from 'components/TimeDropdown/TimeDropdown';

interface DurationSectionProps {
  control: any;
  unitDuration: number;
}

const INTERVAL_OPTIONS_PER_UNIT_DURATION = {
  5: [5, 10, 15, 20, 30],
  10: [10, 20, 30],
  15: [15, 30],
  20: [20],
  30: [30],
};

const DurationSection: FC<DurationSectionProps> = ({
  control,
  unitDuration,
}) => {
  const options =
    INTERVAL_OPTIONS_PER_UNIT_DURATION[
      unitDuration as keyof typeof INTERVAL_OPTIONS_PER_UNIT_DURATION
    ];

  return (
    <>
      <div className="flex justify-between items-center text-14 leading-[2.1rem]">
        <div className="flex items-center gap-x-0.8">
          <h3 className="font-bold">Online Scheduling Time Interval</h3>
          <button data-for="durationSectionInfo" data-tip="">
            <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
          </button>
          <ReactTooltip
            id="durationSectionInfo"
            effect="solid"
            place="bottom"
            className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
          >
            <span className="text-11 text-darkest-grey flex items-center font-normal">
              Set the specific time interval for your Online Scheduler
            </span>
          </ReactTooltip>
        </div>
      </div>
      <Controller
        name={'slotInterval'}
        control={control}
        render={({ field }) => {
          return (
            <TimeDropdown
              options={options}
              value={field.value}
              listBoxProps={field}
            />
          );
        }}
      />
    </>
  );
};

export default DurationSection;

import { useState } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import Table from './Table/Table';
import EditOrCreateUser, {
  IEditOrCreateUserForm,
} from 'pages/UserPage/EditOrCreateUser/EditOrCreateUser';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { BAD_REQUEST } from 'utils/constants/statusCode';

const AdminUserPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');
  const matchMutate = useMatchMutate();

  const handleCreateUser = async ({
    email,
    ...rest
  }: IEditOrCreateUserForm) => {
    try {
      await axiosInstance.post('/clinic-user/auth/create-account', {
        email,
        clinics: [
          {
            id: null,
            ...rest,
          },
        ],
      });
      await matchMutate(/\/clinic-users\/global-admin\?[\s\S]+/);
      setIsModalOpen(false);
      renderToast({
        message: 'A user has created successfully',
        type: 'success',
      });
    } catch (error: any) {
      let message = 'An error has occurred. Please try again.';
      if (error.response?.data.statusCode === BAD_REQUEST) {
        setError('Email already exists');
        message = `Email already exists. Please enter a unique email.`;
      }
      renderToast({ message, type: 'error' });
    }
  };

  return (
    <>
      {isModalOpen && (
        <EditOrCreateUser
          isGlobalAdminMode
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreateUser}
          error={error}
          setError={setError}
        />
      )}
      <div className="pt-2.4 pl-3 pr-4 text-14 leading-[2.1rem]">
        <h3 className="text-24 font-bold leading-[3.6rem]">Global Users</h3>
        <CommonButton
          variant="secondary"
          className="mt-2.4 mb-3 !min-h-[3.2rem]"
          onClick={() => setIsModalOpen(true)}
        >
          Create New User
        </CommonButton>
        <div className="bg-white shadow-primary rounded-[1rem] mt-3">
          <Table />
        </div>
      </div>
    </>
  );
};

export default AdminUserPage;

import { FC, useState } from 'react';
import { Listbox, Switch } from '@headlessui/react';
import { range } from 'lodash';
import { Controller } from 'react-hook-form';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import ReactTooltip from 'react-tooltip';
import {
  EXAM_PLACEMENT,
  MAX_DOCTOR_DURATION,
  MAX_HYGIENIST_DURATION,
} from 'utils/constants';

interface AdvancedSectionProps {
  control: any;
  isSplitScheduling?: boolean;
  timeInterval?: number;
  hygienistDuration?: number | null;
  doctorDuration?: number | null;
}

const EXAM_PLACEMENT_OPTIONS = [
  { label: 'Before/after hygiene appointment', value: null },
  { label: 'Before hygiene appointment', value: EXAM_PLACEMENT.HEAD },
  { label: 'After hygiene appointment', value: EXAM_PLACEMENT.TAIL },
];

const AdvancedSection: FC<AdvancedSectionProps> = ({
  control,
  isSplitScheduling,
  doctorDuration,
  hygienistDuration,
  timeInterval = 0,
}) => {
  const [isAdvancedSectionOpened, setIsAdvancedSectionOpened] = useState(true);

  const getHygienistDuration = () => {
    const limit = MAX_HYGIENIST_DURATION + timeInterval;
    const start = timeInterval;
    if ((!hygienistDuration && !doctorDuration) || !doctorDuration) {
      return range(start, limit, timeInterval).map((value) => value);
    }

    const diff = limit - doctorDuration;

    return range(start, diff, timeInterval).map((value) => value);
  };

  const getDoctorDuration = () => {
    const start = 0;

    const limit = MAX_DOCTOR_DURATION + timeInterval;
    const max = MAX_HYGIENIST_DURATION + timeInterval;

    if ((!hygienistDuration && !doctorDuration) || !hygienistDuration) {
      return range(start, limit, timeInterval).map((value) => value);
    }

    const diff = max - hygienistDuration;

    return diff > 0
      ? range(start, diff, timeInterval).map((value) => value)
      : [0];
  };

  return (
    <div>
      <button
        className="flex gap-x-0.8"
        onClick={() => setIsAdvancedSectionOpened(!isAdvancedSectionOpened)}
      >
        <TriangleIcon
          className={`${
            isAdvancedSectionOpened ? '' : '-rotate-90'
          } transition-transform`}
        />
        <span className="font-bold text-16 leading-[2.4rem]">Advanced</span>
      </button>
      {isAdvancedSectionOpened && (
        <>
          <div className="flex gap-x-10 items-center mt-2">
            <label className="font-bold text-16 leading-[2.4rem]" htmlFor="">
              Split Scheduling
            </label>
            <div className="flex gap-x-1.6 items-center">
              <Controller
                control={control}
                name="isSplitScheduling"
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      checked={field.value}
                      className={`${
                        field.value ? 'bg-magenta' : 'bg-[#78788029]'
                      } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
                    >
                      <span
                        className={`${
                          field.value
                            ? 'translate-x-[2.3rem]'
                            : 'translate-x-[2px]'
                        } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
                      />
                    </Switch>
                    <span className="uppercase">
                      {field.value ? 'On' : 'Off'}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          {isSplitScheduling && (
            <div className="flex flex-col gap-y-2.4 mt-2.4">
              <div className="flex flex-col gap-y-0.8">
                <div className="flex items-center gap-x-1">
                  <label
                    className="font-bold text-16 leading-[2.4rem]"
                    htmlFor=""
                  >
                    Exam Placement
                  </label>
                  <button data-for="exam-placement-info" data-tip="">
                    <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
                  </button>
                  <ReactTooltip
                    id="exam-placement-info"
                    effect="solid"
                    place="bottom"
                    className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
                  >
                    <span className="text-11 text-darkest-grey flex items-center font-normal">
                      This setting will apply to all exam and cleaning services
                      with activated split scheduling.
                    </span>
                  </ReactTooltip>
                </div>
                <Controller
                  control={control}
                  name={'examPlacement'}
                  render={({ field }) => {
                    return (
                      <Listbox {...field} disabled={!isSplitScheduling}>
                        {({ open }) => {
                          return (
                            <div className="relative">
                              <Listbox.Button
                                className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                                  open
                                    ? 'border-magenta shadow-input-active'
                                    : ''
                                }`}
                              >
                                <span className="block pr-3 text-darkest-grey">
                                  {
                                    EXAM_PLACEMENT_OPTIONS.find(
                                      (item) => item.value === field.value
                                    )!.label
                                  }
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <DropdownIcon className="w-1.4 h-1.4 " />
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className="absolute mt-0.8 w-full rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-20">
                                {EXAM_PLACEMENT_OPTIONS.map(
                                  ({ label, value }) => (
                                    <Listbox.Option
                                      key={value}
                                      className="relative cursor-default select-none pl-1.6"
                                      value={value}
                                    >
                                      {({ selected }) => (
                                        <span
                                          className={`block truncate cursor-pointer hover:text-magenta ${
                                            selected ? 'text-magenta' : ''
                                          }`}
                                        >
                                          {label}
                                        </span>
                                      )}
                                    </Listbox.Option>
                                  )
                                )}
                              </Listbox.Options>
                            </div>
                          );
                        }}
                      </Listbox>
                    );
                  }}
                />
              </div>
              <div className="flex items-center gap-x-2">
                <div className="flex flex-col gap-y-0.8 flex-1">
                  <h3 className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
                    Hygienist Duration
                  </h3>
                  <Controller
                    name={'hygienistDuration'}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!isSplitScheduling) {
                          return true;
                        }

                        return value !== null;
                      },
                    }}
                    render={({ field }) => (
                      <Listbox {...field} disabled={!isSplitScheduling}>
                        {({ open }) => {
                          return (
                            <div className="relative">
                              <Listbox.Button
                                className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                                  open
                                    ? 'border-magenta shadow-input-active'
                                    : ''
                                }`}
                              >
                                <span
                                  className={`block pr-3 ${
                                    field.value && isSplitScheduling
                                      ? 'text-darkest-grey'
                                      : 'text-mid-grey'
                                  } `}
                                >
                                  {field.value
                                    ? getHygienistDuration().includes(
                                        field.value
                                      )
                                      ? `${field.value} minutes`
                                      : ''
                                    : 'Select Duration'}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <DropdownIcon className="w-1.4 h-1.4 " />
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className="absolute mt-0.5 max-h-14 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5">
                                {getHygienistDuration().map((value) => (
                                  <Listbox.Option
                                    key={value}
                                    className="relative cursor-default select-none pl-1.6"
                                    value={value}
                                  >
                                    {({ selected }) => (
                                      <span
                                        className={`block truncate cursor-pointer hover:text-magenta ${
                                          selected ? 'text-magenta' : ''
                                        }`}
                                      >
                                        {value} minutes
                                      </span>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          );
                        }}
                      </Listbox>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-y-0.8 flex-1">
                  <h3 className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
                    Doctor Duration
                  </h3>
                  <Controller
                    name={'doctorDuration'}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!isSplitScheduling) {
                          return true;
                        }

                        return value !== null;
                      },
                    }}
                    render={({ field }) => (
                      <Listbox {...field} disabled={!isSplitScheduling}>
                        {({ open }) => {
                          return (
                            <div className="relative">
                              <Listbox.Button
                                className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                                  open
                                    ? 'border-magenta shadow-input-active'
                                    : ''
                                }`}
                              >
                                <span
                                  className={`block pr-3 ${
                                    field.value !== null && isSplitScheduling
                                      ? 'text-darkest-grey'
                                      : 'text-mid-grey'
                                  } `}
                                >
                                  {field.value !== null
                                    ? getDoctorDuration().includes(field.value)
                                      ? `${
                                          field.value === 0
                                            ? 'Working Day'
                                            : `${field.value} minutes`
                                        }`
                                      : ''
                                    : 'Select Duration'}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <DropdownIcon className="w-1.4 h-1.4" />
                                </span>
                              </Listbox.Button>
                              <Listbox.Options className="absolute mt-0.5 max-h-14 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5">
                                {getDoctorDuration().map((value) => (
                                  <Listbox.Option
                                    key={value}
                                    className="relative cursor-default select-none pl-1.6"
                                    value={value}
                                  >
                                    {({ selected }) => (
                                      <span
                                        className={`block truncate cursor-pointer hover:text-magenta ${
                                          selected ? 'text-magenta' : ''
                                        }`}
                                      >
                                        {value === 0
                                          ? `Working Day`
                                          : `${value} minutes`}
                                      </span>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </div>
                          );
                        }}
                      </Listbox>
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AdvancedSection;

import { FC, useMemo, useState } from 'react';
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useClinicService from 'hooks/useClinicService';
import { SERVICE_NAME } from 'utils/constants';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';
import DraggableServiceTableBody from 'components/DraggableServiceTableBody/DraggableServiceTableBody';
import { reorder } from 'utils/common';

import { loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import ViewMode from 'components/ViewMode/ViewMode';
import NavigationBlocker from 'pages/OfficeSchedulePage/NavigationBlocker/NavigationBlocker';
import useBeforeUnloaded from 'hooks/useBeforeUnloaded';
import CommonButton from 'components/CommonButton/CommonButton';
import VisibilityDropdown from './VisibilityDropdown/VisibilityDropdown';
import DurationColumn from 'components/TableColumn/DurationColumn/DurationColumn';
import StatusColumn from 'components/TableColumn/StatusColumn/StatusColumn';
import useClinic from 'hooks/useClinic';

interface TableProps {
  isSortingEnabled: boolean;
  setIsSortingEnable: React.Dispatch<React.SetStateAction<boolean>>;
  isHygieneChairSetup: boolean;
}

const Table: FC<TableProps> = ({
  isSortingEnabled,
  setIsSortingEnable,
  isHygieneChairSetup,
}) => {
  const [highlightedRowId, setHighlighedRowId] = useState('');

  const { data } = useClinic();

  const {
    data: clinicService,
    isLoading: isClinicServiceLoading,
    mutate,
  } = useClinicService({
    sort: 'desc,asc',
    sortBy: 'status,order',
  });

  const isLoading = isClinicServiceLoading;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOrderUpdated, setIsOrderUpdated] = useState(false);

  useBeforeUnloaded({ when: isOrderUpdated });

  const handleCopyLink = async (shortLink: string) => {
    const cb = navigator.clipboard;
    await cb.writeText(shortLink);

    renderToast({
      type: 'success',
      message: 'Service link has been copied to clipboard',
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Service',
        accessor: (field: any) => field.name,
        id: 'serviceName',
        width: 200,
      },
      {
        Header: 'Duration',
        accessor: (field: any) => {
          return <DurationColumn field={field} />;
        },
        id: 'duration',
        width: 80,
      },
      {
        Header: 'Status',
        accessor: (field: any) => {
          return <StatusColumn field={field} />;
        },
        id: 'status',
        width: 80,
      },
      {
        Header: 'Visibility',
        accessor: (field: any) => {
          if (!field.isActive) return null;
          return <VisibilityDropdown field={field} />;
        },
        id: 'visibility',
      },
      {
        Header: 'Service Link',
        accessor: (field: any) => {
          if (!field.isActive) return null;

          const isParentExamCleaningService =
            field.name === SERVICE_NAME.EXAM_CLEANING;

          return (
            <CommonButton
              variant="secondary"
              className="!min-h-[3.2rem]"
              onClick={() => handleCopyLink(field.shortLink)}
            >
              {isParentExamCleaningService ? 'Copy Smart Link' : 'Copy Link'}
            </CommonButton>
          );
        },
        id: 'link',
      },
      {
        id: 'action_btn',
        width: 25,
        Cell: ({ row }: any) => {
          const isSubRow = row.depth > 0;
          const isParent = row.canExpand;

          return (
            <ThreeDotsOption
              key={highlightedRowId}
              service={row.original}
              isSubRow={isSubRow}
              isParent={isParent}
              isHygieneChairSetup={isHygieneChairSetup}
              timeInterval={data?.timeInterval}
            />
          );
        },
      },
    ],
    [data?.timeInterval, highlightedRowId, isHygieneChairSetup]
  );

  const dataTable = useMemo(() => {
    if (isLoading || !clinicService) {
      return { data: [], metadata: null };
    }

    const updatedDataTableFormat = clinicService.data.map((item) => ({
      ...item,
      ...(item.childServices.length > 0 && {
        subRows: item.childServices.map((service) => ({
          ...service,
          isChildService: true,
        })),
      }),
    }));

    return {
      data: updatedDataTableFormat,
      metadata: clinicService.metadata,
    };
  }, [clinicService, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data ? dataTable.data : [],
        manualPagination: true,
        pageCount: dataTable.metadata ? clinicService?.metadata.total : 0,
      },
      useSortBy,
      useFlexLayout,
      useExpanded,
      usePagination
    );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <DraggableServiceTableBody
        onDragEnd={(result) => {
          if (!clinicService || !result.destination) return;

          const items = reorder(
            clinicService.data,
            result.source.index,
            result.destination.index
          );

          mutate({ ...clinicService, data: items }, { revalidate: false });

          if (isOrderUpdated) return;

          const orderIsUpdated = !!clinicService.data.find(
            (item, index) => item.id !== items[index].id
          );

          setIsOrderUpdated(orderIsUpdated);
        }}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
        onRowMouseEnter={(id) => setHighlighedRowId(id)}
        enabled={isSortingEnabled}
      />
    );
  };

  const handleUpdatedServiceOrder = async () => {
    setIsSubmitting(true);
    const clinicId = loadSelectedClinicId();
    try {
      await axiosInstance.patch(`/clinics/${clinicId}/services/order`, {
        ordersDefaultService: clinicService?.data.map((service, index) => ({
          serviceId: service.id,
          order: index + 1,
        })),
      });
      renderToast({
        type: 'success',
        message: 'Updated order successfully',
      });
      await mutate();
      setIsSortingEnable(false);
      setIsOrderUpdated(false);
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error.response?.status === BAD_REQUEST) {
        message = error.response?.data.message;
      }
      renderToast({
        type: 'error',
        message,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <NavigationBlocker when={isOrderUpdated} />
      <div className="absolute top-6.8 right-4">
        <ViewMode
          isSortingEnabled={isSortingEnabled}
          setIsSortingEnabled={setIsSortingEnable}
          onDone={handleUpdatedServiceOrder}
          isSubmitting={isSubmitting}
        />
      </div>
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="pl-5">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div className="flex items-center gap-x-0.9">
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {renderTableBody()}
      </table>
    </>
  );
};

export default Table;

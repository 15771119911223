import React from 'react';
import { Menu } from '@headlessui/react';
import { highlightText } from 'utils/common';

import { ClinicUserRole, GlobalAdminRole } from 'context/userContext';
import logoIcon from 'assets/icons/Logo-small.svg';
import Option from './Option';

const GlobalAdminOption = ({
  currentClinicUserRole,
  globalAdminRole,
  searchingValue,
  onClick,
}: {
  currentClinicUserRole: ClinicUserRole | null;
  globalAdminRole: GlobalAdminRole | null;
  searchingValue: string;
  onClick: () => void;
}) => {
  if (!globalAdminRole || !currentClinicUserRole) return null;

  const name = 'Global Admin';

  const parts = highlightText(name, searchingValue);

  return (
    <div className="border-t-[0.1rem] border-light-grey w-full py-0.5">
      <Menu.Item
        key={name}
        as="button"
        className="w-full"
        onClick={() => onClick()}
      >
        <Option
          option={{
            avatar: logoIcon,
            name: name,
          }}
          isGlobalAdmin
          highlightedParts={parts}
        />
      </Menu.Item>
    </div>
  );
};

export default GlobalAdminOption;

import { FC } from 'react';
import { ReactComponent as ProgressIcon } from 'assets/icons/progress.svg';
import { ReactComponent as FailIcon } from 'assets/icons/fail.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { SYNC_STATUS } from 'utils/constants';
import ReactTooltip from 'react-tooltip';
import { datetimeFormat } from 'utils/datetime';
import { useUser } from 'context/userContext';

interface SyncStatusProps {
  isLoading: boolean;
  data: {
    status: string;
    lastSyncAt: string;
  };
  successfulMessage?: string;
}

const SyncStatus: FC<SyncStatusProps> = ({
  isLoading,
  data,
  successfulMessage = 'Sync successfully',
}) => {
  const {
    user: { currentClinicUserRole },
  } = useUser();

  return (
    <>
      {isLoading && <LoadingSpinner className="all-child:fill-magenta" />}
      {data?.status === SYNC_STATUS.SUCCESS && (
        <div className="text-green-secondary font-bold flex">
          <SuccessIcon />
          <span className="ml-0.8">{successfulMessage}</span>
        </div>
      )}
      {data?.status === SYNC_STATUS.FAIL && (
        <div className="text-[#D31759] font-bold flex">
          <FailIcon />
          <span className="ml-0.8">Failed</span>
          <button
            className="ml-0.8"
            data-for={`error-${data.lastSyncAt}`}
            data-tip=""
          >
            <InfoIcon />
          </button>
          <ReactTooltip
            id={`error-${data.lastSyncAt}`}
            place="bottom"
            effect="solid"
            className="!bg-lightest-grey !border-light-grey !rounded-[0.8rem] !w-[23rem] !h-[auto] !px-[1.6rem] !py-[0.7rem] z-[9999] flex justify-center items-center  !opacity-100"
          >
            <span className="text-11 text-darkest-grey flex items-center font-normal">
              Sync attempt failed at{' '}
              {datetimeFormat({
                dateString: data.lastSyncAt,
                pattern: 'MMM DD, hh:mm A',
                timezone: currentClinicUserRole!.clinic.timezone,
              })}
              .
            </span>
          </ReactTooltip>
        </div>
      )}
      {data?.status === SYNC_STATUS.IN_PROGRESS && (
        <div className="text-[#4D97E1] font-bold flex">
          <ProgressIcon />
          <span className="ml-0.8">In progress</span>
        </div>
      )}
    </>
  );
};

export default SyncStatus;

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { cloneDeep, range } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { DoctorSchedule, PMSClinicSchedule } from 'interfaces/clinicSchedule';
import { IScheduleLayer } from 'pages/SchedulePage';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import {
  PMS_EVENT_TYPE,
  SERVICE_NAME,
  SPECIALTY_NAME,
  TIME_GRID_WORKING_HOUR,
} from 'utils/constants';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { ReactComponent as CrossContainerIcon } from 'assets/icons/cross-container.svg';
import TimeGridLabel from './TimeGridLabel/TimeGridLabel';
import TimeLine from './TimeLine';
import {
  EmptyEvent,
  IChairEvent,
  IEvent,
  IPractitionerEvent,
  NO_APPOINTMENT_ID,
  ONE_MINUTE_IN_PX,
  calculateTopPositionByTime,
  groupByOverlappedEvents,
  isSelectedEventAvailable,
  filterPractitionerTypeForExamAndCleaning,
  calculateDentistAvailabilities,
  PRACTITIONER_WITH_NO_OPERATORY,
  PRACTITIONER_WITH_NO_APPOINTMENT,
  PRACTITIONER_OFF,
} from '../utils';
import { IDebugModeDropdown } from '..';
import TimeGridTooltip from '../TimeGridTooltip';
import styles from './TimeGridContent.module.css';
import CardDetail from '../CardDetail/CardDetail';
import { useSidebar } from 'context/sidebarContext';
import {
  MINUTE_OF_A_BLOCK,
  calculateRangeBlock,
} from 'utils/getTimeBlockFromTImeString';
import useClinicService from 'hooks/useClinicService';
import useClinic from 'hooks/useClinic';
import CrossCellPerUnitDuration from './CrossCellPerUnitDuration/CrossCellPerUnitDuration';
import getPractitionerName from 'utils/getPractitionerName';

const DEFAULT_DATA = [
  { id: 'column-1', events: [], name: '' },
  { id: 'column-2', events: [], name: '' },
  { id: 'column-3', events: [], name: '' },
  { id: 'column-4', events: [], name: '' },
  { id: 'column-5', events: [], name: '' },
  { id: 'column-6', events: [], name: '' },
  { id: 'column-7', events: [], name: '' },
];

const MAX_COLUMN = 10;

const LAYER_INDEX = {
  [PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING]: 1,
  [PMS_EVENT_TYPE.BLOCKED]: 2,
  [PMS_EVENT_TYPE.OFFICE_CLOSED_HOUR]: 3,
  [PMS_EVENT_TYPE.OFFICE_HOLIDAY]: 4,
  [PMS_EVENT_TYPE.EVENT]: 5,
  [PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS]: 6,
  [PMS_EVENT_TYPE.AVAILABLE_EVENT]: 7,
  MAXIMUM_Z_INDEX: 9999,
};

interface IProps {
  data?:
    | IPractitionerEvent[]
    | IChairEvent[]
    | typeof DEFAULT_DATA
    | EmptyEvent[];
  pmsClinicSchedule: PMSClinicSchedule | undefined;
  clinicWorkingHour: {
    startTime: string;
    endTime: string;
  };
  workingHourPractitioners: (DoctorSchedule & { name: string })[];
  scheduleLayer: IScheduleLayer;
  chartWorkingHour: {
    startTime: string;
    endTime: string;
  };
  config: {
    headerHeight: number;
    cellHeight: number;
  };
  HeaderCell: React.FC<any>;
  EventCell: React.FC<any>;
  date: string;
  isDebugMode: boolean;
  selectedDebugModeDropdown: IDebugModeDropdown | null;
  isLoading: boolean;
  isCollapsed: boolean;
  practitioners?: IMapPractitionerColor[];
}

const TimeGridContent = ({
  data,
  pmsClinicSchedule,
  clinicWorkingHour,
  chartWorkingHour,
  workingHourPractitioners,
  scheduleLayer,
  config,
  HeaderCell,
  date,
  EventCell,
  isDebugMode,
  selectedDebugModeDropdown,
  isLoading,
  isCollapsed,
  practitioners,
}: IProps) => {
  const timeGridRef = useRef<HTMLDivElement | null>(null);
  const availableHoveringTimeOutRef = useRef<any>(null);
  const mainContentRef = useRef<HTMLDivElement>(null);
  const [timelineWidth, setTimelineWidth] = useState<number | undefined>();

  const { data: clinicServices } = useClinicService({
    sort: 'asc',
    sortBy: 'order',
  });

  const { data: clinic } = useClinic();

  const columnRef = useRef<HTMLDivElement | null>(null);

  const { isCollapsed: isSidebarCollapsed } = useSidebar();

  const [selectedApptId, setSelectedApptId] = useState('');

  const availableElementRef = useRef<any>({
    currentTarget: null,
    clientY: null,
  });

  const previousMousePosition = useRef({
    operatoryId: '',
    clientY: 0,
  });

  const [availableEvent, setAvailableEvent] = useState<
    {
      fromTime: string;
      toTime: string;
      practitionerId: string;
      operatoryId: string;
    }[]
  >([]);

  const { startTime, endTime } = chartWorkingHour;

  const diff = moment(endTime, 'HH:mm:ss').diff(
    moment(startTime, 'HH:mm:ss'),
    'hours'
  );

  useEffect(() => {
    setAvailableEvent([]);
  }, [selectedDebugModeDropdown, data]);

  useLayoutEffect(() => {
    setTimeout(() => {
      const mainContent = mainContentRef.current;
      if (!data || !mainContent) return;

      setTimelineWidth(mainContent.scrollWidth);
    }, 100);
  }, [isCollapsed, data, isLoading]);

  useEffect(() => {
    if (timeGridRef && timeGridRef.current) {
      const top = calculateTopPositionByTime({
        fromTime: chartWorkingHour.startTime,
        toTime: clinicWorkingHour.startTime,
        extraHeight: 60 * ONE_MINUTE_IN_PX,
      });

      if (data?.length === 1) {
        // Scroll to top when select item
        (timeGridRef.current as any).scrollTo({
          top: 0,
          left: 0,
        });
      } else {
        // Scroll to working hour of practice when showing all items
        (timeGridRef.current as any).scrollTo({
          top,
          left: 0,
        });
      }
    }
  }, [
    clinicWorkingHour,
    config.headerHeight,
    chartWorkingHour.startTime,
    data?.length,
  ]);

  const filterPractitionerByOp = (operatoryId: string) => {
    let selectedPractitioners = selectedDebugModeDropdown?.practitioners || [];

    if (selectedDebugModeDropdown?.service.isSplitScheduling) {
      const dentists = filterPractitionerTypeForExamAndCleaning({
        practitioners,
        specialist: SPECIALTY_NAME.DENTIST,
      });

      selectedPractitioners = selectedPractitioners.concat(dentists);
    }

    return selectedPractitioners.filter((practitioner) => {
      const operatoriesByPractitioner =
        workingHourPractitioners.find(
          (practitionerWorkingHour: any) =>
            practitionerWorkingHour.id === practitioner.id
        )?.operatories || [];

      return operatoriesByPractitioner.some((op) => op.id === operatoryId);
    });
  };

  const onHoverCell = ({
    operatoryId,
    element,
  }: {
    operatoryId: string;
    element: any;
  }) => {
    if (!clinic.unitDuration) return;
    const { currentTarget, clientY } = element;

    const cellAt =
      (currentTarget.offsetTop - config.headerHeight) / (60 * ONE_MINUTE_IN_PX); // be calculated from the bottom of header cell

    const mousePositionY = clientY - currentTarget.getBoundingClientRect().top; // distance from mouse position to nearest element

    const duration = clinic.unitDuration;

    const steps =
      Math.floor(mousePositionY / (duration * ONE_MINUTE_IN_PX)) * duration; // allow fromTime to calculate 10 minutes when searching available slot

    const fromTime = moment(cellAt, 'HH')
      .add(steps, 'minutes')
      .format('HH:mm:ss');

    if (
      previousMousePosition.current.clientY === clientY &&
      previousMousePosition.current.operatoryId === operatoryId
    ) {
      return;
    }

    if (
      selectedDebugModeDropdown &&
      selectedDebugModeDropdown.practitioners.length !== 0
    ) {
      // Check selected practitioner working on the hovering operatory
      let selectedDebugModePractitionerIds =
        selectedDebugModeDropdown.practitioners.map((item) => item.id);

      const isFlag = (practitioners || []).some(
        (item) =>
          selectedDebugModePractitionerIds.includes(item.id) &&
          item.operatories.some((item2) => item2.id === operatoryId)
      );

      if (!isFlag) {
        setAvailableEvent([]);
        return;
      }

      let duration = selectedDebugModeDropdown.service.duration;

      if (selectedDebugModeDropdown.service.isSplitScheduling) {
        duration = selectedDebugModeDropdown.service.hygienistDuration || 0;
      }

      const toTime = moment(fromTime, 'HH:mm:ss')
        .add(duration * MINUTE_OF_A_BLOCK, 'minutes')
        .format('HH:mm:ss');

      // FromTime is clicked outside office hour
      const isAvailableEventOutsideClinicWorkingHour =
        !moment(fromTime, 'HH:mm:ss').isBetween(
          moment(clinicWorkingHour.startTime, 'HH:mm:ss'),
          moment(clinicWorkingHour.endTime, 'HH:mm:ss'),
          'minutes',
          '[]'
        ) ||
        !moment(toTime, 'HH:mm:ss').isBetween(
          moment(clinicWorkingHour.startTime, 'HH:mm:ss'),
          moment(clinicWorkingHour.endTime, 'HH:mm:ss'),
          'minutes',
          '[]'
        );

      if (isAvailableEventOutsideClinicWorkingHour) {
        return;
      }

      // filter selected practitioner id if outside working hour based on fromTime and toTime
      selectedDebugModePractitionerIds =
        selectedDebugModePractitionerIds.filter((selectedPractitionerId) => {
          const tmp1 = pmsClinicSchedule?.doctors.find(
            (item2) => item2.id === selectedPractitionerId
          )?.workingHour;

          if (tmp1?.start && tmp1?.end) {
            const workingHourDoctorRangeBlock = calculateRangeBlock(
              tmp1.start,
              tmp1.end
            );
            const hoverAvailabilityRangeBlock = calculateRangeBlock(
              fromTime,
              toTime
            );

            return hoverAvailabilityRangeBlock.every((block) =>
              workingHourDoctorRangeBlock.includes(block)
            );
          }

          return false;
        });

      const busySlotsByOp = (data as IChairEvent[]).filter(
        (item: any) => item.id === operatoryId
      )[0];

      const occupiedEventsHasAssignedPractitioner = busySlotsByOp.events.filter(
        (item: any) =>
          [
            PMS_EVENT_TYPE.EVENT,
            PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
          ].includes(item.type)
      );

      const eventsBySelectedDebugModePractitioners = (
        data as IChairEvent[]
      ).reduce((accum: any, currentValue: IChairEvent) => {
        const tmpEvents = currentValue.events.filter((event) => {
          return selectedDebugModePractitionerIds.includes(
            event.practitionerId
          );
        });

        accum = accum.concat(tmpEvents);
        return accum;
      }, []);

      const isSelectedTimeAvailable =
        occupiedEventsHasAssignedPractitioner.every((event) => {
          return isSelectedEventAvailable(
            { fromTime, toTime },
            { fromTime: event.fromTime, toTime: event.toTime }
          );
        });

      if (isSelectedTimeAvailable) {
        // Filter practitioner has appts in other ops
        const availablePractitionerIds =
          selectedDebugModePractitionerIds.filter((practitionerId) => {
            const foundEvents = eventsBySelectedDebugModePractitioners.filter(
              (eve: any) => eve.practitionerId === practitionerId
            );

            if (foundEvents.length === 0) return true;

            const isAvailablePractitioner = foundEvents.every((eve: any) => {
              return isSelectedEventAvailable(
                { fromTime, toTime },
                { fromTime: eve.fromTime, toTime: eve.toTime }
              );
            });

            return isAvailablePractitioner;
          });

        // Display available event for dentist for exam & cleaning
        let normalizedAvailabilities = availablePractitionerIds.map((item) => ({
          practitionerId: item,
          operatoryId,
          fromTime,
          toTime,
        }));

        if (selectedDebugModeDropdown.service.isSplitScheduling) {
          const examCleaningServices = clinicServices?.data.find(
            (service) => service.name === SERVICE_NAME.EXAM_CLEANING
          );

          const examPlacement = examCleaningServices?.examPlacement;

          const dentists = filterPractitionerTypeForExamAndCleaning({
            practitioners,
            specialist: SPECIALTY_NAME.DENTIST,
          });

          const dentistsWithOp =
            pmsClinicSchedule?.doctors.filter((item) => {
              return dentists.some((item2) => item2.id === item.id);
            }) || [];

          const dentistAvailabilities = calculateDentistAvailabilities({
            hygienistAvailability: {
              operatoryId,
              fromTime,
              toTime,
            },
            clinicWorkingHour,
            dentistsWithOp: dentistsWithOp,
            selectedService: selectedDebugModeDropdown.service,
            timeGridData: cloneDeep(data) as any,
            examPlacement,
          });

          normalizedAvailabilities = normalizedAvailabilities.concat(
            dentistAvailabilities
          );
        }
        setAvailableEvent(normalizedAvailabilities);
      }
    }

    previousMousePosition.current = {
      clientY: clientY,
      operatoryId,
    };
  };

  const getScrollBarWidth = () => {
    const timeGrid = timeGridRef.current!;
    const scrollbarYWidth = timeGrid.offsetWidth - timeGrid.clientWidth;
    const scrollbarXWidth = timeGrid.offsetHeight - timeGrid.clientHeight;

    return { scrollbarXWidth, scrollbarYWidth };
  };

  const onEventCellClicked = (id: string) => {
    if (isDebugMode) return;

    const scrollbarWidth = getScrollBarWidth();

    timeGridRef.current!.style.overflow = 'hidden';
    timeGridRef.current!.style.paddingRight = `${scrollbarWidth.scrollbarYWidth}px`;
    timeGridRef.current!.style.paddingBottom = `${scrollbarWidth.scrollbarXWidth}px`;

    setSelectedApptId(id);
  };

  const onCardDetailClosed = () => {
    setSelectedApptId('');
    timeGridRef.current!.style.paddingRight = `0px`;
    timeGridRef.current!.style.paddingBottom = `0px`;
    timeGridRef.current!.style.overflow = 'auto';
  };

  const isSingleItemView = data && data.length === 1;
  const isOfficeClosed =
    (pmsClinicSchedule?.workingHour.start === null &&
      pmsClinicSchedule?.workingHour.end === null) ||
    false;

  const getCardClassNamePosition = (
    columnIndex: number,
    numberOfCardPerColumn: number,
    eventIds: number
  ) => {
    const cardWidth = 420;
    const eventIndex = eventIds + 1;

    const mainContentWidth = mainContentRef.current!.clientWidth;
    const cellWidth = mainContentWidth / data!.length;
    const column = columnIndex + 1;

    const extraSpaceForMultipleEventPerCell =
      numberOfCardPerColumn === 0
        ? 0
        : (cellWidth / numberOfCardPerColumn) * eventIndex;

    const isCardOverflowMainContent =
      cellWidth * column + cardWidth + extraSpaceForMultipleEventPerCell >
      mainContentWidth;

    const isCellContainCard = cellWidth > 420;

    if (isCellContainCard) {
      return 'right-0';
    }

    return isCardOverflowMainContent ? 'right-[50%]' : 'left-[50%]';
  };

  // 10REM FROM HEADER + DEBUG, 4REM FROM PADDING TOP+BOTTOM, 5REM FROM NAVIGATOR ABOVE

  const getStyleForCell = () => {
    const isMoreSpaceAvailable = isCollapsed || isSidebarCollapsed;

    const mainContent = mainContentRef.current;
    const columnCell = columnRef.current;

    const className: React.CSSProperties = {
      flexBasis:
        (data?.length || 1) > MAX_COLUMN
          ? `calc(95% / ${MAX_COLUMN})`
          : `calc(100% / ${data?.length || 1})`,
      flexGrow: 0,
    };

    if (!mainContent || !columnCell || !data) return className;

    if (data.length > MAX_COLUMN && isMoreSpaceAvailable) {
      className.flexBasis =
        columnCell.clientWidth === mainContent.clientWidth
          ? mainContent.clientWidth / (data.length || 1)
          : columnCell.clientWidth;
      className.flexGrow = 1;
    }

    return className;
  };

  return (
    <div
      ref={timeGridRef}
      className="flex-1 border-r border-light-grey overflow-auto h-[calc(100vh-10rem-4rem-5rem)] relative isolate w-full scrollbar"
    >
      <div className="flex">
        <TimeGridLabel config={config} workingHour={chartWorkingHour} />

        <div
          ref={mainContentRef}
          className={`flex flex-1 ${
            isLoading ? 'overflow-hidden opacity-40' : ''
          }`}
        >
          {isLoading && (
            <div className="absolute w-[calc(100%-5rem)] h-full flex justify-center items-center">
              <LoadingSpinner className="all-child:fill-magenta" />
            </div>
          )}
          {(data || DEFAULT_DATA).map((item: any, columnIndex: number) => {
            const { events, ...rest } = item;
            let isFlag = false;

            // Check operatory having available event
            const availableEventByOp = availableEvent.filter(
              (item2) => item2.operatoryId === item.id
            );

            if (availableEventByOp.length !== 0) {
              isFlag = true;
            }

            const practitionersByOp = filterPractitionerByOp(item.id);

            const groupByFromTime = !isFlag
              ? groupByOverlappedEvents(events)
              : groupByOverlappedEvents([
                  ...events,
                  ...(practitionersByOp || [])
                    .filter((practitioner) => {
                      return availableEventByOp.some(
                        (pract) => pract.practitionerId === practitioner.id
                      );
                    })
                    .reduce((accum: any, practitioner: any) => {
                      const availableEventsByPractitioner =
                        availableEventByOp.filter(
                          (availableEvent) =>
                            availableEvent.practitionerId === practitioner.id
                        );

                      for (
                        let j = 0;
                        j < availableEventsByPractitioner.length;
                        j++
                      ) {
                        const availableEventByPractitioner =
                          availableEventsByPractitioner[j];

                        accum.push({
                          fromTime: availableEventByPractitioner!.fromTime,
                          toTime: availableEventByPractitioner!.toTime,
                          service: selectedDebugModeDropdown?.service,
                          practitioner,
                          colorCode: practitioner.colorCode,
                          type: PMS_EVENT_TYPE.AVAILABLE_EVENT,
                        });
                      }

                      return accum;
                    }, []),
                ]);

            return (
              <div
                key={`${item.id}-table-${columnIndex}`}
                // ENSURE ALL COLUMNS ARE THE SAME WIDTH
                className={`relative w-0 ${
                  isSingleItemView ? 'w-full' : 'shrink-0'
                }`}
                ref={columnRef}
                style={getStyleForCell()}
              >
                <div
                  className="border-b border-r border-light-grey border-solid sticky top-0 left-0 bg-white z-20"
                  style={{
                    height: config.headerHeight,
                  }}
                  data-header-cell={true}
                >
                  {data && data.length !== 0 && HeaderCell && (
                    <HeaderCell item={rest} />
                  )}
                </div>

                <div>
                  {range(0, diff + 1).map((_, rowIdx) => {
                    const isBetweenWorkingHour = moment({
                      hour: rowIdx,
                    }).isBetween(
                      moment(clinicWorkingHour.startTime, 'HH:mm:ss'),
                      moment(clinicWorkingHour.endTime, 'HH:mm:ss'),
                      'hour',
                      '[]'
                    );

                    return (
                      <div
                        key={`item-${item.id}-row-${rowIdx}`}
                        onMouseMove={(mouseEvent) => {
                          if (practitionersByOp?.length !== 0) {
                            availableElementRef.current = {
                              currentTarget: mouseEvent.currentTarget,
                              clientY: mouseEvent.clientY,
                            };

                            clearTimeout(availableHoveringTimeOutRef.current);
                            availableHoveringTimeOutRef.current = setTimeout(
                              () => {
                                onHoverCell({
                                  operatoryId: item.id,
                                  element: availableElementRef.current,
                                });
                              },
                              100
                            );
                          } else if (availableEvent !== null) {
                            clearTimeout(availableHoveringTimeOutRef.current);
                            setAvailableEvent([]);
                          }
                        }}
                      >
                        <div
                          className="border-b border-r border-light-grey border-solid relative"
                          style={{
                            height: config.cellHeight,
                          }}
                        >
                          {isBetweenWorkingHour ? (
                            <CrossCellPerUnitDuration
                              unitDuration={clinic?.unitDuration}
                              clinicWorkingHour={clinicWorkingHour}
                              hour={rowIdx}
                            />
                          ) : (
                            <CrossContainerIcon className="w-full h-full bg-[#FBFBFB] absolute" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {data?.[0].id === PRACTITIONER_WITH_NO_OPERATORY && (
                  <div
                    style={{
                      // DISPLAY AT 10AM
                      top: config.headerHeight + 60 * ONE_MINUTE_IN_PX * 10,
                    }}
                    className="absolute left-[50%] -translate-x-[50%] w-[38.8rem] h-[8.9rem] p-2.4 text-center shadow-elevation-07 flex justify-center items-center bg-white"
                  >
                    {getPractitionerName((data[0] as EmptyEvent).practitioner)}{' '}
                    hasn't been assigned to any chairs on this day
                  </div>
                )}

                {data?.[0].id === PRACTITIONER_WITH_NO_APPOINTMENT && (
                  <div
                    style={{
                      // DISPLAY AT 10AM
                      top: config.headerHeight + 60 * ONE_MINUTE_IN_PX * 10,
                    }}
                    className="absolute left-[50%] -translate-x-[50%] w-[38.8rem] h-[8.9rem] p-2.4 text-center shadow-elevation-07 flex justify-center items-center bg-white"
                  >
                    {getPractitionerName((data[0] as EmptyEvent).practitioner)}{' '}
                    doesn't have any appointments on this day
                  </div>
                )}

                {data?.[0].id === PRACTITIONER_OFF && (
                  <div
                    style={{
                      // DISPLAY AT 10AM
                      top: config.headerHeight + 60 * ONE_MINUTE_IN_PX * 10,
                    }}
                    className="absolute left-[50%] -translate-x-[50%] w-[38.8rem] h-[8.9rem] p-2.4 text-center shadow-elevation-07 flex justify-center items-center bg-white"
                  >
                    {getPractitionerName((data[0] as EmptyEvent).practitioner)}{' '}
                    is off on this day
                  </div>
                )}

                {data?.[0].id === NO_APPOINTMENT_ID && (
                  <div
                    style={{
                      top: config.headerHeight + 60 * ONE_MINUTE_IN_PX * 10,
                    }}
                    className="absolute left-[50%] -translate-x-[50%] w-[38.8rem] h-[8.9rem] p-2.4 text-center shadow-elevation-07 flex justify-center items-center bg-white"
                  >
                    There are no appointments available on this day
                  </div>
                )}

                {groupByFromTime.map((events: IEvent[], index: number) => {
                  const minFromTime = events[0].fromTime;
                  let parentTop = calculateTopPositionByTime({
                    fromTime: chartWorkingHour.startTime,
                    toTime: minFromTime,
                    extraHeight: config.headerHeight + 60 * ONE_MINUTE_IN_PX, // chart time is started 1 hour early
                  });

                  const isClosed =
                    events[0].fromTime === TIME_GRID_WORKING_HOUR.START &&
                    events[0].toTime === TIME_GRID_WORKING_HOUR.END;

                  if (isClosed) {
                    parentTop = config.headerHeight;
                  }

                  return (
                    <div
                      key={`group-${index}`}
                      className="absolute w-full left-0 flex flex-row"
                      style={{
                        top: parentTop,
                      }}
                    >
                      {events.map((event: IEvent, eventIdx: number) => {
                        const { fromTime, toTime, type } = event;
                        let childTop = calculateTopPositionByTime({
                          fromTime: chartWorkingHour.startTime,
                          toTime: fromTime,
                          extraHeight:
                            config.headerHeight + 60 * ONE_MINUTE_IN_PX, // chart time is started 1 hour early
                        });

                        if (isClosed) {
                          childTop = 60;
                        }

                        const top = childTop - parentTop;

                        let height =
                          moment(toTime, 'HH:mm:ss').diff(
                            moment(fromTime, 'HH:mm:ss'),
                            'minutes'
                          ) * ONE_MINUTE_IN_PX;

                        if (isClosed) {
                          height += config.headerHeight;
                        }

                        const defaultTop = 8;
                        const heightInTotal = 5 + 18; // 5: padding and 18: the text height

                        const tooltipId = `event-${item.id}-${type}-${fromTime}-${toTime}`;

                        const eventsForRenderingTooltip = isOfficeClosed
                          ? [
                              PMS_EVENT_TYPE.OFFICE_CLOSED_HOUR,
                              PMS_EVENT_TYPE.EVENT,
                              PMS_EVENT_TYPE.OFFICE_HOLIDAY,
                              PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
                            ]
                          : [
                              PMS_EVENT_TYPE.EVENT,
                              PMS_EVENT_TYPE.OFFICE_HOLIDAY,
                              PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
                            ];

                        return (
                          <div key={`${tooltipId}-${eventIdx}`}>
                            {eventsForRenderingTooltip.includes(type) &&
                              isDebugMode && (
                                <ReactTooltip
                                  isCapture
                                  id={tooltipId}
                                  place="right"
                                  effect="solid"
                                  className={`${styles.tooltip}`}
                                  clickable
                                  overridePosition={(
                                    { left, top }: any,
                                    currentEvent: any,
                                    currentTarget: any,
                                    node
                                  ) => {
                                    let tmpTop =
                                      currentEvent.clientY -
                                      (node as any).clientHeight / 2;

                                    let tmpLeft =
                                      currentTarget.getBoundingClientRect()
                                        .left +
                                      currentTarget.getBoundingClientRect()
                                        .width;

                                    if (
                                      tmpLeft + (node as any).clientWidth >=
                                      document.documentElement.clientWidth
                                    ) {
                                      tmpLeft =
                                        tmpLeft -
                                        currentTarget.getBoundingClientRect()
                                          .width -
                                        (node as any).clientWidth;
                                    }

                                    return {
                                      top: tmpTop,
                                      left: tmpLeft,
                                    };
                                  }}
                                >
                                  <TimeGridTooltip
                                    eventWorkingHour={{
                                      startTime: fromTime,
                                      endTime: toTime,
                                    }}
                                    type={type}
                                    isHoliday={
                                      pmsClinicSchedule?.isHoliday || false
                                    }
                                    isClosed={isOfficeClosed}
                                    clinicWorkingHour={clinicWorkingHour}
                                    workingHourPractitioners={
                                      workingHourPractitioners
                                    }
                                    scheduleLayer={scheduleLayer}
                                  />
                                </ReactTooltip>
                              )}

                            <div
                              data-for={tooltipId}
                              data-tip
                              data-event="mouseenter"
                              data-event-off="mouseleave"
                              className="w-full absolute"
                              style={{
                                top,
                                left: `calc((100% / ${events.length}) * ${eventIdx})`,
                                height,
                                maxWidth: `calc(100% / ${events.length})`,
                                zIndex:
                                  // WHEN MODAL OPEN CURRENT CELL WILL OVERRIDE Z-INDEX AND ALWASY ON TOP
                                  selectedApptId === event.appointmentDetail?.id
                                    ? LAYER_INDEX.MAXIMUM_Z_INDEX
                                    : LAYER_INDEX[type],
                              }}
                            >
                              <div
                                className={`pt-[2px] pb-[3px] h-full bg-white
                               ${isSingleItemView ? 'pr-[11px]' : 'pr-[2px]'}
                               ${isDebugMode ? 'pb-0' : 'pb-[3px]'}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {EventCell && (
                                  <>
                                    <EventCell
                                      type={type}
                                      item={event}
                                      onClick={() =>
                                        onEventCellClicked(
                                          event.appointmentDetail?.id
                                        )
                                      }
                                      onMouseLeave={() => {
                                        if (availableEvent.length !== 0) {
                                          setAvailableEvent([]);
                                        }
                                      }}
                                      paddingTop={
                                        height - heightInTotal < defaultTop
                                          ? height - heightInTotal - 2 // 2: extra height
                                          : defaultTop
                                      }
                                    />

                                    {!isDebugMode &&
                                      selectedApptId ===
                                        event.appointmentDetail?.id && (
                                        <CardDetail
                                          key={`${isDebugMode}`}
                                          appointmentId={
                                            event.appointmentDetail.id
                                          }
                                          onClose={onCardDetailClosed}
                                          className={getCardClassNamePosition(
                                            columnIndex,
                                            events.length,
                                            eventIdx
                                          )}
                                        />
                                      )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {clinic && (
        <TimeLine
          timeGridRef={timeGridRef}
          width={timelineWidth}
          workingHour={chartWorkingHour}
          config={config}
          date={date}
          timezone={clinic.timezone}
          isDebugMode={isDebugMode}
        />
      )}
    </div>
  );
};

export default TimeGridContent;

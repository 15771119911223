import { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';
interface ProfileInfoProps {
  title: string;
  data: string;
  isEdit?: boolean;
  isOptional?: boolean;
  isOptimizeScheduleField?: boolean;
  isTooltipDisplayed?: boolean;
}

const ProfileInfo: FC<ProfileInfoProps> = ({
  title,
  data,
  isEdit,
  children,
  isOptional,
  isOptimizeScheduleField,
  isTooltipDisplayed,
}) => {
  const isCenterTitle = isEdit && title !== 'Bio';
  return (
    <div className={`flex gap-x-5 ${isCenterTitle ? 'items-center' : ''}`}>
      <h3
        className={`font-bold w-[16rem] relative flex items-center gap-x-0.5 ${
          isOptimizeScheduleField ? 'self-start' : ''
        }`}
      >
        {title}{' '}
        {!isOptional && isEdit && <span className="text-mid-grey">*</span>}
        {isTooltipDisplayed && isEdit && (
          <>
            <button data-for="optimized-schedule" data-tip="">
              <InfoIcon className="w-2.4 h-2.4 all-child:fill-mid-grey" />
            </button>
            <ReactTooltip
              id="optimized-schedule"
              effect="solid"
              place="bottom"
              className="!bg-white !rounded-[0.8rem] !w-[26.8rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:!border-b-white"
            >
              <span className="text-11 text-darkest-grey flex items-center font-normal">
                When Optimize Schedule is turned on, patients can only schedule
                1/2/3 appointment slots before or after the appointment that is
                available. This ensures a tighter schedule for providers and
                reduces idle time.
              </span>
            </ReactTooltip>
          </>
        )}
      </h3>
      {isEdit ? children : <p className="flex-1 max-w-[36rem]">{data}</p>}
    </div>
  );
};

export default ProfileInfo;

import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';

import useClinicSchedule from 'hooks/useClinicSchedule';
import useBeforeUnloaded from 'hooks/useBeforeUnloaded';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import SelectSchedule from './SelectSchedule/SelectSchedule';
import {
  NEXT_23_MONTH,
  SCHEDULE_TYPE,
  WORKING_HOUR_SYSTEM,
} from 'utils/constants';
import DayTimeSlot from 'pages/OfficeSchedulePage/DayTimeSlot/DayTimeSlot';
import { ClinicSchedule, WeekWorkingHour } from 'interfaces/clinicSchedule';
import { IAppointmentDetail } from 'interfaces/appointments';
import NavigationBlocker from './NavigationBlocker/NavigationBlocker';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import Calendar from './Calendar/Calendar';
import useClinicException from 'hooks/useClinicException';
import useOutsideOfficeHour from 'hooks/useOutsideOfficeHour';
import CustomDaysList from './CustomDaysList/CustomDaysList';
import OutsideOfficeHoursModal from './OutsideOfficeHoursModal/OutsideOfficeHoursModal';
import normalizeWeeklySchedule from 'utils/normalizeWeeklySchedule';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import OutsideApptIndicator from 'components/OutsideHourIndicator/OutsideHourIndicator';
import useScheduleNavigator from 'hooks/useScheduleNavigator';
import CommonButton from 'components/CommonButton/CommonButton';
import AdvancedSettingDrawer from './AdvancedSettingDrawer/AdvancedSetttingDrawer';

export interface WeeklyTimeslot {
  day: string;
  start: string | null;
  end: string | null;
}

const WrapperSchedulePage = () => {
  const today = moment().format('YYYY-MM-DD');

  const [isShowing, setIsShowing] = useState(false);

  const cachedTimeSlots = useRef<WeeklyTimeslot[]>();
  const [timeSlots, setTimeSlots] = useState<WeeklyTimeslot[]>([]);

  const isDataChanged = !isEqual(timeSlots, cachedTimeSlots.current);

  const cachedCurrentWorkingHour = useRef<string>();

  const { state, dispatch } = useScheduleNavigator(today);

  const [isCustomDaysTab, setIsCustomDaysTab] = useState(false);

  const [isOutsideOfficeHoursModal, setIsOutsideOfficeHoursModal] =
    useState(false);

  const [isDataChecking, setIsDataChecking] = useState(false);

  const { data: clinicSchedule, isLoading: isClinicScheduleLoading } =
    useClinicSchedule();

  const { data: clinicException, isLoading: isClinicExceptionLoading } =
    useClinicException({
      shouldRun: !!clinicSchedule,
      source: clinicSchedule ? clinicSchedule.currentWorkingHour : '',
      startDate: moment(state.monthValue).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(state.monthValue).endOf('month').format('YYYY-MM-DD'),
    });

  const {
    data: outsideOfficeHourAppts,
    isLoading: isOutsideOfficeHourApptsLoading,
    mutate: outsideOfficeHourApptsMutate,
  } = useOutsideOfficeHour({
    shouldRun: !!clinicSchedule,
    source: clinicSchedule ? clinicSchedule.currentWorkingHour : '',
  });

  const maxDate = moment().add(NEXT_23_MONTH, 'months').endOf('days').toDate();

  const isAvailableOutsideOfficeHourAppts =
    outsideOfficeHourAppts && outsideOfficeHourAppts.length > 0;

  const isLoading =
    (isClinicScheduleLoading ||
      isClinicExceptionLoading ||
      isOutsideOfficeHourApptsLoading) &&
    // PREVENT SHOW LOADING UI WHEN CHANGE MONTH
    state.monthValue === today;

  useBeforeUnloaded({ when: isDataChanged });

  useEffect(() => {
    if (!clinicSchedule) return;

    const selectedSchedule =
      clinicSchedule.currentWorkingHour === WORKING_HOUR_SYSTEM.FIRSTIN
        ? SCHEDULE_TYPE.DEFAULT
        : SCHEDULE_TYPE.GOOGLE;

    const selectedSourceData = clinicSchedule[
      selectedSchedule as keyof ClinicSchedule
    ] as WeekWorkingHour;

    const timeSlots = normalizeWeeklySchedule(selectedSourceData);

    cachedTimeSlots.current = cloneDeep(timeSlots);
    setTimeSlots(timeSlots);

    if (
      clinicSchedule?.currentWorkingHour !== cachedCurrentWorkingHour.current
    ) {
      cachedCurrentWorkingHour.current = clinicSchedule?.currentWorkingHour;
    }
  }, [clinicSchedule]);

  const onCheckOutsideOfficeHourAppts = async () => {
    const response = await axiosInstance.get<IAppointmentDetail[]>(
      `/appointments/out-office-hours`
    );

    const newApptIds = response.data.map((item) => item.id);
    const prevApptIds = outsideOfficeHourAppts?.map((item) => item.id) || [];

    const isNewAppts = newApptIds?.some((id) => !prevApptIds.includes(id));

    await outsideOfficeHourApptsMutate(response.data, { revalidate: false });

    if (
      (response.data.length !== 0 &&
        prevApptIds.length !== newApptIds.length) ||
      isNewAppts
    ) {
      setIsOutsideOfficeHoursModal(true);
    }
  };

  const handleOnOutsideOfficeButtonClicked = async () => {
    try {
      setIsDataChecking(true);
      const response = await axiosInstance.get<IAppointmentDetail[]>(
        '/appointments/out-office-hours'
      );
      await outsideOfficeHourApptsMutate(response.data, { revalidate: false });
      setIsDataChecking(false);

      if (response.data.length === 0) {
        return renderToast({
          type: 'info',
          message: 'No patients outside of working hours',
        });
      }

      setIsOutsideOfficeHoursModal(true);
    } catch (error) {
      setIsDataChecking(false);
      renderToast({
        type: 'error',
        message: 'An error has occurred. Please try again.',
      });
    }
  };

  return (
    <>
      {isDataChecking && <LoadingScreen />}
      <NavigationBlocker when={isDataChanged} />
      <AdvancedSettingDrawer
        isShowing={isShowing}
        setIsShowing={setIsShowing}
      />
      <div className="pt-4 pl-3 pr-4 text-14 leading-[2.1rem] text-darkest-grey">
        <div className="flex justify-between items-center">
          <h3 className="text-24 font-bold leading-[3.6rem]">
            Office Schedule
          </h3>
          <CommonButton
            variant="secondary"
            className="w-18"
            onClick={() => setIsShowing(true)}
          >
            Advanced Settings
          </CommonButton>
        </div>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <div className="mt-5.3 flex gap-x-2">
              <div className="basis-[35rem]">
                <SelectSchedule />
                <div className="w-full mt-2">
                  <div
                    className="h-4.8 flex
                 justify-evenly items-center border border-light-grey"
                  >
                    <button
                      className={`basis-[13rem] ${
                        !isCustomDaysTab ? 'text-magenta font-bold' : ''
                      }`}
                      onClick={() => setIsCustomDaysTab(false)}
                    >
                      Regular Hours
                    </button>
                    <span className="h-3 border-l border-light-grey"></span>
                    <button
                      className={`basis-[13rem] ${
                        isCustomDaysTab ? 'text-magenta font-bold' : ''
                      }`}
                      onClick={() => setIsCustomDaysTab(true)}
                    >
                      Custom Days
                    </button>
                  </div>
                </div>
                {isCustomDaysTab ? (
                  <CustomDaysList
                    key={clinicSchedule!.currentWorkingHour}
                    monthValue={moment(state.monthValue, 'YYYY-MM-DD').format(
                      'MMMM YYYY'
                    )}
                    setDate={(date) => {
                      dispatch({
                        type: 'customDayNavigation',
                        payload: { monthValue: date },
                      });
                    }}
                    isNavigatedByCalendar={
                      !state.highlightedDate && state.isNavigatedByCalendar
                    }
                    source={clinicSchedule!.currentWorkingHour}
                    onCheckOutsideOfficeHourAppts={
                      onCheckOutsideOfficeHourAppts
                    }
                    version={clinicSchedule!.version}
                  />
                ) : (
                  <DayTimeSlot
                    clinicSchedule={clinicSchedule!}
                    timeSlots={timeSlots}
                    setTimeSlots={setTimeSlots}
                    cachedTimeSlots={cachedTimeSlots.current!}
                    onCheckOutsideOfficeHourAppts={
                      onCheckOutsideOfficeHourAppts
                    }
                  />
                )}
              </div>
              <div className="flex-1">
                <div className="flex flex-row justify-between items-center relative">
                  <MonthViewNavigation
                    date={state.monthValue}
                    setDate={(date) => {
                      dispatch({
                        type: 'monthNavigation',
                        payload: { monthValue: date },
                      });
                    }}
                    maxDate={maxDate}
                  />
                  {isAvailableOutsideOfficeHourAppts && (
                    <OutsideApptIndicator
                      onClick={handleOnOutsideOfficeButtonClicked}
                      numberOfAppts={outsideOfficeHourAppts!.length}
                    >
                      Patient is scheduled outside office hours
                    </OutsideApptIndicator>
                  )}
                </div>

                <Calendar
                  date={state.monthValue}
                  data={clinicException!}
                  googlePlaceId={clinicSchedule!.googlePlaceId}
                  highlightedDate={state.highlightedDate}
                  resetHighlightedDate={() => {
                    dispatch({ type: 'resetHighLightedDate' });
                  }}
                  onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
                  clinicSchedule={clinicSchedule!}
                />
              </div>
            </div>
            {isOutsideOfficeHoursModal && isAvailableOutsideOfficeHourAppts && (
              <OutsideOfficeHoursModal
                data={outsideOfficeHourAppts!}
                onClose={() => {
                  setIsOutsideOfficeHoursModal(false);
                }}
                header={
                  <>
                    <h3 className="font-bold text-20 leading-[3rem]">
                      Patients scheduled outside of office hours
                    </h3>

                    <div className="mt-1.6 mb-1.9">
                      Your custom hours were recently updated and we found the
                      following patients with future appointments outside of
                      your new office hours. Please update the appointments in
                      your practice software.
                    </div>
                  </>
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default WrapperSchedulePage;

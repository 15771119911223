import React from 'react';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import useClinicService from 'hooks/useClinicService';

import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const ServiceDropdown = (props: IProps) => {
  const { data: clinicServices, isLoading } = useClinicService({
    sort: 'asc',
    sortBy: 'order',
  });

  const activeClinicServices = clinicServices?.data?.filter(
    (service) => service.isActive
  );

  const clinicServiceOptions = activeClinicServices?.map((service) => ({
    value: service.id,
    label: service.name,
  }));

  return (
    <Controller
      {...props}
      name="services"
      render={({ field }) => {
        const { onChange, value } = field;

        return (
          <MultipleSelect
            key={`${isLoading}`}
            isLoading={isLoading}
            placeholder={'Select Services'}
            options={clinicServiceOptions}
            value={isLoading ? [] : value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
          />
        );
      }}
    />
  );
};

export default ServiceDropdown;

import { Dispatch, FC, useState } from 'react';
import { capitalize, cloneDeep, isEqual } from 'lodash';
import moment from 'moment';

import axiosInstance from 'apis/axiosInstance';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultTimeView from 'pages/OfficeSchedulePage/DayTimeSlot/DefaultTimeView/DefaultTimeView';
import GoogleTimeView from './GoogleTimeView/GoogleTimeView';
import { WeeklyTimeslot } from '../WrapperSchedulePage';
import { ExceptionDate } from 'interfaces/exceptionDate';
import { renderToast } from 'components/Toast/Toast';
import OverrideModal from './OverrideModal/OverrideModal';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

import {
  DEFAULT_WORKING_HOUR,
  NEXT_23_MONTH,
  WORKING_HOUR_SYSTEM,
} from 'utils/constants';
import useMatchMutate from 'hooks/useMatchMutate';
import getQueryTimeSlotPerDay from 'utils/getQueryTimeSlotPerDay';
import axios from 'axios';
import { useVersion } from '../context/versionContext';
import { ClinicSchedule } from 'interfaces/clinicSchedule';
import EmptySchedule from 'components/EmptySchedule/EmptySchedule';
import getChangedTimeSlots from 'utils/getChangedTimeSlots';

interface DayTimeSlotProps {
  timeSlots: WeeklyTimeslot[];
  setTimeSlots: Dispatch<React.SetStateAction<WeeklyTimeslot[]>>;
  cachedTimeSlots: WeeklyTimeslot[];
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
  clinicSchedule: ClinicSchedule;
}

const DayTimeSlot: FC<DayTimeSlotProps> = ({
  timeSlots,
  setTimeSlots,
  cachedTimeSlots,
  onCheckOutsideOfficeHourAppts,
  clinicSchedule,
}) => {
  const matchMutate = useMatchMutate();
  const { setIsOldVersion } = useVersion();

  const isDefaultSchedule =
    clinicSchedule!.currentWorkingHour === WORKING_HOUR_SYSTEM.FIRSTIN;

  const changedTimeSlots = getChangedTimeSlots(timeSlots, cachedTimeSlots);

  const isGooglePlaceIdNotSetup =
    !isDefaultSchedule && !clinicSchedule?.googlePlaceId;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [exceptionDates, setExceptionDates] = useState<ExceptionDate[] | null>(
    null
  );

  const handleUpdatedTimeSlot = (day: string, value: string, type: string) => {
    const updatedTimeSlots = [...timeSlots];
    const timeSlot = updatedTimeSlots.find((slot) => slot.day === day)!;
    timeSlot[type as keyof WeeklyTimeslot] = value;
    setTimeSlots(updatedTimeSlots);
  };

  const handleCopyTimeSlot = (timeSlots: WeeklyTimeslot[]) => {
    setTimeSlots(timeSlots);
  };

  const handleSaveSchedule = async () => {
    setIsSubmitting(true);

    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment()
      .add(NEXT_23_MONTH, 'months')
      .endOf('months')
      .format('YYYY-MM-DD');

    const queryTimeSlotPerDay = getQueryTimeSlotPerDay(changedTimeSlots);

    try {
      const response = await axiosInstance.get(
        '/clinics-exception/custom-days',
        {
          params: {
            source: WORKING_HOUR_SYSTEM.FIRSTIN,
            startDate,
            endDate,
            days: changedTimeSlots.map((timeSlot) => timeSlot.day),
            ...queryTimeSlotPerDay,
          },
        }
      );
      const isExceptionDateFound = response.data.length > 0;

      if (isExceptionDateFound) {
        setIsSubmitting(false);
        return setExceptionDates(response.data);
      }

      await handleUpdateSchedule();
      await Promise.all([
        matchMutate(/\/clinics-schedule[\s\S]+/),
        matchMutate(/\/clinics-exception[\s\S]+/),
      ]);
      await onCheckOutsideOfficeHourAppts();

      renderToast({
        type: 'success',
        message: 'Regular hours has been updated successfully',
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }

      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    setIsSubmitting(false);
  };

  const handleUpdateSchedule = async () => {
    const body: { [key: string]: [{ start: string; end: string }] | string } = {
      id: clinicSchedule!.defaultWorkingHour.id!,
    };

    changedTimeSlots.forEach(({ day, end, start }) => {
      body[day as keyof typeof body] = [
        {
          start: start!,
          end: end!,
        },
      ];
    });

    await axiosInstance.patch('/clinics-schedule', body, {
      headers: { version: clinicSchedule!.version },
    });
  };

  return (
    <>
      {isSubmitting && <LoadingScreen />}
      {exceptionDates && (
        <OverrideModal
          exceptionsDates={exceptionDates}
          changedTimeSlots={changedTimeSlots}
          onClose={() => setExceptionDates(null)}
          onUpdateSchedule={handleUpdateSchedule}
          onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
          source={clinicSchedule!.currentWorkingHour}
          version={clinicSchedule!.version}
        />
      )}
      <div className="w-full border border-light-grey border-y-0">
        {isGooglePlaceIdNotSetup ? (
          <EmptySchedule />
        ) : (
          timeSlots.map(({ day, start, end }, index) => {
            const isChecked = Boolean(start && end);
            return (
              <div
                key={day}
                className={`h-6.2 border-b border-light-grey flex items-center ${
                  isDefaultSchedule ? 'gap-x-4.8' : 'gap-x-[11.7rem]'
                }`}
              >
                <div
                  className={`flex gap-x-1.1 ${
                    isDefaultSchedule ? 'pl-2.1' : 'pl-4'
                  }`}
                >
                  {isDefaultSchedule && (
                    <input
                      id={`day-${day}`}
                      type="checkbox"
                      className="w-2.2 h-2.2"
                      checked={isChecked}
                      onChange={(event) => {
                        const updatedTimeSlots = [...timeSlots];
                        const timeSlot = updatedTimeSlots.find(
                          (slot) => slot.day === day
                        )!;

                        const start = cachedTimeSlots[index].start
                          ? cachedTimeSlots[index].start
                          : DEFAULT_WORKING_HOUR.START;

                        const end = cachedTimeSlots[index].end
                          ? cachedTimeSlots[index].end
                          : DEFAULT_WORKING_HOUR.END;

                        timeSlot.start = event.target.checked ? start : null;
                        timeSlot.end = event.target.checked ? end : null;
                        setTimeSlots(updatedTimeSlots);
                      }}
                    />
                  )}
                  <label
                    htmlFor={`day-${day}`}
                    className="font-bold w-[3.1rem]"
                  >
                    {capitalize(day)}
                  </label>
                </div>
                <div>
                  {!isChecked ? (
                    <span>Closed</span>
                  ) : (
                    <>
                      {isDefaultSchedule ? (
                        <DefaultTimeView
                          day={day}
                          start={start!}
                          end={end!}
                          timeSlots={timeSlots}
                          onChange={handleUpdatedTimeSlot}
                          onCopy={handleCopyTimeSlot}
                        />
                      ) : (
                        <GoogleTimeView start={start!} end={end!} />
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>

      {isDefaultSchedule && (
        <div className="mt-1.6 flex items-center min-h-[3.2rem]">
          {!isEqual(timeSlots, cachedTimeSlots) && (
            <div className="flex gap-x-1 ml-auto">
              <CommonButton
                variant="secondary"
                className="!min-w-[8rem] !min-h-[3.2rem]"
                onClick={() => {
                  setTimeSlots(cloneDeep(cachedTimeSlots));
                }}
              >
                Cancel
              </CommonButton>
              <CommonButton
                className="!min-w-[8rem] !min-h-[3.2rem]"
                onClick={handleSaveSchedule}
              >
                Save
              </CommonButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DayTimeSlot;

import { useState } from 'react';
import { useUser } from 'context/userContext';
import TableSection from './TableSection/TableSection';
import EditOrCreateUser, {
  IEditOrCreateUserForm,
} from './EditOrCreateUser/EditOrCreateUser';
import axiosInstance from 'apis/axiosInstance';
import axios from 'axios';
import { renderToast } from 'components/Toast/Toast';
import CommonButton from 'components/CommonButton/CommonButton';

const UserPage = () => {
  const { user } = useUser();
  const { currentClinicUserRole } = user;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [key, setKey] = useState(1);
  const [error, setError] = useState('');

  const onClose = () => setIsModalOpen(false);

  const onSubmit = async ({ email, ...rest }: IEditOrCreateUserForm) => {
    try {
      await axiosInstance.post('/clinic-user/auth/create-account', {
        email,
        clinics: [
          {
            id: currentClinicUserRole!.clinic.id,
            ...rest,
          },
        ],
      });
      setKey(key + 1);
      onClose();
      renderToast({
        message: 'A user has created successfully',
        type: 'success',
      });
    } catch (error) {
      let message = 'An error has occurred. Please try again.';
      if (
        axios.isAxiosError(error) &&
        error.response?.data.statusCode === 400
      ) {
        setError('Email already exists');
        message = `Email already exists at ${currentClinicUserRole?.clinic.name}. Please enter a unique email.`;
      }
      renderToast({ message, type: 'error' });
    }
  };

  return (
    <>
      {isModalOpen && (
        <EditOrCreateUser
          onClose={onClose}
          onSubmit={onSubmit}
          error={error}
          setError={setError}
        />
      )}
      <div className="pt-2.4 pl-3 pr-4 text-14 leading-[2.1rem]">
        <h3 className="text-24 font-bold leading-[3.6rem]">Users</h3>
        <CommonButton
          variant="secondary"
          className="mt-2.4 mb-3 !min-h-[3.2rem]"
          onClick={() => setIsModalOpen(true)}
        >
          Create New User
        </CommonButton>

        <div className="bg-white shadow-primary rounded-[1rem] mt-3">
          <TableSection key={`${currentClinicUserRole?.clinic.id}-${key}`} />
        </div>
      </div>
    </>
  );
};

export default UserPage;

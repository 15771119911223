import React, { useMemo, useState } from 'react';
import { Menu } from '@headlessui/react';
import { debounce } from 'lodash';

import { useUser } from 'context/userContext';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/icons/arrow-down.svg';
import { highlightText } from 'utils/common';

interface IOption {
  id: string | null;
  name: string;
}

interface IProps {
  clinicId: string | null;
  onSelect: (clinicId: string | null) => void;
}

const ClinicFilter = ({ clinicId, onSelect }: IProps) => {
  const [searchingValue, setSearchingValue] = useState('');

  const {
    user: { clinicUserRoles },
  } = useUser();

  const initialOptions = useMemo(() => {
    return clinicUserRoles.reduce(
      (accum: IOption[], currentValue) => {
        if (currentValue.clinic) {
          accum.push({
            id: currentValue.clinic.id,
            name: currentValue.clinic.name,
          });
        }

        return accum;
      },
      [{ id: null, name: 'All Practices' }]
    );
  }, [clinicUserRoles]);

  const [searchingClinics, setSearchingClinics] = useState(initialOptions);

  const debounceFn = debounce((value) => {
    const regex = new RegExp(value, 'gi');

    const searchingResults = initialOptions.filter((item) => {
      return item.name.search(regex) !== -1;
    });

    setSearchingClinics(searchingResults);
  }, 100);

  const searchClinic = (event: any) => {
    const value = event.target.value;
    setSearchingValue(value);
    debounceFn(value);
  };

  const clearSearchClinic = () => {
    setSearchingValue('');
    debounceFn('');
  };

  return (
    <Menu as="div" className="relative">
      {({ open }) => {
        const selectedClinicName = initialOptions.find(
          (item) => item.id === clinicId
        )?.name;

        return (
          <>
            <Menu.Button
              className={`text-bold flex flex-row justify-between items-center w-[18rem] h-[4.8rem] py-1.3 px-1.6 rounded-[1rem] shadow-primary hover:shadow-elevation-10 border border-transparent hover:border-magenta ${
                open ? '!border-magenta !shadow-elevation-10' : ''
              }`}
            >
              <span className="font-bold truncate basis-[80%] text-left">
                {selectedClinicName}
              </span>
              <DropdownArrowIcon width={14} height={14} />
            </Menu.Button>
            <Menu.Items
              className={`overflow-hidden top-6 absolute z-[9999] w-[22.5rem] bg-white rounded-[1rem] flex flex-col py-1.6 px-2 shadow-primary ${
                searchingClinics.length > 5 ? 'h-[27.5rem]' : 'h-auto'
              }`}
            >
              <div className="flex flex-row mb-1.6">
                <SearchIcon className="mr-[1rem]" width={20} />
                <input
                  value={searchingValue}
                  placeholder="Search ..."
                  className="text-14 placeholder-mid-grey focus:outline-none focus-visible:outline-none w-full"
                  onChange={searchClinic}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                {searchingValue && (
                  <CancelIcon
                    className="ml-[1rem] cursor-pointer"
                    width={20}
                    onClick={clearSearchClinic}
                  />
                )}
              </div>

              <div className="overflow-auto overscroll-contain scrollbar h-full flex flex-col items-start">
                {searchingClinics.map((item) => {
                  const isSelected = item.id === clinicId;

                  const highlightedParts = highlightText(
                    item.name,
                    searchingValue
                  );

                  return (
                    <Menu.Item
                      key={item.id}
                      as="button"
                      className={`mb-1.6 hover:text-magenta w-full text-left min-h-[2.7rem] truncate ${
                        isSelected ? 'text-magenta' : ''
                      }`}
                      onClick={() => onSelect(item.id)}
                    >
                      {highlightedParts.map((part, index) => (
                        <span
                          key={index}
                          className={part.highlight ? 'text-magenta' : ''}
                        >
                          {part.text}
                        </span>
                      ))}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </>
        );
      }}
    </Menu>
  );
};

export default ClinicFilter;

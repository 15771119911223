import { FC } from 'react';

import SelectTime from '../SelectTime/SelectTime';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import CopyTimeSlot from './CopyTimeSlot/CopyTimeSlot';

interface DefaultTimeViewProps {
  day: string;
  start: string;
  end: string;
  onChange: (day: string, value: string, type: string) => void;
  onCopy: (timeSlots: WeeklyTimeslot[]) => void;
  timeSlots: WeeklyTimeslot[];
}

const DefaultTimeView: FC<DefaultTimeViewProps> = ({
  day,
  start,
  end,
  onChange,
  onCopy,
  timeSlots,
}) => {
  return (
    <div className="flex justify-center items-center gap-x-0.8 relative">
      <SelectTime
        day={day}
        end={end}
        type={'start'}
        value={start}
        onChange={onChange}
      />
      <span className="text-13 leading-[2rem]">-</span>
      <SelectTime
        day={day}
        start={start}
        type={'end'}
        value={end}
        onChange={onChange}
      />
      <CopyTimeSlot
        day={day}
        start={start}
        end={end}
        timeSlots={timeSlots}
        onCopy={onCopy}
      />
    </div>
  );
};

export default DefaultTimeView;

import { MINUTE_OF_A_BLOCK } from './getTimeBlockFromTImeString';

const convertDurationForBE = (duration: number) => {
  return duration / MINUTE_OF_A_BLOCK;
};

const convertDurationForFE = (duration: number) => {
  return duration * MINUTE_OF_A_BLOCK;
};

export { convertDurationForBE, convertDurationForFE };

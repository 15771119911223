import { DoctorSchedule } from 'interfaces/clinicSchedule';
import moment from 'moment';

const getPractitionerWorkingHour = (
  practitionerId?: string,
  workingHourPractitioners?: (DoctorSchedule & { name: string })[],
  isFormatWithoutA?: boolean
) => {
  if (!practitionerId) return '';

  const practitioner = workingHourPractitioners?.find(
    (item) => item.id === practitionerId
  );

  if (!practitioner || !practitioner.workingHour) return '';

  const start = practitioner.workingHour.start;
  const end = practitioner.workingHour.end;

  if (!start || !end) return 'Day Off';

  const isMinuteStartEmpty = start.substring(3, 5) === '00';
  const isMinuteEndEmpty = end.substring(3, 5) === '00';

  if (isFormatWithoutA) {
    return (
      moment(start, 'hh:mmA').format(isMinuteStartEmpty ? 'h' : 'h:mm') +
      '-' +
      moment(end, 'hh:mmA').format(isMinuteEndEmpty ? 'h' : 'h:mm')
    );
  }

  return (
    moment(start, 'hh:mmA').format(isMinuteStartEmpty ? 'hA' : 'h:mmA') +
    '-' +
    moment(end, 'hh:mmA').format(isMinuteEndEmpty ? 'hA' : 'h:mmA')
  );
};

export default getPractitionerWorkingHour;

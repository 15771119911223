import CommonButton from 'components/CommonButton/CommonButton';
import { FC } from 'react';

interface ViewModeProps {
  isSortingEnabled: boolean;
  setIsSortingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  onDone: () => void;
  isSubmitting: boolean;
}

const ViewMode: FC<ViewModeProps> = ({
  isSortingEnabled,
  setIsSortingEnabled,
  onDone,
  isSubmitting,
}) => {
  if (!isSortingEnabled) {
    return (
      <CommonButton
        variant={'secondary'}
        className="!min-h-[3.2rem]"
        onClick={() => setIsSortingEnabled(true)}
      >
        Sort Order
      </CommonButton>
    );
  }

  return (
    <CommonButton
      className="!min-h-[3.2rem]"
      onClick={onDone}
      isLoading={isSubmitting}
    >
      Done Ordering
    </CommonButton>
  );
};

export default ViewMode;

import { FC } from 'react';
import { Controller } from 'react-hook-form';
import TimeDropdown from 'components/TimeDropdown/TimeDropdown';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { TIME_DURATION_OPTIONS } from 'utils/constants';

interface UnitDurationDropdownProps {
  control: any;
}

const UnitDurationDropdown: FC<UnitDurationDropdownProps> = ({ control }) => {
  return (
    <Controller
      control={control}
      rules={{ required: 'This field is required' }}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <TimeDropdown
              options={TIME_DURATION_OPTIONS}
              value={field.value}
              listBoxProps={field}
              listBoxButtonClassName={
                error?.message
                  ? '!shadow-input-error !border-[#f44336] !hover:border-[#f44336] !hover:shadow-input-error focus:border-[#f44336] focus:shadow-input-error'
                  : ''
              }
            />
            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-0.8`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
      name={'unitDuration'}
    />
  );
};

export default UnitDurationDropdown;

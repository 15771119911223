import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';

interface CustomConversionSectionProps {
  control: any;
}

const CustomConversionSection: FC<CustomConversionSectionProps> = ({
  control,
}) => {
  return (
    <section>
      <div className="flex items-center gap-x-0.8 mb-1">
        <p className="font-bold ">Custom Conversion Script</p>
        <button data-for="conversionScript" data-tip="">
          <InfoIcon className="w-2 h-2 all-child:fill-mid-grey" />
        </button>
        <ReactTooltip
          id="conversionScript"
          effect="solid"
          place="bottom"
          className="!bg-white !rounded-[0.8rem] !w-[21rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:!border-b-white !px-1"
        >
          <span className="text-11 text-darkest-grey flex items-center font-normal">
            Used to measure conversions for Google Ads, TikTok Ads, or other
            advertising platforms.
          </span>
        </ReactTooltip>
      </div>
      <Controller
        name={'customScript'}
        control={control}
        render={({ field }) => {
          return (
            <textarea
              {...field}
              className="w-full resize-none h-[19rem] text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active focus-visible:border-magenta focus-visible:shadow-input-active scrollbar !outline-none !ring-transparent"
              placeholder="Input Custom Conversion Script"
            />
          );
        }}
      />
    </section>
  );
};

export default CustomConversionSection;

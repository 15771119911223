import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFoundPage from 'pages/NotFoundPage';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';
import LoginPage from 'pages/LoginPage';
import ProtectedRoutes from './ProtectedRoute';
import PractitionerPage from 'pages/PractitionerPage';
import PracticePage from 'pages/PracticePage';
import ServicesPage from 'pages/ServicesPage';
import AppointmentPage from 'pages/AppointmentPage';
import OfficeSchedulePage from 'pages/OfficeSchedulePage';
import DashboardPage from 'pages/DashboardPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import SetupAccountPage from 'pages/SetupAccountPage';
import LayoutRoute from './LayoutRoute';
import UserPage from 'pages/UserPage';
import { ROLE } from 'utils/constants';
import SyncPage from 'pages/SyncPage';
import AdminDashboardPage from 'pages/AdminDashboardPage';
import AdminAppointmentPage from 'pages/AdminAppointmentPage';
import AdminPracticePage from 'pages/AdminPracticePage';
import AdminUserPage from 'pages/AdminUserPage';
import AdminServicePage from 'pages/AdminServicePage';
import SchedulePage from 'pages/SchedulePage';
import OnlineSchedulerPage from 'pages/OnlineSchedulerPage';

function AppRoute() {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<LoginPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
      <Route path={ROUTES.SETUP_ACCOUNT} element={<SetupAccountPage />} />

      <Route element={<ProtectedRoutes />}>
        <Route element={<LayoutRoute isGlobalAdminPage />}>
          <Route
            path={ADMIN_ROUTES.DASHBOARD}
            element={
              <ProtectedRoutes allowed={[ROLE.GLOBAL_ADMIN]}>
                <AdminDashboardPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ADMIN_ROUTES.APPOINTMENT}
            element={
              <ProtectedRoutes allowed={[ROLE.GLOBAL_ADMIN]}>
                <AdminAppointmentPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ADMIN_ROUTES.PRACTICE}
            element={
              <ProtectedRoutes allowed={[ROLE.GLOBAL_ADMIN]}>
                <AdminPracticePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ADMIN_ROUTES.USER}
            element={
              <ProtectedRoutes allowed={[ROLE.GLOBAL_ADMIN]}>
                <AdminUserPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ADMIN_ROUTES.SERVICE}
            element={
              <ProtectedRoutes allowed={[ROLE.GLOBAL_ADMIN]}>
                <AdminServicePage />
              </ProtectedRoutes>
            }
          />
        </Route>

        <Route element={<LayoutRoute isGlobalAdminPage={false} />}>
          <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
          <Route path={ROUTES.PRACTICE_PROFILE} element={<PracticePage />} />
          <Route path={ROUTES.PRACTITIONER} element={<PractitionerPage />} />
          <Route path={ROUTES.APPOINTMENT} element={<AppointmentPage />} />
          <Route
            path={ROUTES.USER}
            element={
              <ProtectedRoutes
                redirect={ROUTES.DASHBOARD}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <UserPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.SYNC}
            element={
              <ProtectedRoutes
                redirect={ROUTES.DASHBOARD}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <SyncPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.OFFICE_SCHEDULE}
            element={
              <ProtectedRoutes
                redirect={ROUTES.DASHBOARD}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <OfficeSchedulePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.SERVICE}
            element={
              <ProtectedRoutes
                redirect={ROUTES.DASHBOARD}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <ServicesPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.SCHEDULE}
            element={
              <ProtectedRoutes
                redirect={ROUTES.SCHEDULE}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <SchedulePage />
              </ProtectedRoutes>
            }
          />
          <Route
            path={ROUTES.ONLINE_SCHEDULER}
            element={
              <ProtectedRoutes
                redirect={ROUTES.DASHBOARD}
                allowed={[ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN]}
              >
                <OnlineSchedulerPage />
              </ProtectedRoutes>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoute;

import { FC, useEffect, useRef, useState } from 'react';
import { cloneDeep, isEqual, omit } from 'lodash';
import moment from 'moment';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import useClinicSchedule from 'hooks/useClinicSchedule';
import usePractitionerSchedule from 'hooks/usePractitionerSchedule';
import { IPractitioner } from 'interfaces/practitioners';
import DayTimeSlot from './DayTimeSlot/DayTimeSlot';
import { ClinicSchedule, WeekWorkingHour } from 'interfaces/clinicSchedule';
import {
  NEXT_23_MONTH,
  SCHEDULE_TYPE,
  WORKING_HOUR_SYSTEM,
} from 'utils/constants';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import normalizeWeeklySchedule from 'utils/normalizeWeeklySchedule';
import NavigationBlocker from 'pages/OfficeSchedulePage/NavigationBlocker/NavigationBlocker';
import useBeforeUnloaded from 'hooks/useBeforeUnloaded';
import MonthViewNavigation from 'components/MonthViewNavigation/MonthViewNavigation';
import Calendar from './Calendar/Calendar';
import usePractitionerException from 'hooks/usePractitionerException';
import useClinicException from 'hooks/useClinicException';
import useOutsidePractitionerHour from 'hooks/useOutsidePractitionerHour';
import OutsideOfficeHoursModal from 'pages/OfficeSchedulePage/OutsideOfficeHoursModal/OutsideOfficeHoursModal';
import { IAppointmentDetail } from 'interfaces/appointments';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import OutsideApptIndicator from 'components/OutsideHourIndicator/OutsideHourIndicator';
import CustomDaysList from './CustomDaysList/CustomDaysList';
import useScheduleNavigator from 'hooks/useScheduleNavigator';

interface PractitionerHoursProps {
  selectedPractitioner: IPractitioner;
}

const PractitionerHours: FC<PractitionerHoursProps> = ({
  selectedPractitioner,
}) => {
  const today = moment().format('YYYY-MM-DD');

  const [timeSlots, setTimeSlots] = useState<WeeklyTimeslot[]>([]);
  const cachedTimeSlots = useRef<WeeklyTimeslot[]>();
  const { state, dispatch } = useScheduleNavigator(today);

  const [isCustomDaysTab, setIsCustomDaysTab] = useState(false);

  const isDataChanged = !isEqual(timeSlots, cachedTimeSlots.current);

  const { data: clinicSchedule, isLoading: isClinicScheduleLoading } =
    useClinicSchedule();

  const [isOutsideModalOpen, setIsOutsideModalOpen] = useState(false);
  const [isDataChecking, setIsDataChecking] = useState(false);

  const {
    data: practitionerSchedule,
    isLoading: isPractitionerScheduleLoading,
  } = usePractitionerSchedule(selectedPractitioner.id);

  const {
    data: practitionerException,
    isLoading: isPractitionerExceptionLoading,
  } = usePractitionerException({
    practitionerId: selectedPractitioner.id,
    startDate: moment(state.monthValue).startOf('month').format('YYYY-MM-DD'),
    endDate: moment(state.monthValue).endOf('month').format('YYYY-MM-DD'),
  });

  const { data: clinicException, isLoading: isClinicExceptionLoading } =
    useClinicException({
      source: clinicSchedule ? clinicSchedule.currentWorkingHour : '',
      shouldRun: !!clinicSchedule,
      startDate: moment(state.monthValue).startOf('month').format('YYYY-MM-DD'),
      endDate: moment(state.monthValue).endOf('month').format('YYYY-MM-DD'),
    });

  const {
    data: outsidePractitionerHourAppts,
    isLoading: isOutsidePractitionerHourLoading,
    mutate,
  } = useOutsidePractitionerHour(selectedPractitioner.id);

  const isAvailableOutsidePractitionerHourAppts =
    outsidePractitionerHourAppts && outsidePractitionerHourAppts.length > 0;

  useBeforeUnloaded({ when: isDataChanged });

  useEffect(() => {
    if (!practitionerSchedule) return;

    const removeIdFields = omit(practitionerSchedule.workingHour, [
      'id',
    ]) as WeekWorkingHour;

    const timeSlots = normalizeWeeklySchedule(removeIdFields);

    cachedTimeSlots.current = cloneDeep(timeSlots);
    setTimeSlots(timeSlots);
  }, [clinicSchedule, practitionerSchedule]);

  const getClinicSchedule = () => {
    if (!clinicSchedule) return [];

    const selectedSchedule =
      clinicSchedule.currentWorkingHour === WORKING_HOUR_SYSTEM.FIRSTIN
        ? SCHEDULE_TYPE.DEFAULT
        : SCHEDULE_TYPE.GOOGLE;

    const selectedSourceData = clinicSchedule[
      selectedSchedule as keyof ClinicSchedule
    ] as WeekWorkingHour;

    const timeSlots = normalizeWeeklySchedule(selectedSourceData);
    return timeSlots;
  };

  const isPageLoading =
    (isClinicScheduleLoading ||
      isPractitionerScheduleLoading ||
      isClinicExceptionLoading ||
      isPractitionerExceptionLoading ||
      isOutsidePractitionerHourLoading) &&
    state.monthValue === today;

  if (isPageLoading) {
    return <LoadingScreen />;
  }

  // PREVENT FLASH OF CONTENT WHEN USE_EFFECT POPPULATE
  if (!cachedTimeSlots.current) {
    return null;
  }

  const onCheckOutsidePractitionerHourAppts = async () => {
    const response = await axiosInstance.get<IAppointmentDetail[]>(
      `/appointments/out-practitioner-hours?doctorId=${selectedPractitioner.id}`
    );

    const newApptIds = response.data.map((item) => item.id);
    const prevApptIds =
      outsidePractitionerHourAppts?.map((item) => item.id) || [];

    const isNewAppts = newApptIds?.some((id) => !prevApptIds.includes(id));

    await mutate(response.data, { revalidate: false });

    if (
      (response.data.length !== 0 &&
        prevApptIds.length !== newApptIds.length) ||
      isNewAppts
    ) {
      setIsOutsideModalOpen(true);
    }
  };

  const handleClickOutsideApptIndicator = async () => {
    setIsDataChecking(true);

    const response = await axiosInstance.get<IAppointmentDetail[]>(
      `/appointments/out-practitioner-hours?doctorId=${selectedPractitioner.id}`
    );

    await mutate(response.data, { revalidate: false });

    setIsDataChecking(false);
    if (response.data.length === 0) {
      return renderToast({
        type: 'info',
        message: 'No patients outside of working hours',
      });
    }

    setIsOutsideModalOpen(true);
  };

  return (
    <>
      {isDataChecking && <LoadingScreen />}
      {isOutsideModalOpen && isAvailableOutsidePractitionerHourAppts && (
        <OutsideOfficeHoursModal
          isOutsidePractitionerMode
          data={outsidePractitionerHourAppts!}
          onClose={() => setIsOutsideModalOpen(false)}
          header={
            <>
              <h3 className="font-bold text-20 leading-[3rem]">
                Patients scheduled outside of practitioner working hours
              </h3>

              <div className="mt-1.6 mb-1.9">
                {selectedPractitioner.title} {selectedPractitioner.name}’s
                schedule was recently updated and we found the following
                patients with future appointments outside of their new working
                hours. Please update the appointments in your practice software.
              </div>
            </>
          }
        />
      )}
      <NavigationBlocker when={isDataChanged} />
      <div className="flex gap-x-2">
        <div className="basis-[35rem] mt-4.4">
          <div className="w-full">
            <div className="h-4.8 flex justify-evenly items-center border border-light-grey">
              <button
                className={`basis-[13rem] ${
                  !isCustomDaysTab ? 'text-magenta font-bold' : ''
                }`}
                onClick={() => setIsCustomDaysTab(false)}
              >
                Regular Hours
              </button>
              <span className="h-3 border-l border-light-grey"></span>
              <button
                className={`basis-[13rem] ${
                  isCustomDaysTab ? 'text-magenta font-bold' : ''
                }`}
                onClick={() => setIsCustomDaysTab(true)}
              >
                Custom Days
              </button>
            </div>
          </div>
          {isCustomDaysTab ? (
            <CustomDaysList
              selectedPractitioner={selectedPractitioner}
              setDate={(date) =>
                dispatch({
                  type: 'customDayNavigation',
                  payload: { monthValue: date },
                })
              }
              isNavigatedByCalendar={
                !state.highlightedDate && state.isNavigatedByCalendar
              }
              monthValue={moment(state.monthValue, 'YYYY-MM-DD').format(
                'MMMM YYYY'
              )}
              onCheckOutsidePractitionerHourAppts={
                onCheckOutsidePractitionerHourAppts
              }
              version={clinicSchedule!.version}
            />
          ) : (
            <DayTimeSlot
              timeSlots={timeSlots}
              clinicTimeSlots={getClinicSchedule()}
              cachedTimeSlots={cachedTimeSlots.current!}
              setTimeSlots={setTimeSlots}
              practitionerScheduleId={practitionerSchedule!.workingHour.id}
              selectedPractitioner={selectedPractitioner}
              version={clinicSchedule!.version}
              onCheckOutsidePractitionerHourAppts={
                onCheckOutsidePractitionerHourAppts
              }
            />
          )}
        </div>
        <div className="flex-1">
          <div className="flex flex-row justify-between items-center relative">
            <MonthViewNavigation
              date={state.monthValue}
              setDate={(date) =>
                dispatch({
                  type: 'monthNavigation',
                  payload: { monthValue: date },
                })
              }
              maxDate={moment()
                .add(NEXT_23_MONTH, 'months')
                .endOf('days')
                .toDate()}
            />
            {isAvailableOutsidePractitionerHourAppts && (
              <OutsideApptIndicator
                numberOfAppts={outsidePractitionerHourAppts!.length}
                onClick={handleClickOutsideApptIndicator}
                size="lg"
              >
                Patient is scheduled outside practitioner's hours
              </OutsideApptIndicator>
            )}
          </div>
          <Calendar
            date={state.monthValue}
            practitionerException={practitionerException!}
            clinicException={clinicException!}
            highlightedDate={state.highlightedDate}
            resetHighlightedDate={() =>
              dispatch({ type: 'resetHighLightedDate' })
            }
            selectedPractitioner={selectedPractitioner}
            onCheckOutsidePractitionerHourAppts={
              onCheckOutsidePractitionerHourAppts
            }
            version={clinicSchedule!.version}
            practitionerSchedule={practitionerSchedule!}
          />
        </div>
      </div>
    </>
  );
};

export default PractitionerHours;

import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SuportAgentIcon } from 'assets/icons/support-agent.svg';
import styles from './SidebarLink.module.css';
import { SUPPORT_EMAIL } from 'utils/constants';
interface SupportButtonProps {
  isCollapsed: boolean;
}

const SupportButton: FC<SupportButtonProps> = ({ isCollapsed }) => {
  return (
    <a
      href={`mailto:${SUPPORT_EMAIL}`}
      className="text-12 font-bold flex gap-x-1 overflow-hidden items-center mt-auto h-3.5 pl-1.2"
    >
      <div data-for={'customer-suport'} data-tip="" className="flex shrink-0">
        <SuportAgentIcon />
      </div>
      {isCollapsed ? (
        <ReactTooltip
          id={'customer-suport'}
          place="right"
          effect="solid"
          className={`${styles.tooltip} shadow-tooltip`}
        >
          <span className="text-11 text-darkest-grey flex h-full items-center font-normal">
            Customer support
          </span>
        </ReactTooltip>
      ) : (
        <div className={`flex flex-col ${isCollapsed && 'opacity-0'}`}>
          <span>Customer support</span>
          <span className="text-11 font-normal text-magenta">
            {SUPPORT_EMAIL}
          </span>
        </div>
      )}
    </a>
  );
};

export default SupportButton;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import moment from 'moment';

const useAppointmentDateRange = ({ clinicId }: any) => {
  const { data, error, mutate } = useSWR(
    ['/appointments/date-range', clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<{
        earliestDateTime: string | null;
        latestDateTime: string | null;
      }>(url, {
        params: {
          clinicIds: clinicId ? [clinicId] : undefined,
        },
      });

      return response.data;
    }
  );
  return {
    data:
      !data?.earliestDateTime && !data?.latestDateTime
        ? null
        : {
            earliestDateTime: moment
              .utc(data.earliestDateTime as string)
              .format('YYYY-MM-DD'),
            latestDateTime: moment
              .utc(data.latestDateTime as string)
              .format('YYYY-MM-DD'),
          },
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAppointmentDateRange;

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { range } from 'lodash';
import moment from 'moment';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import useClinic from 'hooks/useClinic';

interface SelectTimeProps {
  type: 'start' | 'end';
  day: string;
  value: string;
  onChange: (day: string, value: string, type: string) => void;
  start?: string;
  end?: string;
  isInEditModal?: boolean;
}

const SelectTime: FC<SelectTimeProps> = ({
  value,
  type,
  day,
  onChange,
  start,
  end,
  isInEditModal,
}) => {
  const { data } = useClinic();

  const options = useMemo(() => {
    const duration = data?.timeInterval ? data.timeInterval : 30;

    return range(0, 24, 1)
      .map((hour) => {
        return range(0, 60, duration)
          .map((minute) => {
            const momentObj = moment({
              hour,
              minute,
            });
            return {
              label: momentObj.format('hh:mm A'),
              value: momentObj.format('HH:mm:ss'),
            };
          })
          .flat();
      })
      .flat();
  }, [data?.timeInterval]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  const selectedOption = options.find((option) => option.value === value)!;

  const getOptions = () => {
    let filterdOptions = [...options];
    if (start) {
      filterdOptions = filterdOptions.filter((option) =>
        moment(option.value, 'HH:mm:ss').isAfter(moment(start, 'HH:mm:ss'))
      );
    } else if (end) {
      filterdOptions = filterdOptions.filter((option) =>
        moment(option.value, 'HH:mm:ss').isBefore(moment(end, 'HH:mm:ss'))
      );
    }

    return filterdOptions;
  };

  useEffect(() => {
    const position = buttonRef.current!.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    const listBoxHeight = 302;
    const elementHeight = position.top + 32;
    const isOverflow = elementHeight + listBoxHeight >= screenHeight;
    setIsOverflow(isOverflow);
  }, []);

  return (
    <Listbox
      value={selectedOption}
      onChange={(option: { label: string; value: string }) =>
        onChange(day, option.value, type)
      }
      as="div"
      className="text-12 leading-[1.8rem]"
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={buttonRef}
            className={`flex items-center relative rounded-[0.8rem] shadow-primary border-[0.5px] 
            ${
              open
                ? 'shadow-select-active border-magenta'
                : 'border-transparent'
            }
            ${
              isInEditModal
                ? 'w-12.3 h-3 justify-start pl-1.2'
                : 'w-7 h-3.2 justify-center'
            }`}
          >
            {selectedOption?.label}
            {isInEditModal && (
              <DropdownIcon className="w-1.2 h-1.2 absolute top-0.9 right-0.9" />
            )}
          </Listbox.Button>
          <Listbox.Options
            className={`absolute mt-0.3 bg-white text-base flex flex-col gap-y-1.6 rounded-[1rem] shadow-primary max-h-[30.2rem] scrollbar overflow-auto z-[100] py-0.8 ${
              isInEditModal ? 'w-12.3' : 'w-7.5'
            } ${isOverflow ? '-translate-y-[calc(100%+4rem)]' : ''}`}
          >
            {getOptions().map((option) => (
              <Listbox.Option
                key={option.label}
                value={option}
                className={({ active, selected }) =>
                  `cursor-pointer pl-0.8 ${
                    active || selected ? 'text-magenta' : ''
                  }`
                }
              >
                {option.label}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

export default SelectTime;

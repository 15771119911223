import { FC } from 'react';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import { datetimeFormat } from 'utils/datetime';
import { OFFICE_CLOSED } from 'utils/constants';

interface PracticeScheduleTooltipProps {
  timeSlot: WeeklyTimeslot;
  clinicTimeSlot: WeeklyTimeslot;
}

const PracticeScheduleTooltip: FC<PracticeScheduleTooltipProps> = ({
  timeSlot,
  clinicTimeSlot,
}) => {
  const isClinicTimeSameAsPractitioner =
    timeSlot.start === clinicTimeSlot.start &&
    timeSlot.end === clinicTimeSlot.end;

  const isClinicWorkingDay = Boolean(
    clinicTimeSlot.start && clinicTimeSlot.end
  );

  return (
    <>
      <button
        className="cursor-default"
        data-for={`${timeSlot.day}-practice-schedule`}
        data-tip=""
      >
        <InfoIcon
          className={`w-1.4 h-1.4 ${
            isClinicTimeSameAsPractitioner
              ? 'all-child:fill-grey'
              : 'all-child:fill-magenta'
          }`}
        />
      </button>
      <ReactTooltip
        id={`${timeSlot.day}-practice-schedule`}
        place="bottom"
        effect="solid"
        className={`!bg-white !border-light-grey !rounded-[0.8rem] !w-auto !h-auto !px-[1rem] !py-[0.4rem] !opacity-100 z-[9999] flex justify-center items-center shadow-primary  before:hidden after:hidden ${
          isClinicWorkingDay
            ? '!min-w-[11.8rem] min-h-[4.4rem]'
            : 'min-w-[9rem] min-h-[2.6rem]'
        }`}
      >
        <div className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal">
          {isClinicWorkingDay ? (
            <div className="flex flex-col">
              <span>Practice Hour</span>
              <span>
                {datetimeFormat({
                  dateString: clinicTimeSlot.start!,
                  format: 'HH:mm:ss',
                  pattern: 'hh:mm A',
                })}{' '}
                -{' '}
                {datetimeFormat({
                  dateString: clinicTimeSlot.end!,
                  format: 'HH:mm:ss',
                  pattern: 'hh:mm A',
                })}
              </span>
            </div>
          ) : (
            OFFICE_CLOSED
          )}
        </div>
      </ReactTooltip>
    </>
  );
};

export default PracticeScheduleTooltip;

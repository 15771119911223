import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { PMSClinicSchedule } from 'interfaces/clinicSchedule';

const usePMSClinicSchedule = ({
  date,
  doctorIds,
  shouldRun,
}: {
  date: string;
  doctorIds: string[];
  shouldRun: boolean;
}) => {
  const query = new URLSearchParams({
    date,
  }).toString();

  const convertDoctorIdsToCorrectFormat = doctorIds.reduce(
    (prev, curr) => prev + `&doctorIds[]=${curr}`,
    ''
  );

  const url = `/pms/schedules?${query}${convertDoctorIdsToCorrectFormat}`;
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    shouldRun ? [url, clinicId, doctorIds] : false,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<PMSClinicSchedule>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePMSClinicSchedule;

import React, { useEffect, forwardRef } from 'react';

interface Props {
  indeterminate?: boolean;
  isDisabled?: boolean;
}

const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(
  (
    { indeterminate, isDisabled, ...rest },
    ref: React.Ref<HTMLInputElement>
  ) => {
    const defaultRef = React.useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={combinedRef}
        {...rest}
        disabled={isDisabled}
        className="w-2.2 h-2.2 accent-magenta disabled:cursor-not-allowed disabled:bg-white disabled:opacity-60"
      />
    );
  }
);

export default IndeterminateCheckbox;

import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import TextField from 'components/TextField/TextField';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isValidEmail } from 'utils/validations';
import IconHelper from './IconHelper/IconHelper';
import { ROLE } from 'utils/constants';
import useModal from 'hooks/useModal';

export interface IEditOrCreateUserForm {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
}

interface EditOrCreateUserProps {
  defaultValues?: IEditOrCreateUserForm;
  onClose: () => void;
  onSubmit: (data: IEditOrCreateUserForm) => void;
  error?: string;
  setError?: React.Dispatch<React.SetStateAction<string>>;
  isGlobalAdminMode?: boolean;
}

const initialDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  role: '',
};

const EditOrCreateUser: FC<EditOrCreateUserProps> = ({
  onClose,
  onSubmit,
  error,
  setError,
  defaultValues,
  isGlobalAdminMode,
}) => {
  const { setIsAllowed } = useModal({ onClose });

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
    trigger,
  } = useForm({
    mode: 'all',
    defaultValues: defaultValues
      ? defaultValues
      : {
          ...initialDefaultValues,
          ...(isGlobalAdminMode && { role: ROLE.GLOBAL_ADMIN }),
        },
  });

  const onSubmitData = (data: IEditOrCreateUserForm) => {
    setIsAllowed(false);
    onSubmit(data);
    setIsAllowed(true);
  };

  useEffect(() => {
    if (!error) return;
    trigger('email');
    return () => setError?.('');
  }, [error, setError, trigger]);

  return (
    <DefaultModal className="rounded-[1.6rem]">
      <div className="w-50 text-darkest-grey text-14 leading-[2.1rem]">
        <div className="px-3 pt-1.6 pb-1.3 border-b border-light-grey">
          <h3 className="text-20 leading-[3rem] font-bold">
            {defaultValues ? 'Edit User' : 'Create New User'}
          </h3>
          <p>
            {defaultValues
              ? "Update user's information"
              : 'Invite user to your practice'}
          </p>
        </div>
        <form
          className="px-3 pb-3.2 py-3 flex flex-col gap-y-2.4"
          onSubmit={handleSubmit(onSubmitData)}
        >
          <TextField
            id="email"
            control={control}
            label="Email Address"
            placeholder="Input Email Address"
            disabled={!!defaultValues}
            iconHelper={defaultValues && <IconHelper />}
            rules={{
              required: 'This field is required',
              validate: (value) => {
                if (!isValidEmail(value)) return 'Invalid email address';
                if (error) return error;
              },
              onChange: () => {
                if (!error) return;
                setError?.('');
              },
            }}
          />
          <div className="flex justify-between">
            <TextField
              id="firstName"
              control={control}
              label="First Name"
              placeholder="Input First Name"
              rules={{
                required: 'This field is required',
              }}
            />
            <TextField
              id="lastName"
              control={control}
              label="Last Name"
              placeholder="Input Last Name"
              rules={{
                required: 'This field is required',
              }}
            />
          </div>
          {!isGlobalAdminMode && (
            <fieldset>
              <legend className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
                Select Role
              </legend>
              <div className="mt-1.7 flex gap-x-[11.1rem]">
                <div className="flex items-center gap-x-0.9">
                  <input
                    id="radioUser"
                    type="radio"
                    className="w-2.2 h-2.2"
                    value="practice_user"
                    {...register('role', { required: true })}
                  />
                  <label htmlFor="radioUser">User</label>
                </div>
                <div className="flex items-center gap-x-0.9">
                  <input
                    id="radioAdmin"
                    type="radio"
                    className="w-2.2 h-2.2"
                    value="practice_admin"
                    {...register('role', { required: true })}
                  />
                  <label htmlFor="radioAdmin">Admin</label>
                </div>
              </div>
            </fieldset>
          )}
          <div className="flex justify-end gap-x-1.6">
            <CommonButton
              variant="secondary"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </CommonButton>
            <CommonButton
              disabled={!isValid}
              type="submit"
              isLoading={isSubmitting}
            >
              {defaultValues ? 'Update' : 'Create'}
            </CommonButton>
          </div>
        </form>
      </div>
    </DefaultModal>
  );
};

export default EditOrCreateUser;

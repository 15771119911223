import { useEffect } from 'react';
import { clearIsPageDirty, setPageDirty } from 'utils/storage';

// For reload the page
const useBeforeUnloaded = ({ when }: { when: boolean }) => {
  useEffect(() => {
    if (when) {
      return setPageDirty();
    }
    clearIsPageDirty();
  }, [when]);

  useEffect(() => {
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      if (when) {
        e.preventDefault();
        return (e.returnValue = '');
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      clearIsPageDirty();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [when]);
};

export default useBeforeUnloaded;

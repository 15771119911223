import moment from 'moment';
import useSWR from 'swr';

import { IAppointmentStatisticResponse } from 'interfaces/appointmentStatistic';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { useUser } from 'context/userContext';

const useAppointmentStatistic = () => {
  const {
    user: { currentClinicUserRole },
  } = useUser();
  const clinicId = loadSelectedClinicId();

  const { data, error, mutate } = useSWR(
    ['/statistics/appointment', clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<IAppointmentStatisticResponse>(
        url,
        {
          params: {
            startDate: moment
              .tz(currentClinicUserRole!.clinic.timezone)
              .startOf('days')
              .utc(true)
              .format(),
          },
        }
      );

      return response.data;
    }
  );
  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAppointmentStatistic;

import { FC } from 'react';
import Status from 'pages/UserPage/TableSection/Status/Status';
import { ReactComponent as SplitScheduleIcon } from 'assets/icons/call_split.svg';
import { CLINIC_USER_STATUS } from 'utils/constants';
import ReactTooltip from 'react-tooltip';

interface StatusColumnProps {
  field: any;
}
const { ACTIVE, INACTIVE } = CLINIC_USER_STATUS;

const StatusColumn: FC<StatusColumnProps> = ({ field }) => {
  if (!field.isSplitScheduling) {
    return <Status status={field.isActive ? ACTIVE : INACTIVE} />;
  }
  return (
    <div className="flex gap-x-0.5">
      <Status status={field.isActive ? ACTIVE : INACTIVE} />
      <button
        className="rounded-full w-2.8 h-2.2 bg-[#F4FAFF] flex justify-center items-center border-[0.3px] border-[#4D97E1] border-solid"
        data-for={`splitScheduling`}
        data-tip=""
      >
        <SplitScheduleIcon className="all-child:all-child:fill-[#4D97E1] w-1.6 h-1.6" />
      </button>
      <ReactTooltip
        id={`splitScheduling`}
        place="bottom"
        effect="solid"
        className="!bg-white !rounded-[0.8rem] !w-[15.1rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:!border-b-white !px-1"
      >
        <span className="text-11 text-darkest-grey flex items-center font-normal leading-[1.8rem]">
          Split Scheduling is active
        </span>
      </ReactTooltip>
    </div>
  );
};

export default StatusColumn;

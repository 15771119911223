import { FC } from 'react';
import { datetimeFormat } from 'utils/datetime';

interface GoogleTimeViewProps {
  start: string;
  end: string;
}

const GoogleTimeView: FC<GoogleTimeViewProps> = ({ start, end }) => {
  const formatDate = (date: string) =>
    datetimeFormat({
      dateString: date,
      format: 'HH:mm:ss',
      pattern: 'hh:mmA',
    });

  return (
    <span className="text-12 leading-[1.8rem] font-normal">
      {formatDate(start)} - {formatDate(end)}
    </span>
  );
};

export default GoogleTimeView;

import { FC } from 'react';

interface TabButtonProps {
  isActive: boolean;
  onClick: () => void;
}

const TabButton: FC<TabButtonProps> = ({ isActive, onClick, children }) => {
  return (
    <button
      className={`w-10.4 h-4 flex justify-center items-center rounded-[0.8rem] border-[0.5px] border-transparent shadow-tooltip transition-all hover:shadow-elevation-10 hover:font-bold hover:border-magenta hover:text-magenta ${
        isActive
          ? 'shadow-elevation-10 font-bold text-magenta !border-magenta'
          : ''
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TabButton;

import { FC } from 'react';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

import { IAppointmentStatus } from 'hooks/useAppointment';

const appointmentStatusOption = [
  { label: 'Appointment Requested', value: 'new' },
  { label: 'Accepted', value: 'accepted' },
  { label: 'Completed', value: 'completed' },
  { label: 'Missed', value: 'missed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Error', value: 'error' },
];

interface AppointmentStatusProps {
  selectedStatus: IAppointmentStatus;
  setSelectedStatus: React.Dispatch<React.SetStateAction<IAppointmentStatus>>;
}

const AppointmentStatus: FC<AppointmentStatusProps> = ({
  selectedStatus,
  setSelectedStatus,
}) => {
  const displayLabel = appointmentStatusOption.find(
    (appointmentStatus) => appointmentStatus.value === selectedStatus
  )!.label;

  return (
    <Listbox value={selectedStatus} onChange={setSelectedStatus}>
      {({ open }) => {
        return (
          <div className="relative">
            <Listbox.Button
              className={`relative w-[22rem] h-[4.8rem] cursor-pointer rounded-[1rem] bg-white pt-1.3 pl-1.6 pb-1.4 border-light-grey text-left shadow-primary border border-transparent hover:border-magenta  hover:shadow-elevation-10 outline-none ${
                open ? '!border-magenta !shadow-elevation-10' : ''
              }`}
            >
              <span className="block truncate pr-3 text-darkest-grey font-bold">
                {displayLabel}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[1.7rem]">
                <DropdownIcon className="w-1.4 h-1.4" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-[1rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-10">
              {appointmentStatusOption.map(({ label, value }) => (
                <Listbox.Option
                  key={label}
                  className="relative cursor-default select-none pl-1.6"
                  value={value}
                >
                  <span
                    className={`block truncate cursor-pointer hover:text-magenta ${
                      value === selectedStatus ? 'text-magenta' : ''
                    }`}
                  >
                    {label}
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        );
      }}
    </Listbox>
  );
};

export default AppointmentStatus;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import moment from 'moment';
import { IClinic } from 'interfaces/clinics';

export interface IClinicResponse extends Omit<IClinic, 'workingHours'> {
  workingHours: {
    day: string;
    time: string;
  };
  googlePlaceId?: string;
  externalId?: string;
  subdomain?: string;
  timezone: string;
  pmsType?: string;
}

const useAllClinic = () => {
  const { data, error, mutate } = useSWR('/clinics', async (url) => {
    const response = await axiosInstance.get<{
      metadata: { total: number };
      data: IClinicResponse[];
    }>(url, {
      params: {
        date: moment().format('YYYY-MM-DD'),
        sortBy: 'isActive,name',
        sort: 'desc,asc',
      },
    });
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useAllClinic;

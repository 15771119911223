import React from 'react';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import { sortBy } from 'lodash';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import useStudy from 'hooks/useStudy';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const StudyDropdown = (props: IProps) => {
  const { data: studies, isLoading } = useStudy();

  const studyOptions = studies?.map((study) => ({
    value: study.id,
    label: study.name,
  }));
  return (
    <Controller
      {...props}
      name="studies"
      render={({ field }) => {
        const { onChange, value } = field;

        return (
          <MultipleSelect
            key={`${isLoading}`}
            isLoading={isLoading}
            placeholder={'Select Suffix'}
            options={sortBy(studyOptions, 'label')}
            value={isLoading ? [] : value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
          />
        );
      }}
    />
  );
};

export default StudyDropdown;

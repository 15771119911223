import { Dispatch, FC, useMemo, useState } from 'react';

import { Popover } from '@headlessui/react';
import moment from 'moment';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import { ReactComponent as RemovedInfo } from 'assets/icons/removed-info.svg';

import IndeterminateCheckbox from './IndeterminateCheckbox/IndeterminateCheckbox';
import Pagination, { ITablePaginate } from 'components/Tables/Pagination';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import DefaultModal from 'components/Modals/DefaultModal';
import ConfirmationCard from 'components/Cards/ConfirmationCard';
import axiosInstance from 'apis/axiosInstance';

import { STATUS } from 'utils/constants';
import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';

import styles from './Table.module.css';
import AppointmentDetailModal from 'components/AppointmentDetailModal/AppointmentDetailModal';
import PractitionerNameColumn from 'components/TableColumn/PractitionerNameColumn/PractitionerNameColumn';
import AppointmentDateTimeColumn from 'components/TableColumn/AppointmentDateTimeColumn/AppointmentDateTimeColumn';
import { getErrorReason } from 'utils/common';
import useModal from 'hooks/useModal';
import PopoverOption from 'components/PopoverOption/PopoverOption';
import { renderToast } from 'components/Toast/Toast';

interface TableProps {
  currentPage: number;
  setCurrentPage: Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: Dispatch<React.SetStateAction<number>>;
  sortBy: string;
  setSortBy: Dispatch<React.SetStateAction<string>>;
  sort: 'asc' | 'desc';
  setSort: Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  status: 'new' | 'accepted' | 'only-accepted';
  data: any;
  isLoading: boolean;
  updateDashboardUI: () => void;
}

const Table: FC<TableProps> = ({
  sort,
  sortBy,
  setCurrentPage,
  setPageSize,
  setSort,
  setSortBy,
  status,
  data,
  isLoading,
  updateDashboardUI,
}) => {
  // TODO: change to useReducer in the future
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalAccepted, setIsModalAccepted] = useState(false);
  const [chosenAppointmentId, setChosenAppointmentId] = useState('');
  const [isUpdatedLoading, setIsUpdatedLoading] = useState(false);

  const [isAllSelected, setIsAllSelected] = useState(false);

  const [highlightedRowId, setHighlighedRowId] = useState('');

  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');

  const { setIsAllowed } = useModal({ onClose: () => setIsModalOpen(false) });

  const options = useMemo(
    () => (status === 'new' ? [STATUS.ACCEPT, STATUS.REJECT] : []),
    [status]
  );

  const isActionColumn = (columnId: string) =>
    ['selection', 'action_btn', 'error_management'].includes(columnId);

  const columns = useMemo(
    () => [
      {
        Header: 'Patient',
        accessor: (field: any) => {
          return (
            <div className="flex gap-x-0.8 items-center mr-[20px]">
              {field.patient ? (
                <>
                  <div className="words-break">{field.patient.name}</div>
                </>
              ) : (
                <div />
              )}
            </div>
          );
        },
        id: 'patientName',
      },
      {
        Header: 'Practitioner',
        accessor: (field: any) => {
          return <PractitionerNameColumn field={field} />;
        },
        id: 'doctorName',
      },
      {
        Header: 'Service',
        accessor: (field: any) => {
          const isServiceAvailable =
            !field.service.deletedAt &&
            field.service.isActive &&
            field.service.isAvailable;

          return (
            <div className="words-break pr-5">
              {field.service.name}
              {!isServiceAvailable && (
                <>
                  <RemovedInfo
                    className="inline-block ml-0.6"
                    data-for={field.service.id}
                    data-tip=""
                  />
                  <ReactTooltip
                    id={field.service.id}
                    place="bottom"
                    effect="solid"
                    className={`${styles.tooltip} shadow-tooltip`}
                  >
                    <span className="text-11 text-darkest-grey flex h-full items-center normal-case">
                      This service has been deactivated or removed
                    </span>
                  </ReactTooltip>
                </>
              )}
            </div>
          );
        },
        id: 'serviceName',
        width: 250,
      },
      {
        Header: 'Appointment Date & Time',
        accessor: (field: any) => {
          return <AppointmentDateTimeColumn data={field} />;
        },
        id: 'appointmentDate',
        width: 180,
      },
      {
        Header: 'Requested Time',
        accessor: (field: any) => {
          return (
            <span>
              {moment(field.createdAt).format('MMM DD, YYYY')}
              <br />
              {moment(field.createdAt).format('hh:mm A')}
            </span>
          );
        },
        id: 'createdAt',
      },
      {
        id: 'error_management',
        width: 40,
        Cell: ({ row }: any) => {
          const errorReason = getErrorReason(row.original);
          const id = row.original.id;

          return (
            <ErrorIcon errorReason={errorReason} id={id}>
              <PopoverOption appointment={row.original} />
            </ErrorIcon>
          );
        },
      },
      {
        id: 'action_btn',
        width: 25,
        Header: ({ selectedFlatRows }: any) => {
          return (
            <Popover className="relative">
              <Popover.Button
                disabled={selectedFlatRows.length === 0}
                className="flex items-center disabled:cursor-not-allowed disabled:opacity-30"
              >
                <MoreIcon className="h-2.2" />
              </Popover.Button>

              <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 w-16.5 bg-white shadow-primary rounded-[0.8rem] mt-0.6">
                {options.map((title) => (
                  <Popover.Button
                    key={title}
                    className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left text-14 leaing-[2.1rem] text-darkest-grey"
                    onClick={() => {
                      setIsModalOpen(true);
                      setIsModalAccepted(title === 'Accept' ? true : false);
                      setIsAllSelected(true);
                    }}
                  >
                    {title}
                  </Popover.Button>
                ))}
              </Popover.Panel>
            </Popover>
          );
        },
        Cell: ({ row }: any) => {
          const handleClickOption = async (
            id: string,
            isModalAccepted: boolean
          ) => {
            setIsModalOpen(true);
            setIsModalAccepted(isModalAccepted);
            setChosenAppointmentId(id);
            await updateDashboardUI();
          };

          if (status === 'accepted') return null;

          const errorReason = getErrorReason(row.original);

          return (
            <ThreeDotsOption
              key={highlightedRowId}
              id={row.original.id}
              options={options}
              errorReason={errorReason}
              patientName={row.original.patient?.name || ''}
              onClickReject={() => handleClickOption(row.original.id, false)}
              onClickAccept={() => handleClickOption(row.original.id, true)}
              updateDashboardUI={updateDashboardUI}
            />
          );
        },
      },
    ],
    [highlightedRowId, options, status, updateDashboardUI]
  );

  const dataTable = useMemo(() => {
    if (isLoading) {
      return { data: [], metadata: null };
    }
    return { data: data.data, metadata: data.metadata };
  }, [data?.data, data?.metadata, isLoading]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: dataTable.data ? dataTable.data : [],
      manualPagination: true,
      pageCount: dataTable.metadata ? data.metadata.total : 0,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: 50,
          Header: ({ rows, toggleRowSelected, selectedFlatRows }) => {
            const style = {
              cursor: 'pointer',
            };

            const numberOfNonErrorRows = rows.reduce((previous, current) => {
              const errorReason = getErrorReason(current.original as any);
              if (!errorReason) {
                return previous + 1;
              }
              return previous;
            }, 0);

            const checked: boolean =
              rows.length > 1 &&
              numberOfNonErrorRows > 0 &&
              selectedFlatRows.length === numberOfNonErrorRows;

            const indeterminate: boolean =
              !checked && selectedFlatRows.length > 0;

            const disabled: boolean =
              rows.length === 1 || status === 'accepted';

            const overiddenOnChange = (
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              rows.forEach((row) => {
                const errorReason = getErrorReason(row.original as any);
                if (!errorReason) {
                  toggleRowSelected(row.id, event.currentTarget.checked);
                }
              });
            };

            const modifiedToggleAllRowsProps = {
              onChange: overiddenOnChange,
              style: style,
              checked: checked,
              indeterminate: indeterminate,
              isDisabled: disabled,
            };

            return (
              <div className="flex items-center pl-1.7">
                <IndeterminateCheckbox {...modifiedToggleAllRowsProps} />
              </div>
            );
          },
          Cell: ({ row }) => {
            const errorReason = !!getErrorReason(row.original as any);
            return (
              <div className="flex items-center pl-1.7">
                <IndeterminateCheckbox
                  isDisabled={status === 'accepted' || errorReason}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const handlePaginate = (paginate: ITablePaginate) => {
    setCurrentPage(paginate.currPage);
    setPageSize(paginate.perPage);
  };

  const handleSortColumn = (column: HeaderGroup<object>) => {
    if (isActionColumn(column.id)) return;
    setCurrentPage(1);
    setSortBy(column.id);
    if (column.id === sortBy) {
      return setSort((sort) => (sort === 'asc' ? 'desc' : 'asc'));
    }
    setSort('asc');
  };

  const handleConfirmRequest = async (isConfirmed: boolean) => {
    setIsAllowed(false);
    if (isConfirmed) {
      const updatedStatus = isModalAccepted ? 'accepted' : 'cancelled';

      const toastMessage = isModalAccepted ? 'accepted' : 'rejected';

      const body = isAllSelected
        ? selectedFlatRows.map((row: any) => ({
            appointmentId: row.original.id,
            status: updatedStatus,
          }))
        : [
            {
              appointmentId: chosenAppointmentId,
              status: updatedStatus,
            },
          ];
      setIsUpdatedLoading(true);
      try {
        await axiosInstance.patch('/appointments', body);
        await updateDashboardUI();
        renderToast({
          type: 'success',
          message: `Successfully ${toastMessage} appointment`,
        });
      } catch (error) {
        renderToast({
          type: 'error',
          message: 'Something went wrong. Please try again later',
        });
      }
    }
    setIsAllowed(true);
    setIsUpdatedLoading(false);
    setIsModalOpen(false);
    setIsAllSelected(false);
  };

  const title = isModalAccepted ? 'Accept' : 'Reject';

  const description =
    selectedFlatRows.length > 1 ? 'appointments' : 'appointment';

  const displayTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (data.metadata.total === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[24rem] flex flex-col gap-y-1.6 justify-center items-center">
                <EmptyBucket />
                <span>No appointment available on this list</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              onMouseEnter={() => setHighlighedRowId(row.id)}
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey px-2 group hover:bg-lightPink items-center cursor-pointer"
            >
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className={`text-left p-0 py-1.9 my-auto ${
                      !isActionColumn(cell.column.id) && 'cursor-pointer'
                    }`}
                    {...cell.getCellProps()}
                    onClick={() => {
                      if (!isActionColumn(cell.column.id)) {
                        setSelectedAppointmentId(cell.row.original.id);
                      }
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      {isModalOpen && (
        <DefaultModal>
          <ConfirmationCard
            handleConfirmation={handleConfirmRequest}
            title={`${title} ${
              isAllSelected
                ? `${selectedFlatRows.length} ${description}`
                : 'appointment'
            }`}
            description={`Are you sure you want to ${
              isModalAccepted ? 'accept' : 'reject'
            } ${
              isAllSelected
                ? `${selectedFlatRows.length} ${description}`
                : 'this appointment'
            }?`}
            isLoading={isUpdatedLoading}
            confirmMessage={`${
              isModalAccepted ? 'Yes, accept' : 'Yes, reject'
            }`}
          />
        </DefaultModal>
      )}
      {selectedAppointmentId && (
        <AppointmentDetailModal
          appointmentId={selectedAppointmentId}
          onClose={() => setSelectedAppointmentId('')}
        />
      )}
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="px-2 items-center"
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div
                      className="flex items-center gap-x-0.9 cursor-pointer"
                      onClick={() => handleSortColumn(column)}
                    >
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>
                      {!isActionColumn(column.id) && (
                        <span>
                          <SortArrow
                            className={`${
                              column.id === sortBy && 'fill-magenta'
                            } ${
                              column.id === sortBy &&
                              sort === 'asc' &&
                              'rotate-180'
                            } transition-all`}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {displayTableBody()}
      </table>
      {dataTable?.metadata && dataTable.metadata.total > 0 && (
        <div className="py-1.6">
          <Pagination
            key={status}
            isBorderNotIncluded
            handlePaginating={handlePaginate}
            data={{
              currPage: data.metadata.page,
              perPage: data.metadata.limit,
              total: data.metadata.total,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Table;

import { WeekWorkingHour } from 'interfaces/clinicSchedule';
import { omit } from 'lodash';

const normalizeWeeklySchedule = (weeklySchedule: WeekWorkingHour) => {
  const removeIdFields = omit(weeklySchedule, ['id']);

  const normalizeData = Object.entries(removeIdFields);

  const timeSlots = normalizeData.map((data) => {
    const [day, time] = data;
    // The timesheet here only have 1 array
    const { start, end } = time[0];

    return {
      day,
      start,
      end,
    };
  });

  const sun = timeSlots.pop()!;
  timeSlots.unshift(sun);
  return timeSlots;
};

export default normalizeWeeklySchedule;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { ClinicException } from 'interfaces/clinicException';

const useClinicException = ({
  startDate,
  endDate,
  source,
  shouldRun,
}: {
  startDate: string;
  endDate: string;
  source: string;
  shouldRun: boolean;
}) => {
  const query = new URLSearchParams({
    startDate,
    endDate,
    source,
  }).toString();

  const url = `/clinics-exception?${query}`;
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    shouldRun ? [url, clinicId] : false,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<ClinicException[]>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicException;

import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/images/app-logo.svg';

import { ROUTES } from 'utils/constants/routes';

const NavigationBar = () => {
  return (
    <nav className="h-11 py-3.5 px-8 shadow-elevation-01-light">
      <Link to={ROUTES.DASHBOARD}>
        <Logo />
      </Link>
    </nav>
  );
};

export default NavigationBar;

import { FC, useState } from 'react';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';
import { ExceptionDate } from 'interfaces/exceptionDate';
import CustomDayRow from 'components/CustomDayRow/CustomDayRow';
import { CUSTOM_HOURS } from 'utils/constants';

interface CustomDayItemProps {
  day: ExceptionDate;
  onClick: (exceptionDate: ExceptionDate) => void;
  source: string;
  version: number;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
}

const CustomDayItem: FC<CustomDayItemProps> = ({
  day,
  onClick,
  source,
  version,
  onCheckOutsideOfficeHourAppts,
}) => {
  const [isShown, setIsShown] = useState(false);

  const displayDate = (date: ExceptionDate) => {
    if (typeof date.workingHour !== 'string') {
      return CUSTOM_HOURS;
    }
    if (date.holidayName) {
      return (date.holidayName as string).split('(')[0];
    }

    return 'Closed';
  };

  return (
    <CustomDayRow
      setIsShown={setIsShown}
      getDisplayDate={displayDate}
      onClick={onClick}
      day={day}
    >
      <ThreeDotsOption
        day={day}
        source={source}
        isShown={isShown}
        onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
        version={version}
      />
    </CustomDayRow>
  );
};

export default CustomDayItem;

import { Dispatch, FC, useState } from 'react';
import { capitalize, cloneDeep, isEqual } from 'lodash';
import moment from 'moment';

import DefaultTimeView from 'pages/OfficeSchedulePage/DayTimeSlot/DefaultTimeView/DefaultTimeView';
import CommonButton from 'components/CommonButton/CommonButton';
import PracticeScheduleTooltip from './PracticeScheduleTooltip/PracticeScheduleTooltip';
import EmptySchedule from 'components/EmptySchedule/EmptySchedule';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';
import OverrideModal from '../OverrideModal/OverrideModal';

import { PractitionerException } from 'interfaces/practitionerException';
import { IPractitioner } from 'interfaces/practitioners';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';

import { DAY_OFF, DEFAULT_WORKING_HOUR, NEXT_23_MONTH } from 'utils/constants';
import getChangedTimeSlots from 'utils/getChangedTimeSlots';
import getQueryTimeSlotPerDay from 'utils/getQueryTimeSlotPerDay';

interface DayTimeSlotProps {
  timeSlots: WeeklyTimeslot[];
  clinicTimeSlots: WeeklyTimeslot[];
  cachedTimeSlots: WeeklyTimeslot[];
  setTimeSlots: Dispatch<React.SetStateAction<WeeklyTimeslot[]>>;
  practitionerScheduleId: string | null;
  selectedPractitioner: IPractitioner;
  version: number;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
}

const DayTimeSlot: FC<DayTimeSlotProps> = ({
  timeSlots,
  clinicTimeSlots,
  cachedTimeSlots,
  setTimeSlots,
  practitionerScheduleId,
  selectedPractitioner,
  version,
  onCheckOutsidePractitionerHourAppts,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [exceptionDates, setExceptionDates] = useState<
    PractitionerException[] | null
  >(null);

  const matchMutate = useMatchMutate();

  const changedTimeSlots = getChangedTimeSlots(timeSlots, cachedTimeSlots);

  const handleUpdatedTimeSlot = (day: string, value: string, type: string) => {
    const updatedTimeSlots = [...timeSlots];
    const timeSlot = updatedTimeSlots.find((slot) => slot.day === day)!;
    timeSlot[type as keyof WeeklyTimeslot] = value;
    setTimeSlots(updatedTimeSlots);
  };

  const handleCopyTimeSlot = (timeSlots: WeeklyTimeslot[]) => {
    setTimeSlots(timeSlots);
  };

  const handleUpdateSchedule = async () => {
    const body: { [key: string]: [{ start: string; end: string }] | string } = {
      id: practitionerScheduleId!,
    };

    changedTimeSlots.forEach(({ day, end, start }) => {
      body[day as keyof typeof body] = [
        {
          start: start!,
          end: end!,
        },
      ];
    });

    await axiosInstance.patch(
      `/practitioners/${selectedPractitioner.id}/schedules`,
      body,
      {
        headers: { version },
      }
    );
  };

  const handleSaveSchedule = async () => {
    setIsSubmitting(true);

    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment()
      .add(NEXT_23_MONTH, 'months')
      .endOf('months')
      .format('YYYY-MM-DD');

    const queryTimeSlotPerDay = getQueryTimeSlotPerDay(changedTimeSlots);

    try {
      const response = await axiosInstance.get(
        `/practitioners/${selectedPractitioner.id}/custom-days`,
        {
          params: {
            startDate,
            endDate,
            days: changedTimeSlots.map((timeSlot) => timeSlot.day),
            ...queryTimeSlotPerDay,
          },
        }
      );
      const isExceptionDateFound = response.data.length > 0;

      if (isExceptionDateFound) {
        setIsSubmitting(false);
        return setExceptionDates(response.data);
      }

      await handleUpdateSchedule();

      await Promise.all([
        matchMutate(/\/exceptions\?[\s\S]+/),
        matchMutate(/\/schedules[\s\S]+/),
        onCheckOutsidePractitionerHourAppts(),
      ]);

      renderToast({
        type: 'success',
        message: 'Regular hours has been updated successfully',
      });
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      {isSubmitting && <LoadingScreen />}
      {exceptionDates && (
        <OverrideModal
          exceptionDates={exceptionDates as any}
          onClose={() => setExceptionDates(null)}
          selectedPractitioner={selectedPractitioner}
          changedTimeSlots={changedTimeSlots}
          onUpdateSchedule={handleUpdateSchedule}
          version={version}
          onCheckOutsidePractitionerHourAppts={
            onCheckOutsidePractitionerHourAppts
          }
        />
      )}
      <div className="w-full border border-light-grey border-y-0">
        {!practitionerScheduleId ? (
          <EmptySchedule />
        ) : (
          timeSlots.map(({ day, start, end }, index) => {
            const isChecked = Boolean(start && end);

            return (
              <div
                key={day}
                className="h-6.2 border-b border-light-grey flex items-center gap-x-3.3"
              >
                <div className="flex gap-x-1.1 pl-2.1 items-center">
                  <input
                    id={`day-${day}`}
                    type="checkbox"
                    className="w-2.2 h-2.2"
                    checked={isChecked}
                    onChange={(event) => {
                      const updatedTimeSlots = [...timeSlots];
                      const timeSlot = updatedTimeSlots.find(
                        (slot) => slot.day === day
                      )!;

                      const start = cachedTimeSlots[index].start
                        ? cachedTimeSlots[index].start
                        : DEFAULT_WORKING_HOUR.START;

                      const end = cachedTimeSlots[index].end
                        ? cachedTimeSlots[index].end
                        : DEFAULT_WORKING_HOUR.END;

                      timeSlot.start = event.target.checked ? start : null;
                      timeSlot.end = event.target.checked ? end : null;
                      setTimeSlots(updatedTimeSlots);
                    }}
                  />
                  <label
                    htmlFor={`day-${day}`}
                    className="font-bold w-[3.1rem]"
                  >
                    {capitalize(day)}
                  </label>
                </div>
                <div className="flex items-center gap-x-0.4">
                  <PracticeScheduleTooltip
                    timeSlot={timeSlots[index]}
                    clinicTimeSlot={clinicTimeSlots[index]}
                  />
                  {!isChecked ? (
                    <span className="text-11 leading-[1.8rem]">{DAY_OFF}</span>
                  ) : (
                    <DefaultTimeView
                      day={day}
                      start={start!}
                      end={end!}
                      timeSlots={timeSlots}
                      onChange={handleUpdatedTimeSlot}
                      onCopy={handleCopyTimeSlot}
                    />
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="mt-1.6 flex items-center min-h-[3.2rem]">
        {!isEqual(timeSlots, cachedTimeSlots) && (
          <div className="flex gap-x-1 ml-auto">
            <CommonButton
              variant="secondary"
              className="!min-w-[8rem] !min-h-[3.2rem]"
              onClick={() => {
                setTimeSlots(cloneDeep(cachedTimeSlots));
              }}
            >
              Cancel
            </CommonButton>
            <CommonButton
              className="!min-w-[8rem] !min-h-[3.2rem]"
              onClick={handleSaveSchedule}
            >
              Save
            </CommonButton>
          </div>
        )}
      </div>
    </>
  );
};

export default DayTimeSlot;

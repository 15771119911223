import React, { FC, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { PractitionerException } from 'interfaces/practitionerException';
import { ClinicException } from 'interfaces/clinicException';
import DayCell, { DayCellData } from './DayCell/DayCell';
import Legend from 'components/Legend/Legend';
import { CUSTOM_HOURS, DAY_OFF, OFFICE_CLOSED } from 'utils/constants';
import EditScheduleModal from '../EditScheduleModal/EditScheduleModal';
import { IPractitioner } from 'interfaces/practitioners';
import { PractitionerSchedule } from 'interfaces/practitionerSchedule';

interface CalendarProps {
  date: string;
  practitionerException: PractitionerException[];
  clinicException: ClinicException[];
  highlightedDate: string;
  resetHighlightedDate: () => void;
  selectedPractitioner: IPractitioner;
  version: number;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
  practitionerSchedule: PractitionerSchedule;
}

const Calendar: FC<CalendarProps> = ({
  date,
  practitionerException,
  clinicException,
  highlightedDate,
  resetHighlightedDate,
  selectedPractitioner,
  onCheckOutsidePractitionerHourAppts,
  version,
  practitionerSchedule,
}) => {
  const [selectedDate, setSelectedDate] = useState<DayCellData | null>(null);
  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const parsedDate = date
        ? moment(date, 'YYYY-MM-DD').toDate()
        : new Date();

      calendarApi.gotoDate(parsedDate);
    }
  }, [date]);

  const monthValue =
    practitionerException && clinicException
      ? practitionerException.map((pracExcep, index) => ({
          date: pracExcep.date,
          isPractitionerClosed: !!pracExcep.isClosed,
          isClinicClosed: !!clinicException[index].isClosed,
          isPractitionerException: pracExcep.isException,
          clinicWorkingHour: clinicException[index].workingHour,
          practitionerWorkingHour: pracExcep.workingHour,
          holidayName: clinicException[index].holidayName,
        }))
      : [];

  return (
    <>
      {selectedDate && (
        <EditScheduleModal
          version={version}
          selectedPractitioner={selectedPractitioner}
          selectedDate={{
            date: selectedDate.date,
            workingHour: selectedDate.practitionerWorkingHour,
          }}
          onClose={() => setSelectedDate(null)}
          onCheckOutsidePractitionerHourAppts={
            onCheckOutsidePractitionerHourAppts
          }
          practitionerSchedule={practitionerSchedule}
        />
      )}
      <div className="mt-2" id="schedule-calendar">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          initialDate={new Date()}
          contentHeight={483}
          fixedWeekCount
          showNonCurrentDates
          headerToolbar={false}
          dayCellClassNames={'h-[75px]'}
          dayCellContent={(props) => {
            const dayCellValue = monthValue.find(
              (value) => value.date === moment(props.date).format('YYYY-MM-DD')
            );
            return (
              <DayCell
                selectedDate={date}
                dayInfo={dayCellValue}
                highlightedDate={highlightedDate}
                resetHighlightedDate={resetHighlightedDate}
                onCellClicked={(dayCellData) => setSelectedDate(dayCellData)}
                {...props}
              />
            );
          }}
        />

        <div className="flex flex-row justify-end mt-1.6 gap-x-6">
          <Legend icon="circle">{CUSTOM_HOURS}</Legend>
          <Legend icon="square" className="before:bg-[#F4FAFF]">
            {DAY_OFF}
          </Legend>
          <Legend icon="square">{OFFICE_CLOSED}</Legend>
        </div>
      </div>
    </>
  );
};

export default Calendar;

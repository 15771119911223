const getWebsiteName = (websiteUrl: string) => {
  try {
    const url = new URL(websiteUrl);
    return url.hostname;
  } catch (error) {
    return websiteUrl;
  }
};

export default getWebsiteName;

import React, { useCallback, useEffect, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import DayCell from './DayCell/DayCell';

import styles from './MonthView.module.css';
import axiosInstance from 'apis/axiosInstance';
import getDateRange from 'utils/getDateRange';
import getTimeSlotPer30Minutes from 'utils/getTimeSlotsPer30Minutes';

const getPractitionerAvailability = async ({
  dates,
  serviceId,
  practitionerId,
  currentDate,
  timeBlocks,
  timezone,
}: any) => {
  const res = await axiosInstance.get(
    `practitioners/${practitionerId}/weekly-timeslots`,
    {
      params: {
        dates,
        serviceId: serviceId,
        currentDate,
        timeBlocks,
        timezone,
      },
    }
  );
  return res.data[0]?.clinic?.availableBlocks || [];
};

export interface ITimeslots {
  date: string;
  blocks: number[];
}

interface IProps {
  serviceId: string;
  practitionerId: string | null;
  date: string | null;
  selectedDate?: string;
  timeBlocks: number[];
  currentDate: string;
  onNavigateToDayView: (dateStr: string) => void;
  timezone: string;
}

const MonthView = (props: IProps) => {
  const {
    serviceId,
    practitionerId,
    date,
    selectedDate,
    onNavigateToDayView,
    timeBlocks,
    currentDate,
    timezone,
  } = props;

  const calendarRef = useRef<FullCalendar | null>(null);

  const [timeslots, setTimeslots] = useState<ITimeslots[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onClickDate = (info: any) => {
    const currentDate = moment();
    const isBefore = moment(info.dateStr).isBefore(currentDate, 'days');

    if (!isBefore) {
      onNavigateToDayView(info.dateStr);
    }
  };

  const fetchAvailabilityInDateRange = async (
    datesInFuture: string[],
    callback: any
  ) => {
    const promises = [];
    const tmpDatesInFuture = [...datesInFuture];

    while (tmpDatesInFuture.length !== 0) {
      const ranges = tmpDatesInFuture.splice(0, 7);

      const dates = [ranges[0], ranges[ranges.length - 1]];
      promises.push(callback(dates));
    }

    const availabilities = await Promise.all(promises);

    return availabilities;
  };

  const fetchWeeklyTimeSlot = useCallback(
    async (startDate: Date, endDate: Date) => {
      setIsLoading(true);
      const dateStrings = getDateRange(startDate, endDate);

      const datesInFuture = dateStrings.filter((dateString) =>
        moment(dateString).isSameOrAfter(moment(), 'days')
      );

      if (practitionerId) {
        // Call weekly API of practitioner
        const availabilities = await fetchAvailabilityInDateRange(
          datesInFuture,
          (dates: string[]) =>
            getPractitionerAvailability({
              serviceId,
              practitionerId,
              dates,
              timeBlocks,
              currentDate,
              timezone,
            })
        );

        const updatedavailabilities = availabilities.map((availability) =>
          getTimeSlotPer30Minutes(availability)
        );

        const result = updatedavailabilities.reduce((accum, currentValue) => {
          return accum.concat(currentValue);
        }, []);

        setTimeslots(result);
      }

      setIsLoading(false);
    },
    [currentDate, practitionerId, serviceId, timeBlocks, timezone]
  );

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const parsedDate = date
        ? moment(date, 'YYYY-MM-DD').toDate()
        : new Date();

      calendarApi.gotoDate(parsedDate);

      const calendarData = calendarApi.getCurrentData();
      const renderDateRange = calendarData.dateProfile.renderRange;

      fetchWeeklyTimeSlot(renderDateRange.start, renderDateRange.end);
    }
  }, [date, fetchWeeklyTimeSlot]);

  return (
    <div className={styles.wrapper} id="dashboard-calendar">
      <FullCalendar
        ref={calendarRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialDate={selectedDate || new Date()}
        initialView="dayGridMonth"
        contentHeight={300}
        fixedWeekCount
        showNonCurrentDates
        weekNumberCalculation="ISO"
        headerToolbar={false}
        dayHeaderClassNames="calendar-header"
        dayCellClassNames="calendar-cell"
        dayCellContent={(props) => (
          <DayCell {...props} timeslots={timeslots} isLoading={isLoading} />
        )}
        dateClick={onClickDate}
      />
    </div>
  );
};

export default MonthView;

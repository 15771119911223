import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { PractitionerException } from 'interfaces/practitionerException';

interface IUsePractitionerCustomDayParams {
  startDate: string;
  endDate: string;
  practitionerId: string;
}

const usePractitionerCustomDay = ({
  startDate,
  endDate,
  practitionerId,
}: IUsePractitionerCustomDayParams) => {
  const query = new URLSearchParams({
    startDate,
    endDate,
  }).toString();

  const clinicId = loadSelectedClinicId();
  const url = `/practitioners/${practitionerId}/custom-days?${query}`;

  const { data, error, mutate } = useSWR(
    [url, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<PractitionerException[]>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePractitionerCustomDay;

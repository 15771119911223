import { FC } from 'react';
import { useVersion } from '../context/versionContext';
import DefaultModal from 'components/Modals/DefaultModal';
import CommonButton from 'components/CommonButton/CommonButton';

interface VersionCheckProps {
  onRefresh: () => void;
}

const VersionCheck: FC<VersionCheckProps> = ({ onRefresh }) => {
  const { isOldVersion } = useVersion();

  return isOldVersion ? (
    <DefaultModal>
      <div className="w-48 p-2 text-darkest-grey normal-case">
        <h3 className="text-20 leading-[3rem] font-bold">
          Schedule change detected
        </h3>
        <p className="text-14 leading-[2.1rem] mt-1.6">
          This schedule has been updated by another user or in another tab.
          Please refresh to see the most recent changes.
        </p>
        <div className="flex justify-end gap-x-1.6 mt-2.4">
          <CommonButton variant="primary" onClick={onRefresh}>
            Refresh
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  ) : null;
};

export default VersionCheck;

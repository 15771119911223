import { useReducer } from 'react';

type Action =
  | { type: 'monthNavigation'; payload: { monthValue: string } }
  | { type: 'customDayNavigation'; payload: { monthValue: string } }
  | { type: 'resetHighLightedDate' };

interface ScheduleNavigator {
  monthValue: string;
  highlightedDate: string;
  isNavigatedByCalendar: boolean;
}

const scheduleReducer = (state: ScheduleNavigator, action: Action) => {
  switch (action.type) {
    case 'monthNavigation': {
      return {
        monthValue: action.payload.monthValue,
        highlightedDate: '',
        isNavigatedByCalendar: true,
      };
    }
    case 'customDayNavigation': {
      return {
        monthValue: action.payload.monthValue,
        highlightedDate: action.payload.monthValue,
        isNavigatedByCalendar: false,
      };
    }
    case 'resetHighLightedDate': {
      return {
        ...state,
        highlightedDate: '',
        isNavigatedByCalendar: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const useScheduleNavigator = (today: string) => {
  const [state, dispatch] = useReducer(scheduleReducer, {
    monthValue: today,
    highlightedDate: '',
    isNavigatedByCalendar: false,
  });

  return { state, dispatch };
};

export default useScheduleNavigator;

import { Switch } from '@headlessui/react';
import useClinic from 'hooks/useClinic';
import axiosInstance from 'apis/axiosInstance';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { renderToast } from 'components/Toast/Toast';
import getWebsiteName from 'utils/getWebsiteName';

const ToggleDisplayOnSection = () => {
  const { data, isLoading, mutate } = useClinic();

  const onChange = async () => {
    try {
      await axiosInstance.patch(`/clinics/widget-visibility`, {
        isWidgetVisible: !data!.isWidgetVisible,
      });
      await mutate({ ...data, isWidgetVisible: !data!.isWidgetVisible });

      renderToast({
        type: 'success',
        message: 'Widget visibility has been updated successfully!',
      });
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  if (isLoading) {
    return <LoadingSpinner className="all-child:fill-magenta" />;
  }

  return (
    <>
      <p className="font-bold min-w-[20rem]">
        Display on {data.website ? getWebsiteName(data.website) : data.name}
      </p>
      <div className="flex gap-x-1.6 items-center">
        <Switch
          onChange={onChange}
          checked={data?.isWidgetVisible}
          className={`${
            data?.isWidgetVisible ? 'bg-magenta' : 'bg-[#78788029]'
          } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
        >
          <span
            className={`${
              data?.isWidgetVisible
                ? 'translate-x-[2.3rem]'
                : 'translate-x-[2px]'
            } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
          />
        </Switch>
        <span className="uppercase">
          {data?.isWidgetVisible ? 'On' : 'Off'}
        </span>
      </div>
    </>
  );
};

export default ToggleDisplayOnSection;

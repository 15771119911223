import { createContext, useContext, useMemo, useReducer } from 'react';
import { ROLE } from 'utils/constants';
import { loadSelectedClinicId } from 'utils/storage';
type Action =
  | { type: 'login'; payload: UserProfile }
  | { type: 'getUserProfile'; payload: UserProfile }
  | { type: 'changeClinic'; payload: { clinicId: string } }
  | { type: 'logout' };

type Dispatch = (action: Action) => void;

export interface ClinicLocation {
  id: string;
  city: string;
  state: string;
  country: string;
}

export interface GlobalAdminRole {
  role: string;
  firstName: string;
  lastName: string;
  name: string;
}

interface Clinic {
  id: string;
  name: string;
  avatar: string;
  timezone: string;
  location: ClinicLocation;
}

export interface ClinicUserRole {
  id: string;
  role: string;
  status: string;
  firstName: string;
  lastName: string;
  name: string;
  clinic: Clinic;
}

export interface UserProfile {
  id: string;
  email: string;
  clinicUserRoles: ClinicUserRole[];
  currentClinicUserRole: ClinicUserRole | null;
  globalAdminRole: GlobalAdminRole | null;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = createContext<{
  user: UserProfile;
  dispatch: Dispatch;
} | null>(null);

const initialState = {
  id: '',
  email: '',
  clinicUserRoles: [],
  currentClinicUserRole: null,
  globalAdminRole: null,
};

const userReducer = (state: UserProfile, action: Action) => {
  const clinicId = loadSelectedClinicId();

  switch (action.type) {
    case 'login':
    case 'getUserProfile': {
      let currentClinicUserRole = action.payload.clinicUserRoles.find(
        (clinicUserRole) => clinicUserRole.clinic?.id === clinicId
      );

      if (!currentClinicUserRole) {
        currentClinicUserRole = action.payload.clinicUserRoles[0];

        if (currentClinicUserRole.role === ROLE.GLOBAL_ADMIN) {
          return {
            ...state,
            ...action.payload,
            globalAdminRole: {
              role: ROLE.GLOBAL_ADMIN,
              firstName: currentClinicUserRole.firstName,
              lastName: currentClinicUserRole.lastName,
              name: currentClinicUserRole.name,
            },
          };
        }
      }

      return {
        ...state,
        ...action.payload,
        currentClinicUserRole,
        globalAdminRole:
          currentClinicUserRole.role === ROLE.GLOBAL_ADMIN
            ? {
                role: ROLE.GLOBAL_ADMIN,
                firstName: currentClinicUserRole.firstName,
                lastName: currentClinicUserRole.lastName,
                name: currentClinicUserRole.name,
              }
            : null,
      };
    }
    case 'changeClinic': {
      const currentClinicUserRole = state.clinicUserRoles.find(
        (clinicUserRole) => clinicUserRole.clinic.id === clinicId
      )!;

      return {
        ...state,
        currentClinicUserRole,
      };
    }
    case 'logout': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const UserProvider = ({ children }: UserProviderProps) => {
  const [user, dispatch] = useReducer(userReducer, initialState);

  const value = useMemo(
    () => ({
      user,
      dispatch,
    }),
    [user]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within UserProvider');
  }
  return context;
};

export { UserProvider, useUser };

import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import FullScreenLoading from 'components/FullScreenLoading/FullScreenLoading';
import { useUser } from 'context/userContext';
import useGetUserProfileFirstRender from 'hooks/useGetUserProfileFirstRender';
import { ROUTES } from 'utils/constants/routes';
import useCheckUserRole from 'hooks/useCheckUserRole';
import { clearStorage } from 'utils/storage';
import useSaveHistoryLocation from 'hooks/useSaveHistoryLocation';

interface ProtectedRoutesProps {
  redirect?: string;
  allowed?: string[];
}

const ProtectedRoutes: FC<ProtectedRoutesProps> = ({
  redirect = ROUTES.LOGIN,
  children,
  allowed,
}) => {
  const { user } = useUser();
  const { currentClinicUserRole } = user;
  const { isLoading } = useGetUserProfileFirstRender();
  const { isUserNotAllowed } = useCheckUserRole(allowed);

  useSaveHistoryLocation();

  if (isLoading) {
    return <FullScreenLoading />;
  }

  if (!user.email) {
    clearStorage();
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  if (isUserNotAllowed) {
    return <Navigate to={redirect} replace />;
  }

  return (
    <>
      {children ? (
        children
      ) : (
        <Outlet key={currentClinicUserRole?.clinic?.id || 'global-admin-key'} />
      )}
    </>
  );
};

export default ProtectedRoutes;

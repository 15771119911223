const getTimeSlotPer30Minutes = (
  availableBlocks?: { date: string; blocks: number[] }[]
) => {
  if (!availableBlocks) return [];

  const mergeBlocksWithin30Minutes = availableBlocks.map((avaiBlock) => {
    const updatedAvailabledBlocks = [];

    for (let index = 0; index < avaiBlock.blocks.length; index++) {
      if (index === 0) {
        updatedAvailabledBlocks.push(avaiBlock.blocks[0]);
        continue;
      }

      const lastupdatedAvailabledBlocksValue =
        updatedAvailabledBlocks[updatedAvailabledBlocks.length - 1];

      if (avaiBlock.blocks[index] > lastupdatedAvailabledBlocksValue + 2) {
        updatedAvailabledBlocks.push(avaiBlock.blocks[index]);
      }
    }

    return { ...avaiBlock, blocks: updatedAvailabledBlocks };
  });
  return mergeBlocksWithin30Minutes;
};

export default getTimeSlotPer30Minutes;

import ScriptSnippet from 'components/ScriptSnippet';
import ToggleDisplayOnSection from './ToggleDisplayOnSection/ToggleDisplayOnSection';
import useWidgetGenerate from 'hooks/useWidgetGenerate';

const WidgetSection = () => {
  const { data, isLoading } = useWidgetGenerate();

  return (
    <section className="grid grid-cols-[auto_1fr] gap-y-2.4 items-center gap-x-6 mt-2.4 pb-2.4 border-b border-light-grey">
      <p className="font-bold">Scheduling button script</p>
      <div className="w-15">
        <ScriptSnippet
          isLoading={isLoading}
          url={`${process.env.REACT_APP_FIRSTIN_APP_URL}/static/js/widget.js?key=${data?.widgetKey}`}
          dataApp="first-in"
        />
      </div>

      <p className="font-bold">CTA button script</p>
      <div className="w-15">
        <ScriptSnippet
          isLoading={isLoading}
          url={`${process.env.REACT_APP_FIRSTIN_APP_URL}/static/js/customWidgetModal.js?key=${data?.widgetKey}`}
          dataApp="custom-script-first-in"
        />
      </div>

      <ToggleDisplayOnSection />
    </section>
  );
};

export default WidgetSection;

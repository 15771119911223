import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { IAppointmentDetail } from 'interfaces/appointments';
import { loadSelectedClinicId } from 'utils/storage';

interface IUseOutsideOfficeHour {
  shouldRun?: boolean;
  source: string;
}

const useOutsideOfficeHour = ({ shouldRun, source }: IUseOutsideOfficeHour) => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR<IAppointmentDetail[]>(
    shouldRun ? ['/appointments/out-office-hours', source, clinicId] : null,
    async (url) => {
      const response = await axiosInstance(url);

      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useOutsideOfficeHour;

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import type { Location } from 'react-router-dom';
import moment from 'moment';
import { IAppointmentDetail } from 'interfaces/appointments';
import { calculateRangeBlock } from './getTimeBlockFromTImeString';

export const capitalizeAvatarName = (input: string | undefined) => {
  if (!input) return;
  const fullName = input.split(' ');
  if (fullName.length < 2) return;
  const [firstName, lastName] = fullName;
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export const highlightText = (text: string, inputValue: string) => {
  const matches = match(text, inputValue, {
    findAllOccurrences: true,
    insideWords: true,
  });
  const parts = parse(text, matches);

  return parts;
};

export const isInAdminPage = (location: Location) => {
  return location.pathname.search(/\/admin\/.*/) !== -1;
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const calculateAge = (
  dob: string | null,
  format: string | string[] = ['YYYY-MM-DD']
) => {
  if (dob && moment(dob, format).isValid()) {
    const currentDate = moment().format('YYYY-MM-DD');
    return Math.abs(
      moment(currentDate, 'YYYY-MM-DD').diff(moment(dob, format), 'years')
    );
  }

  return '';
};

export const getErrorReason = (appointment: IAppointmentDetail) => {
  let { errorReason, isSplitScheduling, linkedAppointment } = appointment;

  if (!errorReason && isSplitScheduling && linkedAppointment?.errorReason) {
    errorReason = linkedAppointment?.errorReason;
  }

  return errorReason;
};

// For example: [1,2,3,5,6] => [[1,2,3], [5,6]]
export const groupConsecutiveNumber = (numbers: number[]) => {
  const result = numbers.reduce((accum: number[][], currentValue: number) => {
    const tmp = accum[accum.length - 1];

    if (!tmp || tmp[tmp.length - 1] !== currentValue - 1) {
      accum.push([]);
    }

    accum[accum.length - 1].push(currentValue);

    return accum;
  }, []);

  return result;
};

export const generateBlockNumbers = (
  events: {
    fromTime: string;
    toTime: string;
  }[]
) => {
  const blockNumbers = events.reduce((accum: number[], currentEvent) => {
    const blockNumbers = calculateRangeBlock(
      currentEvent.fromTime,
      currentEvent.toTime
    );
    accum = accum.concat(blockNumbers);

    return accum;
  }, []);

  return blockNumbers;
};

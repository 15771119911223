import React from 'react';
import StatisticRow from '../StatisticRow/Row';

interface IProps {
  totalInDateRange: number;
  total: number;
  year: string;
}

const AppointmentSection = ({ totalInDateRange, total, year }: IProps) => {
  return (
    <div className="bg-white rounded-[1rem] py-2 px-3 shadow-primary flex flex-col justify-around bg-appointment bg-no-repeat bg-right-bottom bg-[length:16.6rem_14.1rem]">
      <StatisticRow
        title={`Total Appointments ${year}`}
        value={totalInDateRange}
      />
      <StatisticRow title="Total Appointments" value={total} />
    </div>
  );
};

export default AppointmentSection;

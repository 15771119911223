import React from 'react';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import MultipleSelect from '../MultipleSelect/MultipleSelect';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
}

const OPTIONS = [
  { value: '', label: 'No title' },
  { value: 'Dr', label: 'Dr' },
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
];

const TitleDropdown = (props: IProps) => {
  return (
    <Controller
      {...props}
      name="title"
      render={({ field }) => {
        const { onChange, value } = field;

        const option = OPTIONS.find((item) => item.value === value) || null;

        return (
          <MultipleSelect
            isMulti={false}
            placeholder={'Select Title'}
            options={OPTIONS}
            value={option}
            onChange={(newValue: any) => {
              onChange(newValue.value);
            }}
          />
        );
      }}
    />
  );
};

export default TitleDropdown;

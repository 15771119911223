import { FC } from 'react';

interface StatusProps {
  isActive: boolean;
}

const Status: FC<StatusProps> = ({ isActive, children }) => {
  return (
    <div
      className={`w-max h-2.2 flex justify-center items-center px-1 py-0.2 text-12 rounded-full border-[0.3px] ${
        isActive ? 'text-magenta bg-[#FFF7FF]' : 'text-[#4D97E1] bg-[#F4FAFF]'
      }`}
    >
      {children}
    </div>
  );
};

export default Status;

import { useState } from 'react';
import { sortBy } from 'lodash';
import {
  Controller,
  FieldValues,
  RegisterOptions,
  UseFormSetValue,
} from 'react-hook-form';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync.svg';
import usePMSProvider from 'hooks/usePMSProvider';

import { PmsProvider } from 'interfaces/pmsProvider';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import styles from './ProviderIdDropdown.module.css';
import MenuList from './MenuList/MenuList';

interface IProps {
  disabled: boolean;
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  setValue?: UseFormSetValue<any>;
}
const SyncButton = ({
  isSynced,
  onClick,
}: {
  isSynced: boolean;
  onClick: () => void;
}) => {
  if (isSynced) {
    return (
      <div className="pl-1.2 mb-2">
        <div className="flex flex-row items-center mb-0.5">
          <SyncIcon className={styles.inactiveSyncIcon} />
          <span className="text-grey ml-0.2 text-1.4">
            Sync Practitioner ID
          </span>
        </div>

        <div className="text-red-01 text-1.2">
          You must wait 24 hours for the next sync.
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={onClick}
      className="flex flex-row items-center cursor-pointer pl-1.2 mb-2"
    >
      <SyncIcon className={styles.activeSyncIcon} />
      <span className="text-magenta ml-0.2 text-1.4">Sync Practitioner ID</span>
    </div>
  );
};

const ProviderIdDropdown = ({ disabled, setValue, ...props }: IProps) => {
  const [isUpdated, setIsUpdated] = useState(false);

  const {
    data: { data: pmsProviders, isSynced },
    isLoading,
  } = usePMSProvider({ isUpdated });

  const onSync = () => {
    setIsUpdated(true);
  };

  const pmsProviderOptions = pmsProviders.map((pmsProvider: PmsProvider) => ({
    label: pmsProvider.externalId,
    value: pmsProvider.externalId,
  }));

  return (
    <Controller
      {...props}
      name="externalId"
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        const option = pmsProviderOptions.find(
          (item: any) => item.value === value
        );

        return (
          <>
            <MultipleSelect
              isError={!!error?.message}
              isMulti={false}
              closeMenuOnSelect
              isDisabled={disabled}
              key={`${isLoading}`}
              isLoading={isLoading}
              placeholder={'Select Practitioner ID'}
              options={sortBy(pmsProviderOptions, 'label')}
              value={isLoading ? [] : option}
              onChange={(newValue: any) => {
                if (setValue) {
                  const fullName =
                    pmsProviders.find(
                      (item) => item.externalId === newValue.value
                    )?.name || '';

                  const firstName = fullName.split(' ')[0] || '';
                  const lastName = fullName.split(' ')[1] || '';

                  setValue('firstName', firstName);
                  setValue('lastName', lastName);
                }

                onChange(newValue.value);
              }}
              MenuList={(props: any) => (
                <div>
                  {isLoading ? (
                    <div className="flex justify-center">
                      <LoadingSpinner className="all-child:fill-magenta" />
                    </div>
                  ) : (
                    <>
                      <SyncButton isSynced={isSynced} onClick={onSync} />
                      <MenuList {...props} pmsProviders={pmsProviders} />
                    </>
                  )}
                </div>
              )}
            />

            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-1`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default ProviderIdDropdown;

import { IAppointmentDetail } from 'interfaces/appointments';
import { ERROR_REASON } from './constants';

export const getDentistWorkingHour = (appointment: IAppointmentDetail) => {
  if (!appointment.linkedAppointment && appointment.isSplitScheduling) {
    if (appointment.errorReason === ERROR_REASON.DUPLICATE_BOOKING) {
      return 'Doctor is unavailable';
    } else {
      return `Doctor's working day`;
    }
  }

  return '';
};

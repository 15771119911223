import moment from 'moment';
import { convertDurationForFE } from 'utils/convertDuration';
const initialFormat = ['HH:mm:ss', 'HH:mm A'];
const format = 'hh:mm A';

const getAppointmentStartTime = (data: any) => {
  const momentApptStartTime = moment(data.startTime, initialFormat);
  if (!data.linkedAppointment) {
    return momentApptStartTime.format(format);
  }

  const momentLinkedApptStartTime = moment(
    data.linkedAppointment.startTime,
    initialFormat
  );
  const isCurrentBeforeLinked = moment(momentApptStartTime).isBefore(
    momentLinkedApptStartTime
  );

  return isCurrentBeforeLinked
    ? momentApptStartTime.format(format)
    : momentLinkedApptStartTime.format(format);
};

const getAppointmentEndTime = (data: any) => {
  const momentApptEndTime = moment(data.startTime, initialFormat).add(
    convertDurationForFE(data.duration),
    'minute'
  );
  if (!data.linkedAppointment) {
    return momentApptEndTime.format(format);
  }
  const momentLinkedApptEndTime = moment(
    data.linkedAppointment.startTime,
    initialFormat
  ).add(convertDurationForFE(data.linkedAppointment.duration), 'minute');

  const isCurrentAfterLinked = moment(momentApptEndTime).isAfter(
    momentLinkedApptEndTime
  );

  return isCurrentAfterLinked
    ? momentApptEndTime.format(format)
    : momentLinkedApptEndTime.format(format);
};

const getHygienistWorkingTime = (data: any) => {
  const momentHygienistStartTime = moment(data.startTime, initialFormat);
  const momentHygienistEndTime = moment(data.startTime, initialFormat).add(
    convertDurationForFE(data.duration),
    'minute'
  );
  return `${momentHygienistStartTime.format(
    'hh:mm A'
  )} - ${momentHygienistEndTime.format('hh:mm A')}`;
};

const getDoctorWorkingTime = (data: any) => {
  const momentDoctorStartTime = moment(
    data.linkedAppointment.startTime,
    initialFormat
  );

  const momentDoctorEndTime = moment(
    data.linkedAppointment.startTime,
    initialFormat
  ).add(convertDurationForFE(data.linkedAppointment.duration), 'minute');

  return `${momentDoctorStartTime.format(
    'hh:mm A'
  )} - ${momentDoctorEndTime.format('hh:mm A')}`;
};

export {
  getAppointmentStartTime,
  getAppointmentEndTime,
  getHygienistWorkingTime,
  getDoctorWorkingTime,
};

import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { ONE_MINUTE_IN_PX, calculateTopPositionByTime } from '../../utils';

interface IProps {
  date: string;
  workingHour: {
    startTime: string;
    endTime: string;
  };
  config: {
    headerHeight: number;
    cellHeight: number;
  };
  isDebugMode: boolean;
  width?: number;
  timeGridRef: React.MutableRefObject<HTMLDivElement | null>;
  timezone: string;
}

const TimeLine = ({
  timeGridRef,
  workingHour,
  config,
  date,
  isDebugMode,
  width,
  timezone,
}: IProps) => {
  const [isStartingScroll, setIsStartingScroll] = useState(false);

  useEffect(() => {
    if (!timeGridRef?.current) {
      return;
    }

    const onScroll = () => {
      if (timeGridRef.current!.scrollLeft > 10 && !isStartingScroll) {
        setIsStartingScroll(true);
      } else if (timeGridRef.current!.scrollLeft < 10 && isStartingScroll) {
        setIsStartingScroll(false);
      }
    };

    timeGridRef.current!.addEventListener('scroll', onScroll);

    return () => {
      if (!timeGridRef?.current) {
        return;
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeGridRef.current!.removeEventListener('scroll', onScroll);
    };
  }, [isStartingScroll, timeGridRef]);

  const currentTime = moment().tz(timezone).format('HH:mm:ss');

  const calculateTop = useCallback(() => {
    const value = calculateTopPositionByTime({
      fromTime: workingHour.startTime,
      toTime: currentTime,
      extraHeight: config.headerHeight + 60 * ONE_MINUTE_IN_PX, // chart time is started 1 hour early
    });

    return value;
  }, [config.headerHeight, currentTime, workingHour.startTime]);

  const [top, setTop] = useState(() => {
    return calculateTop();
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      const value = calculateTop();

      setTop(value);
    }, 10 * 3600); // 10 minutes
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !moment(date, 'YYYY-MM-DD').isSame(
      moment().tz(timezone).format('YYYY-MM-DD'),
      'days'
    )
  ) {
    return null;
  }

  return (
    <div
      className={`
      absolute h-[1px] left-[5rem] bg-magenta border border-magenta pointer-events-none
      before:content-[''] before:w-[8px] before:h-[8px] before:block before:absolute before:rounded-full before:border-magenta before:border before:bg-white 
      before:-top-[4px] before:-left-[8px] before:bg-magenta
      ${isDebugMode ? 'before:bg-magenta' : ''}
      `}
      style={{
        top,
        ...(width && { width }),
        zIndex: isStartingScroll ? 0 : 20,
      }}
    />
  );
};

export default TimeLine;

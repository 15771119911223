import moment from 'moment';

const getWelcomeMessage = () => {
  const now = moment();
  let message = '';
  const timeFormat = 'HH:mm';

  if (
    now.isBetween(
      moment('05:00', timeFormat),
      moment('11:59', timeFormat),
      null,
      '[]'
    )
  ) {
    message = 'Good morning';
  } else if (
    now.isBetween(
      moment('12:00', timeFormat),
      moment('16:59', timeFormat),
      null,
      '[]'
    )
  ) {
    message = 'Good afternoon';
  } else if (
    now.isBetween(
      moment('17:00', timeFormat),
      moment('23:59', timeFormat),
      null,
      '[]'
    ) ||
    now.isBetween(
      moment('00:00', timeFormat),
      moment('04:59', timeFormat),
      null,
      '[]'
    )
  ) {
    message = 'Good evening';
  }

  return message;
};

export default getWelcomeMessage;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { PMS_TYPE } from 'utils/constants';
import { PmsProvider } from 'interfaces/pmsProvider';

const usePMSProvider = ({ isUpdated }: { isUpdated: boolean }) => {
  const url = `/practitioners/pms?isUpdated=${isUpdated}`;

  const { data, error, mutate } = useSWR(url, async (...resource) => {
    const response = await axiosInstance.get<{
      isSynced: boolean;
      data: PmsProvider[];
    }>(url, {
      params: {
        type: PMS_TYPE.NEX_HEALTH,
      },
    });
    return response.data;
  });

  return {
    data: data || { isSynced: false, data: [] },
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePMSProvider;

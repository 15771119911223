import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { PractitionerException } from 'interfaces/practitionerException';

const usePractitionerException = ({
  practitionerId,
  startDate,
  endDate,
}: {
  practitionerId: string;
  startDate: string;
  endDate: string;
}) => {
  const query = new URLSearchParams({
    startDate,
    endDate,
  }).toString();

  const url = `/practitioners/${practitionerId}/exceptions?${query}`;
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR<PractitionerException[]>(
    [url, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<PractitionerException[]>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePractitionerException;

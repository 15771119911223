import React, { ReactNode } from 'react';
import moment from 'moment';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-01.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/cissor.svg';

import { calculateAge, capitalizeAvatarName } from 'utils/common';
import getPractitionerName from 'utils/getPractitionerName';
import { IAppointmentDetail } from 'interfaces/appointments';
import DefaultModal from 'components/Modals/DefaultModal';
import CommonButton from 'components/CommonButton/CommonButton';
import useModal from 'hooks/useModal';

interface IProps {
  data: IAppointmentDetail[];
  onClose: () => void;
  header: ReactNode;
  isOutsidePractitionerMode?: boolean;
}

const OutsideOfficeHoursModal = ({
  data,
  onClose,
  header,
  isOutsidePractitionerMode,
}: IProps) => {
  useModal({ onClose });

  return (
    <DefaultModal>
      <div className="relative max-w-[68rem] pt-2 pb-2.9 px-3.7 text-darkest-grey text-14 leading-[2.1rem] flex flex-col">
        {header}

        <div className="mb-1.4">
          {data.length} {data.length > 1 ? 'appointments' : 'appointment'} found
        </div>
        <div className="relative">
          <div className="grid grid-cols-[30rem_30rem] gap-1.6 scrollbar overflow-y-auto max-h-[22rem] pt-1 -mt-1 -mr-2.8 -ml-1 pl-1 pb-4">
            {data.map((item) => {
              const avatarText = capitalizeAvatarName(
                `${item.patient.firstName
                  .replace(/\s/g, '')
                  .trim()} ${item.patient.lastName.replace(/\s/g, '').trim()}`
              );

              const patientNameWithAge = `${item.patient.name}, ${calculateAge(
                item.patient.dob,
                'YYYY-MM-DD'
              )}`;

              const appointmentDate = moment(
                item.appointmentDate,
                'YYYY-MM-DD'
              ).format('ddd, MMM D, YYYY');

              const appointmentTime = moment(item.startTime, 'HH:mm:ss').format(
                'hh:mm A'
              );

              const practitionerFullName = getPractitionerName(item.doctor);

              const serviceName = item.service?.name || '';

              return (
                <div
                  key={item.id}
                  className="flex flex-row p-1.2 rounded-[10px] shadow-primary w-full max-w-[300px]"
                >
                  <div className="flex flex-col items-center basis-3/12 flex-1">
                    <div className="w-[3.2rem] h-[3.2rem] rounded-full bg-magenta flex justify-center items-center mb-[5px] text-white">
                      {avatarText}
                    </div>
                    <div className="text-12 font-bold text-center">
                      {patientNameWithAge}
                    </div>
                  </div>

                  <div className="ml-[10px]">
                    <div className="flex flex-row items-center text-11 mb-[6px]">
                      <CalendarIcon className="mr-[8px]" />
                      <span>
                        {appointmentDate} at {appointmentTime}
                      </span>
                    </div>

                    {!isOutsidePractitionerMode && (
                      <div className="flex flex-row items-center text-11 mb-[6px]">
                        <ProfileIcon className="mr-[8px]" />
                        <span>{practitionerFullName}</span>
                      </div>
                    )}

                    <div className="flex flex-row items-center text-11 mb-[6px]">
                      <ServiceIcon className="mr-[8px]" />
                      <span>{serviceName}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full h-[4rem] bg-blur-bg-bottom-2 absolute bottom-0 z-10" />
        </div>

        <div className="flex flex-row justify-end pt-[10px]">
          <CommonButton variant="secondary" className="px-2" onClick={onClose}>
            Already updated in Practice Software
          </CommonButton>
          <div className="mx-0.8" />
          <CommonButton variant="primary" onClick={onClose}>
            Skip for now
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default OutsideOfficeHoursModal;

import { FC, useMemo, useState } from 'react';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Pagination, { ITablePaginate } from 'components/Tables/Pagination';
import useClinicUser from 'hooks/useClinicUser';
import Status from './Status/Status';

import { ROLE } from 'utils/constants';
import { datetimeFormat } from 'utils/datetime';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';

interface TableSectionProps {}

const defaultTableState = {
  currentPage: 1,
  pageSize: 10,
  sortBy: 'createdAt',
  sort: 'desc' as const,
};

const TableSection: FC<TableSectionProps> = () => {
  const [currentPage, setCurrentPage] = useState(defaultTableState.currentPage);
  const [pageSize, setPageSize] = useState(defaultTableState.pageSize);

  const [sortBy, setSortBy] = useState(defaultTableState.sortBy);
  const [sort, setSort] = useState<'asc' | 'desc'>(defaultTableState.sort);
  const [highlightedRowId, setHighlighedRowId] = useState('');

  const { data, isLoading, mutate } = useClinicUser({
    limit: pageSize,
    page: currentPage,
    sort,
    sortBy,
  });

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return <div className="pl-1.7">First Name</div>;
        },
        accessor: (field: any) => {
          return <div className="pl-1.7">{field.firstName}</div>;
        },
        id: 'firstName',
        width: '120',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
        id: 'lastName',
        width: '120',
      },
      {
        Header: 'Email',
        accessor: 'clinicUser.email',
        id: 'email',
        width: '200',
      },
      {
        Header: 'Role',
        accessor: (field: any) => {
          if (field.role === ROLE.PRACTICE_ADMIN) {
            return 'Admin';
          }
          if (field.role === ROLE.PRACTICE_USER) {
            return 'User';
          }
        },
        id: 'role',
        width: '150',
      },
      {
        Header: 'Status',
        accessor: (field: any) => {
          return <Status status={field.status} />;
        },
        id: 'status',
        width: '150',
      },
      {
        Header: 'Created Date',
        accessor: (field: any) => {
          return datetimeFormat({
            dateString: field.createdAt,
            pattern: 'MMM DD, YYYY',
          });
        },
        id: 'createdAt',
        width: '200',
      },
      {
        id: 'action_btn',
        width: 25,
        Cell: ({ row }: any) => {
          return (
            <ThreeDotsOption
              data={row.original}
              mutate={mutate}
              key={highlightedRowId}
            />
          );
        },
      },
    ],
    [highlightedRowId, mutate]
  );

  const dataTable = useMemo(() => {
    if (isLoading) {
      return { data: [], metadata: null };
    }
    return { data: data.data, metadata: data.metadata };
  }, [data?.data, data?.metadata, isLoading]);

  const isNonSortableColumn = (columnId: string) =>
    ['email', 'action_btn'].includes(columnId);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data,
        manualPagination: true,
        pageCount: dataTable.metadata,
      },
      useSortBy,
      usePagination,
      useFlexLayout
    );

  const displayTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (data.metadata.total === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[24rem] flex flex-col gap-y-1.6 justify-center items-center">
                <EmptyBucket />
                <span>No users available on this list</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              onMouseEnter={() => setHighlighedRowId(row.id)}
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey px-2 group hover:bg-lightPink"
            >
              {row.cells.map((cell: any) => {
                return (
                  <td
                    className={`text-left p-0 py-1.9`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  const handlePaginate = (paginate: ITablePaginate) => {
    setCurrentPage(paginate.currPage);
    setPageSize(paginate.perPage);
  };

  const handleSortColumn = (column: HeaderGroup<object>) => {
    if (isNonSortableColumn(column.id)) return;
    setSortBy(column.id);
    if (column.id === sortBy) {
      return setSort((sort) => (sort === 'asc' ? 'desc' : 'asc'));
    }
    setSort('asc');
  };

  return (
    <>
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="px-2">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div
                      className="flex items-center gap-x-0.9 cursor-pointer"
                      onClick={() => handleSortColumn(column)}
                    >
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>
                      {!isNonSortableColumn(column.id) && (
                        <SortArrow
                          className={`${
                            column.id === sortBy && 'fill-magenta'
                          } ${
                            column.id === sortBy &&
                            sort === 'asc' &&
                            'rotate-180'
                          } transition-all`}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {displayTableBody()}
      </table>
      {dataTable?.metadata && data.metadata.total > 0 && (
        <div className="py-1.6">
          <Pagination
            isBorderNotIncluded
            handlePaginating={handlePaginate}
            data={{
              currPage: data.metadata.page,
              perPage: data.metadata.limit,
              total: data.metadata.total,
            }}
          />
        </div>
      )}
    </>
  );
};

export default TableSection;

import { createContext, useContext, useMemo, useState } from 'react';

interface VersionProviderProps {
  children: React.ReactNode;
}

const VersionContext = createContext<{
  isOldVersion: boolean;
  setIsOldVersion: React.Dispatch<React.SetStateAction<boolean>>;
} | null>(null);

const VersionProvider = ({ children }: VersionProviderProps) => {
  const [isOldVersion, setIsOldVersion] = useState(false);

  const value = useMemo(
    () => ({
      isOldVersion,
      setIsOldVersion,
    }),
    [isOldVersion]
  );

  return (
    <VersionContext.Provider value={value}>{children}</VersionContext.Provider>
  );
};

const useVersion = () => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within VersionProvider');
  }
  return context;
};

export { VersionProvider, useVersion };

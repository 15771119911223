import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import useAppointment from 'hooks/useAppointment';

import TabButton from '../TabButton/TabButton';
import Table from '../Table/Table';
import useAppointmentStatistic from 'hooks/useAppointmentStatistic';
import { useUser } from 'context/userContext';

const defaultTableState = {
  currentPage: 1,
  pageSize: 5,
  sortBy: 'createdAt',
  sort: 'asc' as const,
  status: 'new' as const,
};

const TableSection = () => {
  const [currentPage, setCurrentPage] = useState(defaultTableState.currentPage);
  const [pageSize, setPageSize] = useState(defaultTableState.pageSize);

  const [sortBy, setSortBy] = useState(defaultTableState.sortBy);
  const [sort, setSort] = useState<'asc' | 'desc'>(defaultTableState.sort);

  // only-accepted means only return appointment which has the value of status field as accepted
  const [status, setStatus] = useState<'new' | 'accepted' | 'only-accepted'>(
    defaultTableState.status
  );

  const [totalNewAppointRequests, setTotalNewAppointRequests] =
    useState<number>();

  const { data: appointmentStatistic, mutate: mutateAppointmentStatistic } =
    useAppointmentStatistic();

  const {
    user: { currentClinicUserRole },
  } = useUser();

  const startDate = moment().tz(currentClinicUserRole!.clinic.timezone);

  const { data, isLoading, mutate } = useAppointment({
    page: currentPage,
    limit: pageSize,
    startDate: startDate.startOf('days').utc(true).format(),
    sort,
    sortBy,
    status: status === 'accepted' ? 'only-accepted' : status,
    ...(status === 'accepted' && {
      endDate: startDate.endOf('days').utc(true).format(),
    }),
  });

  useEffect(() => {
    if (data && status === 'new') {
      setTotalNewAppointRequests(data?.metadata?.total);
    }
  }, [data, status]);

  const handleChangeTab = (status: 'new' | 'accepted') => {
    setStatus(status);
    setCurrentPage(defaultTableState.currentPage);
    setSortBy(status === 'new' ? defaultTableState.sortBy : 'appointmentDate');
    setSort(defaultTableState.sort);
  };

  const updateDashboardUI = useCallback(async () => {
    await mutateAppointmentStatistic();
    await mutate();
  }, [mutate, mutateAppointmentStatistic]);

  return (
    <>
      <div className="flex items-center text-14 text-darkest-grey mt-4">
        <TabButton
          isActive={status === 'new'}
          amount={totalNewAppointRequests}
          title={'New appointment requests'}
          onClick={() => {
            handleChangeTab('new');
          }}
        />
        <TabButton
          isActive={status === 'accepted'}
          amount={appointmentStatistic?.upcoming.totalInToday}
          title={'Today’s online appointments'}
          onClick={() => handleChangeTab('accepted')}
        />
      </div>

      <div className="mt-1.6 pb-10">
        <div className="bg-white shadow-primary rounded-[1rem] mt-2.4">
          <Table
            data={data}
            isLoading={isLoading}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sort={sort}
            setSort={setSort}
            status={status}
            updateDashboardUI={updateDashboardUI}
          />
        </div>
      </div>
    </>
  );
};

export default TableSection;

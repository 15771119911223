import { FC, useRef, useState } from 'react';
import moment from 'moment';
import axios from 'axios';

import DefaultModal from 'components/Modals/DefaultModal';
import CommonButton from 'components/CommonButton/CommonButton';
import { ClinicException } from 'interfaces/clinicException';
import SelectHoursRange from 'components/SelectHoursRange/SelectHoursRanger';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';

import SelectType from 'components/SelectType/SelectType';
import DateRange, { IDateRange } from 'components/DateRange/DateRange';
import ApplyOption from 'components/ApplyOption/ApplyOption';
import { ExceptionDate } from 'interfaces/exceptionDate';
import OverrideModal from '../DayTimeSlot/OverrideModal/OverrideModal';
import { WeeklyTimeslot } from '../WrapperSchedulePage';
import {
  NEXT_23_MONTH,
  SCHEDULE_TYPE,
  WORKING_HOUR_SYSTEM,
} from 'utils/constants';
import getQueryTimeSlotPerDay from 'utils/getQueryTimeSlotPerDay';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { useVersion } from '../context/versionContext';
import {
  getInitialType,
  getInitialWorkingHours,
} from 'utils/getInitialEditScheduleState';
import {
  ClinicSchedule,
  WeekWorkingHour,
  WorkingHour,
} from 'interfaces/clinicSchedule';
import useModal from 'hooks/useModal';

interface EditScheduleModalProps {
  source: string;
  version: number;
  selectedDate: ClinicException;
  onClose: () => void;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
  isCreatedMode?: boolean;
  clinicSchedule?: ClinicSchedule;
}

const options = [
  { label: 'Working Day', isWorkingDay: true },
  { label: 'Day Closed', isWorkingDay: false },
];

const EditScheduleModal: FC<EditScheduleModalProps> = ({
  source,
  version,
  selectedDate,
  onClose,
  onCheckOutsideOfficeHourAppts,
  isCreatedMode,
  clinicSchedule,
}) => {
  const { setIsAllowed } = useModal({ onClose });

  const [selectedType, setSelectedType] = useState(() =>
    getInitialType(selectedDate.workingHour, options)
  );

  const [isSingleDay, setIsSingleDay] = useState(true);

  const [workingHours, setWorkingHours] = useState(() =>
    getInitialWorkingHours(selectedDate.workingHour)
  );

  const [selectedSingleDate, setSelectedSingleDate] = useState(() =>
    moment(selectedDate.date).toDate()
  );

  const [dateRange, setDateRange] = useState<IDateRange>(() => ({
    dateFrom: moment(selectedDate.date).toDate(),
    dateTo: null,
  }));

  const dateRangePickerRef = useRef<HTMLButtonElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [exceptionDates, setExceptionDates] = useState<ExceptionDate[] | null>(
    null
  );

  const matchMutate = useMatchMutate();

  const { setIsOldVersion } = useVersion();

  const handleUpdateWorkingHours = (_: string, value: string, type: string) => {
    setWorkingHours({ ...workingHours, [type]: value });
  };

  const getChangedTimeSlots = () => {
    const changedTimeSlots: WeeklyTimeslot[] = [];

    const updateTimeSlot = {
      start: selectedType.isWorkingDay ? workingHours.start : null,
      end: selectedType.isWorkingDay ? workingHours.end : null,
    };

    changedTimeSlots.push({
      ...updateTimeSlot,
      day: moment(dateRange.dateFrom).format('ddd'),
    });

    const endDay = moment(dateRange.dateTo);

    // GET DAY RANGE
    const daysPerWeek = 7;
    for (let i = 1; i < daysPerWeek; i++) {
      const nextDay = moment(dateRange.dateFrom).add(i, 'd');
      if (nextDay.isSameOrBefore(endDay)) {
        const day = nextDay.format('ddd').toLowerCase();
        changedTimeSlots.push({ ...updateTimeSlot, day });
      }
    }

    return changedTimeSlots;
  };

  const handleExceptionChecked = async () => {
    setIsSubmitting(true);
    const changedTimeSlots = getChangedTimeSlots();

    const queryTimeSlotPerDay = getQueryTimeSlotPerDay(changedTimeSlots);

    try {
      const response = await axiosInstance.get(
        '/clinics-exception/custom-days',
        {
          params: {
            source,
            startDate: moment(dateRange.dateFrom).format('YYYY-MM-DD'),
            endDate: moment(dateRange.dateTo).format('YYYY-MM-DD'),
            ...queryTimeSlotPerDay,
          },
        }
      );
      const isExceptionDateFound = response.data.length > 0;
      if (isExceptionDateFound) {
        setIsSubmitting(false);
        setExceptionDates(response.data);
        return;
      }

      await onUpdatedSchedule();
      await onCheckOutsideOfficeHourAppts();
      await matchMutate(/\/clinics-exception[\s\S]+/);
      renderToast({
        message: 'Custom hours have been updated successfully',
        type: 'success',
      });

      onClose();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }
    }
    setIsSubmitting(false);
  };

  const handleUpdateSingle = async () => {
    setIsSubmitting(true);

    try {
      // CHECK IF SELECTED DAY IS CUSTOM DAY OF NOT
      const response = await axiosInstance.get('/clinics-exception', {
        params: {
          source,
          startDate: moment(selectedSingleDate).format('YYYY-MM-DD'),
          endDate: moment(selectedSingleDate)
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        },
      });

      const exceptionDateId = response.data[0]?.id;

      const body = [
        {
          date: moment(selectedSingleDate).format('YYYY-MM-DD'),
          isClosed: !selectedType.isWorkingDay,
          isHoliday: false,
          shifts: [
            {
              start: selectedType.isWorkingDay ? workingHours.start : null,
              end: selectedType.isWorkingDay ? workingHours.end : null,
            },
          ],
          ...(exceptionDateId && { id: exceptionDateId }),
        },
      ];

      if (exceptionDateId) {
        await axiosInstance.patch('/clinics-exception', body, {
          params: {
            source,
          },
          headers: {
            version,
          },
        });
      } else {
        await axiosInstance.post('/clinics-exception', body, {
          params: {
            source,
          },
          headers: {
            version,
          },
        });
      }
      await matchMutate(/\/clinics-exception[\s\S]+/);

      renderToast({
        message: 'Custom hours have been updated successfully',
        type: 'success',
      });
      await onCheckOutsideOfficeHourAppts();

      onClose();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    setIsAllowed(false);
    if (!isSingleDay) {
      await handleExceptionChecked();
      return;
    }
    await handleUpdateSingle();
  };

  const onUpdatedSchedule = async () => {
    const body = [];

    const startDate = moment(
      isSingleDay ? selectedSingleDate : dateRange.dateFrom
    );

    const { data } = await axiosInstance.get<ClinicException[]>(
      '/clinics-exception',
      {
        params: {
          source,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: moment(dateRange.dateTo).format('YYYY-MM-DD'),
        },
      }
    );

    for (let i = 0; i < data.length; i++) {
      const isExcpetionDate = data[i].isException;

      if (isExcpetionDate) continue;

      const day = {
        date: data[i].date,
        isClosed: !selectedType.isWorkingDay,
        isHoliday: false,
        shifts: [
          {
            start: selectedType.isWorkingDay ? workingHours.start : null,
            end: selectedType.isWorkingDay ? workingHours.end : null,
          },
        ],
      };
      body.push(day);
    }

    if (body.length === 0) return;

    await axiosInstance.post('/clinics-exception', body, {
      params: {
        source,
      },
      headers: {
        version,
      },
    });
  };

  const getButtonDisabledState = () => {
    const isDayRangeOptionNotSelect = !dateRange.dateTo && !isSingleDay;

    const isDateToSameAsDateFrom =
      !isSingleDay &&
      moment(dateRange.dateTo).dayOfYear() ===
        moment(dateRange.dateFrom).dayOfYear();

    return isDayRangeOptionNotSelect || isDateToSameAsDateFrom;
  };

  const getWorkingHoursForExceptionClosedDate = () => {
    if (!clinicSchedule) return;
    const selectedSchedule =
      clinicSchedule.currentWorkingHour === WORKING_HOUR_SYSTEM.FIRSTIN
        ? SCHEDULE_TYPE.DEFAULT
        : SCHEDULE_TYPE.GOOGLE;

    const selectedSourceData = clinicSchedule[
      selectedSchedule as keyof ClinicSchedule
    ] as WeekWorkingHour;

    const value = selectedSourceData[
      selectedDate.day as keyof WeekWorkingHour
    ]![0] as WorkingHour;

    return { start: value.start!, end: value.end! };
  };

  return (
    <>
      {exceptionDates ? (
        <OverrideModal
          exceptionsDates={exceptionDates}
          changedTimeSlots={getChangedTimeSlots()}
          onClose={() => {
            setExceptionDates(null);
            onClose();
          }}
          onBtnClose={() => setExceptionDates(null)}
          onUpdateSchedule={onUpdatedSchedule}
          onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
          source={source}
          version={version}
        />
      ) : (
        <DefaultModal className="top-[45%]">
          <div className="w-[394px] min-h-[290px] pl-3 pr-3.3 pt-2.2 pb-2.6 text-14 leading-[2.1rem] text-darkest-grey">
            <h3 className="text-16 leading-[2.4rem] font-bold">
              {isCreatedMode ? 'Add custom day(s)' : 'Edit office schedule'}
            </h3>
            <div className="flex items-center mt-1.5">
              <span className="text-13 leading-[2rem]">Type</span>
              <SelectType
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                options={options}
              />
            </div>
            <div className="mt-2.7">
              <div
                className={`flex items-center ${
                  !selectedType.isWorkingDay ? 'opacity-30 relative -z-10' : ''
                }`}
              >
                <span className="text-13 leading-[2rem]">Hours</span>
                <SelectHoursRange
                  day={selectedDate.day}
                  defaultValue={{
                    start:
                      selectedDate.isException && selectedDate.isClosed
                        ? getWorkingHoursForExceptionClosedDate()!.start
                        : workingHours.start,
                    end:
                      selectedDate.isException && selectedDate.isClosed
                        ? getWorkingHoursForExceptionClosedDate()!.end
                        : workingHours.end,
                  }}
                  start={workingHours.start}
                  end={workingHours.end}
                  onChange={handleUpdateWorkingHours}
                />
              </div>
            </div>
            <div className="mt-2.9">
              <div className="flex items-center">
                <span className="text-13 leading-[2rem]">Apply to</span>
                <ApplyOption
                  isSingleDay={isSingleDay}
                  setIsSingleDay={setIsSingleDay}
                  dateTo={dateRange.dateTo}
                  dateRangePickerRef={dateRangePickerRef}
                />
              </div>
            </div>

            <div className="mt-1.9 flex">
              {isSingleDay && (
                <CustomDatePicker
                  selected={selectedSingleDate}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setSelectedSingleDate(date);
                    }
                  }}
                  minDate={new Date()}
                  maxDate={moment().add(NEXT_23_MONTH, 'months').toDate()}
                  customInput={
                    <div className="flex items-center">
                      <span className="text-13 leading-[2rem]">Date</span>
                      <button
                        className={`w-full h-3 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active px-1.6 ml-2.4`}
                      >
                        {moment(selectedSingleDate).format('MMMM DD, YYYY')}
                      </button>
                    </div>
                  }
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [30, -2],
                      },
                    },
                  ]}
                />
              )}
              <div className={isSingleDay ? 'hidden' : 'flex'}>
                <DateRange
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  dateRangePickerRef={dateRangePickerRef}
                />
              </div>
            </div>

            <div className="mt-2.8 flex gap-x-1.6 justify-end">
              <CommonButton
                variant="secondary"
                className="!min-w-[12rem]"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </CommonButton>
              <CommonButton
                className="!min-w-[12rem]"
                onClick={handleConfirm}
                isLoading={isSubmitting}
                disabled={getButtonDisabledState()}
              >
                Confirm
              </CommonButton>
            </div>
          </div>
        </DefaultModal>
      )}
    </>
  );
};

export default EditScheduleModal;

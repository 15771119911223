import { ChangeEvent, FC } from 'react';
import moment from 'moment';
import { datetimeFormat } from 'utils/datetime';
import { ExceptionDate } from 'interfaces/exceptionDate';
import { PractitionerException } from 'interfaces/practitionerException';

export interface OptionClinicException extends ExceptionDate {
  checked: boolean;
}

export interface OptionPractitionerException
  extends Required<PractitionerException> {
  checked: boolean;
}

interface OverrideModalBodyProps {
  options: OptionClinicException[] | OptionPractitionerException[];
  selectAllRef: React.MutableRefObject<HTMLInputElement | null>;
  handleChangeCheckbox: (id: string, isChecked: boolean) => void;
  handleChangeSelectAll: (e: ChangeEvent<HTMLInputElement>) => void;
  totalSelectOptions: number;
  displayHour: (
    option: OptionClinicException | OptionPractitionerException
  ) => string;
}

const OverrideModalBody: FC<OverrideModalBodyProps> = ({
  options,
  selectAllRef,
  handleChangeCheckbox,
  handleChangeSelectAll,
  totalSelectOptions,
  displayHour,
}) => {
  const isYearInTheFuture = (dateString: string) => {
    return moment().isBefore(moment(dateString, 'YYYY-MM-DD'), 'years');
  };

  return (
    <>
      <div className="border-b-[2px] border-grey pb-0.9 mt-2.4 grid grid-cols-[70px_1fr_1fr] items-center">
        <div className="flex items-center">
          <input
            className="w-2.2 h-2.2"
            type="checkbox"
            id="selectAll"
            ref={selectAllRef}
            onChange={handleChangeSelectAll}
          />
          <span className="text-[1rem] leading-[1.8rem] ml-[6px]">
            ({totalSelectOptions})
          </span>
        </div>
        <span className="font-bold">Date</span>
        <span className="font-bold">Hours</span>
      </div>
      <div className="max-h-[28rem] scrollbar overflow-auto text-darkest-grey ">
        {options.map((option, index) => (
          <div
            key={option.id}
            className={`grid grid-cols-[70px_1fr_1fr] h-4 items-center border-b border-b-light-grey ${
              index % 2 ? 'bg-lightest-grey' : 'bg-white'
            }`}
          >
            <input
              className="w-2.2 h-2.2"
              type="checkbox"
              id={option.id}
              checked={option.checked}
              onChange={(e) =>
                handleChangeCheckbox(option.id, e.target.checked)
              }
            />
            <label htmlFor={option.id}>
              {datetimeFormat({
                dateString: option.date,
                format: 'YYYY-MM-DD',
                pattern: `ddd, MMM DD${
                  isYearInTheFuture(option.date) ? ', YYYY' : ''
                }`,
              })}
            </label>
            <span>{displayHour(option)}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default OverrideModalBody;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

const useSync = () => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    ['/sync', clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useSync;

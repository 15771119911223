import React from 'react';
import StatisticRow from '../StatisticRow/Row';

interface IProps {
  activeTotal: number;
  total: number;
}

const PracticeSection = ({ activeTotal, total }: IProps) => {
  return (
    <div className="bg-white rounded-[1rem] py-2 px-3 shadow-primary flex flex-col justify-around bg-home bg-no-repeat bg-right-bottom bg-[length:16.6rem_14.1rem]">
      <StatisticRow title="Total Active Practices" value={activeTotal} />
      <StatisticRow title="Total Practices" value={total} />
    </div>
  );
};

export default PracticeSection;

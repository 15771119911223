import React, { FC } from 'react';

import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

interface TabButtonProps {
  isActive: boolean;
  amount?: number;
  title: string;
  onClick: () => void;
}

const TabButton: FC<TabButtonProps> = ({
  title,
  isActive,
  amount,
  onClick,
}) => {
  return (
    <button
      className={`flex gap-x-0.6 py-1 px-2 rounded-[1rem] font-bold transition-[box-shadow] ${
        isActive && 'shadow-primary'
      }`}
      onClick={onClick}
    >
      {title}
      {amount === undefined ? (
        <LoadingSpinner className="font-normal text-magenta w-2.5" />
      ) : (
        <span className="font-normal text-magenta">({amount})</span>
      )}
    </button>
  );
};

export default TabButton;

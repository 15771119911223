import { ExceptionDate } from 'interfaces/exceptionDate';
import { PractitionerException } from 'interfaces/practitionerException';
import { useEffect, useRef } from 'react';

interface IUseScrollToMonthCalendar {
  data?: PractitionerException[] | ExceptionDate[];
  isNavigatedByCalendar: boolean;
  monthValue: string;
}

const useScrollToMonthCalendar = ({
  data,
  isNavigatedByCalendar,
  monthValue,
}: IUseScrollToMonthCalendar) => {
  const monthRef = useRef<HTMLDivElement[]>([]);

  // HANDLE SCROLL LOGIC
  useEffect(() => {
    if (!data || !isNavigatedByCalendar) return;

    for (let i = 0; i < monthRef.current.length; i++) {
      if (monthValue === monthRef.current[i]?.dataset.month) {
        return monthRef.current[i]?.scrollIntoView();
      }
    }
  }, [monthValue, data, isNavigatedByCalendar]);

  const register = (monthValue: string) => ({
    ref: (element: HTMLDivElement | null) => {
      if (!element) return;
      const ref = monthRef.current.find(
        (e) => element.dataset.month === e.dataset.month
      );
      if (ref) return;
      monthRef.current.push(element);
    },
    'data-month': monthValue,
  });

  return { register };
};

export default useScrollToMonthCalendar;

import React from 'react';
import classNames from 'classnames';
import { Switch } from '@headlessui/react';

interface IProps {
  isDebugMode: boolean;
  onChangeDebugMode: () => void;
}

const DebugToggleHeader = ({ isDebugMode, onChangeDebugMode }: IProps) => {
  const parentStyle = classNames({
    'bg-lightest-grey': !isDebugMode,
    'bg-main-gradient': isDebugMode,
  });

  return (
    <div
      className={`h-full w-full p-0.9 flex flex-row items-center border border-light-grey border-solid rounded-t-[10px] border-b-0 ${parentStyle} `}
    >
      <div
        className={`flex-1 text-center font-bold text-14 text-white ${
          isDebugMode ? 'visible' : 'invisible'
        }`}
      >
        Debug Mode
      </div>
      <div className="text-right">
        {isDebugMode ? (
          <span className="text-12 mr-1 text-white">Turn off Debug Mode</span>
        ) : (
          <span className="text-12 mr-1 text-darkest-grey">Debug Mode</span>
        )}

        <Switch
          checked={isDebugMode}
          onChange={onChangeDebugMode}
          className={`${
            isDebugMode ? 'bg-white' : 'bg-mid-grey'
          } relative inline-flex h-1 w-2 items-center rounded-full`}
        >
          <span
            className={`${
              isDebugMode
                ? 'translate-x-[12px] bg-magenta'
                : 'translate-x-[2px] bg-white'
            } inline-block h-[6px] w-[6px] transform rounded-full transition`}
          />
        </Switch>
      </div>
    </div>
  );
};

export default DebugToggleHeader;

import { FC } from 'react';
import { Listbox } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

interface TimeDropdownProps {
  options: number[];
  value: number;
  listBoxButtonClassName?: string;
  listBoxProps: any;
}

const TimeDropdown: FC<TimeDropdownProps> = ({
  options,
  value,
  listBoxButtonClassName,
  listBoxProps,
}) => {
  return (
    <Listbox {...listBoxProps}>
      {({ open }) => {
        return (
          <div className="relative text-14 leading-[2.1rem] mt-0.8">
            <Listbox.Button
              className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                open ? 'border-magenta shadow-input-active' : ''
              } ${listBoxButtonClassName}`}
            >
              <span
                className={`block pr-3 ${
                  value ? 'text-darkest-grey' : 'text-mid-grey'
                }`}
              >
                {value ? `${value} minutes` : 'Select PMS Unit Duration'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <DropdownIcon className="w-1.4 h-1.4" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute mt-0.5 max-h-16 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-10">
              {options?.map((option) => (
                <Listbox.Option
                  key={option}
                  className="relative cursor-default select-none pl-1.6"
                  value={option}
                >
                  <span
                    className={`block truncate cursor-pointer hover:text-magenta ${
                      value === option ? 'text-magenta' : ''
                    }`}
                  >
                    {option} minutes
                  </span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        );
      }}
    </Listbox>
  );
};

export default TimeDropdown;

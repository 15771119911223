import React from 'react';
import moment from 'moment';
import cx from 'classnames';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';

import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';

import styles from './DayViewNavigation.module.css';

interface Props {
  date: string;
  onDateChange: (date: string) => void;
}

const DayViewNavigation: React.FC<Props> = ({ date, onDateChange }) => {
  const prevClassName = cx({
    [styles['btn']]: true,
    [styles['disabled']]: moment(date, 'YYYY-MM-DD').isSame(moment(), 'days'),
  });

  const clickPrev = () => {
    const oldDate = moment(date, 'YYYY-MM-DD').subtract(5, 'days');
    if (moment().isBefore(oldDate)) {
      onDateChange(oldDate.format('YYYY-MM-DD'));
      return;
    }
    onDateChange(moment().format('YYYY-MM-DD'));
  };

  const clickNext = () => {
    onDateChange(
      moment(date, 'YYYY-MM-DD').add(5, 'days').format('YYYY-MM-DD')
    );
  };

  return (
    <div className={styles['container']}>
      <button onClick={clickPrev}>
        <LeftArrow className={prevClassName} />
      </button>

      <CustomDatePicker
        selected={moment(date, 'YYYY-MM-DD').toDate()}
        onChange={(date: Date | null) => {
          if (date) {
            onDateChange(moment(date).format('YYYY-MM-DD'));
          }
        }}
        minDate={new Date()}
        calendarClassName={styles['calendar']}
        customInput={
          <div className={styles['content']}>
            {`${moment(date, 'YYYY-MM-DD').format('MMM DD')} - ${moment(
              date,
              'YYYY-MM-DD'
            )
              .add(4, 'days')
              .format('MMM DD')}`}
          </div>
        }
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [-20, -1],
            },
          },
        ]}
      />
      <button onClick={clickNext}>
        <RightArrow className={styles['btn']} />
      </button>
    </div>
  );
};

export default DayViewNavigation;

import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

interface InfoTooltipProps {
  id: string;
  display: boolean;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ id, display }) => {
  return (
    <div className={display ? 'block' : 'hidden'}>
      <button className="-translate-y-0.2" data-for={id} data-tip="">
        <InfoIcon />
      </button>
      <ReactTooltip
        id={id}
        place="bottom"
        effect="solid"
        className="!bg-lightest-grey !border-light-grey !rounded-[0.8rem] !w-[23rem] !h-[auto] !px-[1.6rem] !py-[0.9rem] z-[9999] flex justify-center items-center  !opacity-100"
      >
        <span className="text-11 text-darkest-grey flex items-center font-normal">
          You can only sync once every 24 hours. Syncing is currently in
          progress or has already been synced within the last 24 hours. If you
          want to sync again, please come back later
        </span>
      </ReactTooltip>
    </div>
  );
};

export default InfoTooltip;

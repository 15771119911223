import React from 'react';
import { DayCellContentArg } from '@fullcalendar/react';
import moment from 'moment';

import { isNextMonth, isPastMonth } from 'utils/datetime';
import { ClinicException } from 'interfaces/clinicException';
import { isArray } from 'lodash';
import { WORKING_HOUR_EXCEPTION } from 'utils/constants';

const { CLOSED } = WORKING_HOUR_EXCEPTION;

const DayCell = (
  props: DayCellContentArg & {
    exception: ClinicException | null;
    selectedDate: string;
    onCellClicked: (date: ClinicException) => void;
    highlightedDate: string;
    resetHighlightedDate: () => void;
  }
) => {
  const formattedDate = moment(props.date).format('YYYY-MM-DD');

  const renderWorkingHour = () => {
    if (isNextMonth(formattedDate, props.selectedDate)) {
      return null;
    }

    if (props.exception === null) {
      return null;
    }

    let displayedTime = '';

    if (props.exception.isHoliday || props.exception.isClosed) {
      displayedTime =
        props.exception.holidayName || (props.exception.workingHour as string);
    } else if (isArray(props.exception.workingHour)) {
      const workingHours = props.exception.workingHour;

      if (workingHours[0]?.start && workingHours[0].end) {
        const startTime = moment(workingHours[0].start, 'HH:mm:ss').format(
          'hh:mmA'
        );
        const endTime = moment(workingHours[0].end, 'HH:mm:ss').format(
          'hh:mmA'
        );

        displayedTime = `${startTime} - ${endTime}`;
      } else if (
        workingHours[0]?.start === null &&
        workingHours[0].end === null
      ) {
        displayedTime = CLOSED;
      }
    }

    return displayedTime;
  };

  const renderDayNumber = () => {
    const firstDayOfNextMonth = moment()
      .clone()
      .add(1, 'months')
      .startOf('months');

    if (firstDayOfNextMonth.isSame(moment(props.date), 'days')) {
      return `${props.dayNumberText} ${firstDayOfNextMonth.format('MMM')}`;
    }

    return props.dayNumberText;
  };

  const workingHour = renderWorkingHour();

  const isInThisMonth =
    !isPastMonth(formattedDate, props.selectedDate) &&
    !isNextMonth(formattedDate, props.selectedDate);

  const isFutureInThisMonth = !props.isPast && isInThisMonth;

  const handleOnCellClicked = () => {
    if (!isFutureInThisMonth) return;
    props.onCellClicked(props.exception!);
  };

  return (
    <div
      className={`relative h-full flex flex-col justify-between py-[0.5rem] px-[0.7rem] cursor-pointer border ${
        props.highlightedDate === props.exception?.date
          ? 'border-magenta'
          : 'border-transparent'
      } ${
        isFutureInThisMonth &&
        (workingHour === CLOSED || props.exception?.isHoliday)
          ? 'bg-lightPink'
          : '!bg-white'
      } ${
        isFutureInThisMonth && props.exception !== null
          ? 'hover:border-magenta'
          : 'cursor-not-allowed'
      }`}
      onClick={handleOnCellClicked}
      onMouseEnter={() => {
        if (props.highlightedDate && isFutureInThisMonth) {
          props.resetHighlightedDate();
        }
      }}
      onMouseLeave={() => {
        if (props.highlightedDate === props.exception?.date) {
          props.resetHighlightedDate();
        }
      }}
    >
      <div
        className={`font-bold text-right ${
          isFutureInThisMonth ? 'text-darkest-grey' : 'text-grey'
        } ${
          props.isToday
            ? 'bg-magenta w-[24px] h-[24px] leading-[24px] border-magenta rounded-full !text-center !text-white self-end'
            : ''
        }`}
      >
        {!props.exception?.isHoliday &&
          !props.exception?.isClosed &&
          props.exception?.isException &&
          isFutureInThisMonth && (
            <div className="absolute left-[7px] bg-lightBlue w-[6px] h-[6px] rounded-full" />
          )}
        <div>{renderDayNumber()}</div>
      </div>
      {isFutureInThisMonth && (
        <div className="text-center font-normal">{workingHour}</div>
      )}
    </div>
  );
};

export default DayCell;

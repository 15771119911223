import { useEffect, useState } from 'react';

interface IUseModalParams {
  onClose: () => void;
  isLoading?: boolean;
}

const useModal = ({ onClose, isLoading }: IUseModalParams) => {
  const [isAllowed, setIsAllowed] = useState(true);

  useEffect(() => {
    const element = document.querySelector(`div[data-first-in-modal='true']`);
    if (!isAllowed || !element || isLoading) return;

    const onClickOutside = (e: MouseEvent) => {
      if (!element.contains(e.target as Node)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', onClickOutside);
    return () => {
      window.removeEventListener('mousedown', onClickOutside);
    };
  }, [isAllowed, isLoading, onClose]);

  useEffect(() => {
    if (!isAllowed) return;

    const onEscapeButtonClicked = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', onEscapeButtonClicked);
    return () => {
      window.removeEventListener('keydown', onEscapeButtonClicked);
    };
  }, [isAllowed, onClose]);

  return { setIsAllowed, isAllowed };
};

export default useModal;

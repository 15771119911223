import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import { debounce, sortBy } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
import { ClinicUserRole, useUser } from 'context/userContext';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';
import { ROLE } from 'utils/constants';
import {
  clearIsPageDirty,
  clearStorage,
  getIsPageDirty,
  loadAuthToken,
  saveRole,
  saveSelectedClinicId,
} from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';

import LeavePageModal from 'components/LeavePageModal/LeavePageModal';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { isInAdminPage } from 'utils/common';
import CurrentView from './CurrentView';
import GlobalAdminOption from './GlobalAdminOption';
import ClinicOptions from './ClinicOptions';
import MenuButton from './MenuButton';

const ClinicMenu = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { user, dispatch } = useUser();
  const { currentClinicUserRole, globalAdminRole, clinicUserRoles } = user;

  const [searchingValue, setSearchingValue] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isChecking, setIsChecking] = useState(false);

  const [selectedClinicUserRole, setSelectedClinicUserRole] =
    useState<ClinicUserRole | null>(null);

  const navigate = useNavigate();

  const location = useLocation();

  const getInitialSearchingClinicUserRoles = () => {
    if (currentClinicUserRole) {
      return clinicUserRoles.filter(
        (item) => item.clinic?.id !== currentClinicUserRole.clinic?.id
      );
    }

    return clinicUserRoles;
  };

  const [searchingClinicUserRoles, setSearchingClinicUserRoles] = useState<
    ClinicUserRole[]
  >(() => {
    return getInitialSearchingClinicUserRoles();
  });

  const refreshToken = loadAuthToken()?.refreshToken;

  const handleLogout = async () => {
    if (getIsPageDirty()) {
      return setIsModalOpen(true);
    }

    navigate(ROUTES.LOGIN);
    clearStorage();
    dispatch({ type: 'logout' });

    try {
      await axiosInstance.post('/clinic-user/auth/logout', null, {
        headers: {
          Authorization: `JWT ${refreshToken}`,
        },
      });
    } catch (error) {}
  };

  const handleOnModalSubmit = () => {
    clearIsPageDirty();

    if (selectedClinicUserRole) {
      handleChangeClinic(selectedClinicUserRole);
      return;
    } else {
      handleChangeClinic(null);
      return;
    }
  };

  const handleChangeClinic = (clinicUserRole: ClinicUserRole | null) => {
    if (getIsPageDirty()) {
      setSelectedClinicUserRole(clinicUserRole);
      return setIsModalOpen(true);
    }

    if (clinicUserRole === null) {
      // navigate to admin dashboard
      saveSelectedClinicId('');
      saveRole(ROLE.GLOBAL_ADMIN);
      dispatch({
        type: 'changeClinic',
        payload: { clinicId: '' },
      });

      if (!isInAdminPage(location)) {
        navigate(ADMIN_ROUTES.DASHBOARD);
        return;
      }
    } else {
      // navigate to practice dashboard
      if (clinicUserRole.clinic) {
        const clinicId = clinicUserRole.clinic.id;

        saveSelectedClinicId(clinicId);

        dispatch({ type: 'changeClinic', payload: { clinicId } });
      }

      const role = clinicUserRole.role;
      saveRole(role);

      if (isInAdminPage(location)) {
        navigate(ROUTES.DASHBOARD);
        return;
      }
    }
  };

  const clearSearchClinic = () => {
    setSearchingValue('');
    debounceFn('');
  };

  const handleCheckUserProfile = async (isDropdownOpen: boolean) => {
    if (isDropdownOpen) return;
    setIsChecking(true);
    clearSearchClinic();
    try {
      const response = await axiosInstance.get('/clinic-user/auth/profile');
      if (response.data.clinicUserRoles.length === 0) {
        return handleLogout();
      }

      const sortedClinicUserRoles = sortBy(
        response.data.clinicUserRoles,
        (clinicUserRole) => clinicUserRole.clinic?.name
      );

      response.data.clinicUserRoles = sortedClinicUserRoles;

      dispatch({ type: 'getUserProfile', payload: response.data });
    } catch (error) {}
    setIsChecking(false);
  };

  const debounceFn = debounce((value) => {
    const regex = new RegExp(value, 'gi');

    const searchingResults = clinicUserRoles.filter((item) => {
      if (!item.clinic) {
        return true;
      }

      return (
        item.clinic.name.search(regex) !== -1 &&
        item.clinic.id !== currentClinicUserRole?.clinic.id
      );
    });

    setSearchingClinicUserRoles(searchingResults);
  }, 100);

  const searchClinic = (event: any) => {
    const value = event.target.value;
    setSearchingValue(value);
    debounceFn(value);
  };

  return (
    <>
      {isModalOpen && (
        <LeavePageModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleOnModalSubmit}
        />
      )}

      <Menu
        as={'div'}
        className={`flex gap-x-1 items-center overflow-hidden ${
          isCollapsed ? 'pl-0' : 'pl-1'
        }`}
      >
        {({ open }) => {
          return (
            <>
              <Menu.Button
                className={`w-full p-0.8 focus:bg-light-grey-secondary rounded-[10rem] hover:bg-light-grey-secondary focus:outline-none ${
                  open ? 'bg-light-grey-secondary' : ''
                }`}
                onClick={() => handleCheckUserProfile(open)}
              >
                <MenuButton
                  currentClinicUserRole={currentClinicUserRole}
                  globalAdminRole={globalAdminRole}
                  isCollapsed={isCollapsed}
                />
              </Menu.Button>
              <Menu.Items
                className={`absolute top-1 ${
                  isCollapsed ? 'left-5.4' : 'left-19'
                } z-[9999] mt-0.8 bg-white rounded-[1rem] shadow-primary flex flex-col items-start w-[30rem] overflow-hidden p-1.6 focus:outline-none  ${
                  searchingClinicUserRoles.length > 5 ? 'h-[48rem]' : 'h-auto'
                }`}
                onScroll={(event: any) => {
                  event.stopPropagation();
                }}
              >
                {isChecking ? (
                  <>
                    <div className="m-auto">
                      <LoadingSpinner className="all-child:fill-magenta" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex flex-row item-centers mb-[1.6rem] w-full">
                      <SearchIcon className="mr-[1rem]" width={20} />
                      <input
                        value={searchingValue}
                        placeholder="Search ..."
                        className="text-14 placeholder-mid-grey focus:outline-none focus-visible:outline-none w-full"
                        onChange={searchClinic}
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      {searchingValue && (
                        <CancelIcon
                          className="ml-[1rem] cursor-pointer stroke-white"
                          width={20}
                          onClick={clearSearchClinic}
                        />
                      )}
                    </div>
                    <CurrentView
                      currentClinicUserRole={currentClinicUserRole}
                      globalAdminRole={globalAdminRole}
                      searchingValue={searchingValue}
                    />

                    <GlobalAdminOption
                      currentClinicUserRole={currentClinicUserRole}
                      globalAdminRole={globalAdminRole}
                      searchingValue={searchingValue}
                      onClick={() => handleChangeClinic(null)}
                    />
                    <ClinicOptions
                      clinicUserRoles={searchingClinicUserRoles}
                      searchingValue={searchingValue}
                      onClick={(clinicUserRole) =>
                        handleChangeClinic(clinicUserRole)
                      }
                    />
                  </>
                )}
              </Menu.Items>
            </>
          );
        }}
      </Menu>
    </>
  );
};

export default ClinicMenu;

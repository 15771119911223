import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';

const getChangedTimeSlots = (
  currentTimeSlots: WeeklyTimeslot[],
  cachedTimeSlots: WeeklyTimeslot[]
) => {
  const diff = currentTimeSlots.filter(
    (timeSlot, index) =>
      timeSlot.start !== cachedTimeSlots[index].start ||
      timeSlot.end !== cachedTimeSlots[index].end
  );
  return diff;
};

export default getChangedTimeSlots;

import React from 'react';
import CommonButton from 'components/CommonButton/CommonButton';

interface IProps {
  handleConfirmation: (status: boolean) => void;
  title: string;
  description: string;
  isLoading?: boolean;
  confirmMessage?: string;
}
const ConfirmationCard = (props: IProps) => {
  const {
    title,
    description,
    handleConfirmation,
    isLoading,
    confirmMessage = 'Yes',
  } = props;

  return (
    <div className="pt-2.4 p-3.2 w-[500px]">
      <div className="font-bold text-20 mb-1.7">{title}</div>
      <div className="text-16 mb-3">{description}</div>
      <div className="flex justify-end items-center text-14">
        <CommonButton
          variant="secondary"
          onClick={() => handleConfirmation(false)}
        >
          Cancel
        </CommonButton>
        <div className="mx-0.8" />
        <CommonButton
          variant="primary"
          onClick={() => handleConfirmation(true)}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {confirmMessage}
        </CommonButton>
      </div>
    </div>
  );
};

export default ConfirmationCard;

import { FC } from 'react';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import useModal from 'hooks/useModal';

interface LeavePageModalProps {
  onClose: () => void;
  onSubmit: () => void;
}

const LeavePageModal: FC<LeavePageModalProps> = ({ onClose, onSubmit }) => {
  useModal({ onClose });

  return (
    <DefaultModal>
      <div className="w-48 p-2 text-darkest-grey normal-case">
        <h3 className="text-20 leading-[3rem] font-bold">Leave page?</h3>
        <p className="text-14 leading-[2.1rem] mt-1.6">
          Are you sure you want to leave this page? Changes that you made may
          not be saved.
        </p>
        <div className="flex justify-end gap-x-1.6 mt-2.4">
          <CommonButton variant="secondary" onClick={onClose}>
            Cancel
          </CommonButton>
          <CommonButton onClick={onSubmit}>Yes, leave</CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default LeavePageModal;

import { FC, useState } from 'react';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as PasswordShowIcon } from 'assets/icons/password-show.svg';
import { ReactComponent as PasswordHideIcon } from 'assets/icons/password-hide.svg';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

interface PasswordFieldProps {
  control: any;
  id: string;
  placeholder: string;
  label?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  defaultValue?: string;
  displayedRequiredIcon?: boolean;
}

const PasswordField: FC<PasswordFieldProps> = ({
  id,
  label = 'Password',
  placeholder,
  displayedRequiredIcon,
  ...props
}) => {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div className="flex flex-col gap-y-0.8">
      {label && (
        <label
          className="text-darkest-grey text-16 leading-[2.4rem] font-bold"
          htmlFor={id}
        >
          {label}{' '}
          {displayedRequiredIcon && <span className="text-red-01">*</span>}
        </label>
      )}
      <Controller
        name={id}
        {...props}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <div className="relative">
                <input
                  id={id}
                  type={isHidden ? 'password' : 'text'}
                  className={`w-full h-4.8 text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey ${
                    error?.message
                      ? 'shadow-input-error border-[#f44336] hover:border-[#f44336] hover:shadow-input-error focus:border-[#f44336] focus:shadow-input-error'
                      : 'shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active'
                  }`}
                  placeholder={placeholder}
                  {...field}
                />
                <button
                  type="button"
                  className="absolute right-1.7 top-1.3"
                  onClick={() => setIsHidden((isHidden) => !isHidden)}
                >
                  {isHidden ? <PasswordShowIcon /> : <PasswordHideIcon />}
                </button>
              </div>
              {error?.message && (
                <div className={`flex items-center gap-x-1 mt-0.2`}>
                  <WarningIcon />
                  <span className="text-12 text-red-01">{error.message}</span>
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default PasswordField;

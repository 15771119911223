import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import NavigationBar from 'components/NavigationBar/NavigationBar';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PasswordForm from '../../components/PasswordForm/PasswordForm';

import axiosInstance from 'apis/axiosInstance';
import ResultPage from './ResultPage/ResultPage';
import { renderToast } from 'components/Toast/Toast';

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [isVerifying, setIsVerifying] = useState(true);
  const [isSearchParamsInvalid, setIsSearchParamsInvalid] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const email = searchParams.get('email');
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      if (!email || !token) {
        setIsSearchParamsInvalid(true);
        setIsVerifying(false);
        return;
      }

      try {
        await axiosInstance.post('/clinic-user/auth/reset-password/verify', {
          email,
          token,
        });
      } catch (error) {
        setIsSearchParamsInvalid(true);
      }

      setIsVerifying(false);
    };

    verifyToken();
  }, [email, token]);

  const onSubmit = async (data: {
    password: string;
    confirmPassword: string;
  }) => {
    try {
      await axiosInstance.post('/clinic-user/auth/reset-password', {
        token,
        email,
        password: data.password,
      });
      setIsSubmitSuccessful(true);
    } catch (error) {
      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  const isResetPasswordFormDisplayed =
    !isVerifying && !isSearchParamsInvalid && !isSubmitSuccessful;

  return (
    <>
      <NavigationBar />
      {isVerifying && <LoadingScreen />}
      {isSearchParamsInvalid && <ResultPage isSuccess={false} />}
      {isSubmitSuccessful && <ResultPage isSuccess={true} />};
      {isResetPasswordFormDisplayed && (
        <PasswordForm
          headerComponent={
            <div className="px-3 pt-1.6 pb-2.1 border-b border-light-grey">
              <h3 className="font-bold text-[2rem] leading-[3rem]">
                Reset your password
              </h3>
              <p className="mt-0.8">
                A request has been made to reset your account password using
                your email address <span className="text-magenta">{email}</span>
              </p>
            </div>
          }
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default ResetPasswordPage;

import { FC, useState } from 'react';
import moment from 'moment';
import { AppointmentWithColorCode } from '../AppointmentList';
import { calculateAge } from 'utils/common';
import CardDetail from 'pages/SchedulePage/TimeGrid/CardDetail/CardDetail';
import getPractitionerName from 'utils/getPractitionerName';

interface AppointmentItemProps {
  appointment: AppointmentWithColorCode;
  selectedAppointmentId: string;
  setSelectedAppointmentId: React.Dispatch<React.SetStateAction<string>>;
  index: number;
}

const AppointmentItem: FC<AppointmentItemProps> = ({
  appointment,
  selectedAppointmentId,
  setSelectedAppointmentId,
  index,
}) => {
  const {
    startTime,
    endTime,
    appointmentDate,
    doctor,
    patient,
    service,
    createdAt,
    colorCode,
    backgroundColorCode,
    id,
  } = appointment;

  const [className, setClassName] = useState('');

  const onSelectedAppointment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const containerScrollbar = e.currentTarget.parentElement!.parentElement!;

    const heightContent = containerScrollbar.offsetHeight;

    const itemHeight = 114;

    const isItemOverflown = (index + 1) * itemHeight > heightContent;

    setSelectedAppointmentId(id);

    setClassName(isItemOverflown ? 'fixed top-[75%]' : '');
  };

  const onClose = () => setSelectedAppointmentId('');

  return (
    <>
      {selectedAppointmentId === id && (
        <CardDetail
          className={`translate-x-[78%] cursor-auto ${className}`}
          appointmentId={selectedAppointmentId}
          onClose={onClose}
        />
      )}
      <div
        className="min-h-[11.4rem] flex cursor-pointer"
        onClick={onSelectedAppointment}
      >
        <div className="basis-[8rem] flex flex-col bg-lightest-grey items-center justify-center ">
          <span className="text-11 leading-[1.8rem]">
            {moment(appointmentDate, 'YYYY-MM-DD').format('ddd')}
          </span>
          <span className="font-bold">
            {moment(appointmentDate, 'YYYY-MM-DD').format('MMM DD')}
          </span>
          <span className="mt-1.1 text-11 leading-[1.8rem]">
            {moment(startTime, 'HH:mm:ss').format('hh:mmA')}
          </span>
          <span className="mt-1.1 text-11 leading-[1.8rem]">
            {moment(endTime, 'HH:mm:ss').format('hh:mmA')}
          </span>
        </div>
        <div className="flex-1 flex flex-col text-13 leading-[1.8rem] pt-1.1 pb-0.9 pl-1.4 pr-1">
          {doctor ? (
            <span
              className="text-[1rem] self-start border-[0.3px] px-[9.5px] rounded-full"
              style={{
                borderColor: colorCode,
                color: colorCode,
                backgroundColor: backgroundColorCode,
              }}
            >
              {getPractitionerName(doctor)}
            </span>
          ) : (
            <span className="text-[1rem] self-start border-[0.3px] px-[9.5px] rounded-full text-[#767676] border-[#888] bg-lightest-grey">
              Unknown practitioner
            </span>
          )}
          {patient && (
            <span className="mt-0.6 font-bold">
              {patient.name}, {calculateAge(patient?.dob, 'YYYY-MM-DD')}
            </span>
          )}{' '}
          {service && (
            <span className="mt-0.4 leading-[2rem]">{service.name}</span>
          )}
          <span className="mt-auto text-[1rem]">
            Requested on {moment(createdAt).format('MMM DD, hh:mmA')}
          </span>
        </div>
      </div>
    </>
  );
};

export default AppointmentItem;

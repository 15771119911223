import React, { FC } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';

interface AppointmentSectionProps {
  title: string;
  value?: number | string;
  isLoading?: boolean;
  isMarginTopIncluded?: boolean;
}

const AppointmentSection: FC<AppointmentSectionProps> = ({
  title,
  value,
  isLoading,
  isMarginTopIncluded,
}) => {
  return (
    <div
      className={`flex flex-col gap-y-0.6 ${isMarginTopIncluded && 'mt-5.6'}`}
    >
      <h4 className="text-16 font-bold leading-[2.4rem]">{title}</h4>
      {isLoading ? (
        <LoadingSpinner className="w-3.1 h-3.6 text-30 font-bold text-magenta" />
      ) : (
        <span className="text-30 font-bold leading-[3.6rem] text-magenta">
          {value}
        </span>
      )}
    </div>
  );
};

export default AppointmentSection;

import useSWR from 'swr';
import { IPractitioner } from 'interfaces/practitioners';
import { loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';

interface IUserClinicPractitionerParams {
  serviceId?: string;
  shouldRun?: boolean;
  isIgnoredPractitionerTemporary?: boolean;
  sortBy?: string;
  sort?: string;
}

const useClinicPractitioner = ({
  shouldRun,
  isIgnoredPractitionerTemporary = true,
  ...rest
}: IUserClinicPractitionerParams) => {
  const clinicId = loadSelectedClinicId();

  const query = new URLSearchParams(rest as any).toString();

  const url = `/clinics/${clinicId}/practitioners${query ? `?${query}` : ''}`;

  const { data: result, error } = useSWR(
    shouldRun ? url : null,
    async (url) => {
      const response = await axiosInstance.get<{
        data: IPractitioner[];
        metadata: { total: number };
      }>(url);

      return response.data;
    }
  );

  const normalizedData = result && {
    ...result,
    data: result.data.filter((item) => {
      if (isIgnoredPractitionerTemporary) {
        return item.isTemporary === false;
      }

      return item;
    }),
  };

  return {
    data: normalizedData,
    error,
    isLoading: !result && !error,
  };
};

export default useClinicPractitioner;

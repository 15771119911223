import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

interface IUseClinicUserParams {
  limit?: number;
  page?: number;
  sort?: 'asc' | 'desc';
  sortBy?: string;
}

const useClinicUser = ({
  limit = 5,
  page = 1,
  sort = 'desc',
  sortBy = 'createdAt',
}: IUseClinicUserParams) => {
  const params = {
    limit: limit.toString(),
    page: page.toString(),
    sort,
    sortBy,
  };

  const query = new URLSearchParams(params).toString();
  const url = `/clinic-users?${query}`;
  const clinicId = loadSelectedClinicId();

  const { data, error, mutate } = useSWR(
    [url, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);

      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useClinicUser;

import React from 'react';
import useAppointmentStatistic from 'hooks/useAppointmentStatistic';
import AppointRow from '../AppointRow/AppointRow';

import { PastAppointmentStatistic } from 'interfaces/appointmentStatistic';

const PAST_DATA = [
  {
    title: 'Past 7 days',
    property: 'totalInPast7days' as keyof PastAppointmentStatistic,
  },
  {
    title: 'Past 30 days',
    property: 'totalInPast30days' as keyof PastAppointmentStatistic,
  },
];

const PastAppointSection = () => {
  const { data, isLoading } = useAppointmentStatistic();

  const getTotalAppointForBothPatients = (
    property: keyof PastAppointmentStatistic
  ) => {
    if (!data) return;
    const totalAppointForBothPatinets = Object.values(
      data.past[property]
    ).reduce((previous, current) => previous + current, 0);
    return totalAppointForBothPatinets;
  };

  const getAppointForNewOrOldPatients = (
    property: keyof PastAppointmentStatistic,
    type: 'new' | 'old'
  ) => {
    if (!data) return;
    if (type === 'new') {
      return data.past[property].newPatient === 0
        ? '-'
        : data.past[property].newPatient;
    }
    return data.past[property].oldPatient === 0
      ? '-'
      : data.past[property].oldPatient;
  };

  return (
    <div className="bg-white shadow-primary px-3 py-2">
      <h4 className="text-16 font-bold leading-[2.4rem] ">
        Past online appointments
      </h4>
      <main className="text-14 leading-[2.1rem] text-darkest-grey font-normal flex flex-col mt-1.6">
        {PAST_DATA.map(({ title, property }, index) => {
          return (
            <div
              key={title}
              className={`flex flex-col gap-y-0.8 ${index === 1 && 'mt-2.8'}`}
            >
              <AppointRow
                title={title}
                value={getTotalAppointForBothPatients(property)}
                isBold
                isLoading={isLoading}
              />
              <AppointRow
                title="New Patients"
                value={getAppointForNewOrOldPatients(property, 'new')}
                isPaddingIncluded
                isLoading={isLoading}
              />
              <AppointRow
                title="Existing Patients"
                value={getAppointForNewOrOldPatients(property, 'old')}
                isPaddingIncluded
                isLoading={isLoading}
              />
            </div>
          );
        })}
      </main>
    </div>
  );
};

export default PastAppointSection;

import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as MailOutline } from 'assets/icons/mail_outline.svg';
import { ReactComponent as PhoneIphone } from 'assets/icons/phone_iphone.svg';
import { ReactComponent as Cake } from 'assets/icons/cake.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-outline.svg';
import { parsePhoneNumber } from 'libphonenumber-js';

import styles from './PatientCard.module.css';
import { DATE_TIME_FORMAT, datetimeFormat } from 'utils/datetime';
import { IPmsPatient } from 'interfaces/patients';
import { calculateAge, capitalizeAvatarName } from 'utils/common';
import { checkAndGetCorrectPhoneNumberForSearch } from 'utils/formatPhoneNumber';

interface PatientCardProps {
  fullName: string;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phoneNumber: string;
  isSelected?: boolean;
  isNewPatient?: boolean;
  pmsPatient?: IPmsPatient;
}

const PatientCard: FC<PatientCardProps> = ({
  dob,
  email,
  fullName,
  firstName,
  lastName,
  phoneNumber,
  children,
  isSelected,
  isNewPatient,
  pmsPatient,
}) => {
  const avatarName = capitalizeAvatarName(
    `${firstName.replace(/\s/g, '').trim()} ${lastName
      .replace(/\s/g, '')
      .trim()}`
  );

  const getParsedPhoneNumber = () => {
    try {
      const parsedPhoneNumber = parsePhoneNumber(`+${phoneNumber}`);
      return parsedPhoneNumber;
    } catch (error) {
      return null;
    }
  };

  const parsedPhoneNumber = getParsedPhoneNumber();

  const formatPhoneNumber = parsedPhoneNumber
    ? `${
        parsedPhoneNumber.countryCallingCode
      } ${parsedPhoneNumber.formatNational()}`
    : phoneNumber;

  const isPmsEmailDiff = pmsPatient && pmsPatient.email !== email;
  const isPmsPhoneNumberDiff =
    pmsPatient &&
    checkAndGetCorrectPhoneNumberForSearch(pmsPatient.phoneNumber) !==
      checkAndGetCorrectPhoneNumberForSearch(phoneNumber);
  const isPmsDobDiff = pmsPatient && pmsPatient.dob !== dob;

  const formattedDob = dob
    ? calculateAge(dob, ['MMM DD, YYYY', 'YYYY-MM-DD'])
    : '';

  return (
    <div
      className={`min-h-10 py-1 rounded-[1rem] shadow-primary border border-lightest-grey w-[45rem] relative ${
        isSelected && 'border-magenta'
      }`}
    >
      {children}
      <div className="flex items-center h-full gap-x-[8rem]">
        <div className="flex flex-col justify-center items-center gap-y-0.5 pl-1.4 w-[14.2rem]">
          <div className="w-3.2 h-3.2 rounded-full bg-magenta text-white flex items-center justify-center">
            {avatarName}
          </div>
          <span className="font-bold text-12 leading-[1.8rem] w-[10rem] text-center">
            {fullName}
            {formattedDob ? `, ${formattedDob}` : ''}
          </span>
        </div>
        <div className="flex flex-col text-darkest-grey gap-y-0.6 text-11 leading-[1.8rem]">
          <div className="flex gap-x-0.8">
            {isPmsEmailDiff && (
              <ReactTooltip
                id={`email-${pmsPatient.id}`}
                place="bottom"
                effect="solid"
                className={`${styles.tooltip}`}
              >
                <p className="text-darkest-grey text-11 leading-[1.8rem] py-1.5 pl-1.6">
                  This email exists in the Practice Software and is different
                  than what the patient used to schedule on FirstIn. We will
                  continue to communicate with the patient using the FirstIn
                  email and leave the Practice Software email as is.
                </p>
              </ReactTooltip>
            )}
            <span
              {...(isPmsEmailDiff && {
                'data-for': `email-${pmsPatient.id}`,
              })}
              data-tip
            >
              <MailOutline
                className={`w-1.4 h-1.4 ${
                  isPmsEmailDiff ? styles.different : styles.icon
                }`}
              />
            </span>
            <span>{email}</span>
          </div>
          <div className="flex gap-x-0.8 ">
            {isPmsPhoneNumberDiff && (
              <ReactTooltip
                id={`phoneNumber-${pmsPatient.id}`}
                place="bottom"
                effect="solid"
                className={`${styles.tooltip}`}
              >
                <p className="text-darkest-grey text-11 leading-[1.8rem] py-1.5 pl-1.6">
                  This phone number exists in the Practice Software and is
                  different than what the patient used to schedule on FirstIn.
                  We will continue to communicate with the patient using the
                  FirstIn phone number and leave the Practice Software email as
                  is.
                </p>
              </ReactTooltip>
            )}
            <span
              {...(isPmsPhoneNumberDiff && {
                'data-for': `phoneNumber-${pmsPatient.id}`,
              })}
              data-tip
            >
              <PhoneIphone
                className={`w-1.4 h-1.4 ${
                  isPmsPhoneNumberDiff ? styles.different : styles.icon
                }`}
              />
            </span>
            <span>{formatPhoneNumber}</span>
          </div>
          <div className="flex gap-x-0.8 ">
            {isPmsDobDiff && (
              <ReactTooltip
                id={`dob-${pmsPatient.id}`}
                place="bottom"
                effect="solid"
                className={`${styles.tooltip}`}
              >
                <p className="text-darkest-grey text-11 leading-[1.8rem] py-1.5 pl-1.6">
                  This dob exists in the Practice Software and is different than
                  what the patient used to schedule on FirstIn. We will continue
                  to communicate with the patient using the FirstIn dob and
                  leave the Practice Software email as is.
                </p>
              </ReactTooltip>
            )}
            <span
              {...(isPmsDobDiff && {
                'data-for': `dob-${pmsPatient.id}`,
              })}
              data-tip
            >
              <Cake
                className={`w-1.4 h-1.4 ${
                  isPmsDobDiff ? styles.different : styles.icon
                }`}
              />
            </span>
            <span>
              {datetimeFormat({
                dateString: dob,
                format: ['MMM DD, YYYY', 'YYYY-MM-DD'],
                pattern: DATE_TIME_FORMAT.DOB,
              })}
            </span>
          </div>
        </div>
      </div>

      {isNewPatient && (
        <div className="pl-1.4 flex flex-row mt-0.6">
          <WarningIcon className="w-2 h-2 mr-0.6 all-child:fill-secondary-red mr-0.6" />
          <div className="text-11 text-secondary-red">
            Cannot select this patient. Please add this patient to the family
            file in your practice software before matching.
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientCard;

import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { Popover } from '@headlessui/react';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import CopyPanel from './CopyPanel/CopyPanel';

interface CopyTimeSlotProps {
  day: string;
  start: string;
  end: string;
  onCopy: (timeSlots: WeeklyTimeslot[]) => void;
  timeSlots: WeeklyTimeslot[];
}

const CopyTimeSlot: FC<CopyTimeSlotProps> = ({
  day,
  timeSlots,
  start,
  end,
  onCopy,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const triggerButtonRef = useRef<HTMLButtonElement>(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const position = triggerButtonRef.current!.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    const copyPanelHeight = 346;
    const elementHeight = position.top + 26;
    const isOverflow = elementHeight + copyPanelHeight >= screenHeight;
    setIsOverflow(isOverflow);
  }, []);

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            {/* BYPASS FOCUS CAUSED BY REACT-TOOL-TIP */}
            <button
              className={`ml-0.7 p-0.5 flex justify-center items-center hover:bg-[#F5F0F5] focus:bg-[#F5F0F5] outline-none ${
                open ? 'bg-[#F5F0F5]' : ''
              }`}
              data-for={`copy-time-${day}`}
              data-tip=""
              onClick={() => {
                buttonRef.current?.click();
              }}
              ref={triggerButtonRef}
            >
              <CopyIcon className="fill-darkest-grey w-1.6 h-1.6" />
            </button>
            <Popover.Button as="button" className="hidden" ref={buttonRef} />
            <Popover.Panel
              className={`w-15 absolute mt-1.1 bg-white rounded-[1rem] shadow-primary text-darkest-grey text-12 leading-[1.8rem] z-50 ${
                isOverflow ? '-translate-y-[113%]' : ''
              }`}
            >
              <CopyPanel
                key={`${open}`}
                day={day}
                start={start}
                end={end}
                onCopy={onCopy}
                timeSlots={timeSlots}
                onClose={close}
              />
            </Popover.Panel>
            <ReactTooltip
              id={`copy-time-${day}`}
              place="bottom"
              effect="solid"
              className="!bg-lightest-grey !border-light-grey !rounded-[0.8rem] !min-w-[14rem] !h-[2.6rem] !px-[1rem] !py-[0.4rem] !opacity-100 z-[9999] flex justify-center items-center"
            >
              <span className="text-11 text-darkest-grey flex items-center font-normal">
                Copy times for {moment(capitalize(day), 'ddd').format('dddd')}
              </span>
            </ReactTooltip>
          </>
        )}
      </Popover>
    </>
  );
};

export default CopyTimeSlot;

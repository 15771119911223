import CommonButton from 'components/CommonButton/CommonButton';
import Table from './Table/Table';
import useMatchMutate from 'hooks/useMatchMutate';
import { useState } from 'react';
import CreateOrEditService, {
  CreateOrEditServiceSubmitData,
} from 'pages/ServicesPage/Table/CreateOrEditService/CreateOrEditService';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import { convertDurationForBE } from 'utils/convertDuration';

const AdminServicePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSortingEnabled, setIsSortingEnable] = useState(false);

  const matchMutate = useMatchMutate();

  const OnCreateService = async (data: CreateOrEditServiceSubmitData) => {
    try {
      await axiosInstance.post(`/services`, {
        ...data,
        duration: convertDurationForBE(data.duration!),
        isActive: data.status === 'active',
        isPracticeActive: data.isPracticeActive,
      });
      await matchMutate(/\/services\/global-admin\?[^/]+/);
      renderToast({
        type: 'success',
        message: 'Added a service successfully',
      });
      setIsModalOpen(false);
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error.response?.status === BAD_REQUEST) {
        message = error.response?.data.message;
      }

      renderToast({
        type: 'error',
        message,
      });
    }
  };

  return (
    <>
      {isModalOpen && (
        <CreateOrEditService
          isGlobalAdmin
          isCreateMode
          onClose={() => setIsModalOpen(false)}
          onSubmit={OnCreateService}
        />
      )}
      <div className="py-2.4 pl-1.8 pr-4 text-14 leading-[2.1rem] text-darkest-grey relative">
        <div className="flex flex-row justify-between">
          <h3 className="text-24 font-bold leading-[3.6rem]">Services</h3>
        </div>

        <div className="flex mt-0.8">
          <CommonButton
            className="w-18 !min-h-[3.2rem]"
            variant="secondary"
            onClick={() => setIsModalOpen(true)}
          >
            New Service
          </CommonButton>
        </div>

        <div className="bg-white shadow-primary rounded-[1rem] mt-2">
          <Table
            isSortingEnabled={isSortingEnabled}
            setIsSortingEnable={setIsSortingEnable}
          />
        </div>
      </div>
    </>
  );
};

export default AdminServicePage;

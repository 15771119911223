import moment from 'moment';
import { FC } from 'react';
import { getAppointmentStartTime } from '../utils/getAppointmentTime';

interface AppointmentDateTimeColumnProps {
  data: any;
}

const AppointmentDateTimeColumn: FC<AppointmentDateTimeColumnProps> = ({
  data,
}) => {
  return (
    <span>
      {moment(data.appointmentDate).format('MMM DD, YYYY')}
      <br />
      {getAppointmentStartTime(data)}
    </span>
  );
};

export default AppointmentDateTimeColumn;

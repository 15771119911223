import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEqual, uniqBy } from 'lodash';

import {
  PMS_EVENT_TYPE,
  SERVICE_NAME,
  SPECIALTY_NAME,
  TIMEGRID_VIEW,
  TIME_GRID_WORKING_HOUR,
} from 'utils/constants';
import useOperatory from 'hooks/useOperatory';
import usePMSClinicSchedule from 'hooks/usePMSClinicSchedule';
import { IPMSAppointment } from 'hooks/usePMSAppointment';
import { WorkingHour } from 'interfaces/clinicSchedule';
import { calculateRangeBlock } from 'utils/getTimeBlockFromTImeString';
import { generateBlockNumbers } from 'utils/common';
import TimeGridHeader from './TimeGridHeader';
import { INormalizeData, IScheduleLayer } from '..';
import EventCell from './Cell/EventCell';
import HeaderCell from './Cell/HeaderCell';
import {
  NO_APPOINTMENT_ID,
  ONE_MINUTE_IN_PX,
  blockDoctorChairForExamAndCleaning,
  blockUnavailableByAppts,
  blockUnavailableByDentistNotAvailableForSplitScheduling,
  blockUnavailableByDoctorWorkingHour,
  blockUnavailableByNotEnoughDuration,
  blockUnavailableByOpNotAssignedToSelectedPractitioners,
  // blockUnavailableChairForHygienist,
  filterPractitionerTypeForExamAndCleaning,
  filterWorkingPractitionerForExamAndCleaning,
  normalizedChairEvents,
  normalizedPractitionerEvents,
  normalizedUnavailableEvents,
} from './utils';
import { IMapPractitionerColor, checkSelectedPractitioner } from '../utils';
import TimeGridContent from './TimeGridContent';
// import useClinic from 'hooks/useClinic';
// import useClinicService from 'hooks/useClinicService';

interface IProps {
  data: INormalizeData[];
  date: string; // YYYY-MM-DD
  isCollapsed: boolean;
  onChangeDate: (date: string) => void;
  onToggleCollapse: () => void;
  selectedView: string;
  setSelectedView: React.Dispatch<React.SetStateAction<string>>;
  isDebugMode: boolean;
  scheduleLayer: IScheduleLayer;
  unavailableAppointments: IPMSAppointment[];
  practitioners?: IMapPractitionerColor[];
  isLoading: boolean;
  selectedPractitioner: IMapPractitionerColor | null;
}

export interface IDebugModeDropdown {
  service: {
    id: string;
    duration: number;
    isSplitScheduling: boolean;
    hygienistDuration?: number;
    doctorDuration?: number;
    name: string;
  };
  practitioners: { id: string; colorCode: string }[];
}

const TIME_GRID_CONFIG = {
  headerHeight: 60,
  cellHeight: ONE_MINUTE_IN_PX * 60, // represent for 60 minutes => 1minutes = 2.2px
};

const TimeGrid = ({
  data,
  date,
  isCollapsed,
  onChangeDate,
  onToggleCollapse,
  selectedView,
  setSelectedView,
  isDebugMode,
  scheduleLayer,
  unavailableAppointments,
  practitioners,
  isLoading,
  selectedPractitioner,
}: IProps) => {
  const [selectedDebugModeDropdown, setSelectedDebugModeDropdown] =
    useState<IDebugModeDropdown | null>(null);

  const { data: pmsClinicSchedule, isLoading: isPmsClinicScheduleLoading } =
    usePMSClinicSchedule({
      shouldRun: !isLoading,
      date,
      doctorIds: practitioners
        ? practitioners.map((practitioner) => practitioner.id)
        : [],
    });

  // const { data: clinic } = useClinic();

  // const { data: clinicService } = useClinicService();

  const { data: operatories = [] } = useOperatory();

  useEffect(() => {
    if (!isDebugMode) {
      setSelectedDebugModeDropdown(null);
    }
  }, [isDebugMode]);

  const chartWorkingHour = useMemo(() => {
    return {
      startTime: TIME_GRID_WORKING_HOUR.START,
      endTime: TIME_GRID_WORKING_HOUR.END,
    };
  }, []);

  const normalizedEventData = useMemo(() => {
    if (selectedView === TIMEGRID_VIEW.PRACTITIONER) {
      return normalizedPractitionerEvents(data);
    } else if (selectedView === TIMEGRID_VIEW.CHAIR) {
      const chairEvents = normalizedChairEvents(data, operatories);

      return normalizedUnavailableEvents(
        chairEvents,
        unavailableAppointments,
        isDebugMode
      );
    }

    return [];
  }, [data, isDebugMode, operatories, selectedView, unavailableAppointments]);

  const clinicWorkingHour = useMemo(() => {
    if (pmsClinicSchedule) {
      const workingHour = pmsClinicSchedule.workingHour as WorkingHour;

      return {
        startTime: workingHour.start || TIME_GRID_WORKING_HOUR.START,
        endTime: workingHour.end || TIME_GRID_WORKING_HOUR.END,
      };
    }

    return {
      startTime: TIME_GRID_WORKING_HOUR.START,
      endTime: TIME_GRID_WORKING_HOUR.END,
    };
  }, [pmsClinicSchedule]);

  const timeGridData = useMemo(() => {
    if (isLoading) return;

    if (isDebugMode) {
      let timeGridData =
        normalizedEventData.length !== 0 ? normalizedEventData : undefined;

      if (timeGridData) {
        timeGridData = timeGridData.map((item) => {
          let events = item.events.filter((event) => {
            if (!scheduleLayer.isEvent) {
              return event.type !== PMS_EVENT_TYPE.EVENT;
            }

            return true;
          });

          if (scheduleLayer.practitionerIds.length === 0) {
            return {
              ...item,
              events: events.filter((event) => {
                if (event.type !== PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS) {
                  return true;
                }

                return !event.practitionerId;
              }),
            };
          } else {
            return {
              ...item,
              events: events.filter((event) => {
                if (event.type !== PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS) {
                  return true;
                }

                return scheduleLayer.practitionerIds.includes(
                  event.practitionerId
                );
              }),
            };
          }
        });

        if (scheduleLayer.isOfficeHour) {
          timeGridData = timeGridData.map((item) => {
            const isOfficeClosed =
              (pmsClinicSchedule?.workingHour.start === null &&
                pmsClinicSchedule?.workingHour.end === null) ||
              false;

            if (isOfficeClosed) {
              item.events.push({
                fromTime: TIME_GRID_WORKING_HOUR.START,
                toTime: TIME_GRID_WORKING_HOUR.END,
                patientName: '',
                operatoryName: '',
                colorCode: '',
                practitionerId: '',
                type: PMS_EVENT_TYPE.OFFICE_CLOSED_HOUR,
              });
            }

            return {
              ...item,
            };
          });
        }

        if (scheduleLayer.isOfficeHoliday && pmsClinicSchedule?.isHoliday) {
          timeGridData = timeGridData.map((item) => {
            item.events.push({
              fromTime: TIME_GRID_WORKING_HOUR.START,
              toTime: TIME_GRID_WORKING_HOUR.END,
              patientName: '',
              operatoryName: '',
              colorCode: '',
              practitionerId: '',
              type: PMS_EVENT_TYPE.OFFICE_HOLIDAY,
            });

            return {
              ...item,
            };
          });
        }

        if (selectedDebugModeDropdown) {
          const selectedService = selectedDebugModeDropdown.service;

          let selectedPractitioners =
            filterWorkingPractitionerForExamAndCleaning({
              selectedPractitioners: selectedDebugModeDropdown.practitioners,
              pmsClinicSchedule,
            });

          let workingDentists: any = [];

          if (selectedService.isSplitScheduling) {
            // Check hygienist duration has available slot
            let isFlag = false;

            const hygienistsWithOp =
              pmsClinicSchedule?.doctors.filter((item) => {
                return selectedPractitioners.some(
                  (item2) => item2.id === item.id
                );
              }) || [];

            for (let i = 0; i < hygienistsWithOp.length; i++) {
              if (isFlag) {
                break;
              }

              for (let j = 0; j < hygienistsWithOp[i].operatories.length; j++) {
                const operatoryId = hygienistsWithOp[i].operatories[j].id;

                const eventsByOp =
                  timeGridData.find((item3) => item3.id === operatoryId)
                    ?.events || [];

                const unavailableBlockNumbers =
                  generateBlockNumbers(eventsByOp);

                const availableBlockNumbersByClinicWorkingHour =
                  calculateRangeBlock(
                    clinicWorkingHour.startTime,
                    clinicWorkingHour.endTime
                  );

                const availableBlockNumbers =
                  availableBlockNumbersByClinicWorkingHour.filter(
                    (blockNumber) => {
                      return !unavailableBlockNumbers.includes(blockNumber);
                    }
                  );

                if (availableBlockNumbers.length === 0) {
                  isFlag = true;
                  break;
                }
              }
            }

            if (!isFlag) {
              const dentists = filterPractitionerTypeForExamAndCleaning({
                practitioners,
                specialist: SPECIALTY_NAME.DENTIST,
              });

              const tmpWorkingDentists =
                filterWorkingPractitionerForExamAndCleaning({
                  selectedPractitioners: dentists,
                  pmsClinicSchedule,
                });

              if (tmpWorkingDentists.length === 0) {
                selectedPractitioners = [];
                workingDentists = [];
              } else {
                workingDentists = tmpWorkingDentists;
              }
            }
          }

          // First Condition
          // Block available block based on working hour
          timeGridData = blockUnavailableByDoctorWorkingHour({
            selectedPractitioners:
              selectedPractitioners.concat(workingDentists),
            pmsClinicSchedule,
            clinicWorkingHour,
            timeGridData: cloneDeep(timeGridData),
          });

          // Second Condition
          // Block available block based on busy appt
          timeGridData = blockUnavailableByAppts({
            selectedPractitioners:
              selectedPractitioners.concat(workingDentists),
            pmsClinicSchedule,
            timeGridData: cloneDeep(timeGridData),
          });

          // Third Condition
          // Block available block based on assigned chair
          timeGridData = blockUnavailableByOpNotAssignedToSelectedPractitioners(
            {
              selectedPractitioners:
                selectedPractitioners.concat(workingDentists),
              pmsClinicSchedule,
              clinicWorkingHour,
              timeGridData: cloneDeep(timeGridData),
            }
          );

          // Fourth Condition
          // Filter available time slot based on main selected service duration
          timeGridData = blockUnavailableByNotEnoughDuration({
            selectedPractitioners,
            selectedService,
            clinicWorkingHour,
            timeGridData: cloneDeep(timeGridData),
            pmsClinicSchedule,
          });

          // Fifth Condition
          timeGridData =
            blockUnavailableByDentistNotAvailableForSplitScheduling({
              selectedPractitioners,
              workingDentists,
              selectedService: selectedDebugModeDropdown.service,
              pmsClinicSchedule,
              clinicWorkingHour,
              timeGridData: cloneDeep(timeGridData),
            });

          if (
            selectedService.isSplitScheduling &&
            selectedService.name.includes(SERVICE_NAME.EXAM_CLEANING)
          ) {
            // const examCleaningService = clinicService?.data.find(
            //   (item) => item.name === SERVICE_NAME.EXAM_CLEANING
            // );
            // Sixth condition black out dentist chair
            timeGridData = blockDoctorChairForExamAndCleaning({
              timeGridData: cloneDeep(timeGridData),
              practitioners,
              clinicWorkingHour,
            });

            // Disable this behaviour for current deployment

            // Seventh condition black out hygienist chair that can not be hovered
            // timeGridData = blockUnavailableChairForHygienist({
            //   timeGridData: cloneDeep(timeGridData),
            //   practitioners,
            //   selectedDebugModeDropdown,
            //   unitDuration: clinic.unitDuration,
            //   examPlacement: examCleaningService?.examPlacement,
            //   clinicWorkingHour,
            //   pmsClinicSchedule,
            // });
          }
        }
      }

      return timeGridData;
    }

    const appointments = data.filter((item) => item.appointments.length > 0);

    const selectedPractitionerDetail = pmsClinicSchedule?.doctors.find(
      (doctor) => doctor.id === selectedPractitioner?.id
    );

    const isSelectedPractitionerWorking = Boolean(
      selectedPractitionerDetail?.workingHour.start &&
        selectedPractitionerDetail?.workingHour.end
    );

    const result = checkSelectedPractitioner({
      selectedPractitioner,
      isSelectedPractitionerWorking,
      numberOfAppointments: appointments.length,
    });

    if (result) {
      return result;
    }

    // FILTER OP RELATED TO PRACTITIONER CHAIR VIEW
    if (
      selectedView === TIMEGRID_VIEW.CHAIR &&
      selectedPractitioner &&
      appointments.length > 0
    ) {
      const filterOpHasAppt = normalizedEventData.filter((data) =>
        appointments.find((item) =>
          item.appointments.find(
            (appointment) => appointment.operatory.id === data.id
          )
        )
      );

      const filterOpFromPractitioner = normalizedEventData.filter((data) =>
        selectedPractitioner.operatories?.find(
          (operatory) => operatory.id === data.id
        )
      );

      const result = uniqBy(
        [...filterOpFromPractitioner, ...filterOpHasAppt],
        (item) => item.id
      );

      return result;
    }

    // FILTER CHAIR THAT DOES NOT HAVE APPOINTMENT
    const chairsIncludeAppointment = normalizedEventData.filter(
      (data) => data.events.length > 0
    );

    // FILTER CHAIR THAT HAVE AT LEAST ONE APPOINTMENT WHICH IS NOT A LUNCH EVENT
    const updatedDataAppointments = chairsIncludeAppointment.filter(
      (data) =>
        !data.events.every((event) => event.type === PMS_EVENT_TYPE.EVENT)
    );

    return updatedDataAppointments.length !== 0
      ? updatedDataAppointments
      : [
          {
            id: NO_APPOINTMENT_ID,
            name: 'N/A',
            events: [],
            practitioner: null,
          },
        ];
  }, [
    clinicWorkingHour,
    data,
    isDebugMode,
    isLoading,
    normalizedEventData,
    pmsClinicSchedule,
    practitioners,
    scheduleLayer.isEvent,
    scheduleLayer.isOfficeHoliday,
    scheduleLayer.isOfficeHour,
    scheduleLayer.practitionerIds,
    selectedDebugModeDropdown,
    selectedPractitioner,
    selectedView,
  ]);

  const normalizedWorkingHourPractitioners = useMemo(() => {
    return (
      pmsClinicSchedule?.doctors.map((doctorSchedule) => {
        return {
          ...doctorSchedule,
          name:
            practitioners?.find(
              (practitioner) => practitioner.id === doctorSchedule.id
            )?.name || '',
        };
      }) || []
    );
  }, [pmsClinicSchedule?.doctors, practitioners]);

  return (
    <div className="flex-1">
      <TimeGridHeader
        date={date}
        view={selectedView}
        isCollapsed={isCollapsed}
        onChangeDate={onChangeDate}
        onChangeView={(view) => setSelectedView(view)}
        onToggleCollapse={onToggleCollapse}
        isDebugMode={isDebugMode}
        practitioners={practitioners!}
        onChangeDebugModeDropdown={(dropdownOption) => {
          if (!isEqual(selectedDebugModeDropdown, dropdownOption)) {
            setSelectedDebugModeDropdown(dropdownOption);
          }
        }}
      />
      <TimeGridContent
        key={`${selectedView}-${date}-${isDebugMode}`}
        pmsClinicSchedule={pmsClinicSchedule}
        selectedDebugModeDropdown={selectedDebugModeDropdown}
        isDebugMode={isDebugMode}
        clinicWorkingHour={clinicWorkingHour}
        workingHourPractitioners={normalizedWorkingHourPractitioners}
        scheduleLayer={scheduleLayer}
        date={date}
        data={timeGridData}
        chartWorkingHour={chartWorkingHour}
        config={TIME_GRID_CONFIG}
        practitioners={practitioners}
        EventCell={(props: any) => (
          <EventCell
            {...props}
            selectedView={selectedView}
            isDebugMode={isDebugMode}
          />
        )}
        HeaderCell={(props: any) => (
          <HeaderCell
            {...props}
            selectedView={selectedView}
            practitioners={practitioners}
            workingHourPractitioners={normalizedWorkingHourPractitioners}
            operatories={operatories}
            date={date}
          />
        )}
        isLoading={isPmsClinicScheduleLoading}
        isCollapsed={isCollapsed}
      />
    </div>
  );
};

export default TimeGrid;

import OperatorySection from './OperatorySection/OperatorySection';
import AppointmentSection from './AppointmentSection/AppointmentSection';
import InstallationSection from '../PracticeAdminSync/InstallationSection/InstallationSection';
import PractitionerSection from './PractitionerSection/PractitionerSection';
import EmailBlastSection from './EmailBlastSection/EmailBlastSection';

const GlobalAdminSync = () => {
  return (
    <div className="mt-4 w-[71rem]">
      <div className="text-14 leading-[2.1rem] text-darkest-grey">
        <InstallationSection />
        <AppointmentSection />
        <OperatorySection />
        <PractitionerSection />
        <EmailBlastSection />
      </div>
    </div>
  );
};

export default GlobalAdminSync;

import { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { IClinicResponse } from 'hooks/useAllClinic';

import AvatarPlaceHolder from 'assets/icons/avatar-empty-circle.svg';
import { ReactComponent as OpenPracticeViewIcon } from 'assets/icons/open_in_new.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-outline.svg';

import Status from 'components/Status/Status';
import { PRACTICE_FILTER_OPTIONS } from 'utils/constants';
import { useUser } from 'context/userContext';
import { saveRole, saveSelectedClinicId } from 'utils/storage';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';

const { ACTIVE, INACTIVE } = PRACTICE_FILTER_OPTIONS;

interface ItemProps {
  selectedPracticeId: string;
  practice: IClinicResponse;
  isCollapsed: boolean;
  onClick: () => void;
}

const Item: FC<ItemProps> = ({
  selectedPracticeId,
  isCollapsed,
  practice,
  onClick,
}) => {
  const { id, avatar, name, isActive, subdomain, externalId } = practice;
  const isClinicIncludeNexHealthField = subdomain && externalId;

  const { user, dispatch } = useUser();
  const { clinicUserRoles } = user;
  const navigate = useNavigate();

  const handleChangeClinicView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const clinicUserRole = clinicUserRoles.find(
      (clinicUserRole) => clinicUserRole.clinic.id === id
    );

    if (!clinicUserRole) return;

    saveSelectedClinicId(id);
    saveRole(clinicUserRole!.role);
    dispatch({ type: 'changeClinic', payload: { clinicId: id } });
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <div
      className={`h-10 flex items-center pl-1.9 border-b border-light-grey cursor-pointer relative ${
        selectedPracticeId === id ? 'bg-lightPink' : 'bg-white'
      } hover:bg-lightPink group`}
      onClick={onClick}
    >
      <img
        data-for={id}
        data-tip=""
        src={avatar || AvatarPlaceHolder}
        alt="avatar"
        onError={(event) => {
          event.currentTarget.src = AvatarPlaceHolder;
        }}
        className="rounded-full object-cover w-6 h-6 aspect-square shrink-0"
        width={60}
        height={60}
      />
      {isCollapsed ? (
        <ReactTooltip
          id={id}
          place="bottom"
          effect="solid"
          className="!bg-white !border-light-grey !rounded-[0.8rem] !w-auto !h-auto !px-[1rem] !py-[0.8rem] !opacity-100 z-[9999] flex justify-center items-center !min-w-[11.5rem] shadow-primary min-h-[6rem] before:hidden after:hidden"
        >
          <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal mb-0.2">
            {name}
          </span>
          <Status isActive={isActive}>{isActive ? ACTIVE : INACTIVE}</Status>
        </ReactTooltip>
      ) : (
        <div className="ml-2.9 max-w-[15rem]">
          <p className="text-16 leading-[2.4rem] font-bold truncate">{name}</p>
          <div className="mt-0.4">
            <Status isActive={isActive}>
              {!isClinicIncludeNexHealthField && (
                <>
                  <button data-for={id} data-tip="">
                    <WarningIcon className="w-1.6 h-1.6 mr-0.6 all-child:fill-magenta" />
                  </button>
                  <ReactTooltip
                    id={id}
                    place="bottom"
                    effect="solid"
                    className="!bg-white !border-light-grey !p-0 !rounded-[0.8rem] !w-[19rem] !h-auto !opacity-100 z-[9999] !flex justify-center items-center !min-w-[14.1rem] shadow-elevation-09 !min-h-[2.6rem] before:hidden after:hidden"
                  >
                    <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal mb-0.2 px-1 py-0.2">
                      Please add Clinic ID and Subdomain to complete adding this
                      practice
                    </span>
                  </ReactTooltip>
                </>
              )}
              {isActive ? ACTIVE : INACTIVE}
            </Status>
          </div>
          {isActive && isClinicIncludeNexHealthField && (
            <>
              <button
                className="hidden absolute top-3.8 right-3.8 cursor-pointer group-hover:block"
                data-for={`${id}-link`}
                data-tip=""
                onClick={handleChangeClinicView}
              >
                <OpenPracticeViewIcon className="w-2.4 h-2.4 all-child:all-child:fill-magenta" />
              </button>
              <ReactTooltip
                id={`${id}-link`}
                place="bottom"
                effect="solid"
                className="!bg-white !border-light-grey !p-0 !rounded-[0.8rem] !w-auto !h-auto !opacity-100 z-[9999] !flex justify-center items-center !min-w-[14.1rem] shadow-elevation-09 !min-h-[2.6rem] before:hidden after:hidden"
              >
                <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal mb-0.2">
                  Open this practice view
                </span>
              </ReactTooltip>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Item;

import { FC, useMemo, useState } from 'react';
import {
  useExpanded,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useAdminService from 'hooks/useAdminService';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';
import DraggableServiceTableBody from 'components/DraggableServiceTableBody/DraggableServiceTableBody';
import { reorder } from 'utils/common';

import ViewMode from 'components/ViewMode/ViewMode';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import NavigationBlocker from 'pages/OfficeSchedulePage/NavigationBlocker/NavigationBlocker';
import useBeforeUnloaded from 'hooks/useBeforeUnloaded';
import { MINUTE_OF_A_BLOCK } from 'utils/getTimeBlockFromTImeString';

interface TableProps {
  isSortingEnabled: boolean;
  setIsSortingEnable: React.Dispatch<React.SetStateAction<boolean>>;
}

const Table: FC<TableProps> = ({ isSortingEnabled, setIsSortingEnable }) => {
  const [highlightedRowId, setHighlighedRowId] = useState('');

  const [isOrderUpdated, setIsOrderUpdated] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useBeforeUnloaded({ when: isOrderUpdated });

  const {
    data: service,
    isLoading,
    mutate,
  } = useAdminService({
    sort: 'desc,asc',
    sortBy: 'status,order',
  });

  const columns = useMemo(
    () => [
      {
        Header: 'Service',
        accessor: (field: any) => field.name,
        id: 'serviceName',
      },
      {
        Header: 'Duration',
        accessor: (field: any) =>
          `${field.duration * MINUTE_OF_A_BLOCK} minutes`,
        id: 'duration',
        width: 50,
      },
      {
        Header: 'Status',
        accessor: (field: any) => (
          <div
            className={`w-max h-2.2 flex justify-center items-center px-1 py-0.2 text-12 rounded-full border-[0.3px] ${
              field.isActive
                ? 'text-magenta bg-[#FFF7FF]'
                : 'text-mid-grey bg-lightest-grey'
            }`}
          >
            {field.isActive ? 'Available' : 'Unavailable'}
          </div>
        ),
        id: 'status',
        width: 80,
      },
      {
        id: 'action_btn',
        width: 25,
        Cell: ({ row }: any) => {
          const isSubRow = row.depth > 0;
          const isParent = row.canExpand;

          return (
            <ThreeDotsOption
              key={highlightedRowId}
              isParent={isParent}
              service={row.original}
              isSubRow={isSubRow}
            />
          );
        },
      },
    ],
    [highlightedRowId]
  );

  const dataTable = useMemo(() => {
    if (isLoading || !service) {
      return { data: [], metadata: null };
    }

    const updatedDataTableFormat = service.data.map((item) => ({
      ...item,
      ...(item.childServices.length > 0 && { subRows: item.childServices }),
    }));

    return {
      data: updatedDataTableFormat,
      metadata: service.metadata,
    };
  }, [service, isLoading]);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: dataTable.data ? dataTable.data : [],
        manualPagination: true,
        pageCount: dataTable.metadata ? service?.metadata.total : 0,
      },
      useSortBy,
      useFlexLayout,
      useExpanded,
      usePagination
    );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <DraggableServiceTableBody
        onDragEnd={(result) => {
          if (!service || !result.destination) return;

          const items = reorder(
            service.data,
            result.source.index,
            result.destination.index
          );

          mutate({ ...service, data: items }, { revalidate: false });

          if (isOrderUpdated) return;

          const orderIsUpdated = !!service.data.find(
            (item, index) => item.id !== items[index].id
          );

          setIsOrderUpdated(orderIsUpdated);
        }}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
        onRowMouseEnter={(id: string) => setHighlighedRowId(id)}
        enabled={isSortingEnabled}
      />
    );
  };

  const handleUpdatedServiceOrder = async () => {
    setIsSubmitting(true);
    try {
      await axiosInstance.patch(`/services/order`, {
        ordersDefaultService: service?.data.map((service, index) => ({
          serviceId: service.id,
          order: index + 1,
        })),
      });
      renderToast({
        type: 'success',
        message: 'Updated order successfully',
      });
      await mutate();
      setIsSortingEnable(false);
      setIsOrderUpdated(false);
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error.response?.status === BAD_REQUEST) {
        message = error.response?.data.message;
      }
      renderToast({
        type: 'error',
        message,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <NavigationBlocker when={isOrderUpdated} />
      <div className="absolute top-6.8 right-4">
        <ViewMode
          isSortingEnabled={isSortingEnabled}
          setIsSortingEnabled={setIsSortingEnable}
          onDone={handleUpdatedServiceOrder}
          isSubmitting={isSubmitting}
        />
      </div>
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="pl-5">
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div className="flex items-center gap-x-0.9">
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {renderTableBody()}
      </table>
    </>
  );
};

export default Table;

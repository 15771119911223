import React from 'react';

import { TIMEGRID_VIEW } from 'utils/constants';
import { Specialty } from 'interfaces/specialty';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import { Operatory } from 'interfaces/operatory';
import ChairHeaderCell from './ChairHeaderCell/ChairHeaderCell';
import PractitionerHeaderCell from './PractitionerHeaderCell/PractitionerHeaderCell';
import { DoctorSchedule } from 'interfaces/clinicSchedule';

export interface IPractitionerHeaderCellProps {
  id: string;
  avatar: string;
  name: string;
  specialties: Specialty[];
}

export interface IChairHeaderCellProps {
  id: string;
  name: string;
}

interface IProps {
  item: IPractitionerHeaderCellProps | IChairHeaderCellProps;
  selectedView: string;
  practitioners?: IMapPractitionerColor[];
  operatories: Operatory[];
  date: string;
  workingHourPractitioners: (DoctorSchedule & { name: string })[];
}

const HeaderCell = (props: IProps) => {
  const {
    item,
    selectedView,
    practitioners,
    operatories,
    date,
    workingHourPractitioners,
  } = props;

  return selectedView === TIMEGRID_VIEW.PRACTITIONER ? (
    <PractitionerHeaderCell
      headerCellItem={item as IPractitionerHeaderCellProps}
      operatories={operatories}
      practitioners={practitioners}
      date={date}
      workingHourPractitioners={workingHourPractitioners}
    />
  ) : (
    <ChairHeaderCell
      headerCellItem={item as IChairHeaderCellProps}
      practitioners={practitioners}
      date={date}
      workingHourPractitioners={workingHourPractitioners}
    />
  );
};

export default HeaderCell;

import React, { FC } from 'react';
import { ReactComponent as DotIcon } from 'assets/icons/dot.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/green-check.svg';

interface PasswordGuidanceProps {
  currentPassword: string;
}

export const VALIDATIONS = [
  {
    title: 'At least 8 characters',
    pattern: /^.{8,}$/,
  },
  {
    title: 'At least 1 upper case letter (A-Z)',
    pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z]).+$/,
  },
  {
    title: 'At least 1 number (0-9)',
    pattern: /^(?=.*\d).+$/,
  },
];

const PasswordGuidance: FC<PasswordGuidanceProps> = ({ currentPassword }) => {
  return (
    <div className="bg-lightest-grey px-1.7 py-2.4 rounded-[1rem] shadow-primary text-14">
      <h3 className="text-darkest-grey font-bold">
        Your password must contain:
      </h3>
      <ul className="mt-0.4 pl-1 list-none flex flex-col gap-y-0.4">
        {VALIDATIONS.map(({ title, pattern }, index) => {
          const isValid = pattern.test(currentPassword);
          return (
            <li
              className={`flex items-center ${
                isValid ? 'text-green-secondary' : 'text-red-01'
              }`}
              key={`validation-${index}`}
            >
              {isValid ? (
                <CheckIcon className="w-1 h-1.2 all-child:fill-green-secondary" />
              ) : (
                <DotIcon className="w-1 h-0.5" />
              )}
              <span className="ml-0.9">{title}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordGuidance;

import { sortBy } from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

export interface Exception {
  id: string;
  date: string;
}

const getInitialOptions = <T extends Exception>(exceptionsDates: T[]) => {
  const options = exceptionsDates.map((exceptionDate) => ({
    ...exceptionDate,
    checked: true,
  }));
  const sortOptionsByDate = sortBy(options, 'date');
  return sortOptionsByDate;
};

const useOverrideModal = <T extends Exception>(exceptionsDates: T[]) => {
  const selectAllRef = useRef<HTMLInputElement | null>(null);
  const [options, setOptions] = useState(() =>
    getInitialOptions(exceptionsDates)
  );

  const totalSelectOptions = options.reduce((prev, curr) => {
    return curr.checked ? prev + 1 : prev;
  }, 0);

  useEffect(() => {
    if (totalSelectOptions === options.length) {
      selectAllRef.current!.checked = true;
      selectAllRef.current!.indeterminate = false;
    }
    if (totalSelectOptions < options.length) {
      selectAllRef.current!.checked = false;
      selectAllRef.current!.indeterminate = true;
    }
    if (totalSelectOptions === 0) {
      selectAllRef.current!.checked = false;
      selectAllRef.current!.indeterminate = false;
    }
  }, [options.length, totalSelectOptions]);

  const handleChangeCheckbox = (id: string, isChecked: boolean) => {
    const updatedOptions = [...options].map((option) => {
      if (option.id === id) {
        option.checked = isChecked;
      }
      return option;
    });
    setOptions(updatedOptions);
  };

  const handleChangeSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const isTargetCheckedOrIndeterminate =
      e.target.checked || e.target.indeterminate;

    const updatedOptions = [...options].map((option) => ({
      ...option,
      checked: isTargetCheckedOrIndeterminate,
    }));

    setOptions(updatedOptions);
  };

  return {
    options,
    totalSelectOptions,
    handleChangeCheckbox,
    handleChangeSelectAll,
    selectAllRef,
  };
};

export default useOverrideModal;

import React, { ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as PracticeProfileIcon } from 'assets/icons/practice-profile.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/service.svg';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';
import { ReactComponent as AppointmentIcon } from 'assets/icons/appointment.svg';
import { ReactComponent as OnlineSchedulerIcon } from 'assets/icons/online-scheduler.svg';

import { ReactComponent as PractitionersIcon } from 'assets/icons/practitioners.svg';
import { ReactComponent as OfficeScheduleIcon } from 'assets/icons/access-time.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/chevron-left.svg';
import { ReactComponent as UserCirleIcon } from 'assets/icons/user_circle.svg';
import { ReactComponent as SyncIcon } from 'assets/icons/sync.svg';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';
import { ROLE } from 'utils/constants';
import { loadRole } from 'utils/storage';

import AuthNavigationBar from 'components/NavigationBar/AuthNavigationBar';
import ClinicMenu from 'components/ClinicMenu/ClinicMenu';
import SidebarLink from './SidebarLink/SidebarLink';
import SupportButton from './SidebarLink/SupportButton';
import BackToMenu from './SidebarLink/BackToMenu';
import SettingButton from './SidebarLink/SetttingButton';

import styles from './SidebarLink/SidebarLink.module.css';
import { useSidebar } from 'context/sidebarContext';

const adminNavigationLinks = [
  { title: 'Dashboard', to: ADMIN_ROUTES.DASHBOARD, icon: DashboardIcon },
  {
    title: 'Appointments',
    to: ADMIN_ROUTES.APPOINTMENT,
    icon: AppointmentIcon,
  },
  {
    title: 'Practice',
    to: ADMIN_ROUTES.PRACTICE,
    icon: PracticeProfileIcon,
  },
];

const adminSettingLinks = [
  {
    title: 'Global User',
    to: ADMIN_ROUTES.USER,
    icon: UserCirleIcon,
    allowed: [ROLE.GLOBAL_ADMIN],
  },
  {
    title: 'Services',
    to: ADMIN_ROUTES.SERVICE,
    icon: ServiceIcon,
    allowed: [ROLE.GLOBAL_ADMIN],
  },
];

const protectedLinks = [
  {
    title: 'Office Schedule',
    to: ROUTES.OFFICE_SCHEDULE,
    icon: OfficeScheduleIcon,
    allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
  },
  { title: 'Profile', to: ROUTES.PRACTICE_PROFILE, icon: PracticeProfileIcon },
  { title: 'Practitioners', to: ROUTES.PRACTITIONER, icon: PractitionersIcon },
  {
    title: 'Services',
    to: ROUTES.SERVICE,
    icon: ServiceIcon,
    allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
  },
  {
    title: 'Online Scheduler',
    to: ROUTES.ONLINE_SCHEDULER,
    icon: OnlineSchedulerIcon,
    allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
  },
  {
    title: 'User',
    to: ROUTES.USER,
    icon: UserCirleIcon,
    allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
  },
  {
    title: 'Sync',
    to: ROUTES.SYNC,
    icon: SyncIcon,
    allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
  },
];

const DefaultLayout = ({
  children,
  isGlobalAdminPage,
}: {
  children: ReactNode;
  isGlobalAdminPage: boolean;
}) => {
  const location = useLocation();
  const role = loadRole();

  const { isCollapsed, setIsCollapsed } = useSidebar();

  const navigationLinks =
    role === ROLE.PRACTICE_USER
      ? [
          { title: 'Dashboard', to: ROUTES.DASHBOARD, icon: DashboardIcon },
          {
            title: 'Profile',
            to: ROUTES.PRACTICE_PROFILE,
            icon: PracticeProfileIcon,
          },
          {
            title: 'Appointments',
            to: ROUTES.APPOINTMENT,
            icon: AppointmentIcon,
          },
        ]
      : [
          { title: 'Dashboard', to: ROUTES.DASHBOARD, icon: DashboardIcon },
          {
            title: 'Appointments',
            to: ROUTES.APPOINTMENT,
            icon: AppointmentIcon,
          },
          {
            title: 'Schedule',
            to: ROUTES.SCHEDULE,
            icon: ScheduleIcon,
            allowed: [ROLE.GLOBAL_ADMIN, ROLE.PRACTICE_ADMIN],
          },
        ];

  const isUserAllowedToViewSettingTab = [
    ROLE.GLOBAL_ADMIN,
    ROLE.PRACTICE_ADMIN,
  ].includes(role);

  const isPracticeAdminInSettingTab =
    role !== ROLE.PRACTICE_USER &&
    protectedLinks.some((link) => link.to === location.pathname);

  const isInSettingTab = isGlobalAdminPage
    ? adminSettingLinks.some((link) => link.to === location.pathname)
    : isPracticeAdminInSettingTab;

  const renderGlobalAdminSideBar = () => {
    return (
      <>
        {isInSettingTab && (
          <BackToMenu
            isGlobalAdminPage={isGlobalAdminPage}
            isCollapsed={isCollapsed}
          />
        )}
        <div
          className={`flex flex-col gap-y-2 mt-4.2 ${
            isInSettingTab ? 'mt-2' : 'mt-4.2'
          }`}
        >
          {isInSettingTab
            ? adminSettingLinks.map((link) => (
                <SidebarLink
                  isCollapsed={isCollapsed}
                  key={link.title}
                  {...link}
                />
              ))
            : adminNavigationLinks.map((link) => (
                <SidebarLink
                  isCollapsed={isCollapsed}
                  key={link.title}
                  {...link}
                />
              ))}
        </div>
        {isUserAllowedToViewSettingTab && !isInSettingTab && (
          <SettingButton
            isGlobalAdminPage={isGlobalAdminPage}
            isCollapsed={isCollapsed}
          />
        )}
      </>
    );
  };

  const renderPracticeUserSideBar = () => {
    return (
      <>
        {isInSettingTab && (
          <BackToMenu
            isCollapsed={isCollapsed}
            isGlobalAdminPage={isGlobalAdminPage}
          />
        )}
        <div
          className={`flex flex-col gap-y-2  ${
            isInSettingTab ? 'mt-2' : 'mt-4.2'
          }`}
        >
          {isInSettingTab
            ? protectedLinks.map((link) => (
                <SidebarLink
                  isCollapsed={isCollapsed}
                  key={link.title}
                  {...link}
                />
              ))
            : navigationLinks.map((link) => (
                <SidebarLink
                  isCollapsed={isCollapsed}
                  key={link.title}
                  {...link}
                />
              ))}
        </div>
        {isUserAllowedToViewSettingTab && !isInSettingTab && (
          <SettingButton
            isGlobalAdminPage={isGlobalAdminPage}
            isCollapsed={isCollapsed}
          />
        )}
      </>
    );
  };

  return (
    <>
      <AuthNavigationBar />
      <div className="flex">
        <aside className="flex-initial shrink-0 bg-lightest-grey h-[calc(100vh-6rem)] sticky top-[6rem] z-10">
          <nav
            className={`flex flex-col ${
              isCollapsed ? 'w-[6.4rem]' : 'w-[19rem]'
            } text-14 relative py-2 px-[5px] transition-all duration-300 h-full`}
          >
            <ClinicMenu isCollapsed={isCollapsed} />

            {isGlobalAdminPage
              ? renderGlobalAdminSideBar()
              : renderPracticeUserSideBar()}

            <SupportButton isCollapsed={isCollapsed} />
            <button
              data-for={'expand-collapse-button'}
              data-tip=""
              className="w-2.4 h-2.4 bg-white rounded-full flex items-center justify-center shadow-primary absolute -right-1 top-0.8"
              onClick={() => setIsCollapsed((state) => !state)}
            >
              <CollapseIcon
                className={`w-1.4 h-1.4 transition-transform duration-300 ${
                  isCollapsed && 'rotate-180'
                }`}
              />
            </button>
            <ReactTooltip
              id={'expand-collapse-button'}
              place="right"
              effect="solid"
              className={`${styles.tooltip} shadow-tooltip`}
            >
              <span className="text-11 text-darkest-grey flex h-full items-center">
                {isCollapsed ? 'Expand' : 'Collapse'}
              </span>
            </ReactTooltip>
          </nav>
        </aside>
        <main className="flex-1">{children}</main>
      </div>
    </>
  );
};

export default DefaultLayout;

import { Dispatch, FC, MutableRefObject } from 'react';

interface ApplyOptionProps {
  isSingleDay: boolean;
  setIsSingleDay: Dispatch<React.SetStateAction<boolean>>;
  dateTo: Date | null;
  dateRangePickerRef: MutableRefObject<HTMLButtonElement | null>;
}

const ApplyOption: FC<ApplyOptionProps> = ({
  isSingleDay,
  setIsSingleDay,
  dateTo,
  dateRangePickerRef,
}) => {
  return (
    <div className="flex flex-1 justify-between ml-4.1">
      <div className="flex gap-x-0.9">
        <input
          type="radio"
          id="singleDay"
          className="w-2.2 h-2.2"
          checked={isSingleDay}
          onChange={() => setIsSingleDay(true)}
        />
        <label htmlFor="singleDay" className="text-13 leading-[2rem]">
          Single Day
        </label>
      </div>
      <div className="flex gap-x-0.9">
        <input
          type="radio"
          id="dateRange"
          className="w-2.2 h-2.2"
          checked={!isSingleDay}
          onChange={() => {
            setIsSingleDay(false);
            if (dateTo) return;

            dateRangePickerRef.current?.click();
          }}
        />
        <label htmlFor="dateRange" className="text-13 leading-[2rem]">
          Date Range
        </label>
      </div>
    </div>
  );
};

export default ApplyOption;

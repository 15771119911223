import { FC, useState } from 'react';
import { capitalize } from 'lodash';

import CommonButton from 'components/CommonButton/CommonButton';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';

interface CopyPanelProps {
  day: string;
  start: string;
  end: string;
  onCopy: (timeSlots: WeeklyTimeslot[]) => void;
  timeSlots: WeeklyTimeslot[];
  onClose: (
    focusableElement?:
      | HTMLElement
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void;
}

const CopyPanel: FC<CopyPanelProps> = ({
  day,
  end,
  onCopy,
  start,
  timeSlots,
  onClose,
}) => {
  const [checkedTimeSlots, setCheckedTimeSlots] = useState(() =>
    timeSlots.map(({ day: timeSlotDay }) => ({
      day: timeSlotDay,
      disabled: timeSlotDay === day,
      checked: timeSlotDay === day,
    }))
  );

  const handleCheckedTimeSlots = (day: string, isChecked: boolean) => {
    const updatedCheckedTimeSlots = [...checkedTimeSlots].map((timeSlot) => {
      const { day: timeSlotDay } = timeSlot;
      if (timeSlotDay === day) {
        timeSlot.checked = isChecked;
      }
      return timeSlot;
    });
    setCheckedTimeSlots(updatedCheckedTimeSlots);
  };

  const handleClickApply = () => {
    const checkedOptions = checkedTimeSlots.filter(
      ({ checked, disabled }) => checked && !disabled
    );
    const copyTimeSlots = timeSlots.map((timeSlot) => {
      const updatedTimeSlot = checkedOptions.find(
        (option) => option.day === timeSlot.day
      );
      return updatedTimeSlot ? { ...timeSlot, start, end } : { ...timeSlot };
    });
    onCopy(copyTimeSlots);
    onClose();
  };

  return (
    <div className="flex flex-col justify-between gap-y-1.6 pt-1.6 pb-1.8 px-2">
      <h3>Copy times to</h3>
      {checkedTimeSlots.map(({ day, checked, disabled }) => (
        <div className="flex justify-between" key={day}>
          <label htmlFor={`option-${day}`}>{capitalize(day)}</label>
          <input
            id={`option-${day}`}
            type="checkbox"
            className="w-1.8 h-1.8"
            checked={checked}
            disabled={disabled}
            onChange={(e) => handleCheckedTimeSlots(day, e.target.checked)}
          />
        </div>
      ))}
      <CommonButton
        className="!min-w-[11rem]"
        onClick={() => {
          handleClickApply();
        }}
      >
        Apply
      </CommonButton>
    </div>
  );
};

export default CopyPanel;

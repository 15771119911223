import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';
import { ReactComponent as VisibilityIcon } from 'assets/icons/visibility.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/icons/visibility_off.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import CommonButton from 'components/CommonButton/CommonButton';
import { loadSelectedClinicId } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';

interface VisibilityDropdownProps {
  field: any;
}

const VisibilityDropdown: FC<VisibilityDropdownProps> = ({ field }) => {
  const [isPublic, setIsPublic] = useState<boolean>(() => field.isPublic);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const matchMutate = useMatchMutate();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(e.target.value === 'on');
  };

  const onSave = async () => {
    setIsSubmitting(true);
    const clinicId = loadSelectedClinicId();

    const url = `clinics/${clinicId}/services/${field.id}`;

    try {
      await axiosInstance.patch(url, {
        isPublic,
      });

      await matchMutate(/\/services\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Edit a service successfully`,
      });
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Popover className="relative" key={field.isPublic}>
      <Popover.Button
        className="flex items-center gap-x-0.8"
        disabled={field.isChildService}
      >
        {field.isPublic ? (
          <>
            <VisibilityIcon className="w-1.6 h-1.6" />
            <span>Public</span>
          </>
        ) : (
          <>
            <VisibilityOffIcon className="w-1.6 h-1.6" />
            <span>Private</span>
          </>
        )}
        <TriangleIcon
          className={`hidden ${
            field.isChildService ? '' : 'group-hover:block'
          }  w-1.4 h-1.4`}
        />
      </Popover.Button>
      <Popover.Panel className="absolute -translate-x-5 z-10 bg-white shadow-primary rounded-[1rem] mt-0.6 w-24 overflow-hidden">
        {({ close }) => {
          return (
            <>
              <div className="p-2">
                <div>
                  <div className="flex items-center gap-x-0.8">
                    <input
                      name="isPublic"
                      type="radio"
                      className="w-2.4 h-2.4"
                      id="public"
                      defaultChecked={field.isPublic}
                      onChange={onChange}
                      value="on"
                    />
                    <label htmlFor="public" className="font-bold">
                      Public
                    </label>
                  </div>
                  <fieldset className="mt-0.8">
                    Everyone can see this service
                  </fieldset>
                </div>
                <div className="mt-2">
                  <div className="flex items-center gap-x-0.8">
                    <input
                      name="isPublic"
                      type="radio"
                      className="w-2.4 h-2.4"
                      id="unlisted"
                      defaultChecked={!field.isPublic}
                      onChange={onChange}
                      value="off"
                    />
                    <label htmlFor="unlisted" className="font-bold">
                      Private
                    </label>
                  </div>
                  <fieldset className="mt-0.8">
                    Anyone with the link can see this service
                  </fieldset>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-1.6 h-5.6 bg-white shadow-elevation-05-search pr-1.6">
                <CommonButton
                  className="!min-w-[8rem]"
                  variant="secondary"
                  onClick={() => close()}
                  disabled={isSubmitting}
                >
                  Cancel
                </CommonButton>
                <CommonButton
                  className="!min-w-[8rem]"
                  variant="primary"
                  onClick={onSave}
                  isLoading={isSubmitting}
                >
                  Save
                </CommonButton>
              </div>
            </>
          );
        }}
      </Popover.Panel>
    </Popover>
  );
};

export default VisibilityDropdown;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';
import { PractitionerSchedule } from 'interfaces/practitionerSchedule';

const usePractitionerSchedule = (practitionerId: string) => {
  const clinicId = loadSelectedClinicId();
  const { data, error, mutate } = useSWR(
    [`/practitioners/v3/${practitionerId}/schedules`, clinicId],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<PractitionerSchedule>(url);
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default usePractitionerSchedule;

import { FC, useState } from 'react';
import CustomDayPopover from 'components/CustomDayPopover/CustomDayPopover';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { PractitionerException } from 'interfaces/practitionerException';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import moment from 'moment';
import EditScheduleModal from '../../../EditScheduleModal/EditScheduleModal';
import { IPractitioner } from 'interfaces/practitioners';

interface ThreeDotsOptionProps {
  day: PractitionerException;
  isShown: boolean;
  version: number;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
  selectedPractitioner: IPractitioner;
}

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  isShown,
  version,
  day,
  onCheckOutsidePractitionerHourAppts,
  selectedPractitioner,
}) => {
  const [modal, setModal] = useState<'edit' | 'delete' | null>(null);

  const matchMutate = useMatchMutate();

  const handleDeleteCustomDay = async () => {
    try {
      await axiosInstance.delete(
        `/practitioners/${selectedPractitioner.id}/exceptions`,
        {
          headers: {
            version,
          },
          data: [{ id: day.id }],
        }
      );
      renderToast({
        type: 'success',
        message: 'Deleted a custom day successfully',
      });

      await Promise.all([
        matchMutate(/\/exceptions\?[\s\S]+/),
        matchMutate(/\/custom-days\?[\s\S]+/),
        onCheckOutsidePractitionerHourAppts(),
      ]);

      setModal(null);
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again. ',
      });
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {modal === 'delete' && (
        <ConfirmationModal
          title={'Delete this custom day?'}
          description={`Are you sure you want to delete this custom day on ${moment(
            day.date,
            'YYYY-MM-DD'
          ).format(
            'MMMM DD, YYYY'
          )}? This will revert this day to regular hours.`}
          onClose={() => setModal(null)}
          onSubmit={handleDeleteCustomDay}
          submitBtnTitle="Yes, delete"
        />
      )}
      {modal === 'edit' && (
        <EditScheduleModal
          selectedDate={{
            date: day.date,
            workingHour: day.workingHour,
          }}
          onClose={() => setModal(null)}
          selectedPractitioner={selectedPractitioner}
          onCheckOutsidePractitionerHourAppts={
            onCheckOutsidePractitionerHourAppts
          }
          version={version}
        />
      )}
      <CustomDayPopover isShown={isShown} setModal={setModal} />
    </div>
  );
};

export default ThreeDotsOption;

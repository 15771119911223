import { FC } from 'react';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { datetimeFormat } from 'utils/datetime';
import { useUser } from 'context/userContext';

interface LatestSyncMessageProps {
  isLoading: boolean;
  lastSyncAt: string | null;
  lastSuccessSyncAt?: string;
  isUpdateMessage?: boolean;
  successfulMessage?: string;
}

const LatestSyncMessage: FC<LatestSyncMessageProps> = ({
  isLoading,
  lastSyncAt,
  isUpdateMessage,
  lastSuccessSyncAt,
  successfulMessage,
}) => {
  const {
    user: { currentClinicUserRole },
  } = useUser();

  if (isLoading) {
    return <LoadingSpinner className="all-child:fill-magenta" />;
  }

  if (!lastSyncAt) return null;

  const textMessage =
    successfulMessage ||
    `Latest successful ${isUpdateMessage ? 'update' : 'sync'}`;

  return (
    <span className="text-12 leading-[1.8rem] text-[#828282]">
      {`${textMessage}: ${datetimeFormat({
        dateString: lastSuccessSyncAt ? lastSuccessSyncAt : lastSyncAt,
        pattern: 'MMM DD, hh:mm A (zz)',
        timezone: currentClinicUserRole!.clinic.timezone,
      })}`}
    </span>
  );
};

export default LatestSyncMessage;

import { FC, useLayoutEffect } from 'react';

interface OverlayProps {}

const Overlay: FC<OverlayProps> = ({ children }) => {
  useLayoutEffect(() => {
    // Prevent scroll when modal open
    document.body.style.overflow = 'hidden';

    // Avoid content shift
    const isScrollBarShown =
      document.documentElement.scrollHeight >
      document.documentElement.clientHeight;

    if (isScrollBarShown) {
      document.body.style.paddingRight = '15px';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
    };
  }, []);

  return (
    <div className="fixed w-screen h-screen top-0 left-0 bg-opacity-40 bg-[#373737] z-[999]">
      {children}
    </div>
  );
};

export default Overlay;

import React, { useState } from 'react';
import NavigationBar from 'components/NavigationBar/NavigationBar';
import FormInput from './FormInput/FormInput';
import CardContainer from 'components/Layout/CardContainer/CardContainer';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import axiosInstance from 'apis/axiosInstance';
import axios from 'axios';
import { renderToast } from 'components/Toast/Toast';

const ForgotPasswordPage = () => {
  const [currentEmailSent, setCurrentEmailSent] = useState('');

  const onSubmit = async (data: { email: string }) => {
    try {
      await axiosInstance.post('/clinic-user/auth/reset-password-link', data);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 500) {
        renderToast({
          message: 'Something went wrong. Please try again later',
          type: 'error',
        });
      }
    }
    setCurrentEmailSent(data.email);
  };

  return (
    <>
      <NavigationBar />
      {!currentEmailSent ? (
        <FormInput onSubmit={onSubmit} />
      ) : (
        <CardContainer title="Forgot Password">
          <div className="px-3 pt-2 pb-4 flex flex-col items-center">
            <p>
              We have sent a link to your email
              <span className="text-magenta"> {currentEmailSent}</span>. Please
              check your inbox to process further.
            </p>
            <Link
              to={ROUTES.LOGIN}
              className="mt-0.3 flex justify-center items-center w-[20rem] h-[4rem] bg-magenta text-white font-bold rounded-full hover:bg-magenta-lighter"
            >
              Go to Practice App
            </Link>
          </div>
        </CardContainer>
      )}
    </>
  );
};

export default ForgotPasswordPage;

import React from 'react';
import { range } from 'lodash';
import moment from 'moment';
import useClinic from 'hooks/useClinic';
import { getDurationRangeArray } from '../../utils';

interface IProps {
  workingHour: {
    startTime: string;
    endTime: string;
  };
  config: {
    headerHeight: number;
    cellHeight: number;
  };
}

const TimeGridLabel = ({ workingHour, config }: IProps) => {
  const { startTime, endTime } = workingHour;

  const { data } = useClinic();

  const diff = moment(endTime, 'HH:mm:ss').diff(
    moment(startTime, 'HH:mm:ss'),
    'hours'
  );

  const is5MinutesDuration = data?.unitDuration === 5;

  return (
    <div className="min-w-[50px] sticky left-0 z-20 bg-white">
      <div
        className="border-b border-r border-l border-light-grey border-solid"
        style={{
          height: config.headerHeight,
        }}
      />

      <div>
        {range(0, diff + 1).map((hour, index) => {
          const label = moment(startTime, 'HH:mm:ss')
            .clone()
            .add(hour, 'hours')
            .format('hA');

          const isNotLastRow = index !== diff;

          return (
            <div
              key={`time-grid-${label}`}
              style={{
                height: config.cellHeight,
              }}
            >
              <div
                className="border-r border-l border-light-grey border-solid relative"
                style={{
                  height: config.cellHeight,
                }}
              >
                <div
                  className={`absolute w-full h-full flex flex-col justify-evenly items-center py-1.2 ${
                    is5MinutesDuration ? 'py-0.4' : 'py-1.2'
                  }`}
                >
                  {data &&
                    getDurationRangeArray(data.unitDuration).map((minute) => (
                      <span
                        key={minute}
                        className={`text-mid-grey text-[0.9rem] ${
                          is5MinutesDuration
                            ? 'leading-[1rem]'
                            : 'leading-[1.8rem]'
                        }`}
                      >
                        {minute}
                      </span>
                    ))}
                </div>
                {isNotLastRow && (
                  <span className="text-dim-grey font-bold text-11 absolute -bottom-[12px] left-[10px]">
                    {label}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeGridLabel;

import React from 'react';

import { highlightText } from 'utils/common';
import logoIcon from 'assets/icons/Logo-small.svg';
import { ClinicUserRole, GlobalAdminRole } from 'context/userContext';
import { getLocationName } from './utils';
import ClinicOption from './Option';

const CurrentView = ({
  currentClinicUserRole,
  globalAdminRole,
  searchingValue,
}: {
  currentClinicUserRole: ClinicUserRole | null;
  globalAdminRole: GlobalAdminRole | null;
  searchingValue: string;
}) => {
  if (currentClinicUserRole) {
    const clinicName = currentClinicUserRole.clinic.name;

    const parts = highlightText(clinicName, searchingValue);

    return (
      <div className="w-full pb-0.8">
        <div className="text-[1rem] font-bold text-mid-grey mb-0.8">
          CURRENT VIEW
        </div>

        <ClinicOption
          option={{
            avatar: currentClinicUserRole.clinic.avatar,
            name: currentClinicUserRole.clinic.name,
            description: getLocationName(currentClinicUserRole.clinic.location),
          }}
          isActive
          highlightedParts={parts}
        />
      </div>
    );
  } else if (globalAdminRole) {
    const parts = highlightText('Global Admin', searchingValue);
    return (
      <div className="w-full pb-0.8">
        <div className="text-[1rem] font-bold text-mid-grey mb-0.8">
          CURRENT VIEW
        </div>

        <ClinicOption
          option={{
            avatar: logoIcon,
            name: 'Global Admin',
          }}
          isActive
          isGlobalAdmin
          highlightedParts={parts}
        />
      </div>
    );
  }

  return null;
};

export default CurrentView;

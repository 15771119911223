import { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import useSync from 'hooks/useSync';
import CommonButton from 'components/CommonButton/CommonButton';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import axiosInstance from 'apis/axiosInstance';
import SyncStatus from '../../SyncStatus/SynStatus';
import { SYNC_STATUS } from 'utils/constants';
import LatestSyncMessage from '../LatestSyncMessage/LatestSyncMessage';
import useClinicPractitioner from 'hooks/useClinicPractitioner';
import useOperatory from 'hooks/useOperatory';
import { renderToast } from 'components/Toast/Toast';

const AppointmentSection = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { data: sync, isLoading: isSyncLoading, mutate } = useSync();

  const { data: clinicPractitioner } = useClinicPractitioner({
    shouldRun: true,
  });

  const { data: operatory } = useOperatory();

  const handleSyncAppointments = async () => {
    setIsButtonLoading(true);
    try {
      const response = await axiosInstance.get('/sync/appointments');
      renderToast({
        message: `Successfully synced ${response.data.data.length} appointments`,
        type: 'success',
      });
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    await mutate();
    setIsButtonLoading(false);
  };

  const isClinicHavePractitioners =
    clinicPractitioner && clinicPractitioner.metadata.total > 0;

  const isClinicHaveOperatories = operatory && operatory.length > 0;

  const isButtonDisabled =
    sync?.appointment?.status === SYNC_STATUS.IN_PROGRESS ||
    !isClinicHavePractitioners ||
    !isClinicHaveOperatories;

  return (
    <div className="pb-2 border-b border-light-grey mt-[2.55rem]">
      <div className="flex justify-between">
        <div className="basis-[45rem]">
          <div className="flex gap-x-0.9">
            <h3 className="font-bold">
              Get latest appointments from the Connector
            </h3>
            <button
              className="-translate-y-0.2"
              data-for={'appointment-info'}
              data-tip=""
            >
              <InfoIcon />
            </button>
            <ReactTooltip
              id={'appointment-info'}
              place="bottom"
              effect="solid"
              className="!bg-lightest-grey !border-light-grey !rounded-[0.8rem] !w-[23rem] !h-[auto] !px-[1.6rem] !py-[0.9rem] z-[9999] flex justify-center items-center  !opacity-100"
            >
              <span className="text-11 text-darkest-grey flex items-center font-normal">
                This action should only be completed during the initial set up
                or when there are appointment discrepancies.
              </span>
            </ReactTooltip>
          </div>
          <p className="mt-2">
            Click the Sync button to get the latest appointments from the
            Connector
          </p>
        </div>
        <div className="flex flex-col items-end">
          <CommonButton
            variant="secondary"
            className="!min-h-[3.2rem]"
            onClick={handleSyncAppointments}
            isLoading={isButtonLoading}
            disabled={isButtonDisabled}
          >
            Sync
          </CommonButton>
        </div>
      </div>
      <div className="flex flex-col gap-y-0.8 mt-2">
        <SyncStatus
          isLoading={isSyncLoading}
          data={{
            ...sync?.appointment,
            status: isButtonLoading
              ? SYNC_STATUS.IN_PROGRESS
              : sync?.appointment?.status,
          }}
        />
        <LatestSyncMessage
          isLoading={isSyncLoading}
          lastSyncAt={sync?.appointment?.lastSyncAt}
          lastSuccessSyncAt={sync?.appointment?.lastSuccessSyncAt}
        />
      </div>
    </div>
  );
};

export default AppointmentSection;

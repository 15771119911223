import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import React from 'react';

import classNames from 'classnames';

import styles from './CommonButton.module.css';

interface Props {
  variant?: 'primary' | 'secondary' | 'text';
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
}

const CommonButton: React.FC<Props> = ({
  variant = 'primary',
  className = '',
  disabled,
  isLoading,
  onClick = () => {},
  children,
  type = 'button',
}) => {
  const buttonStyle = classNames({
    [styles.btn]: true,
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
  });

  return (
    <button
      className={`${buttonStyle} ${className}`}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      {isLoading ? (
        <LoadingSpinner className={variant === 'primary' ? 'text-white' : ''} />
      ) : (
        children
      )}
    </button>
  );
};

export default CommonButton;

import { useState } from 'react';
import { Listbox } from '@headlessui/react';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';
import { WORKING_HOUR_SYSTEM } from 'utils/constants';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import LeavePageModal from 'components/LeavePageModal/LeavePageModal';
import useClinicSchedule from 'hooks/useClinicSchedule';
import axios from 'axios';
import { useVersion } from '../context/versionContext';
import { clearIsPageDirty, getIsPageDirty } from 'utils/storage';

const options = [
  { label: 'Custom', value: WORKING_HOUR_SYSTEM.FIRSTIN },
  { label: 'Google', value: WORKING_HOUR_SYSTEM.GOOGLE },
];

const SelectSchedule = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [savedSelectedOption, setSavedSelectedOption] = useState('');

  const { data, mutate } = useClinicSchedule();

  const { setIsOldVersion } = useVersion();

  const selectedScheduleLabel = options.find(
    (option) => option.value === data!.currentWorkingHour
  )!.label;

  const handleOnOptionChanged = async (selectedOption: string) => {
    if (selectedOption === data!.currentWorkingHour) return;

    if (getIsPageDirty()) {
      setSavedSelectedOption(selectedOption);
      return setIsModalOpen(true);
    }

    setIsLoading(true);
    try {
      await axiosInstance.patch(
        '/clinics-schedule/update-system',
        {
          system: selectedOption,
        },
        { headers: { version: data!.version } }
      );

      await mutate();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return setIsOldVersion(true);
      }
      renderToast({
        message: 'Something went wrong. Please try again',
        type: 'error',
      });
    }
    setIsLoading(false);
  };

  const handleOnModalSubmit = async () => {
    clearIsPageDirty();
    setIsModalOpen(false);
    await handleOnOptionChanged(savedSelectedOption);
    setSavedSelectedOption('');
  };

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isModalOpen && (
        <LeavePageModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleOnModalSubmit}
        />
      )}
      <Listbox
        value={data!.currentWorkingHour}
        onChange={handleOnOptionChanged}
      >
        <Listbox.Button className="w-8 flex items-center gap-x-0.8 relative">
          <span className="font-bold text-16 leading-[2.4rem]">
            {selectedScheduleLabel}
          </span>
          <ArrowDownIcon />
        </Listbox.Button>
        <Listbox.Options className="absolute mt-0.7 w-[15rem] bg-white py-1.6 text-base flex flex-col gap-y-1.6 rounded-[1rem] shadow-primary z-10">
          {options.map((option) => (
            <Listbox.Option
              key={option.value}
              value={option.value}
              className={({ active, selected }) =>
                `cursor-pointer pl-2 ${
                  active || selected ? 'text-magenta' : ''
                }`
              }
            >
              {option.label}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </>
  );
};

export default SelectSchedule;

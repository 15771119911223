import moment from 'moment';

export const CALENDAR_TYPE = {
  ALL_TIME: 'All time',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  THIS_QUARTER: 'This quarter',
  NEXT_7DAYS: 'Next 7 days',
  NEXT_30DAYS: 'Next 30 days',
  NEXT_90DAYS: 'Next 90 days',
  CUSTOM_DATE_RANGE: 'Custom date range',
};

export const getDateRangeByCalendarType = (type: string) => {
  if (type === CALENDAR_TYPE.THIS_WEEK) {
    return {
      fromDate: moment().startOf('weeks').format('YYYY-MM-DD'),
      toDate: moment().endOf('weeks').format('YYYY-MM-DD'),
    };
  }

  if (type === CALENDAR_TYPE.THIS_QUARTER) {
    return {
      fromDate: moment().startOf('quarters').format('YYYY-MM-DD'),
      toDate: moment().endOf('quarters').format('YYYY-MM-DD'),
    };
  }

  if (type === CALENDAR_TYPE.NEXT_7DAYS) {
    return {
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().add(7, 'days').format('YYYY-MM-DD'),
    };
  }

  if (type === CALENDAR_TYPE.NEXT_30DAYS) {
    return {
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().add(30, 'days').format('YYYY-MM-DD'),
    };
  }

  if (type === CALENDAR_TYPE.NEXT_90DAYS) {
    return {
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().add(90, 'days').format('YYYY-MM-DD'),
    };
  }

  if (type === CALENDAR_TYPE.THIS_MONTH) {
    return {
      fromDate: moment().startOf('months').format('YYYY-MM-DD'),
      toDate: moment().endOf('months').format('YYYY-MM-DD'),
    };
  }

  // default this month
  return {
    fromDate: moment().startOf('months').format('YYYY-MM-DD'),
    toDate: moment().endOf('months').format('YYYY-MM-DD'),
  };
};

import { FC, useState } from 'react';
import SidePanel from 'components/SidePanel/SidePanel';
import { PRACTICE_FILTER_OPTIONS } from 'utils/constants';
import Item from './Item/Item';
import { IClinicResponse } from 'hooks/useAllClinic';
import CreatePracticeModal from '../CreatePracticeModal/CreatePracticeModal';
interface PracticePanelProps {
  practices: IClinicResponse[];
  setSelectedPractice: React.Dispatch<
    React.SetStateAction<IClinicResponse | null>
  >;
  selectedPractice: IClinicResponse;
  totalPracticeByStatus: {
    active: number;
    inactive: number;
    all: number;
  } | null;
}

const { ACTIVE, ALL } = PRACTICE_FILTER_OPTIONS;

const PracticePanel: FC<PracticePanelProps> = ({
  practices,
  selectedPractice,
  setSelectedPractice,
  totalPracticeByStatus,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(ACTIVE);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);

  const getFilterPractice = (filterOption: string) => {
    const filterPractice =
      filterOption !== ALL
        ? practices.filter((practice) => {
            if (filterOption === ACTIVE) {
              return practice.isActive;
            }
            return !practice.isActive;
          })
        : practices;
    return filterPractice;
  };

  const filterPractice = getFilterPractice(selectedFilter);

  const isOptionDropdownDisabled = (option: string) => {
    return (
      totalPracticeByStatus?.[
        option.toLowerCase() as keyof typeof totalPracticeByStatus
      ] === 0
    );
  };

  const onFilterOptionClicked = (option: string) => {
    const firstPractice = getFilterPractice(option)[0];

    setSelectedFilter(option);
    setSelectedPractice(firstPractice);
  };

  return (
    <>
      {isModalOpen && (
        <CreatePracticeModal
          onClose={() => setisModalOpen(false)}
          setSelectedPractice={setSelectedPractice}
        />
      )}
      <SidePanel
        title={'Practices'}
        isCollapsed={isCollapsed}
        onCollapsedIconClicked={() => setIsCollapsed(!isCollapsed)}
        selectedFilter={selectedFilter}
        onFilterOptionClicked={onFilterOptionClicked}
        isOptionDropdownDisabled={isOptionDropdownDisabled}
        numberOfFilters={filterPractice.length}
        options={PRACTICE_FILTER_OPTIONS}
        onAddClick={() => setisModalOpen(true)}
      >
        {filterPractice.map((practice) => (
          <Item
            key={practice.id}
            selectedPracticeId={selectedPractice.id}
            practice={practice}
            isCollapsed={isCollapsed}
            onClick={() => setSelectedPractice(practice)}
          />
        ))}
      </SidePanel>
    </>
  );
};

export default PracticePanel;

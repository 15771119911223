import { FC, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import DefaultModal from 'components/Modals/DefaultModal';
import CommonButton from 'components/CommonButton/CommonButton';
import SelectHoursRange from 'components/SelectHoursRange/SelectHoursRanger';

import SelectType from 'components/SelectType/SelectType';
import DateRange, { IDateRange } from 'components/DateRange/DateRange';
import ApplyOption from 'components/ApplyOption/ApplyOption';

import { NEXT_23_MONTH } from 'utils/constants';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { WeekWorkingHour, WorkingHour } from 'interfaces/clinicSchedule';
import {
  getInitialType,
  getInitialWorkingHours,
} from 'utils/getInitialEditScheduleState';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import useClinicDateOff from 'hooks/useClinicDateOff';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import getQueryTimeSlotPerDay from 'utils/getQueryTimeSlotPerDay';
import { PractitionerException } from 'interfaces/practitionerException';
import OverrideModal from '../OverrideModal/OverrideModal';
import { IPractitioner } from 'interfaces/practitioners';
import { PractitionerSchedule } from 'interfaces/practitionerSchedule';
import useModal from 'hooks/useModal';

interface EditScheduleModalProps {
  selectedDate: {
    date: string;
    workingHour: string | WorkingHour[];
  };
  onClose: () => void;
  selectedPractitioner: IPractitioner;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
  version: number;
  isCreatedMode?: boolean;
  practitionerSchedule?: PractitionerSchedule;
}

const options = [
  { label: 'Working Day', isWorkingDay: true },
  { label: 'Day Off', isWorkingDay: false },
];

const EditScheduleModal: FC<EditScheduleModalProps> = ({
  selectedDate,
  onClose,
  selectedPractitioner,
  onCheckOutsidePractitionerHourAppts,
  version,
  isCreatedMode,
  practitionerSchedule,
}) => {
  const url = `/practitioners/${selectedPractitioner.id}/exceptions`;

  const { data, isLoading } = useClinicDateOff({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().add(NEXT_23_MONTH, 'months').format('YYYY-MM-DD'),
    isClosed: true,
  });

  const { setIsAllowed } = useModal({ onClose, isLoading });

  const matchMutate = useMatchMutate();

  const [selectedType, setSelectedType] = useState(() =>
    getInitialType(selectedDate.workingHour, options)
  );

  const [isSingleDay, setIsSingleDay] = useState(true);

  const [workingHours, setWorkingHours] = useState(() =>
    getInitialWorkingHours(selectedDate.workingHour)
  );

  const [selectedSingleDate, setSelectedSingleDate] = useState(() =>
    moment(selectedDate.date).toDate()
  );

  const [dateRange, setDateRange] = useState<IDateRange>(() => ({
    dateFrom: moment(selectedDate.date).toDate(),
    dateTo: null,
  }));

  const dateRangePickerRef = useRef<HTMLButtonElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [exceptionDates, setExceptionDates] = useState<
    PractitionerException[] | null
  >(null);

  const handleUpdateWorkingHours = (_: string, value: string, type: string) => {
    setWorkingHours({ ...workingHours, [type]: value });
  };

  useEffect(() => {
    if (!data) return;

    const isOfficeClosedIncludeDateFrom = !!data.find(
      (d) => d.date === moment(dateRange.dateFrom).format('YYYY-MM-DD')
    );

    if (isOfficeClosedIncludeDateFrom) {
      setDateRange((dateRange) => ({ ...dateRange, dateFrom: null }));
    }
  }, [data, dateRange.dateFrom]);

  const getButtonDisabledState = () => {
    const isDayRangeOptionNotSelect = !dateRange.dateTo && !isSingleDay;

    const isSelectedDateOfficeClosed = isSingleDay
      ? !!data?.find(
          (d) => d.date === moment(selectedSingleDate).format('YYYY-MM-DD')
        )
      : !!data?.find(
          (d) => d.date === moment(dateRange.dateFrom).format('YYYY-MM-DD')
        );

    return isDayRangeOptionNotSelect || isSelectedDateOfficeClosed;
  };

  const getExcludeDates = () => {
    if (!data) return [];
    return data.map((d) => moment(d.date).toDate());
  };

  const getChangedTimeSlots = () => {
    const changedTimeSlots: WeeklyTimeslot[] = [];

    const updateTimeSlot = {
      start: selectedType.isWorkingDay ? workingHours.start : null,
      end: selectedType.isWorkingDay ? workingHours.end : null,
    };

    changedTimeSlots.push({
      ...updateTimeSlot,
      day: moment(dateRange.dateFrom).format('ddd'),
    });

    const endDay = moment(dateRange.dateTo);

    // GET DAY RANGE
    const daysPerWeek = 7;
    for (let i = 1; i < daysPerWeek; i++) {
      const nextDay = moment(dateRange.dateFrom).add(i, 'd');
      if (nextDay.isSameOrBefore(endDay)) {
        const day = nextDay.format('ddd').toLowerCase();
        changedTimeSlots.push({ ...updateTimeSlot, day });
      }
    }

    return changedTimeSlots;
  };

  const onUpdatedSchedule = async () => {
    const body = [];

    const startDate = moment(
      isSingleDay ? selectedSingleDate : dateRange.dateFrom
    );

    const { data: practitionerExceptions } = await axiosInstance.get<
      PractitionerException[]
    >(url, {
      params: {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: moment(dateRange.dateTo).format('YYYY-MM-DD'),
      },
    });

    const officeClosedDates = data!.map(
      (officeClosedDate) => officeClosedDate.date
    );

    for (let i = 0; i < practitionerExceptions.length; i++) {
      const isExcpetionDate = practitionerExceptions[i].isException;

      const isCurrentDateOfficeClosed = officeClosedDates.includes(
        practitionerExceptions[i].date
      );

      if (isExcpetionDate || isCurrentDateOfficeClosed) continue;

      const day = {
        date: practitionerExceptions[i].date,
        isClosed: !selectedType.isWorkingDay,
        shifts: [
          {
            start: selectedType.isWorkingDay ? workingHours.start : null,
            end: selectedType.isWorkingDay ? workingHours.end : null,
          },
        ],
      };
      body.push(day);
    }

    if (body.length === 0) return;

    await axiosInstance.post(url, body, {
      headers: {
        version,
      },
    });
  };

  const handleExceptionChecked = async () => {
    setIsSubmitting(true);
    const changedTimeSlots = getChangedTimeSlots();

    const queryTimeSlotPerDay = getQueryTimeSlotPerDay(changedTimeSlots);

    try {
      const response = await axiosInstance.get<PractitionerException[]>(
        `/practitioners/${selectedPractitioner.id}/custom-days`,
        {
          params: {
            startDate: moment(dateRange.dateFrom).format('YYYY-MM-DD'),
            endDate: moment(dateRange.dateTo).format('YYYY-MM-DD'),
            ...queryTimeSlotPerDay,
          },
        }
      );
      const isExceptionDateFound = response.data.length > 0;

      const officeClosedDates = data!.map(
        (officeClosedDate) => officeClosedDate.date
      );

      const nonOfficeClosedDates = response.data.filter(
        (exceptionDate) => !officeClosedDates.includes(exceptionDate.date)
      );

      if (isExceptionDateFound && nonOfficeClosedDates.length > 0) {
        setIsSubmitting(false);
        setExceptionDates(nonOfficeClosedDates);
        return;
      }

      await onUpdatedSchedule();

      await Promise.all([
        matchMutate(/\/exceptions\?[\s\S]+/),
        matchMutate(/\/custom-days\?[\s\S]+/),
        onCheckOutsidePractitionerHourAppts(),
      ]);
      renderToast({
        message: 'Custom hours have been updated successfully',
        type: 'success',
      });

      onClose();
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    setIsSubmitting(false);
  };

  const handleUpdateSingle = async () => {
    setIsSubmitting(true);

    try {
      // CHECK IF SELECTED DAY IS CUSTOM DAY OF NOT
      const response = await axiosInstance.get(url, {
        params: {
          startDate: moment(selectedSingleDate).format('YYYY-MM-DD'),
          endDate: moment(selectedSingleDate)
            .add(1, 'days')
            .format('YYYY-MM-DD'),
        },
      });

      const exceptionDateId = response.data[0]?.id;

      const body = [
        {
          date: moment(selectedSingleDate).format('YYYY-MM-DD'),
          isClosed: !selectedType.isWorkingDay,
          shifts: [
            {
              start: selectedType.isWorkingDay ? workingHours.start : null,
              end: selectedType.isWorkingDay ? workingHours.end : null,
            },
          ],
          ...(exceptionDateId && { id: exceptionDateId }),
        },
      ];

      if (exceptionDateId) {
        await axiosInstance.patch(url, body, {
          headers: {
            version,
          },
        });
      } else {
        await axiosInstance.post(url, body, {
          headers: {
            version,
          },
        });
      }

      await Promise.all([
        matchMutate(/\/exceptions\?[\s\S]+/),
        matchMutate(/\/custom-days\?[\s\S]+/),
        onCheckOutsidePractitionerHourAppts(),
      ]);

      renderToast({
        message: 'Custom hours have been updated successfully',
        type: 'success',
      });

      onClose();
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
      setIsSubmitting(false);
    }
  };

  const handleConfirm = async () => {
    setIsAllowed(false);
    if (!isSingleDay) {
      await handleExceptionChecked();
      return;
    }
    await handleUpdateSingle();
  };

  const getWorkingHoursForExceptionClosedDate = () => {
    if (!practitionerSchedule) {
      return;
    }

    const date = moment(selectedDate.date).format('ddd').toLowerCase();

    const value = practitionerSchedule.workingHour[
      date as keyof WeekWorkingHour
    ]![0] as WorkingHour;

    return { start: value.start!, end: value.end! };
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return exceptionDates ? (
    <OverrideModal
      exceptionDates={exceptionDates as any}
      changedTimeSlots={getChangedTimeSlots()}
      selectedPractitioner={selectedPractitioner}
      onUpdateSchedule={onUpdatedSchedule}
      onClose={() => {
        setExceptionDates(null);
        onClose();
      }}
      onBtnClose={() => setExceptionDates(null)}
      version={version}
      onCheckOutsidePractitionerHourAppts={onCheckOutsidePractitionerHourAppts}
    />
  ) : (
    <DefaultModal className="top-[45%]">
      <div className="w-[394px] min-h-[290px] pl-3 pr-3.3 pt-2.2 pb-2.6 text-14 leading-[2.1rem] text-darkest-grey relative">
        <h3 className="text-16 leading-[2.4rem] font-bold">
          {isCreatedMode ? 'Add custom day(s)' : 'Edit practitioner schedule'}
        </h3>
        <div className="flex items-center mt-1.5">
          <span className="text-13 leading-[2rem]">Type</span>
          <SelectType
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            options={options}
          />
        </div>
        <div className="mt-2.7">
          <div
            className={`flex items-center ${
              !selectedType.isWorkingDay ? 'opacity-30 relative -z-10' : ''
            }`}
          >
            <span className="text-13 leading-[2rem]">Hours</span>
            <SelectHoursRange
              day={selectedDate.date}
              defaultValue={{
                start:
                  typeof selectedDate.workingHour === 'string'
                    ? getWorkingHoursForExceptionClosedDate()!.start
                    : workingHours.start,
                end:
                  typeof selectedDate.workingHour === 'string'
                    ? getWorkingHoursForExceptionClosedDate()!.end
                    : workingHours.end,
              }}
              start={
                typeof selectedDate.workingHour === 'string'
                  ? getWorkingHoursForExceptionClosedDate()!.start
                  : workingHours.start
              }
              end={
                typeof selectedDate.workingHour === 'string'
                  ? getWorkingHoursForExceptionClosedDate()!.end
                  : workingHours.end
              }
              onChange={handleUpdateWorkingHours}
            />
          </div>
        </div>
        <div className="mt-2.9">
          <div className="flex items-center">
            <span className="text-13 leading-[2rem]">Apply to</span>
            <ApplyOption
              isSingleDay={isSingleDay}
              setIsSingleDay={setIsSingleDay}
              dateTo={dateRange.dateTo}
              dateRangePickerRef={dateRangePickerRef}
            />
          </div>
        </div>

        <div className="mt-1.9 flex">
          {isSingleDay && (
            <CustomDatePicker
              excludeDates={getExcludeDates()}
              selected={selectedSingleDate}
              onChange={(date: Date | null) => {
                if (date) {
                  setSelectedSingleDate(date);
                }
              }}
              minDate={new Date()}
              maxDate={moment().add(NEXT_23_MONTH, 'months').toDate()}
              customInput={
                <div className="flex items-center">
                  <span className="text-13 leading-[2rem]">Date</span>
                  <button
                    className={`w-full h-3 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active px-1.6 ml-2.4`}
                  >
                    {moment(selectedSingleDate).format('MMMM DD, YYYY')}
                  </button>
                </div>
              }
              popperModifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [30, -2],
                  },
                },
              ]}
            />
          )}
          <div className={isSingleDay ? 'hidden' : 'flex'}>
            <DateRange
              dateRange={dateRange}
              setDateRange={setDateRange}
              dateRangePickerRef={dateRangePickerRef}
              excludeDates={getExcludeDates()}
            />
          </div>
        </div>

        <div className="mt-2.8 flex gap-x-1.6 justify-end">
          <CommonButton
            variant="secondary"
            className="!min-w-[12rem]"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </CommonButton>
          <CommonButton
            className="!min-w-[12rem]"
            disabled={getButtonDisabledState()}
            onClick={handleConfirm}
            isLoading={isSubmitting}
          >
            Confirm
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default EditScheduleModal;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

interface IWidgetGenerateResponse {
  widgetKey: string;
}

const useWidgetGenerate = () => {
  const id = loadSelectedClinicId();

  const { data, error, mutate } = useSWR(
    ['/widget/generate', id],
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get<IWidgetGenerateResponse>(url);

      return response.data;
    }
  );

  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export default useWidgetGenerate;

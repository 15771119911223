import { SPECIALTY_NAME } from './constants';

const getPractitionerName = <
  T extends {
    firstName: string;
    name: string;
    title: string;
    specialist: string;
  }
>(
  agr: T
) => {
  const { firstName, name, specialist, title } = agr;
  if (specialist === SPECIALTY_NAME.HYGIENIST) {
    return firstName;
  }

  return title ? `${title}. ${name}` : name;
};

export default getPractitionerName;

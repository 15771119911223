import { FC, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import toast from 'react-hot-toast';
import { Popover } from '@headlessui/react';
import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import Toast, { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { getErrorReason } from 'utils/common';
import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import { IAppointmentDetail } from 'interfaces/appointments';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

interface PopoverOptionProps {
  appointment: IAppointmentDetail;
  isAppointmentInThePast?: boolean;
}

const {
  DUPLICATE_BOOKING,
  OUT_OF_OFFICE_HOURS,
  OUT_OF_PRACTITIONER_HOURS,
  PARTIAL_PATIENT_MATCH,
  GENERAL_ERROR,
  PATIENT_NEED_ADD_TO_FAMILY,
  WRITE_FAIL,
} = ERROR_REASON;

const PopoverOption: FC<PopoverOptionProps> = ({
  appointment,
  isAppointmentInThePast,
}) => {
  const errorReason = appointment && getErrorReason(appointment);

  const matchMutate = useMatchMutate();

  const [errorModal, setErrorModal] = useState('');
  const [confirmModalMode, setConfirmModalMode] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  const getOptions = () => {
    if (isAppointmentInThePast) {
      return ['Accept'];
    }

    const options = ['Reject'];

    if (!errorReason && appointment.status !== 'accepted') {
      options.unshift('Accept');
    }

    if (
      errorReason === DUPLICATE_BOOKING ||
      errorReason === OUT_OF_OFFICE_HOURS ||
      errorReason === OUT_OF_PRACTITIONER_HOURS
    ) {
      options.unshift('Modify Appointment');
    }
    if (errorReason === PARTIAL_PATIENT_MATCH) {
      options.unshift('Select matching patient');
    }
    if ([GENERAL_ERROR, WRITE_FAIL].includes(errorReason)) {
      options.unshift('Accept');
    }
    return options;
  };

  const checkedIfErrorIsTheSame = async () => {
    setIsChecking(true);
    let isErrorTheSame = true;
    try {
      const response = await axiosInstance.get(
        `/appointments/${appointment.id}`
      );

      const errorReason = getErrorReason(response.data);

      if (!errorReason) {
        isErrorTheSame = false;
        await matchMutate(/\/appointments\?[\s\S]+/);
        handleAppointmentAlreadyResolved(false);
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsChecking(false);
    return isErrorTheSame;
  };

  const handleSubmitConfirmation = async () => {
    try {
      const updatedStatus =
        confirmModalMode === 'Accept' ? 'accepted' : 'cancelled';

      const toastMessage =
        confirmModalMode === 'Accept' ? 'accepted' : 'rejected';

      await axiosInstance.patch('/appointments', [
        {
          appointmentId: appointment.id,
          status: updatedStatus,
        },
      ]);
      await matchMutate(/\/appointments\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Successfully ${toastMessage} appointment`,
      });
      setConfirmModalMode('');
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const handleClickOption = async (option: string) => {
    if (option === 'Accept' || option === 'Reject') {
      return setConfirmModalMode(option);
    }

    const isErrorTheSame = await checkedIfErrorIsTheSame();
    if (!isErrorTheSame) return;

    if (option === 'Modify Appointment') {
      return setErrorModal('timeslot');
    }
    if (option === 'Select matching patient') {
      return setErrorModal('patient');
    }
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{appointment.patient.name}</strong> has been resolved.
        Please accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  if (
    ![APPOINTMENT_STATUS.NEW.KEY, APPOINTMENT_STATUS.ERROR.KEY].includes(
      appointment.status
    )
  ) {
    return null;
  }

  return (
    <>
      {isChecking && <LoadingScreen />}
      {errorModal === 'timeslot' && (
        <DuplicateAppointment
          id={appointment.id}
          mode={errorReason}
          onClose={() => setErrorModal('')}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}
      {confirmModalMode && (
        <ConfirmationModal
          title={`${confirmModalMode} appointment`}
          description={`Are you sure you want to ${confirmModalMode.toLowerCase()} this appointment?`}
          onClose={() => setConfirmModalMode('')}
          onSubmit={handleSubmitConfirmation}
          submitBtnTitle={`Yes, ${confirmModalMode.toLowerCase()}`}
        />
      )}
      {errorModal === 'patient' && (
        <PartialPatient
          id={appointment.id}
          onClose={() => setErrorModal('')}
          onErrorPersist={(message: string) => {
            toast((t) => {
              t.duration = Infinity;
              return (
                <Toast
                  id={t.id}
                  message={
                    <>
                      This partial patient match error for{' '}
                      <strong>{appointment.patient.name}</strong> has been
                      resolved. {message}
                    </>
                  }
                  type={'warning'}
                  isButtonIncluded
                  buttonTitle="Resolve Now"
                  onButtonClicked={() => {
                    setErrorModal('timeslot');
                  }}
                />
              );
            });
          }}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
        />
      )}
      <Popover.Panel
        className={`flex flex-col gap-y-1.6 absolute py-2 -right-10 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 ${
          errorReason &&
          ![GENERAL_ERROR, PATIENT_NEED_ADD_TO_FAMILY, WRITE_FAIL].includes(
            errorReason
          ) &&
          !isAppointmentInThePast
            ? 'w-21'
            : 'w-16.5'
        }`}
      >
        {getOptions().map((option) => (
          <Popover.Button
            key={option}
            className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={() => handleClickOption(option)}
          >
            {option}
          </Popover.Button>
        ))}
      </Popover.Panel>
    </>
  );
};

export default PopoverOption;

import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';

import DayCell from './DayCell/DayCell';
import EditScheduleModal from '../EditScheduleModal/EditScheduleModal';
import { ClinicException } from 'interfaces/clinicException';

import { CUSTOM_HOURS, WORKING_HOUR_SYSTEM } from 'utils/constants';
import './Calendar.module.css';
import { ClinicSchedule } from 'interfaces/clinicSchedule';
import Legend from 'components/Legend/Legend';

const Calendar = ({
  googlePlaceId,
  date,
  data,
  highlightedDate,
  resetHighlightedDate,
  onCheckOutsideOfficeHourAppts,
  clinicSchedule,
}: {
  googlePlaceId: string | null;
  date: string;
  data: ClinicException[];
  highlightedDate: string;
  resetHighlightedDate: () => void;
  onCheckOutsideOfficeHourAppts: () => Promise<void>;
  clinicSchedule: ClinicSchedule;
}) => {
  const [selectedDate, setSelectedDate] = useState<ClinicException | null>(
    null
  );

  const isDefaultSchedule =
    clinicSchedule!.currentWorkingHour === WORKING_HOUR_SYSTEM.FIRSTIN;

  const calendarRef = useRef<FullCalendar | null>(null);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const parsedDate = date
        ? moment(date, 'YYYY-MM-DD').toDate()
        : new Date();

      calendarApi.gotoDate(parsedDate);
    }
  }, [date]);

  const handleCellClicked = (clinicException: ClinicException) => {
    setSelectedDate(clinicException);
  };

  return (
    <>
      {selectedDate && (
        <EditScheduleModal
          selectedDate={selectedDate}
          onClose={() => setSelectedDate(null)}
          source={clinicSchedule!.currentWorkingHour}
          onCheckOutsideOfficeHourAppts={onCheckOutsideOfficeHourAppts}
          version={clinicSchedule!.version}
          clinicSchedule={clinicSchedule}
        />
      )}
      <div className="mt-2" id="schedule-calendar">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          initialDate={new Date()}
          contentHeight={483}
          fixedWeekCount
          showNonCurrentDates
          headerToolbar={false}
          dayCellClassNames={'h-[75px]'}
          dayCellContent={(props) => {
            let exception;

            if (!googlePlaceId && !isDefaultSchedule) {
              exception = null;
            } else {
              exception =
                (data &&
                  data.find(
                    (item) =>
                      moment(props.date).format('YYYY-MM-DD') === item.date
                  )) ||
                null;
            }

            return (
              <DayCell
                {...props}
                exception={exception}
                selectedDate={date}
                onCellClicked={handleCellClicked}
                highlightedDate={highlightedDate}
                resetHighlightedDate={resetHighlightedDate}
              />
            );
          }}
        />

        <div className="flex gap-x-6 justify-end mt-1.6">
          <Legend icon="circle">{CUSTOM_HOURS}</Legend>
          <Legend icon="square">Closed</Legend>
        </div>
      </div>
    </>
  );
};

export default Calendar;

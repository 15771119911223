import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const getDateRange = (start: Date, end: Date) => {
  const rangeDates = [];

  const range = moment.range(start, end);
  for (let day of range.by('days') as any) {
    rangeDates.push(day.format('YYYY-MM-DD'));
  }

  return rangeDates;
};

export default getDateRange;

import React from 'react';
import moment from 'moment';
import { DayCellContentArg } from '@fullcalendar/react';
import { ITimeslots } from '../MonthView';

import styles from './DayCell.module.css';

const DayCell = (
  props: DayCellContentArg & { timeslots: ITimeslots[]; isLoading: boolean }
) => {
  const dayNumberText =
    props.isOther && props.isFuture && props.dayNumberText === '1'
      ? moment(new Date(props.date)).format('D MMM')
      : props.dayNumberText;

  const blocks =
    props.timeslots.find((item: any) =>
      moment(item.date).isSame(props.date, 'days')
    )?.blocks || [];

  return (
    <div className={styles.cellWrapper}>
      <div>
        <span className={styles.dayNumber}>{dayNumberText.split(' ')[0]}</span>
        {dayNumberText.split(' ')[1] && (
          <span className={styles.monthNumber}>
            &nbsp;{dayNumberText.split(' ')[1]}
          </span>
        )}
      </div>
      {props.isLoading ? (
        <div className={styles.loading} />
      ) : (
        <>
          {!props.isPast && blocks.length === 0 && (
            <div className={styles.openSlot}>-</div>
          )}
          {!props.isPast && blocks.length !== 0 && (
            <div className={styles.openSlot}>{`${blocks.length} open`}</div>
          )}
        </>
      )}
    </div>
  );
};

export default DayCell;

import React from 'react';
import { Link } from 'react-router-dom';

import CardContainer from 'components/Layout/CardContainer/CardContainer';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ROUTES } from 'utils/constants/routes';
import NavigationBar from 'components/NavigationBar/NavigationBar';

const NotFoundPage = () => {
  return (
    <>
      <NavigationBar />
      <CardContainer>
        <div className="py-4 flex flex-col justify-center items-center gap-y-2.4">
          <CrossIcon />
          <span className="text-16 font-bold leading-[2.4rem]">
            Invalid or expired link
          </span>
          <Link
            to={ROUTES.DASHBOARD}
            className="px-3.9 py-1 bg-magenta text-white font-bold rounded-full hover:bg-magenta-lighter"
          >
            Go to Practice App
          </Link>
        </div>
      </CardContainer>
    </>
  );
};

export default NotFoundPage;

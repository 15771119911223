import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { IService } from 'hooks/useClinicService';
import CreateOrEditService, {
  CreateOrEditServiceSubmitData,
} from 'pages/ServicesPage/Table/CreateOrEditService/CreateOrEditService';
import axiosInstance from 'apis/axiosInstance';
import {
  convertDurationForBE,
  convertDurationForFE,
} from 'utils/convertDuration';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

interface ThreeDotsOptionProps {
  service: IService;
  isSubRow: boolean;
  isParent: boolean;
}

const OPTIONS = {
  EDIT: { label: 'Edit', value: 'edit' },
  AVAILABLE: { label: 'Set as available', value: 'available' },
  UNAVAILABLE: { label: 'Set as unavailable', value: 'unavailable' },
  DELETE: { label: 'Delete', value: 'delete' },
};

const { EDIT, AVAILABLE, UNAVAILABLE, DELETE } = OPTIONS;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  isSubRow,
  service,
  isParent,
}) => {
  const url = `/services/${service.id}`;

  const [modalMode, setModalMode] = useState<typeof EDIT | null>(null);
  const matchMutate = useMatchMutate();

  const defaultValues = {
    name: service.name,
    duration: convertDurationForFE(service.duration),
    status: service.isActive ? 'active' : 'inactive',
  };

  const getOptions = () => {
    const statusOption = service.isActive ? UNAVAILABLE : AVAILABLE;

    if (isParent) {
      return [statusOption];
    }

    const options = [EDIT];

    if (isSubRow) {
      return options;
    }

    options.push(statusOption);

    options.push(DELETE);

    return options;
  };

  const onClose = () => setModalMode(null);

  const onError = (error: any) => {
    let message = 'Something went wrong. Please try again later';
    if (error.response?.status === BAD_REQUEST) {
      message = error.response?.data.message;
    }

    renderToast({
      type: 'error',
      message,
    });
  };

  const onEditService = async (data: CreateOrEditServiceSubmitData) => {
    try {
      await axiosInstance.patch(url, {
        name: data.name,
        duration: convertDurationForBE(data.duration!),
      });
      await matchMutate(/\/services\/global-admin\?[^/]+/);
      renderToast({
        type: 'success',
        message: 'Updated a service successfully',
      });
      onClose();
    } catch (error: any) {
      onError(error);
    }
  };

  const onEditServiceStatus = async () => {
    try {
      await axiosInstance.patch(url, {
        isActive: !service.isActive,
      });

      await matchMutate(/\/services\/global-admin\?[^/]+/);
      renderToast({
        type: 'success',
        message: `${modalMode!.label} a service successfully`,
      });
      onClose();
    } catch (error: any) {
      onError(error);
    }
  };

  const onDeleteService = async () => {
    try {
      await axiosInstance.delete(url);

      await matchMutate(/\/services\/global-admin\?[^/]+/);
      renderToast({
        type: 'success',
        message: 'Deleted a service successfully',
      });
      onClose();
    } catch (error: any) {
      onError(error);
    }
  };

  return (
    <>
      {modalMode === EDIT && (
        <CreateOrEditService
          isNameDisabled={isSubRow}
          onClose={onClose}
          onSubmit={onEditService}
          defaultValues={defaultValues}
          isExamCleaningService={isSubRow}
        />
      )}
      {(modalMode === AVAILABLE || modalMode === UNAVAILABLE) && (
        <ConfirmationModal
          title={`Set service as ${modalMode.value}?`}
          description={`Are you sure you want to set ${service.name} as ${modalMode.value}?`}
          onClose={onClose}
          onSubmit={onEditServiceStatus}
          submitBtnTitle="Yes, confirm"
        />
      )}
      {modalMode === DELETE && (
        <ConfirmationModal
          title={`Delete service?`}
          description={`Are you sure you want to delete ${service.name}?`}
          onClose={onClose}
          onSubmit={onDeleteService}
          submitBtnTitle="Yes, delete"
        />
      )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button className="flex items-center disabled:cursor-not-allowed disabled:opacity-30">
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 w-16.5">
          {getOptions().map((option) => (
            <Popover.Button
              key={option.value}
              className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left"
              onClick={() => setModalMode(option)}
            >
              {option.label}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;

import useSWR from 'swr';

import axiosInstance from 'apis/axiosInstance';

interface IUseNextAvailabilityParams {
  shouldRun?: boolean;
  serviceId: string;
  date: string;
  practitionerId: string;
}

const useNextAvailability = ({
  shouldRun,
  serviceId,
  date,
  practitionerId,
}: IUseNextAvailabilityParams) => {
  const { data, error } = useSWR(
    // Request depend on startDateBlock as well
    shouldRun
      ? `/practitioners/${practitionerId}/appointments/next-availability?date=${date}`
      : null,
    async (key) => {
      const url = key.split('?')[0];
      const response = await axiosInstance.post<{
        nextAvailabilityDate: string;
      }>(url, {
        serviceId,
        date: date,
      });
      return response.data;
    }
  );

  return {
    data,
    error,
    isLoading: !data && !error,
  };
};

export default useNextAvailability;

import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { Specialty } from 'interfaces/specialty';

const useSpecialty = () => {
  const { data, error } = useSWR('/specialties', async (url) => {
    const response = await axiosInstance.get<Specialty[]>(url);
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
  };
};

export default useSpecialty;

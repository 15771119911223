import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import CommonButton from 'components/CommonButton/CommonButton';
import { renderToast } from 'components/Toast/Toast';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { IAppointmentStatus } from 'hooks/useAppointment';
import { loadAuthToken } from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import { IAppointmentDetail } from 'interfaces/appointments';
import { BOOKING_TYPE, PMS_TYPE, SERVICE_NAME } from 'utils/constants';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import { DATE_TIME_FORMAT, datetimeFormat } from 'utils/datetime';

const HEADERS = [
  { label: '', key: 'type' },
  { label: '', key: 'total' },
  { label: 'Patient name', key: 'patientName' },
  { label: 'Patient phone number', key: 'patientPhoneNumber' },
  { label: 'Patient dob', key: 'patientDob' },
  { label: 'Appointment created at', key: 'createdAt' },
  { label: 'Reason', key: 'serviceName' },
  { label: 'Practice name', key: 'clinicName' },
  { label: 'Booking type', key: 'bookingType' },
];

const BOOKING_TYPE_CONTENT = {
  NEW_PATIENT: 'New Patients booked via FirstIn',
  EXISTING_PATIENT_BY_SIGN_IN:
    'Existing Patients booked via FirstIn and were signed in',
  EXISTING_PATIENT_BY_NON_SIGN_IN:
    'Existing patients booked via FirstIn and were not signed in',
  EXISTING_PATIENT_BY_SIGN_IN_WITH_EXAM_CLEANING:
    'Existing Patients booked via FirstIn and were signed in, booked Exam & Cleaning via Smart Link',
  EXISTING_PATIENT_BY_NON_SIGN_IN_WITH_EXAM_CLEANING:
    'Existing patients booked via FirstIn and were not signed in, booked Exam & Cleaning via Smart Link',
};

const CSVScheduleInsights = ({
  appointmentStatus,
  clinicId,
  dateRange,
  sort,
  sortBy,
}: {
  appointmentStatus: IAppointmentStatus;
  clinicId: string | null;
  dateRange: {
    fromDate: string;
    toDate: string;
  };
  sort: 'asc' | 'desc';
  sortBy: string;
}) => {
  const csvRef = useRef<any>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [csvData, setCsvData] = useState<any>([]);

  const getAppointment = async () => {
    setIsLoading(true);

    try {
      const query = new URLSearchParams({
        startDate: moment(dateRange.fromDate, 'YYYY-MM-DD')
          .startOf('days')
          .utc(true)
          .format(),
        endDate: moment(dateRange.toDate, 'YYYY-MM-DD')
          .endOf('days')
          .utc(true)
          .format(),
        sort,
        sortBy,
        ...(appointmentStatus !== 'all' && { status: appointmentStatus }),
        pmsType: PMS_TYPE.FIRST_IN,
      }).toString();

      const url = `/appointments?${query}`;
      const { data: axiosAppointmentResponse } = await axiosInstance.get(url, {
        headers: {
          Authorization: `JWT ${loadAuthToken()?.accessToken}`,
          ...(clinicId && { 'X-ClinicId': clinicId }),
        },
      });

      const csvData = axiosAppointmentResponse.data.map(
        (item: IAppointmentDetail) => {
          const bookingTypeAppt = item.bookingType || [];

          let bookingType = 'Old data';

          if (item.patient?.isNewPatient) {
            bookingType = BOOKING_TYPE_CONTENT.NEW_PATIENT;
          } else {
            // Exam & Cleaning service
            const isBookedByExamAndCleaning =
              item.service?.name?.startsWith(SERVICE_NAME.EXAM_CLEANING) ||
              false;

            if (isBookedByExamAndCleaning) {
              if (bookingTypeAppt.includes(BOOKING_TYPE.SIGN_IN)) {
                bookingType =
                  BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN_WITH_EXAM_CLEANING;
              }

              if (bookingTypeAppt.includes(BOOKING_TYPE.NON_SIGN_IN)) {
                bookingType =
                  BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN_WITH_EXAM_CLEANING;
              }
            } else {
              if (bookingTypeAppt.includes(BOOKING_TYPE.SIGN_IN)) {
                bookingType = BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN;
              }

              if (bookingTypeAppt.includes(BOOKING_TYPE.NON_SIGN_IN)) {
                bookingType =
                  BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN;
              }
            }
          }

          return {
            patientName: item.patient?.name || '',
            patientPhoneNumber: item.patient?.phoneNumber
              ? formatFullPhoneNumber(`+${item.patient.phoneNumber}`)
              : '',
            patientDob: item.patient?.dob
              ? datetimeFormat({
                  dateString: item.patient.dob,
                  pattern: DATE_TIME_FORMAT.DOB,
                })
              : '',
            createdAt: moment(item.createdAt).format('MMM DD, YYYY hh:mm A'),
            serviceName: item.service?.name,
            clinicName: item.clinic!.name,
            bookingType,
          };
        }
      );

      const bookingTypeInTotal = csvData.reduce(
        (accum: any, currentValue: any) => {
          if (
            [
              BOOKING_TYPE_CONTENT.NEW_PATIENT,
              BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN_WITH_EXAM_CLEANING,
              BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN_WITH_EXAM_CLEANING,
              BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN,
              BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN,
            ].includes(currentValue.bookingType)
          ) {
            accum[currentValue.bookingType] += 1;
          }

          return accum;
        },

        {
          [BOOKING_TYPE_CONTENT.NEW_PATIENT]: 0,
          [BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN_WITH_EXAM_CLEANING]: 0,
          [BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN_WITH_EXAM_CLEANING]: 0,
          [BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_SIGN_IN]: 0,
          [BOOKING_TYPE_CONTENT.EXISTING_PATIENT_BY_NON_SIGN_IN]: 0,
        }
      );

      const csvBookingTypeTotalData = Object.keys(bookingTypeInTotal).map(
        (key) => {
          return {
            type: key,
            total: bookingTypeInTotal[key],
          };
        }
      );

      setCsvData([...csvBookingTypeTotalData, {}, ...csvData]);

      if (csvRef.current) {
        csvRef.current.link.click();
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'An error has occurred. Please try again.',
      });
    }

    setIsLoading(false);
  };

  return (
    <>
      <CommonButton
        variant="secondary"
        className="!min-h-[35px] h-3.5 w-18"
        isLoading={isLoading}
        onClick={getAppointment}
      >
        <DownloadIcon />
        <span className="ml-1">Schedule Insights</span>
      </CommonButton>
      <CSVLink
        className="hidden"
        ref={csvRef}
        filename={'schedule-insights-appointments.csv'}
        headers={HEADERS}
        data={csvData}
      />
    </>
  );
};

export default CSVScheduleInsights;

import React, { useState } from 'react';

import Table from './Table/Table';
import CommonButton from 'components/CommonButton/CommonButton';
import CreateOrEditService, {
  CreateOrEditServiceSubmitData,
} from './Table/CreateOrEditService/CreateOrEditService';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { loadSelectedClinicId } from 'utils/storage';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import { convertDurationForBE } from 'utils/convertDuration';
import useOperatory from 'hooks/useOperatory';
import HygieneChairSection from './HygieneChairSection/HygieneChairSection';
import useClinic from 'hooks/useClinic';

const ServicesPage: React.FC = () => {
  const { data: operatories } = useOperatory();

  const { data: clinic } = useClinic();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isSortingEnabled, setIsSortingEnabled] = useState(false);

  const isHygieneChairSetup = operatories?.some((item) => item.isHygieneChair);

  const matchMutate = useMatchMutate();

  const onCreateService = async (data: CreateOrEditServiceSubmitData) => {
    const id = loadSelectedClinicId();
    try {
      await axiosInstance.post(`clinics/${id}/services`, {
        ...data,
        duration: convertDurationForBE(data.duration!),
        isActive: data.status === 'active',
      });
      await matchMutate(/\/services\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: 'Added a service successfully',
      });
      setIsModalOpen(false);
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  return (
    <>
      {isModalOpen && (
        <CreateOrEditService
          isCreateMode
          onClose={() => setIsModalOpen(false)}
          onSubmit={onCreateService}
          timeInterval={clinic?.timeInterval}
        />
      )}
      <div className="py-2.4 pl-1.8 pr-4 text-14 leading-[2.1rem] text-darkest-grey relative">
        <div className="flex flex-row justify-between">
          <h3 className="text-24 font-bold leading-[3.6rem]">Services</h3>
        </div>

        <div className="flex mt-0.8">
          <CommonButton
            className="w-18 !min-h-[3.2rem]"
            variant="secondary"
            onClick={() => setIsModalOpen(true)}
          >
            New Service
          </CommonButton>
        </div>

        <HygieneChairSection />

        <div className="bg-white shadow-primary rounded-[1rem]">
          <Table
            isSortingEnabled={isSortingEnabled}
            setIsSortingEnable={setIsSortingEnabled}
            isHygieneChairSetup={Boolean(isHygieneChairSetup)}
          />
        </div>
      </div>
    </>
  );
};

export default ServicesPage;

import { IPMSAppointment } from 'hooks/usePMSAppointment';
import { IPractitioner } from 'interfaces/practitioners';
import { BACKGROUND_COLOR_CODES, COLOR_CODES } from 'utils/constants';
import { IOperatory } from 'interfaces/useReducerTypes';
import {
  PRACTITIONER_OFF,
  PRACTITIONER_WITH_NO_APPOINTMENT,
  PRACTITIONER_WITH_NO_OPERATORY,
} from '../TimeGrid/utils';

const DEFAULT_COLOR_CODE = '#373737';
const DEFAULT_BACKGROUND_COLOR_CODE = '#FFFFFF';

export interface IMapPractitionerColor extends IPractitioner {
  colorCode: string;
  backgroundColorCode: string;
}

const getMapPractitionerColor = (
  practitioners?: IPractitioner[]
): IMapPractitionerColor[] => {
  if (!practitioners) return [];
  const data = practitioners.map((practitioner, index) => ({
    ...practitioner,
    colorCode: COLOR_CODES[index],
    backgroundColorCode: BACKGROUND_COLOR_CODES[index],
  }));
  return data;
};

const getMapPractitionerAppointment = <T extends IPractitioner>(
  practitioners: T[],
  appointments: IPMSAppointment[]
) => {
  const data = practitioners.map((practitioner) => {
    const appointmentsOfPractitioner = appointments.filter(
      (appointment) => appointment.doctor?.id === practitioner.id
    );
    return { ...practitioner, appointments: appointmentsOfPractitioner };
  });
  return data;
};

const getMapOperatoryAppointment = (
  practitioners: IMapPractitionerColor[],
  appointments: IPMSAppointment[],
  operatories: IOperatory[]
) => {
  const data = operatories.map((operatory) => {
    const appointmentsByOp = appointments.filter(
      (appt) => appt.operatory.id === operatory.id
    );

    const normalizedAppts = appointmentsByOp.map((appt) => {
      const colorCode =
        practitioners.find(
          (practitioner) => practitioner.id === appt.doctor?.id
        )?.colorCode || DEFAULT_COLOR_CODE;

      return {
        ...appt,
        colorCode,
      };
    });

    return {
      ...operatory,
      appointments: normalizedAppts,
    };
  });

  return data;
};

const checkSelectedPractitioner = ({
  selectedPractitioner,
  isSelectedPractitionerWorking,
  numberOfAppointments,
}: {
  selectedPractitioner: IMapPractitionerColor | null;
  isSelectedPractitionerWorking: boolean;
  numberOfAppointments: number;
}) => {
  if (!selectedPractitioner) return;

  if (selectedPractitioner && selectedPractitioner.operatories?.length === 0) {
    return [
      {
        id: PRACTITIONER_WITH_NO_OPERATORY,
        name: 'N/A',
        events: [],
        practitioner: selectedPractitioner,
      },
    ];
  } else if (selectedPractitioner && !isSelectedPractitionerWorking) {
    return [
      {
        id: PRACTITIONER_OFF,
        name: 'N/A',
        events: [],
        practitioner: selectedPractitioner,
      },
    ];
  } else if (selectedPractitioner && numberOfAppointments === 0) {
    return [
      {
        id: PRACTITIONER_WITH_NO_APPOINTMENT,
        name: 'N/A',
        events: [],
        practitioner: selectedPractitioner,
      },
    ];
  }
};

export {
  checkSelectedPractitioner,
  getMapPractitionerColor,
  getMapPractitionerAppointment,
  getMapOperatoryAppointment,
  DEFAULT_COLOR_CODE,
  DEFAULT_BACKGROUND_COLOR_CODE,
};

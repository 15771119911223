import { FC } from 'react';

interface CardContainerProps {
  title?: string;
  headerComponent?: React.ReactChild;
}

const CardContainer: FC<CardContainerProps> = ({
  title,
  children,
  headerComponent,
}) => {
  return (
    <div className="w-70 mx-auto mt-3.8 rounded-[1.6rem] border-light-grey border text-darkest-grey text-[1.4rem] leading-[2.1rem]">
      {!headerComponent && title && (
        <h3 className="font-bold text-[2rem] leading-[3rem] px-3 py-1.4 border-b border-light-grey">
          {title}
        </h3>
      )}
      {headerComponent}
      {children}
    </div>
  );
};

export default CardContainer;

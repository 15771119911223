import {
  parsePhoneNumberFromString as parsePhoneNumber,
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from 'libphonenumber-js';
import { isNil } from 'lodash';

export const formatPhoneNumber = (phoneInput: string) => {
  const internationalCode = phoneInput.split(' ')[0];
  const rest = phoneInput.split(`${internationalCode} `)[1];
  const result = internationalCode + ' ' + rest.split(' ').join('-');
  return result;
};

export const formatNationalPhoneNumber = (
  fullPhoneNumber: string, // eg: 12133734
  countryCode: CountryCode
) => {
  const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
  const callingCode = getCountryCallingCode(countryCode as CountryCode);

  if (phoneNumber && callingCode === phoneNumber.countryCallingCode) {
    const formattedPhoneNumber = phoneNumber.formatNational();
    return formattedPhoneNumber;
  } else {
    const asYouType = new AsYouType(countryCode);
    const formattedPhoneNumber = asYouType.input(fullPhoneNumber);
    return formattedPhoneNumber;
  }
};

export const concatCountryCodeAndPhoneNumber = (
  input: string,
  countryCode: string
) => {
  const countryCallingCode = getCountryCallingCode(
    countryCode.toUpperCase() as CountryCode
  );

  const parsedPhoneNumber = parsePhoneNumber(`+${input}`, {
    extract: true,
  });

  if (
    parsedPhoneNumber &&
    countryCallingCode === parsedPhoneNumber.countryCallingCode
  ) {
    return `${parsedPhoneNumber.countryCallingCode}${parsedPhoneNumber.nationalNumber}`;
  }

  return `${countryCallingCode}${input}`;
};

export const formatPhoneNumberWithCountryCode = (
  fullPhoneNumber: string, // eg: 12133734
  countryCode: CountryCode
) => {
  const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
  const callingCode = getCountryCallingCode(
    countryCode.toUpperCase() as CountryCode
  );

  if (phoneNumber && callingCode === phoneNumber.countryCallingCode) {
    const formattedPhoneNumber = phoneNumber.formatNational();
    return `+${callingCode} ${formattedPhoneNumber}`;
  }

  return fullPhoneNumber;
};

export const geCountryByPhoneNumber = (
  fullPhoneNumber: string
): CountryCode | string => {
  const phoneNumber = parsePhoneNumber(`+${fullPhoneNumber}`);
  return phoneNumber?.country?.toLowerCase() || '';
};

export const formatFullPhoneNumber = (fullPhoneNumber: string) => {
  // eg: +123456
  const parsedPhoneNumber = parsePhoneNumber(fullPhoneNumber);
  return `${
    parsedPhoneNumber?.countryCallingCode
  } ${parsedPhoneNumber?.formatNational()}`;
};

export const cleanPhoneNumber = (phoneNumber: string) => {
  const cleanPhoneNumber = phoneNumber
    .split('')
    .filter((value) => /\d/.test(value))
    .join('');
  return cleanPhoneNumber;
};

export const checkAndGetCorrectPhoneNumberForSearch = (
  fullPhoneNumber: string
) => {
  if (isNil(fullPhoneNumber)) {
    return null;
  }

  // Get 10 digits at most
  if (fullPhoneNumber[0] === '1') {
    // US, Canada phone number
    return fullPhoneNumber.slice(1, 11);
  } else {
    // other country phone number
    return fullPhoneNumber.slice(0, 10);
  }
};

export const getAllowedCountry = () => {
  let result = process.env.REACT_APP_STAGE_ENV !== 'production' ? ['vn'] : [];
  const allowedCountries = ['us', 'ca'];

  result = result.concat(allowedCountries);

  return result;
};

import { FC, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';

import { IScheduleLayer } from '..';
import { IMapPractitionerColor } from '../utils';
import getPractitionerName from 'utils/getPractitionerName';

interface ScheduleLayerProps {
  practitioners: IMapPractitionerColor[];
  scheduleLayer: IScheduleLayer;
  onChangeScheduleLayers: (data: any) => void;
}

const ScheduleLayer: FC<ScheduleLayerProps> = ({
  practitioners,
  scheduleLayer,
  onChangeScheduleLayers,
}) => {
  const pmsPractitionerCheckRef = useRef<HTMLInputElement | null>(null);

  const onCheckAllPmsPractitioner = () => {
    const isCheckedAll =
      practitioners.length === scheduleLayer.practitionerIds.length;

    if (isCheckedAll) {
      onChangeScheduleLayers({
        practitionerIds: [],
      });
    } else {
      const practitionerIds = practitioners.map((item) => item.id);

      onChangeScheduleLayers({
        practitionerIds,
      });
    }
  };

  const onCheckPmsPractitioner = (
    isChecked: boolean,
    practitionerId: string
  ) => {
    const checkAllInput = pmsPractitionerCheckRef.current!;
    if (isChecked) {
      let index = scheduleLayer.practitionerIds.indexOf(practitionerId);

      const practitionerIds = [...scheduleLayer.practitionerIds];

      practitionerIds.splice(index, 1);

      if (practitionerIds.length === 0) {
        checkAllInput.indeterminate = false;
        checkAllInput.checked = false;
      } else {
        checkAllInput.indeterminate = true;
      }

      onChangeScheduleLayers({
        practitionerIds,
      });
    } else {
      const practitionerIds = [
        ...scheduleLayer.practitionerIds,
        practitionerId,
      ];

      if (practitionerIds.length === practitioners.length) {
        checkAllInput.indeterminate = false;
        checkAllInput.checked = true;
      } else {
        checkAllInput.indeterminate = true;
      }

      onChangeScheduleLayers({
        practitionerIds,
      });
    }
  };

  return (
    <div className="border-t border-t-light-grey py-1.6 px-2 flex-1 overflow-auto">
      <h3 className="font-bold">Schedule Layers</h3>
      <ul className="mt-1.6 flex flex-col gap-y-1.2">
        <li className="flex gap-x-0.8 items-center">
          <input
            id="officeHours"
            type="checkbox"
            className="w-2.4 h-2.4"
            checked={scheduleLayer.isOfficeHour}
            onChange={() => {
              onChangeScheduleLayers({
                isOfficeHour: !scheduleLayer.isOfficeHour,
              });
            }}
          />
          <label htmlFor="officeHours">Office Hours</label>
        </li>
        <li className="flex gap-x-0.8 items-center">
          <input
            id="officeHolidays"
            type="checkbox"
            className="w-2.4 h-2.4"
            checked={scheduleLayer.isOfficeHoliday}
            onChange={() => {
              onChangeScheduleLayers({
                isOfficeHoliday: !scheduleLayer.isOfficeHoliday,
              });
            }}
          />
          <label htmlFor="officeHolidays">Office Holidays</label>
        </li>
        <li className="flex gap-x-0.8 items-center">
          <input
            id="event"
            type="checkbox"
            className="w-2.4 h-2.4"
            checked={scheduleLayer.isEvent}
            onChange={() => {
              onChangeScheduleLayers({
                isEvent: !scheduleLayer.isEvent,
              });
            }}
          />
          <label htmlFor="event">Event</label>
        </li>
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <li className="flex gap-x-0.8 items-center">
                <input
                  id="pms"
                  type="checkbox"
                  className="w-2.4 h-2.4"
                  checked={
                    practitioners.length ===
                    scheduleLayer.practitionerIds.length
                  }
                  ref={pmsPractitionerCheckRef}
                  onChange={onCheckAllPmsPractitioner}
                />
                <label htmlFor="pms">PMS Practitioner Hours</label>
                <Disclosure.Button
                  className={`translate-x-6 ${
                    !open ? 'rotate-180 transition-transform' : ''
                  }`}
                >
                  <DropdownIcon className="w-1.6 h-1.6" />
                </Disclosure.Button>
              </li>
              <Disclosure.Panel
                as="ul"
                className="flex flex-col gap-y-1.6 pl-3.2"
              >
                {practitioners.map((practitioner, index) => {
                  const isChecked = scheduleLayer.practitionerIds.some(
                    (item: string) => item === practitioner.id
                  );

                  return (
                    <li
                      key={`${practitioner.id}-${index}`}
                      className="flex gap-x-0.8 items-center"
                    >
                      <input
                        id={practitioner.id}
                        type="checkbox"
                        className="w-2.4 h-2.4"
                        checked={isChecked}
                        onChange={() => {
                          onCheckPmsPractitioner(isChecked, practitioner.id);
                        }}
                        style={{ color: practitioner.colorCode }}
                      />
                      <label htmlFor={practitioner.id}>
                        {getPractitionerName(practitioner)}
                      </label>
                    </li>
                  );
                })}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </ul>
    </div>
  );
};

export default ScheduleLayer;

import moment from 'moment';

const getIsSyncDisabled = <T extends { lastSyncAt: string }>(
  data: T,
  isGlobalAdmin: boolean
) => {
  if (!data) return false;
  const isSynWithin24Hour = moment().isBefore(
    moment(data.lastSyncAt).add(1, 'day')
  );
  return isSynWithin24Hour && !isGlobalAdmin;
};

export default getIsSyncDisabled;

import React, { FC } from 'react';
import { ReactComponent as RadioOn } from 'assets/icons/radio-on.svg';
import { ReactComponent as RadioOff } from 'assets/icons/radio-off.svg';

interface RadioButtonProps {
  isChecked: boolean;
  className?: string;
}

const RadioButton: FC<RadioButtonProps> = ({ isChecked, className }) => {
  return isChecked ? (
    <RadioOn className={`${className} all-child:fill-magenta`} />
  ) : (
    <RadioOff className={className} />
  );
};

export default RadioButton;

import React from 'react';

import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg';

interface Props {
  className?: string;
}

const LoadingSpinner: React.FC<Props> = ({ className }) => {
  return <LoadingIcon className={`animate-spin fill-current ${className}`} />;
};

export default LoadingSpinner;

import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mantine/dates';
import moment from 'moment';

import './CalendarPicker.module.css';
import { CALENDAR_TYPE } from 'utils/getDateRangeByCalendarType';

const CalendarPicker = ({
  calendarType,
  dateRange,
  onChange,
}: {
  calendarType: string;
  dateRange: {
    fromDate: string;
    toDate: string | null;
  } | null;
  onChange: (params: {
    fromDate: string | null;
    toDate: string | null;
  }) => void;
}) => {
  useEffect(() => {
    setCalendarFromDate(
      dateRange
        ? moment(dateRange.fromDate, 'YYYY-MM-DD').toDate()
        : moment().toDate()
    );
  }, [dateRange]);

  const [calendarFromDate, setCalendarFromDate] = useState(
    dateRange
      ? moment(dateRange.fromDate, 'YYYY-MM-DD').toDate()
      : moment().toDate()
  );

  return (
    <DatePicker
      id="admin-appt-calendar"
      type="range"
      numberOfColumns={2}
      size="xl"
      withCellSpacing={false}
      firstDayOfWeek={0}
      {...(calendarType !== CALENDAR_TYPE.CUSTOM_DATE_RANGE
        ? {
            __onDayMouseEnter: () => {
              return false; // Avoid choose day button
            },
          }
        : {})}
      {...(calendarType !== CALENDAR_TYPE.CUSTOM_DATE_RANGE && dateRange
        ? {
            minDate: moment(dateRange.fromDate, 'YYYY-MM-DD').toDate(),
            maxDate: moment(dateRange.toDate, 'YYYY-MM-DD').toDate(),
          }
        : {})}
      value={
        dateRange
          ? [
              moment(dateRange.fromDate, 'YYYY-MM-DD').toDate(),
              dateRange.toDate
                ? moment(dateRange.toDate, 'YYYY-MM-DD').toDate()
                : null,
            ]
          : [null, null]
      }
      date={calendarFromDate}
      onDateChange={(date) => setCalendarFromDate(date)}
      renderDay={(date) => {
        const day = date.getDate();

        if (dateRange) {
          const isActive =
            moment(dateRange.fromDate, 'YYYY-MM-DD').isSame(
              moment(date).format('YYYY-MM-DD'),
              'days'
            ) ||
            moment(dateRange.toDate, 'YYYY-MM-DD').isSame(
              moment(date).format('YYYY-MM-DD'),
              'days'
            );

          return <div className={isActive ? 'active-day' : ''}>{day}</div>;
        }

        return <div>{day}</div>;
      }}
      onChange={(value) => {
        if (calendarType === CALENDAR_TYPE.CUSTOM_DATE_RANGE) {
          onChange({
            fromDate: value[0] ? moment(value[0]).format('YYYY-MM-DD') : null,
            toDate: value[1] ? moment(value[1]).format('YYYY-MM-DD') : null,
          });
        }
      }}
      excludeDate={(date) => {
        if (calendarType !== CALENDAR_TYPE.CUSTOM_DATE_RANGE && dateRange) {
          if (
            moment(date).isBetween(
              moment(dateRange.fromDate, 'YYYY-MM-DD'),
              moment(dateRange.toDate, 'YYYY-MM-DD'),
              'days',
              '[]'
            )
          ) {
            return false;
          }

          return true;
        }

        return false;
      }}
    />
  );
};

export default CalendarPicker;

import React from 'react';
import MultipleSelect, { Option } from '../MultipleSelect/MultipleSelect';
import { sortBy } from 'lodash';
import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';
import useSpecialty from 'hooks/useSpecialty';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { SPECIALTY_NAME } from 'utils/constants';

interface IProps {
  control: any;
  placeholder?: string;
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  specialties: Option[];
}

const SpecialtyDropdown = (props: IProps) => {
  const { data: studies, isLoading } = useSpecialty();

  const isHygienestSpecialtySelected = props.specialties.find(
    (specialty) => specialty.label === SPECIALTY_NAME.HYGIENIST
  );

  const specialtyOptions = studies?.map((specialty) => ({
    value: specialty.id,
    label: specialty.name,
    isDisabled:
      props.specialties.length !== 0
        ? isHygienestSpecialtySelected
          ? specialty.name !== SPECIALTY_NAME.HYGIENIST
          : specialty.name === SPECIALTY_NAME.HYGIENIST
        : false,
  }));

  return (
    <Controller
      {...props}
      name="specialties"
      render={({ field, fieldState: { error } }) => {
        const { onChange, value } = field;

        return (
          <>
            <MultipleSelect
              isError={!!error?.message}
              key={`${isLoading}`}
              isLoading={isLoading}
              placeholder={'Select Specialty'}
              options={sortBy(specialtyOptions, 'label')}
              value={isLoading ? [] : value}
              onChange={(newValue: any) => {
                onChange(newValue);
              }}
            />
            {error?.message && (
              <div className={`flex items-center gap-x-1 mt-1`}>
                <WarningIcon />
                <span className="text-12 text-red-01">{error.message}</span>
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default SpecialtyDropdown;

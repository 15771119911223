import { FC, useState } from 'react';
import { Popover } from '@headlessui/react';
import { KeyedMutator } from 'swr';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import axiosInstance from 'apis/axiosInstance';
import { CLINIC_USER_STATUS } from 'utils/constants';
import EditOrCreateUser, {
  IEditOrCreateUserForm,
} from 'pages/UserPage/EditOrCreateUser/EditOrCreateUser';
import { renderToast } from 'components/Toast/Toast';
import { ClinicUser } from 'interfaces/clinicUser';

interface ThreeDotsOptionProps {
  data: ClinicUser;
  mutate: KeyedMutator<any>;
}

const CLINIC_USER_ACTION = {
  active: ['Edit', 'Deactivate', 'Delete'],
  inactive: ['Edit', 'Activate', 'Delete'],
  pending: ['Edit', 'Resend invitation', 'Delete'],
};

const displayGenericErrorMessage = () => {
  renderToast({
    message: 'An error has occurred. Please try again.',
    type: 'error',
  });
};

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({ data, mutate }) => {
  const options =
    CLINIC_USER_ACTION[data.status as keyof typeof CLINIC_USER_ACTION];

  const [modal, setModal] = useState<
    'activate' | 'deactivate' | 'resend' | 'delete' | 'edit' | null
  >(null);

  const [updateUserClinicData, setUpdateUserClinicData] =
    useState<IEditOrCreateUserForm | null>(null);

  const handleOptionClicked = (option: string) => {
    if (option === 'Activate') {
      setModal('activate');
    }
    if (option === 'Deactivate') {
      setModal('deactivate');
    }
    if (option === 'Resend invitation') {
      setModal('resend');
    }
    if (option === 'Delete') {
      setModal('delete');
    }
    if (option === 'Edit') {
      setModal('edit');
    }
  };

  const handleCloseModal = () => setModal(null);

  const handleOnSubmit = async () => {
    try {
      await axiosInstance.patch('/clinic-user/auth/update-account', {
        clinicUserId: data.clinicUserId,
        clinics: [
          {
            id: data.clinicId,
            status:
              modal === 'activate'
                ? CLINIC_USER_STATUS.ACTIVE
                : CLINIC_USER_STATUS.INACTIVE,
            role: data.role,
            firstName: data.firstName,
            lastName: data.lastName,
          },
        ],
      });
      renderToast({
        message: `${data.clinicUser.email} has been ${
          modal === 'activate' ? 'activated' : 'deactivated'
        }  successfully`,
        type: 'success',
      });
      await mutate();
      handleCloseModal();
    } catch (error) {
      displayGenericErrorMessage();
    }
  };

  const handleOnResend = async () => {
    try {
      await axiosInstance.post('/clinic-user/auth/setup-account-link', {
        email: data.clinicUser.email,
        clinicId: data.clinicId,
      });
      renderToast({
        message: 'Invitation email has been sent successfully',
        type: 'success',
      });
      handleCloseModal();
    } catch (error) {
      displayGenericErrorMessage();
    }
  };

  const handleOnDelete = async () => {
    try {
      await axiosInstance.delete(
        `/clinic-user/auth/${data.clinicUserId}/clinic-user-roles/${data.id}`
      );
      renderToast({
        message: `${data.clinicUser.email} has been deleted successfully`,
        type: 'success',
      });
      await mutate();
      handleCloseModal();
    } catch (error) {
      displayGenericErrorMessage();
    }
  };

  const handleOnUpdate = async () => {
    try {
      await axiosInstance.patch('/clinic-user/auth/update-account', {
        clinicUserId: data.clinicUserId,
        clinics: [
          {
            id: data.clinicId,
            firstName: updateUserClinicData!.firstName,
            lastName: updateUserClinicData!.lastName,
            role: updateUserClinicData!.role,
          },
        ],
      });
      renderToast({
        message: 'User’s information has updated successfully',
        type: 'success',
      });
      await mutate();
      setUpdateUserClinicData(null);
      handleCloseModal();
    } catch (error) {
      setUpdateUserClinicData(null);
      displayGenericErrorMessage();
    }
  };

  return (
    <>
      {modal === 'activate' && (
        <ConfirmationModal
          title={`Activate user?`}
          description={`Are you sure you want to activate ${data.clinicUser.email}?`}
          onClose={handleCloseModal}
          onSubmit={handleOnSubmit}
        />
      )}
      {modal === 'deactivate' && (
        <ConfirmationModal
          title={`Deactivate user?`}
          description={`Are you sure you want to deactivate ${data.clinicUser.email}?`}
          submitBtnTitle="Deactivate"
          onClose={handleCloseModal}
          onSubmit={handleOnSubmit}
        />
      )}
      {modal === 'delete' && (
        <ConfirmationModal
          title={`Delete user?`}
          description={`Are you sure you want to delete ${data.clinicUser.email}?`}
          submitBtnTitle="Delete"
          onClose={handleCloseModal}
          onSubmit={handleOnDelete}
        />
      )}
      {modal === 'resend' && (
        <ConfirmationModal
          title={`Resend invitation?`}
          description={`Are you sure you want to resend the invitation to ${data.clinicUser.email}?`}
          submitBtnTitle="Resend"
          onClose={handleCloseModal}
          onSubmit={handleOnResend}
        />
      )}
      {modal === 'edit' && (
        <EditOrCreateUser
          onClose={handleCloseModal}
          onSubmit={(data) => setUpdateUserClinicData(data)}
          defaultValues={{
            email: data.clinicUser.email,
            firstName: data.firstName,
            lastName: data.lastName,
            role: data.role,
          }}
        />
      )}
      {updateUserClinicData && (
        <ConfirmationModal
          title="Update user?"
          description="Are you sure you want to update this user?"
          submitBtnTitle="Yes, update"
          onClose={() => setUpdateUserClinicData(null)}
          onSubmit={handleOnUpdate}
        />
      )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button className="flex items-center disabled:cursor-not-allowed disabled:opacity-30">
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 w-16.5 bg-white shadow-primary rounded-[2rem] mt-0.6">
          {options.map((option) => (
            <Popover.Button
              key={option}
              className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left"
              onClick={() => handleOptionClicked(option)}
            >
              {option}
            </Popover.Button>
          ))}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;

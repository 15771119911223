import { ReactComponent as ScheduleIcon } from 'assets/icons/access-time.svg';

const EmptySchedule = () => {
  return (
    <div className="h-43.4 border-b border-light-grey">
      <div className="flex flex-col justify-center items-center text-center h-full">
        <ScheduleIcon className="w-4 h-4 all-child:all-child:fill-grey" />
        <span className="w-24.5 text-12 leading-[1.8rem] mt-2">
          No Google Places ID has been defined. Please contact FirstIn Admin for
          support.
        </span>
      </div>
    </div>
  );
};

export default EmptySchedule;

import { FC } from 'react';
import { Cell, Row } from 'react-table';
import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import { ReactComponent as DragHandleIcon } from 'assets/icons/drag_handle.svg';
interface ColumnProps {
  cell: Cell<any>;
  row: Row<any>;
  isSortingEnabled?: boolean;
}

const Column: FC<ColumnProps> = ({ cell, row, isSortingEnabled }) => {
  const isColumnExpendable = cell.column.id === 'serviceName' && row.canExpand;

  const isSortingIndicatorIncluded =
    cell.column.id === 'serviceName' && isSortingEnabled;

  const isSubRow = row.depth > 0;

  return (
    <td
      className={`text-left p-0 py-1.9 flex items-center
      ${isColumnExpendable ? 'flex items-center cursor-pointer gap-x-1' : ''} 
      ${isSortingIndicatorIncluded ? 'flex items-center gap-x-1.6' : ''} 
      ${isSubRow && cell.column.id === 'serviceName' ? 'translate-x-3' : ''}
      `}
      {...cell.getCellProps()}
      onClick={() => {
        if (!isColumnExpendable) return;
        row.toggleRowExpanded();
      }}
    >
      {isColumnExpendable && (
        <SortArrow
          className={`${
            row.isExpanded ? 'rotate-0' : '-rotate-90'
          } fill-darkest-grey transition-transform`}
        />
      )}
      {isSortingIndicatorIncluded && (
        <DragHandleIcon className="all-child:all-child:fill-mid-grey h-2.1" />
      )}
      {cell.render('Cell')}
    </td>
  );
};

export default Column;

import { FC, ReactNode } from 'react';
import toast from 'react-hot-toast';
import { ReactComponent as RemoveCircleIcon } from 'assets/icons/remove_circle.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check_circle.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning_toast.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info_toast.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface ToastProps {
  id: string;
  message: string | ReactNode;
  type: 'success' | 'warning' | 'error' | 'info';
  isButtonIncluded?: boolean;
  buttonTitle?: string;
  onButtonClicked?: () => void;
}

const Toast: FC<ToastProps> = ({
  id,
  message,
  type,
  buttonTitle,
  isButtonIncluded,
  onButtonClicked,
}) => {
  const toastClassName = () => {
    if (type === 'success') {
      return '!bg-[#EAFDE7] !border-[#83D972] text-green-secondary';
    }
    if (type === 'error') {
      return '!bg-red-03 !border-[#FFA39E] text-red-01';
    }
    if (type === 'warning') {
      return 'bg-[#FFF9EE] border-[#FFC656] text-[#FFC656]';
    }
    if (type === 'info') {
      return '!bg-[#F4FAFF] !border-[#4D97E1] text-[#4D97E1]';
    }
  };

  return (
    <div
      className={`text-left w-full p-1.8 border rounded-[1rem] ${toastClassName()}`}
    >
      <div className="flex gap-x-1.8">
        {type === 'success' && (
          <CheckCircleIcon className="all-child:all-child:fill-green-secondary w-2 h-2" />
        )}
        {type === 'error' && (
          <RemoveCircleIcon className="all-child:all-child:fill-red-01 w-2 h-2" />
        )}
        {type === 'warning' && (
          <WarningIcon className="all-child:fill-[#FFC656] w-2 h-2" />
        )}
        {type === 'info' && (
          <InfoIcon className="all-child:all-child:fill-[#4D97E1] w-2 h-2" />
        )}
        <div className="flex-1">
          <div className="flex justify-between gap-x-1.1">
            <span className="text-14 font-normal leading-[2.1rem] text-darkest-grey">
              {message}
            </span>
            <button
              className="self-start cursor-pointer"
              onClick={() => toast.dismiss(id)}
            >
              <CloseIcon className="all-child:fill-mid-grey w-1.6 h-1.6" />
            </button>
          </div>

          {isButtonIncluded && (
            <button
              className="text-[inherit] font-bold mt-[0.6rem] uppercase"
              onClick={() => {
                onButtonClicked?.();
                toast.dismiss(id);
              }}
            >
              {buttonTitle}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const renderToast = ({
  message,
  type,
}: {
  message: string | ReactNode;
  type: 'success' | 'warning' | 'error' | 'info';
}) => {
  toast((t) => <Toast id={t.id} message={message} type={type} />);
};

export { renderToast };

export default Toast;

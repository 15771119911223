import React from 'react';
import moment from 'moment';
import getWelcomeMessage from 'utils/getWelcomeMessage';
import { useUser } from 'context/userContext';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import useAdminStatistic from 'hooks/useAdminStatistic';
import PracticeSection from './PracticeSection/PracticeSection';
import PractitionerSection from './PractitionerSection/PractitionerSection';
import AppointmentSection from './AppointmentSection/AppointmentSection';
import Chart from './Chart/Chart';

const AdminDashboardPage = () => {
  const {
    user: { globalAdminRole },
  } = useUser();

  const { data, isLoading } = useAdminStatistic();

  return (
    <div className="px-4 pt-2.4 h-full flex flex-col">
      {isLoading && <LoadingScreen />}
      <h3 className="text-24 font-bold leading-[3.6rem]">
        {getWelcomeMessage()}, {globalAdminRole!.firstName} 👋
      </h3>

      <div className="grid gap-3 grid-cols-dashboard-layout mt-1.6 grid-rows-[21rem] mb-2.4">
        <PracticeSection
          activeTotal={data?.clinic.activeTotal || 0}
          total={data?.clinic.total || 0}
        />
        <PractitionerSection
          activeTotal={data?.doctor.activeTotal || 0}
          total={data?.doctor.total || 0}
        />
        <AppointmentSection
          totalInDateRange={data?.appointment.totalInDateRange || 0}
          total={data?.appointment.total || 0}
          year={moment().format('YYYY')}
        />
      </div>

      <div className="flex-1 mb-5.4">
        <Chart />
      </div>
    </div>
  );
};

export default AdminDashboardPage;

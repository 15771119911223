import ReactTooltip from 'react-tooltip';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

const IconHelper = () => {
  return (
    <>
      <InfoIcon
        data-for={'info-icon'}
        data-tip=""
        className="absolute top-4.5 right-1.2"
      />
      <ReactTooltip
        id={'info-icon'}
        place="bottom"
        effect="solid"
        className="!bg-lightest-grey !border-light-grey !rounded-[0.8rem] !w-[30rem] !h-[3.6rem] !px-[1.4rem] !py-[0.9rem] !opacity-100 z-[9999] flex justify-center items-center"
      >
        <span className="text-11 text-darkest-grey flex items-center font-normal">
          Contact Admin to update the email for this account
        </span>
      </ReactTooltip>
    </>
  );
};

export default IconHelper;

import { useState } from 'react';
import { VersionProvider } from './context/versionContext';
import WrapperSchedulePage from './WrapperSchedulePage';
import VersionCheck from './VersionCheck/VersionCheck';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import useClinic from 'hooks/useClinic';

const OfficeSchedulePage = () => {
  const [key, setKey] = useState(0);
  const matchMutate = useMatchMutate();

  const { data: clinic } = useClinic();

  const onRefresh = async () => {
    setKey(key + 1);

    await Promise.all([
      matchMutate(/\/clinics-exception[\s\S]+/, { revalidate: true }),
      matchMutate(/\/clinics-schedule[\s\S]+/, { revalidate: true }),
    ]);

    renderToast({ message: 'Page successfully refreshed', type: 'success' });
  };

  return (
    <VersionProvider key={`${key}-${clinic?.updatedAt}`}>
      <VersionCheck onRefresh={onRefresh} />
      <WrapperSchedulePage />
    </VersionProvider>
  );
};

export default OfficeSchedulePage;

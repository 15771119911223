import { FC } from 'react';

import TextField from 'components/TextField/TextField';

interface MetaPixelSectionProps {
  control: any;
}

const MetaPixelSection: FC<MetaPixelSectionProps> = ({ control }) => {
  return (
    <section>
      <p className="font-bold mb-1">Meta Pixel ID</p>
      <TextField
        id={'metaPixelCode'}
        placeholder="Input Meta Pixel ID"
        control={control}
        rules={{
          validate: (value) => {
            if (value === '') return true;

            if (value.match(/^[0-9]+$/)) {
              return true;
            }
            return 'Meta Pixel ID must be numbers only';
          },

          minLength: {
            value: 15,
            message: 'Meta Pixel ID must be at least 15 numbers long',
          },
          maxLength: {
            value: 16,
            message: 'Meta Pixel ID must be at most 16 numbers long',
          },
        }}
      />
    </section>
  );
};

export default MetaPixelSection;

import React, { FC } from 'react';
import PastSection from './PastAppointSection/PastAppointSection';
import TotalSection from './TotalAppointSection/TotalAppointSection';
import UpcomingSection from './UpcomingAppointSection/UpcomingSection';

import getWelcomeMessage from 'utils/getWelcomeMessage';
import { useUser } from 'context/userContext';
import TableSection from './TableSection/TableSection';

const DashboardPage: FC = () => {
  const {
    user: { currentClinicUserRole },
  } = useUser();

  return (
    <div className="px-4 pt-2.4">
      <h3 className="text-24 font-bold leading-[3.6rem]">
        {getWelcomeMessage()}, {currentClinicUserRole!.firstName} 👋
      </h3>
      <div className="grid gap-3 grid-cols-dashboard-layout mt-1.6 grid-rows-[28rem]">
        <UpcomingSection />
        <PastSection />
        <TotalSection />
      </div>
      <TableSection key={currentClinicUserRole?.id} />
    </div>
  );
};

export default DashboardPage;

import React from 'react';

import { Toaster } from 'react-hot-toast';
import { SWRConfig } from 'swr';
import AppRoute from 'routes/AppRoutes';

function App() {
  return (
    <SWRConfig
      value={{ revalidateOnFocus: false, revalidateOnReconnect: false }}
    >
      <AppRoute />
      <Toaster
        toastOptions={{
          className: 'toast',
          icon: null,
        }}
      />
    </SWRConfig>
  );
}

export default App;

import React, { ReactChild, ReactChildren } from 'react';
import { createPortal } from 'react-dom';
import Overlay from 'components/Overlay/Overlay';

interface IProps {
  children: ReactChild | ReactChildren;
  handleClose?: () => void;
  className?: string;
}

const DefaultModal = (props: IProps) => {
  const { children, className } = props;

  return createPortal(
    <Overlay>
      <div
        className={`fixed flex flex-col bg-white shadow-2xl rounded-[10px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-10 ${className}`}
        data-first-in-modal={true}
      >
        {children}
      </div>
    </Overlay>,
    document.body
  );
};

export default DefaultModal;

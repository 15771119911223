import { FC, useState } from 'react';

import { PractitionerException } from 'interfaces/practitionerException';
import useOverrideModal from 'hooks/useOverrideModal';
import DefaultModal from 'components/Modals/DefaultModal';
import OverrideModalBody, {
  OptionClinicException,
  OptionPractitionerException,
} from 'components/OverrideModalBody/OverrideModalBody';
import CommonButton from 'components/CommonButton/CommonButton';
import { datetimeFormat } from 'utils/datetime';
import { IPractitioner } from 'interfaces/practitioners';
import { WeeklyTimeslot } from 'pages/OfficeSchedulePage/WrapperSchedulePage';
import moment from 'moment';
import { capitalize } from 'lodash';
import axiosInstance from 'apis/axiosInstance';
import useMatchMutate from 'hooks/useMatchMutate';
import { renderToast } from 'components/Toast/Toast';
import { DAY_OFF } from 'utils/constants';
import useModal from 'hooks/useModal';

interface OverrideModalProps {
  exceptionDates: Required<PractitionerException>[];
  changedTimeSlots: WeeklyTimeslot[];
  selectedPractitioner: IPractitioner;
  onUpdateSchedule: () => Promise<void>;
  onClose: () => void;
  onBtnClose?: () => void;
  version: number;
  onCheckOutsidePractitionerHourAppts: () => Promise<void>;
}

const OverrideModal: FC<OverrideModalProps> = ({
  exceptionDates,
  onClose,
  onBtnClose,
  selectedPractitioner,
  changedTimeSlots,
  onUpdateSchedule,
  version,
  onCheckOutsidePractitionerHourAppts,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { setIsAllowed } = useModal({
    onClose: onBtnClose ? onBtnClose : onClose,
  });

  const matchMutate = useMatchMutate();

  const {
    options,
    handleChangeCheckbox,
    handleChangeSelectAll,
    selectAllRef,
    totalSelectOptions,
  } = useOverrideModal(exceptionDates);

  const displayHour = (
    option: OptionPractitionerException | OptionClinicException
  ) => {
    if (typeof option.workingHour !== 'string') {
      const startDate = datetimeFormat({
        dateString: option.workingHour[0].start!,
        format: 'HH:mm:ss',
        pattern: 'hh:mmA',
      });
      const endDate = datetimeFormat({
        dateString: option.workingHour[0].end!,
        format: 'HH:mm:ss',
        pattern: 'hh:mmA',
      });

      return `${startDate} - ${endDate}`;
    }

    return DAY_OFF;
  };

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    setIsAllowed(false);
    const filterCheckedOptions = options.filter((option) => option.checked);

    const body = filterCheckedOptions.map((option) => {
      const day = changedTimeSlots.find(
        (timeSlot) =>
          moment(option.date, 'YYYY-MM-DD').day() ===
          moment(capitalize(timeSlot.day), 'ddd').day()
      )!;

      return {
        id: option.id,
        date: option.date,
        isClosed: !day.start && !day.end,
        shifts: [
          {
            start: day.start,
            end: day.end,
          },
        ],
      };
    });

    try {
      await onUpdateSchedule();

      if (body.length > 0) {
        await axiosInstance.patch(
          `/practitioners/${selectedPractitioner.id}/exceptions`,
          body,
          {
            headers: {
              version,
            },
          }
        );
      }

      await Promise.all([
        matchMutate(/\/exceptions\?[\s\S]+/),
        matchMutate(/\/schedules[\s\S]+/),
        onBtnClose ? matchMutate(/\/custom-days\?[\s\S]+/) : null,
        onCheckOutsidePractitionerHourAppts(),
      ]);

      renderToast({
        type: 'success',
        message: 'Regular hours has been updated successfully',
      });

      setIsAllowed(true);
      onClose();
    } catch (error) {
      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
      setIsSubmitting(false);
    }
  };

  return (
    <DefaultModal>
      <div className="w-48 max-h-[50.2rem] pt-2 pb-2.9 px-2 text-darkest-grey text-14 leading-[2.1rem]">
        <h3 className="font-bold text-20 leading-[3rem]">
          Override these custom days?
        </h3>
        <span className="mt-0.8">
          Unselect the days you would like to keep customized for{' '}
          <strong>
            {selectedPractitioner.title} {selectedPractitioner.name}
          </strong>
        </span>
        <OverrideModalBody
          options={options}
          selectAllRef={selectAllRef}
          handleChangeCheckbox={handleChangeCheckbox}
          handleChangeSelectAll={handleChangeSelectAll}
          totalSelectOptions={totalSelectOptions}
          displayHour={displayHour}
        />

        <div className="flex justify-end gap-x-1.6 mt-2.5">
          <CommonButton
            variant="secondary"
            onClick={onBtnClose ? onBtnClose : onClose}
            disabled={isSubmitting}
          >
            Cancel
          </CommonButton>
          <CommonButton isLoading={isSubmitting} onClick={handleOnSubmit}>
            Confirm
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default OverrideModal;

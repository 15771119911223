import { components } from 'react-select';

const ValueContainer = (props: any) => {
  const [values, input] = props.children;
  return (
    <components.ValueContainer {...props}>
      {Array.isArray(values) ? (
        <div className="mx-0.2 max-w-full h-full">
          {values.length} of {props.options.length} selected
        </div>
      ) : (
        <>{values}</>
      )}
      {input}
    </components.ValueContainer>
  );
};

export default ValueContainer;

import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEqual } from 'lodash';
import { IClinicResponse } from 'hooks/useAllClinic';
import ProfileInfo from 'components/ProfileInfo/ProfileInfo';
import { PMS_TYPE, PRACTICE_FILTER_OPTIONS, ROLE } from 'utils/constants';
import { ReactComponent as WarningIcon } from 'assets/icons/warning_toast.svg';
import { ReactComponent as EditPencilIcon } from 'assets/icons/edit.svg';
import { ReactComponent as DiscardIcon } from 'assets/icons/trash.svg';
import TextField from 'components/TextField/TextField';
import CommonButton from 'components/CommonButton/CommonButton';
import GooglePlaceField from './GooglePlaceField/GooglePlaceField';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import PhoneField from './PhoneField/PhoneField';
import axiosInstance from 'apis/axiosInstance';
import { isValidEmail } from 'utils/validations';
import { loadAuthToken, loadRole, loadSelectedClinicId } from 'utils/storage';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import extractGoogleClinicSchedule from 'utils/extractGoogleClinicSchedule';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import {
  cleanPhoneNumber,
  formatFullPhoneNumber,
} from 'utils/formatPhoneNumber';
import { PracticeFormState } from 'interfaces/practiceFormState';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import getWebsiteName from 'utils/getWebsiteName';

const { ACTIVE, INACTIVE } = PRACTICE_FILTER_OPTIONS;

interface ViewOrEditPracticeProps {
  selectedPractice: IClinicResponse;
  setSelectedPractice?: React.Dispatch<
    React.SetStateAction<IClinicResponse | null>
  >;
  isPracticeView?: boolean;
}

const ViewOrEditPractice: FC<ViewOrEditPracticeProps> = ({
  selectedPractice,
  setSelectedPractice,
  isPracticeView,
}) => {
  const {
    id,
    name,
    isActive,
    googlePlaceId,
    phoneNumber,
    address,
    externalId,
    subdomain,
    description,
    avatar,
    email,
    website,
    location: { country, city, state },
    latitude,
    longitude,
    timezone,
    zip,
  } = selectedPractice;

  const [isEdit, setIsEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const matchMutate = useMatchMutate();

  const defaultValues = {
    name,
    email,
    website,
    phoneNumber,
    address,
    googlePlaceId: googlePlaceId ? googlePlaceId : '',
    externalId: externalId ? externalId : '',
    subdomain: subdomain ? subdomain : '',
    isActive: `${isActive}`,
    description,
    country,
    city,
    state,
    googlePlaceResult: null,
    avatar,
    uploadedFile: null,
    zip,
  };

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState,
    handleSubmit,
  } = useForm<PracticeFormState>({
    mode: 'all',
    defaultValues,
  });

  const isClinicIncludeNexHealthField = subdomain && externalId;

  const handleUploadImage = async () => {
    const accessToken = loadAuthToken()?.accessToken;
    const uploadedFile = getValues('uploadedFile');
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);
    const response = await axiosInstance.post(
      `/upload/avatar/clinics?id=${id}`,
      formData,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          ...(loadRole() === ROLE.PRACTICE_ADMIN
            ? {
                'X-ClinicId': loadSelectedClinicId(),
              }
            : {}),
        },
      }
    );
    return response.data.url;
  };

  const getTimezone = async () => {
    const { googlePlaceResult } = getValues();
    if (!googlePlaceResult) return;

    const lat = googlePlaceResult.geometry?.location?.lat() ?? 0;
    const lng = googlePlaceResult.geometry?.location?.lng() ?? 0;

    const isLocationNotChange = lat === latitude && lng === longitude;

    if (isLocationNotChange) return;

    const response = await axiosInstance.get('/gmap/timezone', {
      params: {
        lat,
        lng,
      },
    });

    return response.data.timeZoneId;
  };

  const onSubmit = async () => {
    const accessToken = loadAuthToken()?.accessToken;

    try {
      const [url, updatedTimezone] = await Promise.all([
        handleUploadImage(),
        getTimezone(),
      ]);

      const {
        phoneNumber,
        address,
        city,
        state,
        country,
        isActive,
        description,
        googlePlaceResult,
        email,
        googlePlaceId,
        website,
        avatar,
        name,
        externalId,
        subdomain,
      } = getValues();

      const imageUrl = url ?? avatar;

      const googleClinicSchedule =
        googlePlaceResult && extractGoogleClinicSchedule(googlePlaceResult);

      const response = await axiosInstance.put(
        `/clinics/${id}`,
        {
          avatar: imageUrl,
          name,
          phoneNumber: cleanPhoneNumber(phoneNumber),
          address,
          city,
          state,
          country,
          isActive: isActive === 'true',
          timezone: updatedTimezone ? updatedTimezone : timezone,
          description,
          latitude: googlePlaceResult
            ? googlePlaceResult.geometry?.location?.lat()
            : latitude,
          longitude: googlePlaceResult
            ? googlePlaceResult.geometry?.location?.lng()
            : longitude,
          email,
          googlePlaceId,
          pmsType: PMS_TYPE.NEX_HEALTH,
          website,
          zip: getValues('zip') ?? zip,
          ...(googleClinicSchedule && { googleClinicSchedule }),
          ...(!defaultValues.externalId && { externalId }),
          ...(!defaultValues.subdomain && { subdomain }),
          subdomain,
        },
        {
          headers: {
            Authorization: `JWT ${accessToken}`,
            'X-ClinicId': id,
          },
        }
      );
      renderToast({
        type: 'success',
        message: 'Successfully updated practice',
      });
      await matchMutate(/\/clinics/);

      setSelectedPractice?.({ ...response.data });
    } catch (error: any) {
      let message = 'Something went wrong. Please try again later';
      if (error.response?.status === BAD_REQUEST) {
        message = error.response?.data.message;
      }

      renderToast({
        message,
        type: 'error',
      });
    }
  };

  const handleDeletePractice = async () => {
    const accessToken = loadAuthToken()?.accessToken;
    try {
      await axiosInstance.delete(`/clinics/${id}`, {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'X-ClinicId': id,
        },
      });
      const mutateResponse = await matchMutate(/\/clinics/);

      const updatedClinics = mutateResponse[1].data;

      setSelectedPractice?.(
        updatedClinics.length > 0 ? updatedClinics[0] : null
      );

      renderToast({
        message: 'Successfully delete practice',
        type: 'success',
      });
    } catch (error) {
      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  const isDataSame = isEqual(watch(), defaultValues);

  return (
    <>
      {isDiscardModalOpen && (
        <ConfirmationModal
          title={'Discard changes'}
          description={
            'Are you sure you want to discard all changes? This action cannot be undone.'
          }
          onClose={() => setIsDiscardModalOpen(false)}
          onSubmit={async () => {
            setIsEdit(false);
            setIsDiscardModalOpen(false);
            reset(defaultValues);
          }}
          submitBtnTitle="Yes, discard"
        />
      )}
      {isModalOpen && (
        <ConfirmationModal
          title={'Delete this practice?'}
          description={
            'Are you sure you want to delete this practice? All appointments related to it will be canceled. This action cannot be undone.'
          }
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleDeletePractice}
          submitBtnTitle="Yes, delete"
        />
      )}
      <div
        className={`flex-1 px-4 pt-2.4 text-14 leading-[2.1rem] relative ${
          isEdit ? 'pb-5' : ''
        }`}
      >
        <div className="flex items-center justify-between">
          <h3 className="text-24 leading-[3.6rem] font-bold">
            {isPracticeView ? 'Profile' : name}
          </h3>
          <button
            className={`w-6.4 h-6.4 rounded-full bg-magenta-lightest flex items-center justify-center ${
              isEdit ? 'hidden' : ''
            }`}
            onClick={() => setIsEdit(true)}
          >
            <EditPencilIcon className="all-child:all-child:fill-magenta" />
          </button>
        </div>
        <div
          className={`flex mt-5.7 pb-5 gap-x-6.4 ${
            isPracticeView ? 'px-8' : ''
          } ${!isEdit && !isClinicIncludeNexHealthField ? '!mt-3' : ''}`}
        >
          <div className="flex flex-1 flex-col gap-y-2">
            {!isEdit && !isClinicIncludeNexHealthField && (
              <div className="text-left w-[55.7rem] p-1.8 border rounded-[1rem] bg-[#FFF9EE] border-[#FFC656] text-[#FFC656]">
                <div className="flex gap-x-1.8">
                  <WarningIcon className="all-child:fill-[#FFC656] w-2 h-2" />
                  <div className="flex-1">
                    <div className="flex justify-between gap-x-1.1">
                      <span className="text-14 font-normal leading-[2.1rem] text-darkest-grey">
                        Please add Clinic ID and Subdomain to complete adding
                        this practice
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <ProfileInfo
              title={'Practice Name'}
              data={getValues('name')}
              isEdit={isEdit}
            >
              <div className="min-w-[38rem]">
                <TextField
                  id={'name'}
                  control={control}
                  defaultValue={getValues('name')}
                  rules={{
                    required: 'This field is required',
                  }}
                  placeholder="Input Practice Name"
                />
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Status'}
              data={isActive ? ACTIVE : INACTIVE}
              isEdit={isEdit}
            >
              <div className="flex gap-x-8">
                <div className="flex items-center gap-x-0.8">
                  <input
                    className="w-2.4 h-2.4"
                    type="radio"
                    id="radioActive"
                    value={'true'}
                    defaultChecked={isActive}
                    {...register('isActive')}
                  />
                  <label htmlFor="radioActive">Active</label>
                </div>
                <div className="flex items-center gap-x-0.8">
                  <input
                    className="w-2.4 h-2.4"
                    type="radio"
                    id="radioInactive"
                    value={'false'}
                    defaultChecked={!isActive}
                    {...register('isActive')}
                  />
                  <label htmlFor="radioInactive">Inactive</label>
                </div>
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Google Places ID'}
              data={getValues('googlePlaceId')}
              isEdit={isEdit}
            >
              <div className="min-w-[38rem]">
                <GooglePlaceField
                  control={control}
                  defaultValue={getValues('googlePlaceId')}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Address'}
              data={getValues('address')}
              isEdit={isEdit}
            >
              <div className="min-w-[38rem]">
                <TextField
                  id={'address'}
                  control={control}
                  defaultValue={getValues('address')}
                  rules={{ required: 'This field is required' }}
                  disabled={!!watch('googlePlaceResult')}
                  placeholder="Input Address"
                />
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Email'}
              data={getValues('email')}
              isEdit={isEdit}
            >
              <div className="min-w-[38rem]">
                <TextField
                  id={'email'}
                  control={control}
                  defaultValue={getValues('email')}
                  rules={{
                    required: 'This field is required',
                    validate: (value) => {
                      if (!isValidEmail(value)) return 'Invalid email address';
                    },
                  }}
                  placeholder="Input Email"
                />
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Phone Number'}
              data={`${formatFullPhoneNumber(`+${phoneNumber}`)}`}
              isEdit={isEdit}
            >
              <div className="min-w-[38rem]">
                <PhoneField
                  key={getValues('googlePlaceResult')?.place_id}
                  control={control}
                  phoneNumber={cleanPhoneNumber(watch('phoneNumber'))}
                />
              </div>
            </ProfileInfo>
            <ProfileInfo
              title={'Website'}
              data={getWebsiteName(getValues('website'))}
              isEdit={isEdit}
              isOptional
            >
              <div className="min-w-[38rem]">
                <TextField
                  id={'website'}
                  control={control}
                  defaultValue={getValues('website')}
                  placeholder="Input Website"
                />
              </div>
            </ProfileInfo>
            {!isPracticeView && (
              <ProfileInfo
                isOptional
                title={'Clinic ID'}
                data={getValues('externalId')}
                isEdit={isEdit}
              >
                <div className="min-w-[38rem]">
                  <TextField
                    disabled={!!defaultValues.externalId}
                    id={'externalId'}
                    control={control}
                    defaultValue={getValues('externalId')}
                    placeholder="Input Clinic ID"
                  />
                </div>
              </ProfileInfo>
            )}
            {!isPracticeView && (
              <ProfileInfo
                isOptional
                title={'Subdomain'}
                data={getValues('subdomain')}
                isEdit={isEdit}
              >
                <div className="min-w-[38rem]">
                  <TextField
                    disabled={!!defaultValues.subdomain}
                    id="subdomain"
                    control={control}
                    defaultValue={getValues('subdomain')}
                    placeholder="Input Subdomain"
                  />
                </div>
              </ProfileInfo>
            )}
            <ProfileInfo
              title={'Bio'}
              data={getValues('description')}
              isEdit={isEdit}
              isOptional
            >
              <div className="h-[23.7rem] min-w-[38rem] relative">
                <textarea
                  className="w-full resize-none h-full text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active focus-visible:border-magenta focus-visible:shadow-input-active scrollbar !outline-none !ring-transparent"
                  defaultValue={getValues('description')}
                  {...register('description')}
                  placeholder="Input Bio"
                />
              </div>
            </ProfileInfo>
          </div>
          <div className="basis-[20rem]">
            <ImageUpload
              key={`${isEdit}`}
              avatar={watch('avatar')}
              isEdit={isEdit}
              onRemoveAvatar={() => {
                setValue('avatar', null);
                setValue('uploadedFile', null);
              }}
              setValue={setValue}
            />
          </div>
        </div>
        {isEdit && (
          <div
            className={`absolute bottom-0 w-full -ml-4 bg-white shadow-elevation-07 h-6 px-4 flex ${
              isPracticeView ? 'justify-end' : 'justify-between'
            } items-center`}
          >
            {!isPracticeView && (
              <button
                className="text-secondary-red flex items-center gap-x-0.8"
                onClick={() => setIsModalOpen(true)}
              >
                <DiscardIcon className="w-2 h-2 all-child:fill-secondary-red" />
                <span className="font-bold">Delete</span>
              </button>
            )}
            <div className="flex gap-x-1">
              <CommonButton
                variant="secondary"
                onClick={() => setIsDiscardModalOpen(true)}
              >
                Discard
              </CommonButton>
              <CommonButton
                isLoading={formState.isSubmitting}
                disabled={isDataSame}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </CommonButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewOrEditPractice;

import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import { ReactComponent as LeftArrow } from 'assets/icons/chevron-left.svg';
import { ReactComponent as RightArrow } from 'assets/icons/chevron-right.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/chevron-left.svg';
import ChartViewDropdown from './ChartViewDropdown/ChartViewDropdown';
import DebugViewDropdown from './DebugViewDropdown/DebugViewDropdow';
import { IMapPractitionerColor } from 'pages/SchedulePage/utils';
import { IDebugModeDropdown } from '..';

interface IProps {
  date: string;
  view: string;
  isCollapsed: boolean;
  onChangeDate: (date: string) => void;
  onChangeView: (view: string) => void;
  onToggleCollapse: () => void;
  isDebugMode: boolean;
  practitioners: IMapPractitionerColor[];
  onChangeDebugModeDropdown: (
    dropdownOption: IDebugModeDropdown | null
  ) => void;
}

const TimeGridHeader = ({
  date,
  view,
  isCollapsed,
  onChangeDate,
  onChangeView,
  onToggleCollapse,
  isDebugMode,
  practitioners,
  onChangeDebugModeDropdown,
}: IProps) => {
  const isToday = moment(date, 'YYYY-MM-DD').isSame(moment(), 'days');

  const clickPrev = () => {
    const prevDate = moment(date, 'YYYY-MM-DD')
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    onChangeDate(prevDate);
  };

  const clickNext = () => {
    const nextDate = moment(date, 'YYYY-MM-DD')
      .add(1, 'days')
      .format('YYYY-MM-DD');
    onChangeDate(nextDate);
  };

  const onClickToday = () => {
    if (!isToday) {
      const today = moment().format('YYYY-MM-DD');
      onChangeDate(today);
    }
  };

  return (
    <div className="grid grid-cols-[auto_auto_auto_1fr] items-center border-r border-l border-b border-light-grey h-[5rem] pr-2.3">
      <div className="mr-2">
        <button
          data-for={'grid-expand-collapse-button'}
          data-tip=""
          className="w-3.2 h-3.2 rounded-r-[100px] bg-white flex items-center justify-center shadow-primary"
          onClick={onToggleCollapse}
        >
          <CollapseIcon
            className={`w-1.4 h-1.4 transition-transform duration-300 ${
              isCollapsed && 'rotate-180'
            }`}
          />
        </button>
        <ReactTooltip
          id={'grid-expand-collapse-button'}
          place="right"
          effect="solid"
          className="tooltip shadow-tooltip"
        >
          <span className="text-11 text-darkest-grey flex h-full items-center">
            {isCollapsed ? 'Expand' : 'Collapse'}
          </span>
        </ReactTooltip>
      </div>

      <button
        className={`w-6 h-2.4 text-12 leading-[1.8rem] flex justify-center items-center rounded-[0.8rem] border ${
          isToday
            ? 'shadow-elevation-10 text-magenta border-magenta'
            : 'shadow-elevation-07 text-darkest-grey border-none'
        }`}
        onClick={onClickToday}
      >
        Today
      </button>

      <div className="mx-1.6">
        <div className="flex flex-row items-center gap-x-1">
          <button onClick={clickPrev}>
            <LeftArrow className="w-1.6 h-1.6 cursor-pointer" />
          </button>

          <button onClick={clickNext}>
            <RightArrow className="w-1.6 h-1.6 cursor-pointer" />
          </button>

          <CustomDatePicker
            selected={moment(date, 'YYYY-MM-DD').toDate()}
            onChange={(date: Date | null) => {
              if (date) {
                onChangeDate(moment(date).format('YYYY-MM-DD'));
              }
            }}
            customInput={
              <div className="mx-1.6 cursor-pointer">
                <div className="text-14 font-bold">
                  {moment(date, 'YYYY-MM-DD').format('dddd')}
                </div>
                <div className="text-12 leading-[1.8rem]">
                  {moment(date, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                </div>
              </div>
            }
            popperModifiers={[
              {
                name: 'offset',
                options: {
                  offset: [-20, -1],
                },
              },
            ]}
          />
        </div>
      </div>

      <div className="justify-self-end">
        {isDebugMode ? (
          <DebugViewDropdown
            practitioners={practitioners}
            onChangeDebugModeDropdown={onChangeDebugModeDropdown}
          />
        ) : (
          <ChartViewDropdown view={view} onChangeView={onChangeView} />
        )}
      </div>
    </div>
  );
};

export default TimeGridHeader;

import { useState, useEffect } from 'react';
import PracticePanel from './PracticePanel/PracticePanel';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import useAllClinic, { IClinicResponse } from 'hooks/useAllClinic';
import ViewOrEditPractice from './ViewOrEditPractice/ViewOrEditPractice';
import EmptyContent from 'components/EmptyContent/EmptyContent';
import CreatePracticeModal from './CreatePracticeModal/CreatePracticeModal';

const AdminPracticePage = () => {
  const [selectedPractice, setSelectedPractice] =
    useState<IClinicResponse | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: practices, isLoading } = useAllClinic();

  const getTotalPracticeByStatus = () => {
    if (!practices) return null;

    const totalActivePractice = practices.data.filter(
      (practice) => practice.isActive
    ).length;

    const totalInactivePractice = practices.data.filter(
      (practice) => practice.isActive === false
    ).length;

    return {
      active: totalActivePractice,
      inactive: totalInactivePractice,
      all: practices.metadata.total,
    };
  };

  useEffect(() => {
    if (!practices || practices.metadata.total === 0) return;
    if (selectedPractice) return;

    setSelectedPractice(practices.data[0]);
  }, [practices, selectedPractice]);

  if (isLoading) {
    return (
      <LoadingSpinner className="mx-auto mt-4.8 w-4.8 h-4.8 text-magenta" />
    );
  }

  if (practices?.metadata.total === 0) {
    return (
      <>
        {isModalOpen && (
          <CreatePracticeModal
            onClose={() => setIsModalOpen(false)}
            setSelectedPractice={setSelectedPractice}
          />
        )}
        <EmptyContent
          labelFor="practice"
          onClickAdd={() => setIsModalOpen(true)}
        />
      </>
    );
  }

  return (
    selectedPractice && (
      <div className="flex">
        <PracticePanel
          practices={practices!.data}
          selectedPractice={selectedPractice}
          setSelectedPractice={setSelectedPractice}
          totalPracticeByStatus={getTotalPracticeByStatus()}
        />
        <ViewOrEditPractice
          key={`${selectedPractice.id}${selectedPractice.updatedAt}`}
          selectedPractice={selectedPractice}
          setSelectedPractice={setSelectedPractice}
        />
      </div>
    )
  );
};

export default AdminPracticePage;

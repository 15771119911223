import { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBlocker } from 'hooks/useBlocker';
import LeavePageModal from 'components/LeavePageModal/LeavePageModal';

interface NavigationBlockerProps {
  when: boolean;
}

const NavigationBlocker: FC<NavigationBlockerProps> = ({ when }) => {
  const [isLeavePage, setIsLeavePage] = useState(false);
  const locationCatchedRef = useRef('');
  const navigate = useNavigate();

  useBlocker((tx: any) => {
    setIsLeavePage(true);
    locationCatchedRef.current = tx.location.pathname;
  }, when && !locationCatchedRef.current);

  return isLeavePage ? (
    <LeavePageModal
      onClose={() => {
        setIsLeavePage(false);
        locationCatchedRef.current = '';
      }}
      onSubmit={() => {
        navigate(locationCatchedRef.current);
      }}
    />
  ) : null;
};

export default NavigationBlocker;

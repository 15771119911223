import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import CommonButton from 'components/CommonButton/CommonButton';
import CardContainer from 'components/Layout/CardContainer/CardContainer';
import TextField from 'components/TextField/TextField';

import { ROUTES } from 'utils/constants/routes';
import { isValidEmail } from 'utils/validations';

interface FormInputProps {
  onSubmit: (data: { email: string }) => void;
}

const FormInput: FC<FormInputProps> = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    values: { email: '' },
  });

  return (
    <CardContainer title="Forgot Password">
      <form className="pt-2 px-3 py-4.6" onSubmit={handleSubmit(onSubmit)}>
        <p className="mb-2">
          Have trouble remembering your FirstIn password? Please enter your
          email address below. We will send you a link to reset your password.
        </p>
        <TextField
          id="email"
          control={control}
          label="Email address"
          placeholder="Input your email address"
          rules={{
            required: 'This field is required',
            validate: (value) => {
              if (!isValidEmail(value)) return 'Invalid email address';
            },
          }}
        />
        <div className="flex flex-col items-center mt-2.5 gap-y-2.5">
          <CommonButton
            className="w-[18rem]"
            disabled={!isValid}
            isLoading={isSubmitting}
            type="submit"
          >
            Reset password
          </CommonButton>
          <Link to={ROUTES.LOGIN} className="font-bold text-magenta underline">
            Back to Sign in
          </Link>
        </div>
      </form>
    </CardContainer>
  );
};

export default FormInput;

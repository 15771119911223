import moment from 'moment';

type DayOfWeek = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

type WorkingHour = {
  [key in DayOfWeek]: { start: string | null; end: string | null }[];
};

const extractGoogleClinicSchedule = (place: google.maps.places.PlaceResult) => {
  const reviews = place.reviews
    ? place.reviews.map((review) => ({
        content: review.text,
        authorName: review.author_name,
        authorPhotoUrl: review.profile_photo_url,
        timestamp: review.time,
        rating: review.rating,
      }))
    : [];

  const workingHour: WorkingHour = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  let dayOfWeek: DayOfWeek[] = [
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ];

  place.opening_hours!.periods!.forEach((period) => {
    const day = moment(period.open.day, 'e')
      .format('ddd')
      .toLowerCase() as keyof typeof workingHour;

    const slot = {
      start: moment(period.open.time, 'HHmm').format('HH:mm:ss'),
      end: moment(period.close?.time, 'HHmm').format('HH:mm:ss'),
    };

    workingHour[day]!.push(slot);
    dayOfWeek = dayOfWeek.filter((d) => d !== day);
  });

  dayOfWeek.forEach((day) => {
    workingHour[day].push({ start: null, end: null });
  });

  const data = {
    rating: place.rating || 0,
    reviewCount: place.user_ratings_total || 0,
    googlePlaceId: place.place_id,
    reviews,
    workingHour,
  };
  return data;
};

export default extractGoogleClinicSchedule;

import { useState } from 'react';
import useSync from 'hooks/useSync';

import CommonButton from 'components/CommonButton/CommonButton';
import axiosInstance from 'apis/axiosInstance';
import SyncStatus from '../../SyncStatus/SynStatus';
import { SYNC_STATUS } from 'utils/constants';
import LatestSyncMessage from '../LatestSyncMessage/LatestSyncMessage';
import { renderToast } from 'components/Toast/Toast';
import { useUser } from 'context/userContext';
import getIsSyncDisabled from 'pages/SyncPage/util/getIsSyncDisabled';
import InfoTooltip from 'pages/SyncPage/InfoTooltip/InfoTooltip';

const PractitionerSection = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { user } = useUser();

  const { data, isLoading, mutate } = useSync();

  const isSyncDisabled = getIsSyncDisabled(
    data?.practitioner,
    !!user.globalAdminRole
  );

  const handleUpdateOperatory = async () => {
    setIsButtonLoading(true);
    try {
      const response = await axiosInstance.get('/sync/practitioners');
      const numberOfPractitioners = response.data.data.length;
      const isNewPractitionersObtained = numberOfPractitioners > 0;
      renderToast({
        message: isNewPractitionersObtained
          ? `Successfully obtained ${numberOfPractitioners} practitioners`
          : response.data.message,
        type: isNewPractitionersObtained ? 'success' : 'info',
      });
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    await mutate();
    setIsButtonLoading(false);
  };

  return (
    <div className="py-2 border-b border-light-grey">
      <div className="flex justify-between">
        <div className="basis-[45rem]">
          <div className="flex gap-x-0.9">
            <h3 className="font-bold">
              Get latest active practitioners from the Connector
            </h3>

            <InfoTooltip id={'practitioner'} display={isSyncDisabled} />
          </div>
          <p className="mt-2">
            Click the Update button to get the latest active practitioners from
            the Connector
          </p>
        </div>
        <div className="flex flex-col items-end">
          <CommonButton
            variant="secondary"
            className="!min-h-[3.2rem]"
            onClick={handleUpdateOperatory}
            isLoading={isButtonLoading}
            disabled={
              data?.practitioner?.status === SYNC_STATUS.IN_PROGRESS ||
              isLoading ||
              isSyncDisabled
            }
          >
            Update
          </CommonButton>
        </div>
      </div>
      <div className="flex flex-col gap-y-0.8 mt-2">
        <SyncStatus
          isLoading={isLoading}
          data={{
            ...data?.practitioner,
            status: isButtonLoading
              ? SYNC_STATUS.IN_PROGRESS
              : data?.practitioner?.status,
          }}
        />
        <LatestSyncMessage
          isLoading={isLoading}
          lastSyncAt={data?.practitioner?.lastSyncAt}
          lastSuccessSyncAt={data?.practitioner?.lastSuccessSyncAt}
          isUpdateMessage
        />
      </div>
    </div>
  );
};

export default PractitionerSection;

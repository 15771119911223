import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as SettingIcon } from 'assets/icons/settings.svg';

import styles from './SidebarLink.module.css';
import { ADMIN_ROUTES, ROUTES } from 'utils/constants/routes';

interface SettingButtonProps {
  isCollapsed: boolean;
  isGlobalAdminPage: boolean;
}

const SettingButton: FC<SettingButtonProps> = ({
  isCollapsed,
  isGlobalAdminPage,
}) => {
  const navigate = useNavigate();
  return (
    <button
      className="text-14 flex gap-x-1 overflow-hidden items-center h-4 pl-1.2 mt-2"
      onClick={() => {
        navigate(
          isGlobalAdminPage ? ADMIN_ROUTES.USER : ROUTES.OFFICE_SCHEDULE
        );
      }}
    >
      <div data-for={'settings'} data-tip="" className="flex shrink-0">
        <SettingIcon />
      </div>
      {isCollapsed ? (
        <ReactTooltip
          id={'settings'}
          place="right"
          effect="solid"
          className={`${styles.tooltip} shadow-tooltip`}
        >
          <span className="text-11 text-darkest-grey flex h-full items-center">
            Settings
          </span>
        </ReactTooltip>
      ) : (
        <span className={`flex ${isCollapsed && 'opacity-0'}`}>Settings</span>
      )}
    </button>
  );
};

export default SettingButton;

import { useLocation } from 'react-router-dom';

const useAppointmentSearchParam = () => {
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const errorReason = urlSearchParams.get('errorReason');
  const appointmentId = urlSearchParams.get('appointmentId');
  const clinicId = urlSearchParams.get('clinicId');
  const action = urlSearchParams.get('action');

  const isActionParamCombinationValid = appointmentId && clinicId && action;
  const isErrorParamCombinationValid = appointmentId && clinicId && errorReason;

  return {
    isActionParamCombinationValid,
    isErrorParamCombinationValid,
    params: { errorReason, appointmentId, clinicId, action },
    search: location.search,
  };
};

export default useAppointmentSearchParam;

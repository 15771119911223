import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as TriangleDownIcon } from 'assets/icons/triangle-down.svg';
import defaultAvatar from 'assets/icons/avatar-empty-circle.svg';
import logoIcon from 'assets/icons/Logo-small.svg';
import { ClinicUserRole, GlobalAdminRole } from 'context/userContext';
import { getLocationName } from './utils';
import styles from './MenuButton.module.css';

const MenuButton = ({
  currentClinicUserRole,
  globalAdminRole,
  isCollapsed,
}: {
  currentClinicUserRole: ClinicUserRole | null;
  globalAdminRole: GlobalAdminRole | null;
  isCollapsed: boolean;
}) => {
  let imgSrc = 'invalid-link';
  let name = 'invalid-name';
  let location = '';
  let isCurrentGlobalAdminBtn = false;

  if (currentClinicUserRole) {
    imgSrc = currentClinicUserRole.clinic.avatar || '';
    name = currentClinicUserRole.clinic.name;
    location = getLocationName(currentClinicUserRole.clinic.location);
    isCurrentGlobalAdminBtn = false;
  } else if (globalAdminRole) {
    imgSrc = logoIcon;
    name = 'Global Admin';
    isCurrentGlobalAdminBtn = true;
  }

  if (!isCollapsed) {
    return (
      <>
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center flex-1 w-[70%]">
            <div
              style={{ minWidth: '3rem' }}
              className={`w-3 h-3 mr-0.8 rounded-full ${
                isCurrentGlobalAdminBtn
                  ? 'bg-white border border-light-grey p-0.5'
                  : ''
              }`}
            >
              <img
                src={imgSrc}
                alt={name}
                className={
                  isCurrentGlobalAdminBtn ? '' : 'rounded-full h-full w-full'
                }
                onError={(event) => {
                  event.currentTarget.src = defaultAvatar;
                }}
              />
            </div>

            <div className="truncate text-black text-left">
              <div className="font-bold text-black truncate">{name}</div>
              <div className="text-lightBlack truncate text-11">{location}</div>
            </div>
          </div>

          <div>
            <TriangleDownIcon />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className={`${
            isCurrentGlobalAdminBtn
              ? 'bg-white border border-light-grey p-0.5 rounded-full w-3 h-3'
              : ''
          }`}
        >
          <img
            src={imgSrc}
            alt={name}
            data-for={
              isCurrentGlobalAdminBtn
                ? 'global-admin'
                : currentClinicUserRole?.clinic.id
            }
            className={isCurrentGlobalAdminBtn ? '' : 'w-3 h-3 rounded-full'}
            data-tip=""
            onError={(event) => {
              event.currentTarget.src = defaultAvatar;
            }}
          />
        </div>

        <ReactTooltip
          id={
            isCurrentGlobalAdminBtn
              ? 'global-admin'
              : currentClinicUserRole?.clinic.id
          }
          place="right"
          effect="solid"
          className={`${styles.tooltip} shadow-tooltip`}
        >
          <div className="text-11 text-darkest-grey flex h-full items-center">
            {name}
          </div>
        </ReactTooltip>
      </>
    );
  }
};

export default MenuButton;
